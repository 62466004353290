import { Button } from '@chakra-ui/react';
import {
  Card,
  Modal,
  Divider,
  ButtonRow,
  TextAreaInput,
  SlideToggle,
} from '@himarley/unity';
import React, { useReducer, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { showSnackbar } from '@app/actions/notification';
import RadioButton from '@app/components/elements/RadioButton/RadioButton';
import { DISABLE_MARLEY_OPT_IN } from '@app/constants/permissions';
import { useCheckPermissions } from '@app/helpers/common';
import { capitalizeEachWord } from '@app/helpers/format';
import type { MessageTemplate, MessageTemplateKey } from '@app/types/message-template';
import LightBulb from '@images/icons/lightbulb.svg';

import {
  ConfigOptInStatus, KEYS, LEGAL_MESSAGE_TOKEN, MODAL_ACTIONS,
  MOID_LEGAL_MESSAGE_TOKEN, MOID_LEGAL_MESSAGE, MODAL_RADIO_BUTTON_VALUES_OPT_IN, LEGAL_MESSAGE,
} from './constants';
import { getPreviewMessage } from './helpers';
import MessageInput from './message-input';
import PreviewMessages from './preview-messages';
import styles from './styles/welcome-flow-settings.module.scss';

const BEFORE_LEGAL = 'BEFORE_LEGAL';
const AFTER_LEGAL = 'AFTER_LEGAL';

/**
 * NumberLine component to display a vertical line with a number.
 * @param {number} number - The number to display.
 * @returns {JSX.Element} - The rendered NumberLine component.
 */
const NumberLine = ({ number }: { number: number }) => (
  <div className={styles.numberLine}>
    <div className={styles.number}>{number}</div>
    <div className={styles.verticalLine} />
  </div>
);

/**
 * Tip component to display a tip message with a light bulb icon.
 * @returns {JSX.Element} - The rendered Tip component.
 */
const Tip = () => (
  <div className="tipWrap">
    <LightBulb />
    {' '}
    TIP: Type the &apos;$&apos; symbol to insert a message template variable.
  </div>
);

interface WelcomeFlowSettingsModalProps {
  caseType: string;
  optStatus: string;
  caseAssignment: string;
  messages: MessageTemplate[];
  show: boolean;
  hideModal: () => void;
  tokenOverrideMap?: { [key: string]: string };
  handleSave: (
    messages: MessageTemplate[],
  ) => Promise<unknown[]>;
}

interface State {
  previewOption: string;
  messages: MessageTemplate[];
  showEndingMessage: boolean;
  isSaving: boolean;
  isDirty: boolean;
  tokenOverrideMap?: { [key: string]: string };
}

type Action =
  | { type: typeof MODAL_ACTIONS.SET_MESSAGES; payload: MessageTemplate[] }
  | { type: typeof MODAL_ACTIONS.SET_PREVIEW_OPTION; payload: string }
  | { type: typeof MODAL_ACTIONS.SET_SHOW_ENDING_MESSAGE; payload: boolean }
  | { type: typeof MODAL_ACTIONS.TOGGLE_OPTIONAL_FOLLOW_UP }
  | { type: typeof MODAL_ACTIONS.SET_IS_SAVING; payload: boolean }
  | { type: typeof MODAL_ACTIONS.SET_IS_DIRTY; payload: boolean }
  | { type: typeof MODAL_ACTIONS.SET_TOKEN_OVERRIDE_MAP; payload: { [key: string]: string } }
  | {
    type: typeof MODAL_ACTIONS.UPDATE_MESSAGE;
    payload: {
      value: string;
      key: MessageTemplateKey;
      placement?: string;
      assignment?: string;
      legalMessageToken?: string;
    };
  };

/**
 * Reducer function to handle state updates.
 * @param {State} state - The current state.
 * @param {Action} action - The action to update the state.
 *
 * @returns {State} - The updated state.
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case MODAL_ACTIONS.SET_MESSAGES:
      return { ...state, messages: action.payload };
    case MODAL_ACTIONS.SET_PREVIEW_OPTION:
      return { ...state, previewOption: action.payload };
    case MODAL_ACTIONS.SET_SHOW_ENDING_MESSAGE:
      return { ...state, showEndingMessage: action.payload };
    case MODAL_ACTIONS.TOGGLE_OPTIONAL_FOLLOW_UP: {
      const newMessages = state.messages.map((message) => {
        let updatedMessage = { ...message };
        if (
          message[KEYS.FOLLOWUP_MESSAGE]
        ) {
          updatedMessage = { ...message, enabled: !message.enabled };
        }
        return updatedMessage;
      });

      return {
        ...state,
        messages: newMessages,
        isDirty: true,
      };
    }
    case MODAL_ACTIONS.SET_IS_SAVING:
      return { ...state, isSaving: action.payload };
    case MODAL_ACTIONS.SET_IS_DIRTY:
      return { ...state, isDirty: action.payload };
    case MODAL_ACTIONS.SET_TOKEN_OVERRIDE_MAP:
      return { ...state, tokenOverrideMap: action.payload };
    case MODAL_ACTIONS.UPDATE_MESSAGE: {
      const {
        value,
        key,
        placement,
        assignment,
        legalMessageToken,
      } = action.payload;
      const newMessages = [...state.messages];
      const index = newMessages.findIndex(
        (message) => message[key] && (message.caseAssignment === assignment || !assignment),
      );
      const splitMessage = newMessages[index]?.rawMessage?.split(
        legalMessageToken || LEGAL_MESSAGE_TOKEN,
      ) || [];

      if (placement === BEFORE_LEGAL) {
        splitMessage[0] = value;
      } else if (placement === AFTER_LEGAL) {
        splitMessage[1] = value;
      }

      const updatedMessage = placement ? splitMessage.join(legalMessageToken) : value;

      newMessages.splice(index, 1, {
        ...newMessages[index],
        previewMessage: getPreviewMessage(
          updatedMessage,
          state.tokenOverrideMap,
        ),
        rawMessage: updatedMessage,
      });

      return { ...state, messages: newMessages, isDirty: true };
    }
    default:
      return state;
  }
};

const initialState: State = {
  previewOption: KEYS.ASSIGNED,
  messages: [],
  showEndingMessage: false,
  isSaving: false,
  isDirty: false,
};

/**
 * WelcomeFlowSettingsModal component to manage and display settings for welcome flow messages.
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.caseType - The type of case (e.g., CLAIM, CASE).
 * @param {string} props.optStatus - The opt-in status of the customer.
 * @param {string} props.caseAssignment - The assignment status of the case.
 * @param {MessageTemplate[]} props.messages - The list of initial message templates.
 * @param {boolean} props.show - Flag to show or hide the modal.
 * @param {Function} props.hideModal - Function to hide the modal.
 * @param {{ [key: string]: string }} [props.tokenOverrideMap] - Optional map for token overrides.
 * @param {Function} props.handleSave - Function to handle saving the messages.
 *
 * @returns {JSX.Element} - The rendered WelcomeFlowSettingsModal component.
 */
const WelcomeFlowSettingsModal: React.FC<WelcomeFlowSettingsModalProps> = ({
  caseType,
  optStatus,
  caseAssignment,
  messages: initialMessages,
  show,
  hideModal,
  tokenOverrideMap,
  handleSave,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    tokenOverrideMap,
  });
  const reduxDispatch = useDispatch();
  const isDisableMarleyOptIn = useCheckPermissions([DISABLE_MARLEY_OPT_IN]);

  useEffect(() => {
    dispatch({ type: MODAL_ACTIONS.SET_MESSAGES, payload: initialMessages });
  }, [initialMessages]);

  useEffect(() => {
    dispatch({ type: MODAL_ACTIONS.SET_PREVIEW_OPTION, payload: caseAssignment });
  }, [caseAssignment]);

  useEffect(() => {
    const initialMessage = state.messages.find(
      (message) => message[KEYS.INITIAL_MESSAGE],
    );
    const legalMessageToken = isDisableMarleyOptIn ? MOID_LEGAL_MESSAGE_TOKEN : LEGAL_MESSAGE_TOKEN;
    const firstMessageSplit = initialMessage?.rawMessage?.split(legalMessageToken);
    if (
      firstMessageSplit
      && firstMessageSplit.length > 1
      && firstMessageSplit[1] !== ''
    ) {
      dispatch({ type: MODAL_ACTIONS.SET_SHOW_ENDING_MESSAGE, payload: true });
    }
  }, [state.messages, isDisableMarleyOptIn]);

  /**
   * Handles setting the messages state.
   * @param {Object} params - The parameters for setting messages.
   * @param {string} params.value - The new message value.
   * @param {MessageTemplateKey} params.key - The key of the message to update.
   * @param {string} [params.placement] - The placement of the message (BEFORE_LEGAL or AFTER_LEGAL)
   * @param {string} [params.assignment] - The assignment of the message.
   *
   * @returns {void}
   */
  const handleSetMessages = ({
    value,
    key,
    placement,
    assignment,
    legalMessageToken,
  }: {
    value: string;
    key:
    | typeof KEYS.INITIAL_MESSAGE
    | typeof KEYS.CONFIRMATION_MESSAGE
    | typeof KEYS.FOLLOWUP_MESSAGE;
    placement?: string;
    assignment?: string;
    legalMessageToken?: string
  }) => {
    dispatch({
      type: MODAL_ACTIONS.UPDATE_MESSAGE,
      payload: {
        value,
        key,
        placement,
        assignment,
        legalMessageToken,
      },
    });
  };

  const alphaCaseType = capitalizeEachWord(caseType.toLowerCase());

  /**
   * Handles saving the messages.
   */
  const handleSaveMessages = async () => {
    dispatch({ type: MODAL_ACTIONS.SET_IS_SAVING, payload: true });
    await handleSave(state.messages).catch((err) => {
      dispatch({ type: MODAL_ACTIONS.SET_IS_DIRTY, payload: false });
      reduxDispatch(
        showSnackbar({
          text: err.message || 'Something went wrong',
          isError: true,
        }),
      );
    });
    hideModal();
    dispatch({ type: MODAL_ACTIONS.SET_IS_SAVING, payload: false });
  };

  const showOptionalFollowUp = state.messages.some(
    (message) => message[KEYS.FOLLOWUP_MESSAGE] && message.enabled,
  );
  const optedIn = optStatus === KEYS.OPTED_IN;
  const previewMessages = state.messages.filter((message) => {
    const matchesCaseAssignment = [
      state.previewOption,
      KEYS.ANY,
    ].includes(message.caseAssignment || KEYS.ANY);
    const isNotFollowUp = !message[KEYS.FOLLOWUP_MESSAGE];
    return (
      matchesCaseAssignment
      && (showOptionalFollowUp || isNotFollowUp)
    );
  });

  const assignedMessage = optedIn ? state.messages.find(
    (message) => message[KEYS.INITIAL_MESSAGE]
      && message.caseAssignment === KEYS.ASSIGNED,
  )?.rawMessage
    : state.messages.find(
      (message) => message[KEYS.CONFIRMATION_MESSAGE]
        && message.caseAssignment === KEYS.ASSIGNED,
    )?.rawMessage
    || '';

  const unassignedMessage = optedIn ? state.messages.find(
    (message) => message[KEYS.INITIAL_MESSAGE]
      && message.caseAssignment === KEYS.UNASSIGNED,
  )?.rawMessage
    : state.messages.find(
      (message) => message[KEYS.CONFIRMATION_MESSAGE]
        && message.caseAssignment === KEYS.UNASSIGNED,
    )?.rawMessage
    || '';

  return (
    <Modal
      show={show}
      showCloseButton
      title={isDisableMarleyOptIn ? 'Welcome Message' : `Customer ${ConfigOptInStatus[optStatus]}`}
      className={`confirmation-modal ${styles.optInModal}`}
      toggleModal={hideModal}
      backdrop
      size="xl"
    >
      <div className={styles.instructionText}>
        <span>Required fields labeled with an asterisk.</span>
        <RadioButton
          currentValue={state.previewOption}
          values={MODAL_RADIO_BUTTON_VALUES_OPT_IN}
          setCheckedValue={(value) => dispatch(
            { type: MODAL_ACTIONS.SET_PREVIEW_OPTION, payload: value },
          )}
          isHorizontal
          disabled={false}
        />
      </div>
      <section
        data-testid="welcome-flow-settings-modal"
        className={styles.modalBody}
      >
        <section className={styles.topContent}>
          <section className={styles.modalLeftSection}>
            {!optedIn && (
              <div className={styles.numberLineCardWrap}>
                <NumberLine number={1} />
                <Card>
                  <h2>Opt-In Request</h2>
                  <p>
                    Customers who have not opted in will receive this text
                    message for all new
                    {' '}
                    {caseType.toLowerCase()}
                    s.
                  </p>
                  <MessageInput
                    label="Beginning Message Body"
                    maxLength={1000}
                    value={
                      state.messages
                        .find((message) => message[KEYS.INITIAL_MESSAGE])
                        ?.rawMessage?.split(LEGAL_MESSAGE_TOKEN)[0].replace(/\n\n$/, '') || ''
                    }
                    setValue={(value) => handleSetMessages({
                      value,
                      key: KEYS.INITIAL_MESSAGE,
                      placement: BEFORE_LEGAL,
                      legalMessageToken: LEGAL_MESSAGE_TOKEN,
                    })}
                    testId="beginningMessageInput"
                    allowResize
                  />
                  <Tip />
                  <TextAreaInput
                    value={LEGAL_MESSAGE}
                    onChange={() => { }}
                    onKeyDown={() => { }}
                    autoFocus
                    label="Legal Message"
                    disabled
                    required
                    topRightLabel="Legal message is uneditable."
                    testId="legalMessageInput"
                  />
                  {state.showEndingMessage ? (
                    <>
                      <MessageInput
                        label="Ending Message Body"
                        maxLength={200}
                        value={
                          state.messages
                            .find((message) => message[KEYS.INITIAL_MESSAGE])
                            ?.rawMessage?.split(LEGAL_MESSAGE_TOKEN)[1].replace(/^\n\n/, '') || ''
                        }
                        setValue={(value) => handleSetMessages({
                          value,
                          key: KEYS.INITIAL_MESSAGE,
                          placement: AFTER_LEGAL,
                          legalMessageToken: LEGAL_MESSAGE_TOKEN,
                        })}
                        testId="endingMessageInput"
                        allowResize
                      />
                      <Tip />
                    </>
                  ) : (
                    <button
                      className={styles.optionalEndingBtn}
                      onClick={() => dispatch({
                        type: MODAL_ACTIONS.SET_SHOW_ENDING_MESSAGE,
                        payload: !state.showEndingMessage,
                      })}
                      type="button"
                      data-testid="optionalEndingMessageButton"
                    >
                      + Add Optional Ending Message Body
                    </button>
                  )}
                </Card>
              </div>
            )}
            <div className={styles.numberLineCardWrap}>
              {!optedIn && <NumberLine number={2} />}
              <Card>
                <h2>
                  {optedIn ? `New ${alphaCaseType}` : 'Opt-In'}
                  {' '}
                  Confirmation
                </h2>
                <p>
                  {optedIn
                    ? `The following messages will send when a new ${caseType.toLowerCase()} is opened${isDisableMarleyOptIn ? '' : ' and the customer has previously opted in'}.`
                    : 'When customers opt-in by responding YES they will get one of the following auto-reply text messages'}
                </p>
                <h3>{`Assigned ${alphaCaseType}s`}</h3>
                <MessageInput
                  label={`Assigned ${alphaCaseType} - Message Body`}
                  maxLength={isDisableMarleyOptIn ? 1000 : 1400}
                  required
                  value={
                    isDisableMarleyOptIn ? (assignedMessage || '').split(MOID_LEGAL_MESSAGE_TOKEN)[0].replace(/\n\n$/, '') : assignedMessage
                  }
                  setValue={(value) => handleSetMessages({
                    value,
                    key: optedIn
                      ? KEYS.INITIAL_MESSAGE
                      : KEYS.CONFIRMATION_MESSAGE,
                    assignment: KEYS.ASSIGNED,
                    placement: isDisableMarleyOptIn ? BEFORE_LEGAL : undefined,
                    legalMessageToken: MOID_LEGAL_MESSAGE_TOKEN,
                  })}
                  testId="assignedCaseMessageInput"
                  allowResize
                />
                <Tip />
                <h3>{`Unassigned ${alphaCaseType}s`}</h3>
                <MessageInput
                  label={`Unassigned ${alphaCaseType} - Message Body`}
                  maxLength={isDisableMarleyOptIn ? 1000 : 1400}
                  required
                  value={
                    isDisableMarleyOptIn ? (unassignedMessage || '').split(MOID_LEGAL_MESSAGE_TOKEN)[0].replace(/\n\n$/, '') : unassignedMessage
                  }
                  setValue={(value) => handleSetMessages({
                    value,
                    key: optedIn
                      ? KEYS.INITIAL_MESSAGE
                      : KEYS.CONFIRMATION_MESSAGE,
                    assignment: KEYS.UNASSIGNED,
                    placement: isDisableMarleyOptIn ? BEFORE_LEGAL : undefined,
                    legalMessageToken: MOID_LEGAL_MESSAGE_TOKEN,
                  })}
                  testId="unassignedCaseMessageInput"
                  allowResize
                />
                <Tip />
                {isDisableMarleyOptIn && (
                  <>
                    <h3>Legal Message</h3>
                    <TextAreaInput
                      value={MOID_LEGAL_MESSAGE}
                      onChange={() => { }}
                      onKeyDown={() => { }}
                      autoFocus
                      label="Legal Message"
                      disabled
                      required
                      topRightLabel="Legal message is uneditable."
                      testId="legalMessageInput"
                    />
                  </>
                )}
                {isDisableMarleyOptIn && state.showEndingMessage && (
                  <>
                    <h3>Ending Message</h3>
                    <MessageInput
                      label="Ending Message Body"
                      maxLength={200}
                      value={
                        state.messages
                          .find((message) => message[KEYS.INITIAL_MESSAGE])
                          ?.rawMessage?.split(MOID_LEGAL_MESSAGE_TOKEN)[1].replace(/^\n\n/, '') || ''
                      }
                      setValue={(value) => {
                        handleSetMessages({
                          value,
                          key: KEYS.INITIAL_MESSAGE,
                          placement: AFTER_LEGAL,
                          assignment: KEYS.ASSIGNED,
                          legalMessageToken: MOID_LEGAL_MESSAGE_TOKEN,
                        });
                        handleSetMessages({
                          value,
                          key: KEYS.INITIAL_MESSAGE,
                          placement: AFTER_LEGAL,
                          assignment: KEYS.UNASSIGNED,
                          legalMessageToken: MOID_LEGAL_MESSAGE_TOKEN,
                        });
                      }}
                      testId="endingMessageInput"
                      allowResize
                    />
                    <Tip />
                  </>
                )}
                {isDisableMarleyOptIn && !state.showEndingMessage && (
                  <button
                    className={styles.optionalEndingBtn}
                    onClick={() => dispatch({
                      type: MODAL_ACTIONS.SET_SHOW_ENDING_MESSAGE,
                      payload: !state.showEndingMessage,
                    })}
                    type="button"
                    data-testid="optionalEndingMessageButton"
                  >
                    + Add Optional Ending Message Body
                  </button>
                )}
              </Card>
            </div>
            {!optedIn && (
              <div className={styles.numberLineCardWrap}>
                <NumberLine number={3} />
                <Card>
                  <header>
                    <h2>Optional Follow Up</h2>
                    <SlideToggle
                      onToggle={() => dispatch({
                        type: MODAL_ACTIONS.TOGGLE_OPTIONAL_FOLLOW_UP,
                      })}
                      on={showOptionalFollowUp}
                      testId="optionalFollowUpMessageToggle"
                    />
                  </header>
                  <p>
                    You can configure one additional text message to auto-send
                    after the opt-in confirmation text message.
                  </p>
                  {showOptionalFollowUp && (
                    <>
                      <MessageInput
                        label="Message Body"
                        maxLength={1400}
                        value={
                          state.messages.find(
                            (message) => message[KEYS.FOLLOWUP_MESSAGE],
                          )?.rawMessage || ''
                        }
                        setValue={(value) => handleSetMessages({
                          value,
                          key: KEYS.FOLLOWUP_MESSAGE,
                        })}
                        testId="optionalFollowUpMessageInput"
                        allowResize
                      />
                      <Tip />
                    </>
                  )}
                </Card>
              </div>
            )}
          </section>
          <PreviewMessages
            className={styles.previewBoxModal}
            messages={previewMessages}
            isLoading={previewMessages.length === 0}
            previewLabel=""
          />
        </section>
        <div className={styles.messagesButtonRow}>
          <Divider />
          <ButtonRow type="positive">
            <Button onClick={hideModal} variant="outline">
              Cancel
            </Button>
            <Button
              disabled={state.isSaving || !state.isDirty}
              onClick={handleSaveMessages}
              data-testid={`save-${caseType.toLowerCase()}-messages`}
            >
              {state.isSaving ? 'Saving...' : 'Save Messages'}
            </Button>
          </ButtonRow>
        </div>
      </section>
    </Modal>
  );
};

export default WelcomeFlowSettingsModal;
