import {
  CaseTypeEnum,
  CaseConfig,
  TabPermissions,
} from '@app/types/create-case';

import { CASE_TYPE_CONFIG } from './constants';

/**
 * A flexible utility function that searches through case type configurations to find a matching case type.
 *
 * How it works:
 * 1. Takes a matching condition function that defines the search criteria
 * 2. Converts CASE_TYPE_CONFIG object into array of [type, config] pairs
 * 3. Searches through pairs to find first match using the condition
 * 4. Returns the matching case type or undefined if no match
 *
 * Example usage:
 * ```typescript
 * // Find case type by path
 * const caseType = findCaseTypeBy(config => config.path === '/claims');
 *
 * // Find case type by tab index
 * const caseType = findCaseTypeBy(config => config.tabIndex === 2);
 * ```
 *
 * @param matchCondition - Function that checks if a case config matches desired criteria
 * @returns The matching CaseTypeEnum or undefined if no match is found
 */
export const findCaseTypeBy = (
  matchCondition: (config: CaseConfig) => boolean,
): CaseTypeEnum | undefined => {
  // Convert CASE_TYPE_CONFIG object into array of [type, config] pairs
  const configEntries = Object.entries(CASE_TYPE_CONFIG);

  // Find first entry where config matches our condition
  // The _ is the case type string which we don't need in the condition check
  const matchingEntry = configEntries.find(([_, config]) => matchCondition(config));

  // If we found a match, return its case type (first element of the entry tuple)
  // Otherwise return undefined
  return matchingEntry ? (matchingEntry[0] as CaseTypeEnum) : undefined;
};

/**
 * Determines the default case type based on user permissions.
 *
 * Logic:
 * 1. Checks a prioritized list of case types in order: claim -> policy -> service
 * 2. Returns the first case type that the user has permission for
 * 3. If user has no permissions for any priority types, falls back to 'general'
 *
 * @param permissions - Object containing boolean flags for each case type permission
 * @returns The highest priority case type the user has permission for, or CaseTypeEnum.general as fallback
 */
export const getDefaultCaseType = (
  permissions: TabPermissions,
): CaseTypeEnum => {
  const priorityTypes = [
    CaseTypeEnum.claim,
    CaseTypeEnum.policy,
    CaseTypeEnum.service,
  ];
  return (
    priorityTypes.find((type) => permissions[type]) ?? CaseTypeEnum.general
  );
};

/**
 * Parameters for determining case type from URL path
 */
interface GetCaseTypeFromPathParams {
  /** The full URL pathname to extract case type from */
  pathname: string;
  /** Object containing permission flags for different case types */
  permissions: TabPermissions;
}

/**
 * Extracts and determines the case type and its corresponding tab index from a URL path.
 *
 * Logic:
 * 1. Splits the pathname into segments and finds a segment that matches a configured case path
 * 2. If a valid path segment is found (not 'cases'):
 *    - Uses findCaseTypeBy to get the corresponding case type
 * 3. If no valid path segment:
 *    - Falls back to getDefaultCaseType based on user permissions
 * 4. Always ensures a valid case type by falling back to 'general' if needed
 *
 * @param params - Object containing pathname and permissions
 * @returns Object with resolved case type and its corresponding tab index
 */
export const getCaseTypeFromPath = ({ pathname, permissions }: GetCaseTypeFromPathParams): {
  type: CaseTypeEnum;
  tabIndex: number;
} => {
  // Extract path segment that matches any configured case path
  // 1. Split URL into segments (e.g., '/cases/claims' -> ['', 'cases', 'claims'])
  // 2. Find first segment that matches a path in CASE_TYPE_CONFIG
  const pathSegment = pathname
    .split('/')
    .find((segment) => Object.values(CASE_TYPE_CONFIG).some((config) => config.path === segment));

  // Determine case type based on path segment
  // If we found a valid segment (not 'cases'), use it to look up case type
  // Otherwise fall back to default case type based on permissions
  const caseType = pathSegment && pathSegment !== 'cases'
    ? findCaseTypeBy((config) => config.path === pathSegment)
    : getDefaultCaseType(permissions);

  // Return both the case type and its tab index
  // Use nullish coalescing to ensure we always have a valid case type
  // Look up the tab index from the configuration using the resolved case type
  return {
    type: caseType ?? CaseTypeEnum.general,
    tabIndex: CASE_TYPE_CONFIG[caseType ?? CaseTypeEnum.general].tabIndex,
  };
};
