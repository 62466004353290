import { Icon, IconButton } from '@chakra-ui/react';
import { CirclePlus, SquarePen } from 'lucide-react';
import React, { useMemo, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router';

import { Button } from '@chakra-snippets/button';

import CreateCaseModal from './create-case-modal';
import useScreenSize from '../Hooks/use-screen-size';

interface CreateCaseButtonProps {
  buttonText?: string;
  size?: 'sm' | 'md' | 'lg';
}

const CreateCaseButton = ({ buttonText, size = 'md' }: CreateCaseButtonProps) => {
  const history = useNavigate();
  const location = useLocation();
  const isSmallScreen = useScreenSize();
  const isNavigatingRef = useRef(false);

  const handleCreateClick = useCallback(() => {
    if (isNavigatingRef.current) return;
    isNavigatingRef.current = true;

    const currentPath = location.pathname;
    if (!currentPath.endsWith('/create')) {
      history(`${currentPath}/create`);
    }

    setTimeout(() => {
      isNavigatingRef.current = false;
    }, 100);
  }, [location.pathname, history]);

  const shouldShowModal = useMemo(() => {
    const path = location.pathname;
    return path.includes('create') || path.includes('edit');
  }, [location.pathname]);

  if (isSmallScreen) {
    return (
      <>
        <IconButton
          data-testid="create-case-button"
          size={size}
          onClick={handleCreateClick}
          variant="ghost"
        >
          <SquarePen />
        </IconButton>
        {shouldShowModal && <CreateCaseModal />}
      </>
    );
  }

  return (
    <>
      <Button
        data-testid="create-case-button"
        size={size}
        onClick={handleCreateClick}
      >
        <Icon>
          <CirclePlus />
        </Icon>
        {buttonText || 'Create Case'}
      </Button>
      {shouldShowModal && <CreateCaseModal />}
    </>
  );
};

export default CreateCaseButton;
