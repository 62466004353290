import {
  Text,
} from '@chakra-ui/react';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteUser } from '@app/actions/users';
import AlertDialog from '@app/components/chakra/alert-dialog';
import { type User } from '@app/types/api/user';

interface DeleteOperatorProps {
  user?: User;
  toggleAlert: () => void;
  isOpen: boolean;
  toaster: unknown;
  status: string;
}

const DeleteOperator: React.FC<DeleteOperatorProps> = ({
  user,
  toggleAlert,
  isOpen,
  toaster,
  status,
}) => {
  const [isDeactivating, setIsDeactivating] = useState(false);
  type ThunkAppDispatch = ThunkDispatch<unknown, void, Action>;
  const dispatch:ThunkAppDispatch = useDispatch();

  const handleDelete = () => {
    setIsDeactivating(true);
    dispatch(deleteUser(user && user.id, toaster, status))?.finally(() => {
      setIsDeactivating(false);
      toggleAlert();
    });
  };
  return (
    <AlertDialog
      id="userdelete"
      title="Change Status?"
      isDialogOpen={isOpen}
      toggleDialog={toggleAlert}
      cancelText="Cancel"
      confirmText="Change"
      onConfirm={handleDelete}
      isDisabled={isDeactivating}
    >
      <Text lineClamp={3} data-testid="delete-user-text">
        Changing
        {' '}
        <Text as="b">{user && user.email}</Text>
        {' '}
        to deactivated will prevent the user from accessing Hi Marley.
      </Text>
    </AlertDialog>
  );
};

export default DeleteOperator;
