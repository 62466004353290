import { defineSlotRecipe } from '@chakra-ui/react';

export const alertRecipe = defineSlotRecipe({
  slots: ['indicator', 'title', 'container', 'root'],
  base: {
    indicator: {
      _icon: {
        h: '24px',
        w: '24px',
        color: 'unset',
        mr: '16px',
      },
    },
    title: {
      fontWeight: '600',
    },
    container: {
      pl: '16px',
      pr: '56px',
      fontSize: '16px',
    },
  },
  variants: {
    status: {
      info: {
        root: {
          colorPalette: 'blue',
        },
      },
      success: {
        root: {
          colorPalette: 'green',
        },
      },
      warning: {
        root: {
          colorPalette: 'orange',
        },
      },
      error: {
        root: {
          colorPalette: 'red',
        },
      },
      loading: {
        root: {
          colorPalette: 'blue',
        },
      },
    },
  },
});

export default alertRecipe;
