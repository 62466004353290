import {
  ButtonGroup, HStack, Icon, Text,
} from '@chakra-ui/react';
import { Info } from 'lucide-react';
import React, { useState } from 'react';

import {
  CONFIRMED_TOTAL_LOSS_STATUS, DENIED_TOTAL_LOSS_STATUS,
} from '@app/constants/jobState';
import { Button } from '@chakra-snippets/button';
import { Radio, RadioGroup } from '@chakra-snippets/radio';
import { Tooltip } from '@chakra-snippets/tooltip';

import styles from './TotalLossPicker.module.less';
import MarleyAssistantMessage from '../MarleyAssistantMessage/MarleyAssistantMessage';

interface TotalLossPickerProps {
  onDismiss: () => void;
  activeJobId: string;
  patchJob: (activeJobId: string, args: { totalLossStatus?: string }) => void;
}

const TotalLossPicker: React.FC<TotalLossPickerProps> = ({
  onDismiss,
  activeJobId,
  patchJob,
}) => {
  const [totalLossStatus, setTotalLossStatus] = useState<string | undefined>();

  const updateTotalLossStatus = () => {
    patchJob(activeJobId, { totalLossStatus });
  };

  const tooltip = (
    <>
      <div>Our prediction model identified:</div>
      <div>Towed Away</div>
      <div>Airbags</div>
      <div>Auto Collision</div>
      <div>Flat Tires</div>
      <div>Ditch</div>
      <div>T-boned</div>
    </>
  );

  return (
    <MarleyAssistantMessage>
      <div className={styles.box}>
        <div>
          <div className={styles.title}>Predicted Total Loss</div>
          <div className={styles.body}>
            Based on the information so far this claim might be a total loss.
          </div>
        </div>
      </div>
      <RadioGroup name="isTotalLossSelection">
        <HStack>
          <Radio
            value="totalLoss"
            onChange={() => setTotalLossStatus(CONFIRMED_TOTAL_LOSS_STATUS)}
          >
            Total Loss
          </Radio>
          <Radio
            value="notTotalLoss"
            onChange={() => setTotalLossStatus(DENIED_TOTAL_LOSS_STATUS)}
          >
            Not a Total Loss
          </Radio>
        </HStack>
      </RadioGroup>
      <HStack pt={2} justifyContent="flex-start">
        <ButtonGroup size="sm">
          <Button
            variant="outline"
            onClick={onDismiss}
          >
            Hide
          </Button>
          <Button
            onClick={updateTotalLossStatus}
          >
            Update Claim
          </Button>
        </ButtonGroup>
        <Text pl={1}>How do we predict this?</Text>
        <Tooltip content={tooltip} showArrow>
          <Icon>
            <Info />
          </Icon>
        </Tooltip>
      </HStack>
    </MarleyAssistantMessage>
  );
};

export default TotalLossPicker;
