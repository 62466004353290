import { defineSlotRecipe, defineStyle } from '@chakra-ui/react';

export const fieldSlotRecipe = defineSlotRecipe({
  slots: ['label'],
  base: {
    label: defineStyle({
      marginRight: 'unset !important',
      display: 'inherit !important',
      fontWeight: '400 !important',
      fontSize: '12px !important',
      color: 'gray.500 !important',
    }),
  },
});

export default fieldSlotRecipe;
