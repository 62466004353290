import React from 'react';

import { Button } from '@chakra-snippets/button';

type LockedChatInputProps = {
  message: React.ReactNode;
  unlockChat: () => void;
  isUnlockingChat: boolean;
  buttonText: string;
  buttonLoadingText: string;
};

const LockedChatInput: React.FC<LockedChatInputProps> = ({
  message,
  unlockChat,
  isUnlockingChat,
  buttonText,
  buttonLoadingText,
}) => (
  <div className="locked-chat-input-wrap">
    {message}
    <Button
      onClick={unlockChat}
      variant="outline"
      disabled={isUnlockingChat}
    >
      {isUnlockingChat ? buttonLoadingText : buttonText}
    </Button>
  </div>

);

export default LockedChatInput;
