import { defineSlotRecipe } from '@chakra-ui/react';
import { Table as BaseTable } from '@chakra-ui/theme/components';

export const tableSlotRecipe = defineSlotRecipe({
  className: 'table',
  slots: ['root', 'header', 'columnHeader', 'body', 'row', 'cell'],
  base: {
    root: {
      fontWeight: 500,
      color: 'gray.900',
    },
    header: {
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      zIndex: '1',
    },
    columnHeader: {
      ...BaseTable.baseStyle?.th,
      padding: '3 !important',
      fontSize: 'xs',
      border: '0',
      textTransform: 'none',
      color: 'blue.60',
      fontWeight: 500,
      _hover: {
        backgroundColor: 'gray.50',
        cursor: 'pointer',
      },
      borderBottom: '1px solid var(--chakra-colors-gray-200)',
      borderTop: '1px solid var(--chakra-colors-gray-200)',
    },
    cell: {
      padding: '3 !important',
    },
  },
});

export default tableSlotRecipe;
