export const META_DATA_DATE_OF_LOSS = '91135525-ec31-45b8-84cf-3529a594f780' as keyof ClaimMetaData;
export const META_DATA_POLICY = '038d0c94-d346-11ea-87d0-0242ac130003' as keyof ClaimMetaData & keyof PolicyMetaData;
export const META_DATA_GENERAL = 'a54f88b3-fee1-4216-8c6e-8b8aa5e83a4e' as keyof CaseMetaData;
export const META_DATA_CLAIM = 'f11560d8-0c09-11ea-8d71-362b9e155667' as keyof ClaimMetaData;
export const META_DATA_BRANDING = 'c54f88b2-fee1-4216-8c6e-7a7aa5e83a3e' as keyof BaseMetaData;
export const META_DATA_EFFECTIVE_DATE = '8ae3a30a-0011-4b2d-abf4-d248b6d47b89' as keyof PolicyMetaData;
export const META_DATA_EXPIRATION_DATE = '5935bd29-ad61-41b9-bfa3-1fbe8f99c88c' as keyof PolicyMetaData;
export const META_DATA_DEDUCTIBLE = '4c7a89a2-8ea1-4984-b5d4-43a05c1a4983' as keyof PolicyMetaData;
export const META_DATA_CLAIM_STATUS = '6f96f665-9c13-45a9-95a0-11e81088f85e' as keyof ClaimMetaData;
export const META_DATA_POLICY_NUMBER = 'e969484e-0c0a-11ea-8d71-362b9e155667' as keyof PolicyMetaData & keyof ClaimMetaData & keyof ServiceMetaData;
export const META_DATA_YEAR_BUILT = '2c21403e-22cc-4cc2-988b-28086b7e6c86' as keyof PolicyMetaData;
export const META_DATA_POLICY_TYPE = '270d571f-fe3d-4b7e-bfac-a974c0c669b6' as keyof PolicyMetaData;
export const META_DATA_ZIP_CODE = '753c54ce-fecb-4704-9d5a-f6d01dbbdb6c' as keyof PolicyMetaData;
export const META_DATA_CITY = '7540520d-834c-4fd9-99c6-37173a5f1351' as keyof PolicyMetaData;
export const META_DATA_STREET_ADDRESS_1 = 'c4eddfac-4243-4360-9941-18869c483cdb' as keyof PolicyMetaData;
export const META_DATA_STREET_ADDRESS_2 = 'a06af07d-0bd6-482d-9fc0-1ba6fe59c48e' as keyof PolicyMetaData;
export const META_DATA_STATE = 'f94b9981-9e26-40d5-b2db-4cbdaef467b4' as keyof PolicyMetaData;
export const META_DATA_CASE_NUMBER = 'b1a29248-1af9-407d-9840-cb707b25e64f' as keyof ServiceMetaData;

type MetadataReferenceId<M extends Metadata> =
  M extends PolicyMetaData ? typeof META_DATA_POLICY :
    M extends ClaimMetaData ? typeof META_DATA_CLAIM :
      M extends CaseMetaData ? typeof META_DATA_GENERAL :
        M extends ServiceMetaData ? typeof META_DATA_CASE_NUMBER :
          never;

const ReferenceIdToType = {
  [META_DATA_GENERAL]: 'CASE',
  [META_DATA_CLAIM]: 'CLAIM',
  [META_DATA_POLICY]: 'POLICY',
  [META_DATA_CASE_NUMBER]: 'SERVICE',
};

export enum CaseTypeEnum {
  claim = 'CLAIM',
  policy = 'POLICY',
  general = 'CASE',
  service = 'SERVICE',
}

export enum CaseTypeMetadataEnum {
  CLAIM = '75dd685b-b04b-4113-83e1-8c23f1149c11',
  businessClaim = '36ad685a-bc4b-5213-c3e1-8c28e4549c13',
  POLICY = '11f43fa7-df90-4da2-92b9-caae0d215323',
  CASE = '281443b2-b94d-4958-afe3-45e9b2799439',
  SERVICE = 'a6d4b02f-cac5-4cda-ad40-066e590694ab',
}

export enum CaseVisibilityEnum {
  public = 'public',
  privateRedacted = 'private-redacted',
  privateHidden = 'private-hidden',
}

export enum ContactCaseRoleEnum {
  claimant = 'Claimant',
  insured = 'Insured',
  'injured-worker' = 'Injured Worker',
  agent = 'Agent',
  other = 'Other',
}

interface BaseMetaData {
  META_DATA_BRANDING?: string;
}

interface PolicyMetaData extends BaseMetaData {
  META_DATA_POLICY_NUMBER: string;
}
interface ClaimMetaData extends BaseMetaData {
  META_DATA_POLICY_NUMBER: string;
  META_DATA_DATE_OF_LOSS: string;
  META_DATA_CLAIM_NUMBER: string;
}
interface CaseMetaData extends BaseMetaData {
  META_DATA_REFERENCE: string;
}
interface ServiceMetaData extends BaseMetaData {
  META_DATA_CASE_NUMBER: string;
  META_DATA_POLICY_NUMBER: string;
}

type Metadata = PolicyMetaData | ClaimMetaData | CaseMetaData | ServiceMetaData;

interface CaseData<M extends Metadata> {
  id: string;
  metadataReferenceId: MetadataReferenceId<M> & keyof M;
  creatorId: {
    _id: string;
    firstName: string;
    lastName: string;
  },
  marleyNumber: string;
  referenceId: string;
  metaData: M;
  type: typeof ReferenceIdToType[MetadataReferenceId<M>];
}

type Case = CaseData<CaseMetaData>;
type Policy = CaseData<PolicyMetaData>;
type Claim = CaseData<ClaimMetaData>;
type Service = CaseData<ServiceMetaData>;

export type ActiveCase = Case | Policy | Claim | Service;

export const isCase = (activeCase: ActiveCase): activeCase is Case => activeCase.type === 'CASE';
export const isPolicy = (activeCase: ActiveCase): activeCase is Policy => activeCase.type === 'POLICY';
export const isClaim = (activeCase: ActiveCase): activeCase is Claim => activeCase.type === 'CLAIM';
export const isService = (activeCase: ActiveCase): activeCase is Service => activeCase.type === 'SERVICE';

export interface Contact {
  id: string;
  profile: {
    firstName: string;
    lastName: string;
    email?: string;
    phoneNumber: string;
  }
  optIn: {
    status: string;
  }
  firstName?: string,
  lastName?: string,
  phoneNumber?: string,
  email?: string,
}

export interface IFormInput {
  id: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  contactZipCode?: string;
  contactCaseRole: keyof typeof ContactCaseRoleEnum;
  contactLanguage?: string;
  caseType: CaseTypeEnum;
  insuranceBrand: string;
  lineOfBusiness: string;
  assignedTo: string;
  caseVisibility: CaseVisibilityEnum;
  caseNumber?: string;
  claimNumber?: string;
  claimStatus?: string;
  policyNumber?: string;
  dateOfLoss?: string;
  deductible?: string;
  policyType?: string;
  effectiveDate?: string;
  expirationDate?: string;
  streetAddress1?: string;
  streetAddress2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  yearBuilt?: string;
  reference?: string;
}

export type EnabledTabs = Record<CaseTypeEnum, boolean>;

export type TabPermissions = Record<CaseTypeEnum, boolean>;

export interface TabConfig {
  enabledTabs: EnabledTabs;
  isServiceEnabled: boolean;
  isEditMode: boolean;
  initialCaseType: CaseTypeEnum;
}

// Add type definition for CaseConfig
export interface CaseConfig {
  path: string;
  modalTitle: string;
  orgSettingsTitle: string;
  tabIndex: number;
}
