import { useEffect, Dispatch, SetStateAction } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { type Job } from '@app/types/api/job';
import {
  CaseVisibilityEnum,
  ContactCaseRoleEnum,
  Contact,
  IFormInput,
  CaseTypeEnum,
} from '@app/types/create-case';

import { META_DATA_MAPPING } from '../constants';

type CaseData = Job | null;

interface UseCaseFormInitializationProps {
  isEditMode: boolean;
  caseData: CaseData;
  methods: UseFormReturn<IFormInput>;
  setIsPhoneNumberEntered: (value: boolean) => void;
  setSelectedContact: Dispatch<SetStateAction<Contact | null>>;
  caseType: CaseTypeEnum;
}

const TYPE_SPECIFIC_FORM_DATA: Record<CaseTypeEnum, (caseData: CaseData) => Partial<IFormInput>> = {
  [CaseTypeEnum.claim]: (caseData: CaseData) => ({
    claimNumber: caseData?.referenceId,
    claimStatus: caseData?.metaData?.[META_DATA_MAPPING.CLAIM.claimStatus],
    policyNumber: caseData?.metaData?.[META_DATA_MAPPING.CLAIM.policyNumber],
    dateOfLoss: caseData?.metaData?.[META_DATA_MAPPING.CLAIM.dateOfLoss],
    deductible: caseData?.metaData?.[META_DATA_MAPPING.CLAIM.deductible],
  }),
  [CaseTypeEnum.policy]: (caseData: CaseData) => ({
    policyNumber: caseData?.referenceId,
    deductible: caseData?.metaData?.[META_DATA_MAPPING.POLICY.deductible],
    policyType: caseData?.metaData?.[META_DATA_MAPPING.POLICY.policyType],
    effectiveDate: caseData?.metaData?.[META_DATA_MAPPING.POLICY.effectiveDate],
    expirationDate: caseData?.metaData?.[META_DATA_MAPPING.POLICY.expirationDate],
    streetAddress1: caseData?.metaData?.[META_DATA_MAPPING.POLICY.streetAddress1],
    streetAddress2: caseData?.metaData?.[META_DATA_MAPPING.POLICY.streetAddress2],
    city: caseData?.metaData?.[META_DATA_MAPPING.POLICY.city],
    state: caseData?.metaData?.[META_DATA_MAPPING.POLICY.state],
    zipCode: caseData?.metaData?.[META_DATA_MAPPING.POLICY.zipCode],
    yearBuilt: caseData?.metaData?.[META_DATA_MAPPING.POLICY.yearBuilt],
  }),
  [CaseTypeEnum.service]: (caseData: CaseData) => ({
    caseNumber: caseData?.referenceId,
    policyNumber: caseData?.metaData?.[META_DATA_MAPPING.SERVICE.policyNumber],
  }),
  [CaseTypeEnum.general]: (caseData: CaseData) => ({
    reference: caseData?.referenceId,
  }),
} as const;

const useCaseFormInitialization = ({
  isEditMode,
  caseData,
  methods,
  setIsPhoneNumberEntered,
  setSelectedContact,
  caseType,
}: UseCaseFormInitializationProps) => {
  useEffect(() => {
    if (!isEditMode || !caseData || !caseData.customer) return;

    setIsPhoneNumberEntered(true);
    const { customer } = caseData;

    setSelectedContact({
      id: customer.id || customer._id || '',
      profile: {
        firstName: customer.firstName || '',
        lastName: customer.lastName || '',
        email: customer.email || '',
        phoneNumber: customer.phoneNumber || '',
      },
      optIn: {
        status: customer.optIn?.status || '',
      },
    });

    const baseFormData: IFormInput = {
      id: customer.id || customer._id || '',
      contactCaseRole: (caseData.roleType?.toLowerCase() || 'insured') as keyof typeof ContactCaseRoleEnum,
      caseType: caseData.type as CaseTypeEnum,
      insuranceBrand: caseData.branding || '',
      lineOfBusiness: caseData.lineOfBusiness?._id || '',
      assignedTo: caseData.assignedOperator?.id || '',
      caseVisibility: caseData.privacy as CaseVisibilityEnum,
    };

    // Get type-specific form data
    const typeSpecificData = TYPE_SPECIFIC_FORM_DATA[caseType](caseData);

    methods.reset({ ...baseFormData, ...typeSpecificData });
  }, [
    isEditMode,
    caseData,
    methods,
    setIsPhoneNumberEntered,
    setSelectedContact,
    caseType,
  ]);
};

export default useCaseFormInitialization;
