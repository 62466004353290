import React, {
  useState, useEffect, useRef,
} from 'react';

import { formatTimeToAMPM, formatDateFromISO, formatTimeFromISO } from '@app/helpers/datetime';
import {
  DialogRoot, DialogContent, DialogHeader, DialogBody,
  DialogCloseTrigger, DialogTitle,
} from '@chakra-snippets/dialog';

import ReplyActions from '../../SharedComponents/ReplyRowActions/ReplyActions';
import ReplyMessage from '../../SharedComponents/ReplyRowMessage/ReplyMessage';
import { type Reply } from '../../types';
import { buildSendCadence } from '../../Utils';
import GeneralAutoReplyModal from '../GeneralAutoReplyModal/GeneralAutoReplyModal';
import './GeneralAutoReplyRow.less';

interface GeneralAutoReplyRowProps {
  userId: string;
  reply: Reply;
}

const GeneralAutoReplyRow: React.FC<GeneralAutoReplyRowProps> = ({ userId, reply }) => {
  const [sendCadence, setSendCadence] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  // The empty array as the second argument tells react to load this useEffect
  // once after the initial rendering
  useEffect(() => {
    if (reply?.sendCriteria?.days?.startDate
    && reply?.sendCriteria?.days?.endDate
    && reply?.sendCriteria?.timing === 'SPECIFIED') {
      const startDate = reply?.sendCriteria?.days?.startDate;
      const endDate = reply?.sendCriteria?.days?.endDate;
      setSendCadence(`Sends ${formatDateFromISO(startDate)} ${formatTimeToAMPM(formatTimeFromISO(startDate))} to ${formatDateFromISO(endDate)} ${formatTimeToAMPM(formatTimeFromISO(endDate))}`);
    } else if (reply?.sendCriteria?.days
      && reply?.sendCriteria?.timing === 'SPECIFIED') {
      const getSendCadence = buildSendCadence(reply.sendCriteria.days);
      setSendCadence(getSendCadence);
    } else {
      setSendCadence('Sends all the time');
    }
  }, [reply]);
  return (
    <>
      <div className="auto-reply-row">
        <ReplyActions openEditModal={setIsModalOpen} userId={userId} reply={reply} />
        <ReplyMessage message={reply?.message?.during?.value ?? ''} />
        <div className="auto-reply-send-cadence">
          <p>{sendCadence}</p>
        </div>
      </div>
      {isModalOpen ? (
        <DialogRoot
          data-test="create-auto-reply-modal"
          open={isModalOpen}
          onOpenChange={(e) => setIsModalOpen(e.open)}
          size="lg"
        >
          <DialogContent className="create-auto-reply-modal" ref={contentRef}>
            <DialogHeader>
              <DialogTitle>Edit Away Message</DialogTitle>
            </DialogHeader>
            <DialogBody>
              <GeneralAutoReplyModal
                pageProps={{ reply, userId }}
                toggleModal={setIsModalOpen}
                contentRef={contentRef}
              />
            </DialogBody>
            <DialogCloseTrigger />
          </DialogContent>
        </DialogRoot>
      ) : null}
    </>
  );
};

export default GeneralAutoReplyRow;
