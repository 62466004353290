'use client';

import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';

import { system } from '@app/chakra-theme';

import {
  ColorModeProvider,
  type ColorModeProviderProps,
} from './color-mode';

export const Provider = (props: ColorModeProviderProps) => (
  <ChakraProvider
    value={system}
  >
    <ColorModeProvider {...props} forcedTheme="light" />
  </ChakraProvider>
);

export default Provider;
