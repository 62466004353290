import {
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';
import React from 'react';

import { Switch } from '@chakra-snippets/switch';

const CaseTypeSettingsHeader: React.FC<{
  title: string;
  isServiceType: boolean;
  enabled: boolean;
  onToggle: (enabled: boolean) => void;
}> = ({
  title,
  isServiceType,
  enabled,
  onToggle,
}) => (
  <Flex justify="space-between" align="center" mb={8}>
    <Box>
      <Text fontSize="md" fontWeight="semibold" color="gray.800" mb={1}>
        {title}
        {' case type'}
      </Text>
      <Text fontSize="sm" color="gray.500">
        Operators are allowed to create and manage
        {' '}
        {title.toLowerCase()}
        {' '}
        case types.
      </Text>
    </Box>
    {isServiceType && (
    <Switch
      checked={enabled}
      onChange={() => onToggle(!enabled)}
      data-testid="service-type-enabled-toggle"
      colorPalette="blue"
      size="lg"
    />
    )}
  </Flex>
);

export default CaseTypeSettingsHeader;
