import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from '@chakra-ui/react';
import { Button } from '@chakra-snippets/button';
import UserProfile from './UserProfile';
import { ROLE_OPERATOR, ROLE_ENDUSER } from '../../../../constants/general';
import { defaultRelationships } from './relationships';
import OptedInIcon from '../../../../../images/icons/opted-in-status.svg';
import { formatPhoneNumber } from '../../../../helpers/format';
import './ViewParticipantItem.less';

const ViewParticipantItem = ({
  participant,
  status,
  isLoggedInOperator,
  isPrimaryEndUser,
  onClickRemoveParticipant,
  onClickEditParticipant,
  onClickReinviteParticipant,
}) => {
  const {
    id,
    role,
    relationship,
    verified,
    blocked,
    locked,
    phone,
  } = participant;

  const isOptedIn = verified && !blocked && !locked;
  const isUnsubscribedOrLocked = blocked || locked;
  const shouldShowOptInStatus = role === ROLE_ENDUSER;
  const optInOrOutText = isOptedIn ? 'Opted-In' : 'Not Opted-In';
  const optInStatusText = shouldShowOptInStatus ? optInOrOutText : null;
  const isRemovedStatus = status === 'Removed';

  const handleClickRemoveParticipant = () => onClickRemoveParticipant(id);
  const handleClickEditParticipant = () => onClickEditParticipant(id);
  const handleReinviteParticipant = () => onClickReinviteParticipant(id);

  const renderRemoveButton = () => {
    const isEndUser = role === ROLE_ENDUSER;
    const isPrimaryContact = relationship === defaultRelationships.primaryEndUser;

    // TODO: Why can't we just check isPrimaryEndUser
    const preventButtonRender = (isPrimaryContact && isPrimaryEndUser)
      || !isEndUser
      || isRemovedStatus;
    if (preventButtonRender) {
      return null;
    }
    return (
      <Button
        onClick={handleClickRemoveParticipant}
        disabled={role === ROLE_OPERATOR}
        variant="outline"
        colorPalette="red"
      >
        Remove
      </Button>
    );
  };

  const renderEditButton = () => {
    if (role === ROLE_ENDUSER) {
      return (
        <Button
          variant="outline"
          data-testid={`edit-participant-button-${participant?.id}`}
          onClick={handleClickEditParticipant}
        >
          Edit
        </Button>
      );
    }
    return null;
  };

  const renderOptedInStatus = (optInStatus, isRemoved) => {
    if (optInStatus === 'Opted-In') {
      return (
        <div
          className={`opted-in-status view-participant-item-opt-in-status 
          ${isRemoved ? 'view-participant-item-opt-in-status-removed' : ''}`}
          data-testid={`view-participant-item-opt-in-status-${id}`}
        >
          {optInStatus.toUpperCase()}
          <OptedInIcon className={`opt-in-icon ${isRemoved
            ? 'opt-in-icon-removed'
            : 'opt-in-icon-normal'
          }`}
          />
        </div>
      );
    }

    if (optInStatus === 'Not Opted-In') {
      return (
        <div
          className="not-opted-in-status view-participant-item-opt-in-status"
          data-testid={`view-participant-item-opt-in-status-${id}`}
        >
          {optInStatus.toUpperCase()}
        </div>
      );
    }
    return null;
  };

  const renderReinvite = (participantStatus, isParticipantUnsubscribedOrLocked) => {
    if (participantStatus !== 'Active' && !isParticipantUnsubscribedOrLocked) {
      return (
        <button
          className="reinvite-participant-button"
          data-testId={`reinvite-participant-button-${id}`}
          onClick={handleReinviteParticipant}
          tabIndex="0"
          type="button"
        >
          (Reinvite)
        </button>
      );
    }
    return null;
  };

  return (
    <div
      className={`view-participant-item ${isRemovedStatus ? 'view-participant-item-removed' : ''}`}
      data-testid={`view-participant-item-${id}`}
    >
      <div className="view-participant-item-profile">
        <UserProfile
          profile={participant}
          isLoggedInOperator={isLoggedInOperator}
          isRemoved={isRemovedStatus}
        />
      </div>
      <div className="view-participant-phone-number">
        <div
          className="view-participant-item-phone"
          data-testid={`view-participant-item-phone-${id}`}
        >
          {phone ? formatPhoneNumber(phone) : ''}
        </div>
        {renderOptedInStatus(optInStatusText, isRemovedStatus)}
      </div>
      <div
        className="view-participant-item-status"
        data-testid={`view-participant-item-status-${id}`}
      >
        {status}
        {renderReinvite(status, isUnsubscribedOrLocked)}
      </div>
      <ButtonGroup
        alignItems="flex-end"
        width="200px"
        className="view-participant-item-actions"
        data-testid={`view-participant-item-opt-actions-${id}`}
      >
        {renderRemoveButton()}
        {renderEditButton()}
      </ButtonGroup>
    </div>
  );
};

ViewParticipantItem.propTypes = {
  participant: PropTypes.shape({
    id: PropTypes.string,
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.oneOf([ROLE_OPERATOR, ROLE_ENDUSER]),
    phone: PropTypes.string,
    relationship: PropTypes.string,
    verified: PropTypes.bool,
    blocked: PropTypes.bool,
    locked: PropTypes.bool,
  }).isRequired,
  status: PropTypes.oneOf(['Active', 'Invited', 'Removed']).isRequired,
  isLoggedInOperator: PropTypes.bool,
  isPrimaryEndUser: PropTypes.bool,
  onClickRemoveParticipant: PropTypes.func.isRequired,
  onClickEditParticipant: PropTypes.func.isRequired,
  onClickReinviteParticipant: PropTypes.func.isRequired,
};

ViewParticipantItem.defaultProps = {
  isLoggedInOperator: false,
  isPrimaryEndUser: false,
};

export default ViewParticipantItem;
