import type { ButtonProps } from '@chakra-ui/react';
import { IconButton as ChakraIconButton } from '@chakra-ui/react';
import { X } from 'lucide-react';
import * as React from 'react';

export type CloseButtonProps = ButtonProps;

export const CloseButton = React.forwardRef<
HTMLButtonElement,
CloseButtonProps
>((props, ref) => (
  <ChakraIconButton variant="ghost" aria-label="Close" ref={ref} {...props}>
    {props.children ?? <X />}
  </ChakraIconButton>
));
