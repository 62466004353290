import { Icon, Text } from '@chakra-ui/react';
import {
  ActionMenu,
  IconButton,
  WarningTriangleFilledIcon,
  WarningTriangleIcon,
  CopyIcon,
  EnvelopeIcon,
  EyeIcon,
  PhoneFilledIcon,
  CircleXFilledIcon,
} from '@himarley/unity';
import { useQueryClient } from '@tanstack/react-query';
import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import {
  connect, shallowEqual, useDispatch, useSelector, ConnectedProps,
} from 'react-redux';

import { handleMessageNeedsActionResponse } from '@app/actions/chat';
import { markMessageUnread } from '@app/actions/job';
import { showSnackbar } from '@app/actions/notification';
import { markChatAsUnread } from '@app/actions/profile';
import IconBar from '@app/components/elements/IconBar/IconBar';
import UserPhoto2 from '@app/components/UserPhoto2/UserPhoto2';
import {
  NEEDS_ATTENTION,
  NEEDS_ACTION,
} from '@app/constants/permissions';
import TagItemTypes from '@app/constants/tag-item-types';
import {
  copyTextToClipboard,
  displayNegativeSentiment,
  useCheckPermissions,
} from '@app/helpers/common';
import { useDisplayFailedMessageStatus } from '@app/helpers/featureFlags';
import { isApplicableUnreadMessage } from '@app/helpers/inbox.helpers';
import { StateType } from '@app/types/reducer-state';
import { Tag } from '@chakra-snippets/tag';
import { Tooltip } from '@chakra-snippets/tooltip';

import './MessageFormatter.less';

const mapStateToProps = (state: StateType) => ({
  branding: state?.jobs?.activeJob?.branding,
  companyName: state?.auth?.user?.organization?.name,
  displayName: state?.auth?.user?.organization?.displayName,
  activeJob: state?.jobs?.activeJob,
  allMessages: state?.jobs?.activeChat?.messages,
  authUserId: state?.auth?.user?._id,
  viewedChats: state?.jobs?.viewedChats,
  markChatUnreadError: state?.jobs?.markChatUnreadError,
});

const mapDispatchToProps = { markChatAsUnread, showSnackbar };

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface MessageFormatterProps extends PropsFromRedux {
  children: React.ReactNode;
  timestamp: string;
  authorName: string;
  authorNameMessage?: string;
  authorId: string;
  isAI: boolean;
  imageUrl?: string;
  id: string;
  messageId?: string;
  isRecipient: boolean;
  displayMessage: string;
  relationship?: string;
  messageStatus?: string;
  messageCreatedAt?: string;
  hasNeedsAction: boolean;
  eventType?: string;
  isRedactionMessage: boolean;
  toggleTextRedactionPopup: () => void;
  isUnreadMessage: boolean;
  isInbound: boolean;
  hasIconBar: boolean;
  messageNeedsAttention: boolean;
  bodyPrefix?: string;
}

const MessageFormatter: React.FC<MessageFormatterProps> = ({
  children,
  timestamp,
  authorName,
  authorId,
  authUserId,
  markChatAsUnread: markChatAsUnreadProp,
  isAI,
  imageUrl = '',
  id,
  messageId = '',
  authorNameMessage = '',
  branding,
  viewedChats,
  isRecipient = false,
  displayName,
  companyName,
  hasIconBar,
  displayMessage,
  allMessages,
  activeJob,
  markChatUnreadError,
  showSnackbar: showSnackbarProp,
  messageNeedsAttention,
  relationship = null,
  messageStatus = 'delivered',
  messageCreatedAt = null,
  hasNeedsAction,
  eventType = '',
  isRedactionMessage,
  toggleTextRedactionPopup,
  isUnreadMessage,
  isInbound,
  bodyPrefix,
}) => {
  const dispatch = useDispatch();
  const {
    userId,
  } = useSelector(
    (state) => ({
      userId: state.auth?.user?._id,
    }),
    shallowEqual,
  );
  const orgName = displayName || companyName;
  const jobHasFailedMessage = activeJob && activeJob.showFailedMessage
    ? activeJob.showFailedMessage
    : false;
  const showFailedMessage = useDisplayFailedMessageStatus(
    messageStatus,
    messageCreatedAt,
    jobHasFailedMessage,
  );
  const showNeedsAttention = useCheckPermissions([NEEDS_ATTENTION])
    ? displayNegativeSentiment(
      id,
      messageNeedsAttention,
      isRecipient,
      activeJob,
      allMessages,
    )
    : '';
  const showMessageIndicator = showFailedMessage
    ? 'message-failed'
    : showNeedsAttention;
  const displayNeedsAction = useCheckPermissions([NEEDS_ACTION]) && hasNeedsAction;

  const [isPopoverOpenCopied, setPopoverOpenCopied] = useState(false);
  const [isPopoverOpenMarkUnread, setPopoverOpenMarkUnread] = useState(false);

  useEffect(() => {
    if (markChatUnreadError) {
      showSnackbarProp({
        text: 'Mark Chat Unread was not successful',
        isError: true,
      });
    }
  }, [markChatUnreadError, showSnackbarProp, isPopoverOpenCopied, isPopoverOpenMarkUnread]);

  const onClickCopyText = async () => {
    if (await copyTextToClipboard(displayMessage)) {
      setPopoverOpenCopied(true);
      setTimeout(() => {
        setPopoverOpenCopied(false);
      }, 1000);
    }
  };

  const isUnreadStateRefactorEnabled = process.env.UNREAD_STATE_REFACTOR_ENABLED;
  const queryClient = useQueryClient();

  const onMarkUnreadClick = () => {
    setPopoverOpenMarkUnread(true);
    setTimeout(() => {
      setPopoverOpenMarkUnread(false);
    }, 1000);

    if (isUnreadStateRefactorEnabled) {
      dispatch(markMessageUnread(queryClient, activeJob?._id, id));
    }
    if (viewedChats.has(activeJob._id)) {
      // only make request if chat is viewed
      markChatAsUnreadProp(authUserId, activeJob._id);
    }
  };

  const iconBarClasses = classnames({
    'message-icon-bar': hasIconBar,
    'unread-message': isUnreadMessage,
  });

  const showMarkUnreadOption = useMemo(() => isApplicableUnreadMessage(
    { isInbound, authorId },
    userId,
  ), [isInbound, authorId, userId]);

  return (
    <IconBar
      className={iconBarClasses}
      disabled={!hasIconBar}
      icons={(
        <ActionMenu
          testId="text-toolbar-action-menu-testid"
          className="toolbar-action-menu"
          actions={[
            <IconButton
              isPopoverOpen={isPopoverOpenCopied}
              popoverMessage="Copied!"
              title="Copy Text"
              onClick={onClickCopyText}
            >
              <Icon><CopyIcon /></Icon>
            </IconButton>,
            showMarkUnreadOption && (
              <IconButton
                isPopoverOpen={isPopoverOpenMarkUnread}
                popoverMessage="Mark Unread"
                id="mark-unread"
                title="Mark Unread"
                onClick={onMarkUnreadClick}
              >
                <Icon><EnvelopeIcon /></Icon>
              </IconButton>
            ),
            isRedactionMessage && (
              <IconButton id="view-redacted-text-btn" title="View Redacted Text" onClick={toggleTextRedactionPopup}>
                <Icon><EyeIcon /></Icon>
              </IconButton>
            ),
          ]}
        />
      )}
    >
      <div
        className={`chat-message2 chat-message2-inbound ${showMessageIndicator}`}
        id={messageId}
      >
        <UserPhoto2
          name={authorName}
          id={authorId}
          isAI={isAI}
          imageUrl={imageUrl}
          className="message-photo"
          isRecipient={isRecipient}
          isCustomer={false}
          isFinishedLoading={false}
        />
        <div className="message-box">
          <div className="message-header">
            <div className="message-header-row" data-testid="author-prefix">
              {eventType === 'voice' ? (
                <Icon mr="4px" boxSize="16px"><PhoneFilledIcon /></Icon>
              ) : null}
              {bodyPrefix ? (
                <Text className="message-prefix" title={bodyPrefix} lineClamp={1}>{bodyPrefix}</Text>
              ) : (
                <Text lineClamp={1}>
                  <span className="author-name">
                    {authorName}
                    {relationship && ` (${relationship})`}
                    {authorNameMessage && (
                    <span className="author-name-message">
                      {authorNameMessage}
                    </span>
                    )}
                  </span>
                  {branding && (!isRecipient || isAI) ? (
                    <span className="message-branding">
                      -
                      {branding}
                      {' '}
                      @
                      {orgName}
                    </span>
                  ) : null}
                </Text>
              )}

              <div className="coaching-badges-container">
                <span>
                  {displayNeedsAction && (
                    <Tag
                      size="sm"
                      colorPalette="blue"
                      data-testid="needs-action-badge"
                      onClose={() => dispatch(
                        handleMessageNeedsActionResponse(
                          id,
                          activeJob.id,
                          'dismissed',
                        ),
                      )}
                    >
                      {TagItemTypes.Message.NeedsAction.text}
                    </Tag>
                  )}
                </span>
                <span>
                  {['new-needs-attention', 'old-needs-attention'].includes(showNeedsAttention) ? (
                    <Tooltip showArrow content="There's a high likelihood the customer has concerns and requires your attention.">
                      <Tag p={1} colorPalette="yellow" size="sm">
                        <Icon boxSize="16px">
                          {showNeedsAttention === 'new-needs-attention' ? <WarningTriangleFilledIcon /> : <WarningTriangleIcon />}
                        </Icon>
                      </Tag>
                    </Tooltip>
                  ) : null}
                </span>
              </div>
            </div>
          </div>
          <div className="message-body">{children}</div>
          {showFailedMessage ? (
            <div data-testid="messageFailed" className="message-failed-notice">
              <Icon boxSize="14px" mr="4px" alignSelf="center" color="red.500"><CircleXFilledIcon /></Icon>
              Message failed to send. Please try to send this message again
              later.
            </div>
          ) : null}
          <span className="message-timestamp" data-testid="message-timestamp">{timestamp}</span>
        </div>
      </div>
    </IconBar>
  );
};

export default connector(MessageFormatter);
