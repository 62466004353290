import {
  Flex,
  HStack,
  Heading,
  Container,
  Text,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';

import { GET_WORKFLOW_SETTINGS, GET_WORKFLOW_KEYWORDS } from '@app/constants/endpoints';
import { fetchData } from '@app/hooks/react-query-helpers';
import { useOrganizationId } from '@app/selectors/organization';

import CreateWorkflowSettingsButton from './add-workflow-settings-button';
import DeleteWorkflowSettingsModal from './delete-workflow-settings-modal';
import { WorkflowSettingsWithMetadata } from './types';
import WorkflowSettingsCard from './workflow-settings-card';
import WorkflowSettingsModal from './workflow-settings-modal';
import {
  WorkflowSettings,
} from '../../../types/workflow-settings';

const CfnolSettings = () => {
  const organizationId = useOrganizationId();

  const {
    data: workflowData,
    refetch: refetchWorkflowSettings,
    isLoading: isWorkflowLoading,
  } = useQuery<Record<string, WorkflowSettings>>({
    queryKey: ['getWorkflowSettings', organizationId],
    queryFn: () => fetchData(GET_WORKFLOW_SETTINGS()),
    enabled: !!organizationId,
  });

  const {
    data: keywordData,
    refetch: refetchKeywords,
    isLoading: isKeywordLoading,
  } = useQuery<Record<string, string>>({
    queryKey: ['getWorkflowKeywords', organizationId],
    queryFn: () => fetchData(GET_WORKFLOW_KEYWORDS()),
    enabled: !!organizationId,
  });

  const refetchAllData = () => {
    refetchWorkflowSettings();
    refetchKeywords();
  };

  const [isWorkflowSettingsModalOpen, setIsWorkflowSettingsModalOpen] = useState(false);
  const [isDeleteWorkflowSettingsModalOpen, setIsDeleteWorkflowSettingsModalOpen] = useState(false);
  const [
    selectedWorkflow,
    setSelectedWorkflow,
  ] = useState<WorkflowSettingsWithMetadata | undefined>();
  const [selectedWorkflowToDelete, setSelectedWorkflowToDelete] = useState<string>('');

  const onClickCreateWorkflowSettingsButton = () => {
    setSelectedWorkflow(undefined);
    setIsWorkflowSettingsModalOpen(true);
  };

  const onCloseWorkflowSettingsModal = () => {
    setSelectedWorkflow(undefined);
    setIsWorkflowSettingsModalOpen(false);
  };

  const openDeleteModal = (workflowName: string) => {
    setSelectedWorkflowToDelete(workflowName);
    setIsDeleteWorkflowSettingsModalOpen(true);
  };

  const onCloseDeleteModal = () => {
    setSelectedWorkflowToDelete('');
    setIsDeleteWorkflowSettingsModalOpen(false);
  };

  const getWorkflowType = (workflowName: string) => {
    if (workflowName === 'DEFAULT') {
      return 'DEFAULT';
    }
    return workflowName.split(':').at(-1);
  };

  const workflowSettingsData = Object.entries(workflowData || {}).sort(([nameA], [nameB]) => {
    // Ensure DEFAULT is always first
    if (nameA === 'DEFAULT') {
      return -1;
    }
    if (nameB === 'DEFAULT') {
      return 1;
    }
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const keywordByWorkflowName = Object.entries(keywordData || {})
    .reduce((keywordMap, [keyword, workflowName]) => {
      if (!keywordMap[workflowName]) {
        return {
          ...keywordMap,
          [workflowName]: keyword,
        };
      }
      return keywordMap;
    }, {} as Record<string, string>);

  return (
    <Container maxW="container.xl" mb={8} p={0}>
      <HStack justify="space-between" align="center" mb={2}>
        <Heading size="lg">Conversational FNOL</Heading>

        <CreateWorkflowSettingsButton
          onClick={onClickCreateWorkflowSettingsButton}
        />
      </HStack>
      <HStack justify="space-between" align="center" mb={6}>
        <Text>Workflow settings</Text>
      </HStack>
      {
        isWorkflowLoading || isKeywordLoading ? (
          <Center h="200px">
            <Spinner size="xl" />
          </Center>
        ) : (
          <HStack gap={8} align="stretch">
            <Flex direction="row" wrap="wrap" gap={6}>
              {
            workflowSettingsData.map(([workflowName, workflowSettings]) => (
              <WorkflowSettingsCard
                type={getWorkflowType(workflowName)}
                workflowName={workflowName}
                keywordTrigger={keywordByWorkflowName[workflowName]}
                {...workflowSettings}
                onEdit={() => {
                  setSelectedWorkflow({
                    ...workflowSettings,
                    workflowName,
                    keywordTrigger: keywordByWorkflowName[workflowName],
                  });
                  setIsWorkflowSettingsModalOpen(true);
                }}
                onDelete={() => openDeleteModal(workflowName)}
              />
            ))
            }
            </Flex>
          </HStack>
        )
      }

      {isWorkflowSettingsModalOpen && (
        <WorkflowSettingsModal
          initialData={selectedWorkflow}
          onClose={onCloseWorkflowSettingsModal}
          type={selectedWorkflow ? 'edit' : 'create'}
          refetchAllData={refetchAllData}
        />
      )}
      <DeleteWorkflowSettingsModal
        isOpen={isDeleteWorkflowSettingsModalOpen}
        onClose={onCloseDeleteModal}
        workflowName={selectedWorkflowToDelete}
        refetchAllData={refetchAllData}
      />
    </Container>
  );
};

export default CfnolSettings;
