import {
  Box,
  Flex,
  Spacer,
  Link,
  Text,
  Circle,
} from '@chakra-ui/react';
import get from 'lodash/get';
import { Clock } from 'lucide-react';
import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import { FileDrop } from 'react-file-drop';
import { connect, ConnectedProps } from 'react-redux';

import {
  queueMessage,
  uploadFile,
  sendFile,
  setChatSendFileCompleted,
} from '@app/actions/job';
import usePermissionVerify from '@app/components/elements/hooks/usePermissionVerify';
import { FEATURE_FLAG_VIRUS_SCANNING } from '@app/constants/permissions';
import { isMultiPartyEnabled as shouldShowMultiPartyToolbar } from '@app/helpers/featureFlags';
import { StateType } from '@app/types/reducer-state';
import { Button } from '@chakra-snippets/button';
import { toaster } from '@chakra-snippets/toaster';

import LockedChatInput from './locked-chat-input';
import ChatInput from '../ChatInput/chat-input';
import ListScheduledMessages from '../ChatInput/list-scheduled-messages';
import ChatLog from '../ChatLog/chat-log';
import './ChatModule.less';
import MultiPartyToolbar from '../MultiPartyToolbar/MultiPartyToolbar';

const mapStateToProps = (
  state: StateType,
  ownProps: { chatLocation: string },
) => ({
  loadingTemplate: get(state.templates, 'loadingTemplate', false),
  loadedTemplateError: get(state.templates, 'loadedTemplateError', false),
  chat: get(state.jobs, ownProps.chatLocation, { id: '' }),
  jobLanguagePref: get(state.jobs, 'activeJob.languagePreference'),
  userPermissions: get(state.auth, 'permissions', []),
  uploadFileIsInProgress: state?.jobs?.uploadFileIsInProgress,
  scheduledMessages: get(state.jobs, 'scheduledMessages'),
});

const mapDispatchToProps = {
  uploadFile,
  queueMessage,
  sendFile,
  setChatSendFileCompleted,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type ChatModuleProps = PropsFromRedux & {
  disabled: boolean;
  isInFNOLFlow: boolean;
  isEndingWorkflow: boolean;
  isInChatBotMode: boolean;
  isLocked: boolean;
  endActiveWorkflow: () => void;
  updateEndUserOptStatus: () => void;
  isUnlockingUser: boolean;
  chatRef: React.RefObject<HTMLDivElement>;
};

const ChatModule: React.FC<ChatModuleProps> = ({
  loadingTemplate,
  loadedTemplateError,
  disabled,
  chat,
  isInChatBotMode,
  queueMessage: queueMessageFn,
  userPermissions,
  isInFNOLFlow,
  isLocked,
  endActiveWorkflow,
  updateEndUserOptStatus,
  isUnlockingUser,
  chatRef,
  uploadFile: uploadFileProp,
  uploadFileIsInProgress,
  sendFile: sendFileProp = () => {},
  setChatSendFileCompleted: setChatSendFileCompletedProp = () => {},
  scheduledMessages,
  isEndingWorkflow,
}) => {
  const [previewFile, setPreviewFile] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File>();
  const [fileKey, setFileKey] = useState<File>();
  const [showScheduleMessageListModal, setShowScheduleMessageListModal] = useState(false);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const toastIdRef = useRef<string | null | undefined>(null);

  const { hasAccess: hasVirusScanningEnabled } = usePermissionVerify({
    neededPermissions: [FEATURE_FLAG_VIRUS_SCANNING],
  });

  const handleFileSelectChange = useCallback(
    (files: FileList) => {
      if (uploadFileIsInProgress) {
        return;
      }
      const [file] = files;
      setSelectedFile(file);
      if (file) {
        toastIdRef.current = toaster.create({
          description: 'Uploading file...',
          type: 'loading',
        });
        uploadFileProp(file, toaster, toastIdRef).then((response) => {
          const { key } = response || {};
          if (key) {
            sendFileProp(key, file, toaster, toastIdRef);
            setFileKey(key);
            if (
              file?.type?.indexOf('image') > -1
              && URL.createObjectURL(file)
            ) {
              setPreviewFile(URL.createObjectURL(file));
            }
          }
        });
      }
    },
    [uploadFileIsInProgress, uploadFileProp, sendFileProp],
  );

  const handleVirusScanQueuing = useCallback(() => {
    if (fileKey && selectedFile && toaster) {
      sendFileProp(fileKey, selectedFile, toaster, toastIdRef);
    }
  }, [fileKey, sendFileProp, selectedFile]);

  useEffect(
    () => () => {
      if (toastIdRef?.current) {
        toaster.dismiss(toastIdRef.current);
      }
      setChatSendFileCompletedProp();
    },
    [setChatSendFileCompletedProp],
  );

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (
      uploadFileIsInProgress
      && fileKey
      && selectedFile
      && hasVirusScanningEnabled
    ) {
      intervalId = setInterval(async () => {
        handleVirusScanQueuing();
      }, 5000); // retry after 5 seconds
    } else if (intervalId) {
      clearInterval(intervalId);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [
    hasVirusScanningEnabled,
    fileKey,
    handleFileSelectChange,
    handleVirusScanQueuing,
    selectedFile,
    uploadFileIsInProgress,
  ]);

  const showMultiPartyToolbar = shouldShowMultiPartyToolbar(userPermissions);

  const LockedCustomerInput = (
    <LockedChatInput
      message={(
        <Text>
          This customer is locked out of texting into the case. To learn more
          please refer to our support documentation&nbsp;
          <Link
            variant="underline"
            href="https://himarley.zendesk.com/hc/en-us/articles/17506735953811-Unlock-Users"
          >
            here
          </Link>
        </Text>
      )}
      unlockChat={updateEndUserOptStatus}
      isUnlockingChat={isUnlockingUser}
      buttonText="Unlock Customer"
      buttonLoadingText="Unlocking..."
    />
  );

  const ActiveFNOLCustomerInput = (
    <LockedChatInput
      message={
        <Text>Messaging this customer will end the FNOL workflow.</Text>
      }
      unlockChat={endActiveWorkflow}
      isUnlockingChat={isEndingWorkflow}
      buttonText="Message Customer"
      buttonLoadingText="Ending Workflow..."
    />
  );

  const StandardChatInput = (
    <>
      <ListScheduledMessages
        chatId={chat.id}
        show={showScheduleMessageListModal}
        toggleShow={setShowScheduleMessageListModal}
      />
      <Box p={4} borderTopWidth="1px" borderColor="gray.100">
        <Flex>
          {showMultiPartyToolbar && (
          <MultiPartyToolbar enableAddParticipants={!disabled} />
          )}
          <Spacer />
          {scheduledMessages?.length ? (
            <Box position="relative">
              <Button
                variant="ghost"
                onClick={() => setShowScheduleMessageListModal(true)}
                size="sm"
              >
                <Clock />
                Scheduled
              </Button>
              <Circle
                top="2px"
                left="8px"
                position="absolute"
                bg="marleyRed.500"
                color="white"
                size="4"
                data-testid="scheduled-messages-count"
              >
                {scheduledMessages.length}
              </Circle>
            </Box>
          ) : null}
        </Flex>
        <ChatInput
          handleFileSelectChange={(
            e: React.ChangeEvent<HTMLInputElement>,
          ) => {
            handleFileSelectChange(e?.target?.files as FileList);
            e.target.value = '';
          }}
          previewFile={previewFile}
          chat={chat}
          queueMessage={queueMessageFn}
          disabled={disabled}
          loadedTemplateError={loadedTemplateError}
          setIsKeyboardOpen={setIsKeyboardOpen}
        />
      </Box>
    </>
  );

  let RenderedChatInput;
  if (isLocked) {
    RenderedChatInput = LockedCustomerInput;
  } else if (isInFNOLFlow) {
    RenderedChatInput = ActiveFNOLCustomerInput;
  } else {
    RenderedChatInput = StandardChatInput;
  }

  return (
    <div className="chat-module">
      <FileDrop onDrop={(files) => handleFileSelectChange(files as FileList)}>
        <div className="file-drop-target-inner">Upload to Chat</div>
      </FileDrop>
      <ChatLog
        chat={chat}
        isInChatBotMode={isInChatBotMode}
        chatRef={chatRef}
        isKeyboardOpen={isKeyboardOpen}
      />
      {(loadingTemplate || loadedTemplateError) && (
        <div className="typing-notification-block">
          <div className="typing-notification">
            {loadingTemplate ? '...loading Template' : 'template load error'}
          </div>
        </div>
      )}
      {RenderedChatInput}
    </div>
  );
};

export default connector(ChatModule);
