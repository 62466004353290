import { defineSlotRecipe, defineStyle } from '@chakra-ui/react';
import { theme } from '@chakra-ui/theme';
import { Radio as BaseRadio } from '@chakra-ui/theme/components';

let baseStyle;

if (BaseRadio.baseStyle) {
  baseStyle = BaseRadio.baseStyle({
    colorScheme: 'gray',
    colorMode: 'light',
    theme,
  });
}

export const radioGroupSlotRecipe = defineSlotRecipe({
  slots: ['root', 'control', 'label', 'item'],
  base: {
    ...baseStyle,
    root: defineStyle({
      '& label': {
        fontSize: 'sm !important',

        '& [data-part="item-text"]': {
          paddingLeft: 2,
        },
      },
    }),
  },
});

export default radioGroupSlotRecipe;
