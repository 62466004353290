import { Tabs, Box } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';

interface TabConfig {
  id: string;
  title: string;
  component: React.ReactNode;
}

interface TabRouterProps {
  tabList: TabConfig[];
  basePath: string;
  defaultTabId?: string;
  onTabChange?: (tabId: string) => void;
  className?: string;
  tabContentCss?: React.CSSProperties;
}

const TabRouter: React.FC<TabRouterProps> = ({
  tabList,
  basePath,
  defaultTabId,
  onTabChange,
  className,
  tabContentCss,
}) => {
  const history = useNavigate();
  const location = useLocation();

  const pathSegments = location.pathname.split('/');
  const baseIndex = pathSegments.findIndex((segment) => segment === basePath);
  const activeTab = pathSegments[baseIndex + 1];

  useEffect(() => {
    if (activeTab) {
      const currentTabIndex = tabList.findIndex((tab) => tab.id === activeTab);
      if (currentTabIndex > -1) {
        onTabChange?.(activeTab);
      } else {
        // Invalid tab ID - redirect to default or first tab
        const defaultIndex = defaultTabId
          ? tabList.findIndex((tab) => tab.id === defaultTabId)
          : 0;
        if (defaultIndex > -1) {
          history(`/${basePath}/${tabList[defaultIndex].id}`, { replace: true });
        }
      }
    } else if (tabList.length > 0) {
      // No tab in URL - redirect to default or first tab
      const defaultIndex = defaultTabId
        ? tabList.findIndex((tab) => tab.id === defaultTabId)
        : 0;
      if (defaultIndex > -1) {
        history(`/${basePath}/${tabList[defaultIndex].id}`, { replace: true });
      }
    }
  }, [tabList, history, basePath, defaultTabId, onTabChange, activeTab]);

  const handleTabSelect = (details: Tabs.ValueChangeDetails) => {
    const index = tabList.findIndex((tab) => tab.id === details.value);
    if (tabList[index]) {
      const tab = tabList[index].id;
      onTabChange?.(tab);
      history(`/${basePath}/${tab}`);
    }
  };

  // Only render if we have tabs
  if (!tabList.length) {
    return null;
  }

  return (
    <Box height="100%" width="100%" overflow="hidden">
      <Tabs.Root
        onValueChange={handleTabSelect}
        className={className}
        lazyMount
        unmountOnExit
        variant="marley"
        defaultValue={activeTab || defaultTabId}
      >
        <Tabs.List>
          {tabList.map((tab) => (
            <Tabs.Trigger
              key={tab.id}
              value={tab.id}
              data-testid={`tab-item-${tab.id}`}
            >
              {tab.title}
            </Tabs.Trigger>
          ))}
        </Tabs.List>
        <Box
          position="relative"
          height="100%"
          overflow="hidden"
          flex={1}
          minHeight={0}
        >
          {tabList.map((tab) => (
            <Tabs.Content key={tab.id} value={tab.id} css={tabContentCss}>
              {tab.component}
            </Tabs.Content>
          ))}
        </Box>
      </Tabs.Root>
    </Box>
  );
};

export default TabRouter;
