import React from 'react';

import SimpleSelect from '@app/components/chakra/selects/simple-select';
import { OPERATOR_STATUS_TYPES } from '@app/constants/user-mgmt';

interface StatusFilterProps {
  selectedStatus: string;
  setSelectedStatus: (status: string) => void;
}

const StatusFilter: React.FC<StatusFilterProps> = (
  { selectedStatus, setSelectedStatus },
) => (
  <SimpleSelect
    options={Object.values(OPERATOR_STATUS_TYPES).map((option) => ({
      label: option.charAt(0).toUpperCase() + option.slice(1),
      value: option,
      selected: selectedStatus === option,
    }))}
    onValueChange={(details) => {
      setSelectedStatus(details.value[0]);
    }}
    id="status-filter"
  />
);

export default StatusFilter;
