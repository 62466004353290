import { defineSlotRecipe, defineStyle } from '@chakra-ui/react';
import { theme } from '@chakra-ui/theme';
import { Checkbox as BaseCheckbox } from '@chakra-ui/theme/components';

let baseStyle;

if (BaseCheckbox.baseStyle) {
  baseStyle = BaseCheckbox.baseStyle({
    colorScheme: 'gray',
    colorMode: 'light',
    theme,
  });
}
export const checkboxSlotRecipe = defineSlotRecipe({
  slots: ['root', 'control', 'label'],
  base: {
    ...baseStyle,
    root: defineStyle({
      display: 'inline-flex !important',
      marginBottom: '0 !important',
      marginRight: '0 !important',
      alignItems: 'center',
      cursor: 'pointer',
    }),
    control: defineStyle({
      ...baseStyle?.control,
      color: 'gray.300',
    }),
    label: defineStyle({
      ...baseStyle?.label,
      fontWeight: 'normal',
    }),
  },
  variants: {
    variant: {
      reversed: {
        root: defineStyle({
          flexDirection: 'row-reverse',
        }),
      },
    },
  },
});

export default checkboxSlotRecipe;
