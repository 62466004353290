import { defineSlotRecipe } from '@chakra-ui/react';
import { theme } from '@chakra-ui/theme';
import { Tabs as BaseTabs } from '@chakra-ui/theme/components';

let baseStyle;

if (BaseTabs.baseStyle) {
  baseStyle = BaseTabs.baseStyle({
    colorScheme: 'gray',
    colorMode: 'light',
    theme,
  });
}

export const tabsSlotRecipe = defineSlotRecipe({
  slots: ['root', 'list', 'trigger', 'content'],
  base: {
    ...baseStyle,
  },
  variants: {
    variant: {
      marley: {
        root: {
          width: '100%',
          height: '100%',
          display: 'flex !important',
          flexDirection: 'column',
        },
        list: {
          borderBottom: '1px solid',
          borderColor: 'gray.200',
          gap: 10,
          marginX: 8,
          marginTop: 5,
          bg: 'white',
          position: 'sticky',
          top: 0,
          zIndex: 1,
          marginBottom: 0,
        },
        trigger: {
          fontSize: '.875rem',
          height: '3.8125rem',
          color: 'gray.500',
          padding: 1,
          fontWeight: 'normal',
          transition: 'color 0.2s',
          _hover: {
            color: 'gray.900',
          },
          _selected: {
            color: 'gray.900',
            fontWeight: 500,
            borderBottom: 'none',
            boxShadow: 'inset 0 -4px 0 {colors.marleyRed.500}',
          },
        },
        content: {
          height: '100%',
          width: '100%',
          position: 'absolute',
          inset: 0,
          overflow: 'auto',
          paddingX: 8,
          paddingTop: '12 !important',
          paddingBottom: 0,
        },
      },
    },
  },
  defaultVariants: {
    variant: 'marley',
  },
});

export default tabsSlotRecipe;
