import React, { useState, useRef } from 'react';

import {
  DialogRoot, DialogContent, DialogHeader, DialogBody,
  DialogCloseTrigger, DialogTitle,
} from '@chakra-snippets/dialog';

import OfficeHoursModal from './OfficeHoursModal/OfficeHoursModal';
import ReplyActions from '../SharedComponents/ReplyRowActions/ReplyActions';
import ReplyMessage from '../SharedComponents/ReplyRowMessage/ReplyMessage';
import { type Reply } from '../types';

interface OfficeHoursRowProps {
  userId: string;
  reply: Reply;
}

const OfficeHoursRow: React.FC<OfficeHoursRowProps> = ({ userId, reply }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className="auto-reply-row">
        <ReplyActions openEditModal={setIsModalOpen} userId={userId} reply={reply} />
        <ReplyMessage message={reply?.message?.after?.value ?? ''} />
      </div>
      {isModalOpen ? (
        <DialogRoot
          data-test="create-ooo-modal"
          open={isModalOpen}
          onOpenChange={(e) => setIsModalOpen(e.open)}
          size="lg"
        >
          <DialogContent className="create-ooo-modal" ref={contentRef}>
            <DialogHeader>
              <DialogTitle>New Away Message</DialogTitle>
            </DialogHeader>
            <DialogBody>
              <OfficeHoursModal
                pageProps={{ reply, userId }}
                toggleModal={setIsModalOpen}
                contentRef={contentRef}
              />
            </DialogBody>
            <DialogCloseTrigger />
          </DialogContent>
        </DialogRoot>
      ) : null}
    </>
  );
};

export default OfficeHoursRow;
