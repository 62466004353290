/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { useEffect, useCallback, useState } from 'react';
import _ from 'lodash';
import { Icon } from '@chakra-ui/react';
import { Search } from 'lucide-react';
import './SearchBar.less';
import { FILTER_DEBOUNCE } from '../../constants/config';
import ClearSearch from '../../../images/icons/clearSearch.svg';

const EMPTY_VALUE = '';

/**
 * Generic Search Bar
 * @param   {function} toggleFilterCleared triggered cleared filter
 * @param   {boolean} clearFilter mechanism to clear filter, often used with select
*/
const SearchBar = ({
  filter, label, handleFilterChange, onChange, placeholder, onKeyDown,
  clearFilter, toggleFilterCleared, testId, value,
}, ref) => {
  const [inputValue, setInputValue] = useState(filter || label);

  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);

  useEffect(() => {
    const { current } = ref || {};
    if (clearFilter) {
      if (current) current.value = EMPTY_VALUE;
      toggleFilterCleared();
    }
  }, [clearFilter]);

  const delayedFilter = useCallback(_.debounce((q) => {
    const filterFunc = handleFilterChange || onChange;
    filterFunc(q);
  }, FILTER_DEBOUNCE), []);

  const handleFilter = (ev) => {
    setInputValue(ev.target.value);
    delayedFilter(ev);
  };

  const handleClear = () => {
    setInputValue(EMPTY_VALUE);
    if (ref && ref.current) {
      ref.current.value = EMPTY_VALUE;
    }
    toggleFilterCleared?.();
    handleFilter({ target: { value: EMPTY_VALUE } });
  };

  return (
    <div
      data-test="search-bar"
      data-testid={`search-bar${testId ? `-${testId}` : ''}`}
      className="search-bar"
    >
      <span className="filter-input">
        <span className="search-icon"><Icon><Search /></Icon></span>
        <input
          data-test="search-input"
          data-testid={`search-input${testId ? `-${testId}` : ''}`}
          ref={ref}
          value={inputValue}
          placeholder={placeholder}
          className="form-control"
          onChange={handleFilter}
          onKeyDown={onKeyDown}
        />
        {inputValue && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <button
            data-testid="clear-button-test"
            onClick={handleClear}
            type="button"
            className="clear-search-button"
          >
            <ClearSearch />
          </button>
        )}
      </span>
    </div>
  );
};

export default React.forwardRef(SearchBar);
