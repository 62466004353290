import { Grid, GridItem, Heading } from '@chakra-ui/react';
import React, { useContext } from 'react';

import SelectMenu from '@app/components/chakra/fields/select-menu';
import TextField from '@app/components/chakra/fields/text-field';
import { type IFormInput } from '@app/types/create-case';

import { STATES_OPTIONS } from './constants';
import { CreateCaseContext } from './context';
import DateField from './fields/date-field';
import SharedForm from './shared-form';

const PolicyForm = () => {
  const { contentRef } = useContext(CreateCaseContext);

  return (
    <Grid width="100%" templateColumns={{ sm: 'repeat(1, minmax(0, 1fr))', md: 'repeat(2, minmax(0, 1fr))' }} gap={4}>
      <SharedForm />
      <GridItem colSpan={{ base: 2, md: 1 }}>
        <TextField<IFormInput>
          label="Policy Number"
          name="policyNumber"
          registerOptions={{ required: { value: true, message: 'Policy Number is Required' } }}
        />
      </GridItem>
      <GridItem colSpan={{ base: 2, md: 1 }}>
        <TextField<IFormInput>
          label="Policy Type"
          name="policyType"
        />
      </GridItem>
      <GridItem colSpan={2}>
        <TextField<IFormInput>
          label="Deductible"
          name="deductible"
          leftElement="$"
        />
      </GridItem>
      <GridItem colSpan={{ base: 2, md: 1 }}>
        <DateField
          name="effectiveDate"
          label="Effective Date"
        />
      </GridItem>
      <GridItem colSpan={{ base: 2, md: 1 }}>
        <DateField
          name="expirationDate"
          label="Expiration Date"
          dateLimit="future"
        />
      </GridItem>
      <GridItem colSpan={2}>
        <Heading size="sm">Policy Address</Heading>
      </GridItem>
      <GridItem colSpan={2}>
        <TextField<IFormInput>
          label="Street Address 1"
          name="streetAddress1"
        />
      </GridItem>
      <GridItem colSpan={2}>
        <TextField<IFormInput>
          label="Street Address 2"
          name="streetAddress2"
        />
      </GridItem>
      <GridItem colSpan={{ base: 2, md: 1 }}>
        <TextField<IFormInput>
          label="City"
          name="city"
        />
      </GridItem>
      <GridItem colSpan={{ base: 2, md: 1 }}>
        <SelectMenu<IFormInput>
          id="states"
          label="State"
          name="state"
          options={STATES_OPTIONS}
          contentRef={contentRef}
        />
      </GridItem>
      <GridItem colSpan={{ base: 2, md: 1 }}>
        <TextField<IFormInput>
          label="Zip Code"
          name="zipCode"
          registerOptions={
          {
            pattern: {
              value: /^\d{5}(-\d{4})?$/,
              message: 'Zip Code must be 5 digits or 5+4 format (e.g. 12345 or 12345-6789)',
            },
          }
        }
        />
      </GridItem>
      <GridItem colSpan={{ base: 2, md: 1 }}>
        <TextField<IFormInput>
          label="Year Built"
          name="yearBuilt"
        />
      </GridItem>
    </Grid>
  );
};

export default PolicyForm;
