import {
  IconButton, Icon,
} from '@chakra-ui/react';
import {
  Avatar, Card,
} from '@himarley/unity';
import { Ellipsis, Trash2, SquarePen } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setIsUpdatingBrand } from '@app/actions/organization';
import Row from '@app/components/elements/Row/Row';
import Title from '@app/components/elements/text/Title/Title';
import { StateType } from '@app/types/reducer-state';
import {
  MenuRoot, MenuTrigger, MenuContent, MenuItem,
} from '@chakra-snippets/menu';

import BrandModal from './BrandModal';
import DeleteBrandModal from './DeleteBrandModal';

interface BrandCardProps {
  name: string;
  handleEditBrand: (index: string, name: string) => void;
  handleDeleteBrand: (index: string) => void;
  index: string;
  brandingMap: Map<string, string>;
  hasLoB: boolean;
  isSelected: boolean;
}

const BrandCard: React.FC<BrandCardProps> = ({
  name = '',
  index = '',
  handleEditBrand = () => { },
  handleDeleteBrand = () => { },
  brandingMap = { has: () => { } },
  hasLoB,
  isSelected = false,
}) => {
  const dispatch = useDispatch();
  const isUpdatingBranding = useSelector(
    (state: StateType) => state?.organizations?.isUpdatingBranding,
  );
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (isUpdatingBranding === false) {
      dispatch(setIsUpdatingBrand(null));
      setShowEditModal(false);
      setShowDeleteModal(false);
    } else if (isUpdatingBranding === 'error') {
      dispatch(setIsUpdatingBrand(null));
    }
  }, [dispatch, isUpdatingBranding]);

  const menuItems: {
    id: string;
    onClick: () => void;
    icon: React.ReactNode;
    label: string;
    color?: string;
  }[] = [{
    id: 'edit',
    onClick: () => setShowEditModal(true),
    icon: <Icon><SquarePen /></Icon>,
    label: 'Edit',
  }];

  if (brandingMap.size !== 1) {
    menuItems.push({
      id: 'delete',
      onClick: () => setShowDeleteModal(true),
      icon: <Icon color="red.500"><Trash2 /></Icon>,
      label: 'Delete',
      color: 'red.500',
    });
  }

  return (
    <>
      <BrandModal
        show={showEditModal}
        isEditing
        hideModal={() => setShowEditModal(false)}
        handleEditBrand={handleEditBrand}
        name={name}
        index={index}
        brandingMap={brandingMap}
        isUpdatingBranding={!!isUpdatingBranding}
      />
      {showDeleteModal && (
        <DeleteBrandModal
          show={showDeleteModal}
          hideModal={() => setShowDeleteModal(false)}
          handleDeleteBrand={handleDeleteBrand}
          name={name}
          index={index}
          hasLoB={hasLoB}
          isUpdatingBranding={!!isUpdatingBranding}
        />
      )}
      <Row type="brand">
        <Card className={`${isSelected ? 'selectedBrandCard' : ''} brandList`}>
          <Row type="">
            <div className="brandName">
              <Avatar name={name} isCustomer className="brandAvatar" />
            </div>
            <div className="nameTitle">
              <Title type="subheader">
                <b>{name}</b>
              </Title>
              <div>Active</div>
            </div>
            <div className="iconRow">
              <MenuRoot lazyMount positioning={{ placement: 'bottom-start' }}>
                <MenuTrigger asChild>
                  <IconButton
                    data-testId={`${name?.replaceAll(' ', '')}ActionDropdown`}
                    aria-label="options"
                    variant="ghost"
                  >
                    <Ellipsis />
                  </IconButton>
                </MenuTrigger>
                <MenuContent>
                  {menuItems.map((item) => (
                    <MenuItem
                      color={item.color}
                      key={item.id}
                      onClick={item.onClick}
                      value={item.id}
                    >
                      {item.icon}
                      {item.label}
                    </MenuItem>
                  ))}
                </MenuContent>
              </MenuRoot>
            </div>
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default BrandCard;
