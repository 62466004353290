import { defineRecipe } from '@chakra-ui/react';
import { Input as BaseInput } from '@chakra-ui/theme/components';

export const inputRecipe = defineRecipe({
  base: {
    ...BaseInput.baseStyle,
    width: '100% !important',
  },
  variants: {
    variant: {
      outline: {
        ...BaseInput.variants?.outline,
        base: {
          borderColor: 'gray.300',
          size: 'md',
        },
      },
    },
  },
  defaultVariants: {
    variant: 'outline',
  },
});

export default inputRecipe;
