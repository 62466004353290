import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useIsServiceProductEnabled } from '@app/helpers/featureFlags';
import { CaseTypeEnum } from '@app/types/create-case';
import { StateType } from '@app/types/reducer-state';

const useCaseTypeSelector = <T>(
  caseType: CaseTypeEnum,
  selector: (settings: NonNullable<StateType['organizations']['organizationSettings']['caseTypes'][CaseTypeEnum]>) => T,
  defaultValue: T,
): T => useSelector((state: StateType) => {
    const settings = state.organizations?.organizationSettings?.caseTypes?.[caseType];
    return settings ? selector(settings) : defaultValue;
  });

export const useCaseTypeAutoAssignEnabled = (caseType: CaseTypeEnum): boolean => useCaseTypeSelector(
  caseType,
  (settings) => settings.autoAssignEnabled,
  false,
);

export const useCaseTypeMaxAssignments = (caseType: CaseTypeEnum): number => useCaseTypeSelector(
  caseType,
  (settings) => settings.maxAutoAssignedCases,
  0,
);

const useServiceCaseSelector = <T>(
  selector: (settings: NonNullable<StateType['organizations']['organizationSettings']['caseTypes'][CaseTypeEnum.service]>) => T,
  defaultValue: T,
): T => useSelector((state: StateType) => {
    const settings = state.organizations?.organizationSettings?.caseTypes?.[CaseTypeEnum.service];
    return settings ? selector(settings) : defaultValue;
  });

export const useServiceCaseSettingsEnabled = (): boolean => useServiceCaseSelector(
  (settings) => ('enabled' in settings ? settings.enabled : false),
  false,
);

export interface ServiceCaseStatus {
  isServiceCaseSettingsEnabled: boolean;
  isServiceProductEnabled: boolean;
  isServiceVisible: boolean;
}

export const useServiceCaseStatus = (): ServiceCaseStatus => {
  const isServiceCaseSettingsEnabled = useServiceCaseSettingsEnabled();
  const isServiceProductEnabled = useIsServiceProductEnabled();

  return useMemo(
    () => ({
      isServiceCaseSettingsEnabled,
      isServiceProductEnabled,
      isServiceVisible: isServiceCaseSettingsEnabled && isServiceProductEnabled,
    }),
    [isServiceCaseSettingsEnabled, isServiceProductEnabled],
  );
};

export const useServiceCaseEnabled = (): boolean => {
  const { isServiceVisible } = useServiceCaseStatus();
  return isServiceVisible;
};
