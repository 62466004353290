import {
  Grid,
  GridItem,
  Heading,
} from '@chakra-ui/react';
import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';

import { useCustomStyles } from '@app/chakra-theme/hooks/use-custom-styles';
import useScreenSize from '@app/components/Hooks/use-screen-size';
import {
  DISABLE_MARLEY_OPT_IN,
  CREATE_CLAIMS_FROM_CASES_TAB,
  CREATE_POLICY_FROM_CASES_TAB,
  CREATE_SERVICES_FROM_CASES_TAB,
} from '@app/constants/permissions';
import { useCheckPermissions } from '@app/helpers/common';
import { cleanPhone } from '@app/helpers/format';
import { useServiceCaseEnabled } from '@app/selectors/case-type';
import { type Job } from '@app/types/api/job';
import {
  CaseVisibilityEnum,
  CaseTypeEnum,
  ContactCaseRoleEnum,
  IFormInput,
  Contact,
  TabPermissions,
} from '@app/types/create-case';
import { StateType } from '@app/types/reducer-state';
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from '@chakra-snippets/dialog';

import { getCaseTypeFromPath } from './case-type-utils';
import { CASE_TYPE_CONFIG, TAB_FIELDS } from './constants';
import ContactDetailsForm from './contact-details-form';
import { CreateCaseContext } from './context';
import CreateCaseModalFooter from './create-case-modal-footer';
import CaseTypeTabs from './create-case-modal-tabs';
import { useCaseForm } from './hooks/use-case-form';
import useCaseFormInitialization from './hooks/use-case-form-initialization';

const CreateCaseModal: React.FC = () => {
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [isPhoneNumberEntered, setIsPhoneNumberEntered] = useState(false);
  const isClosingRef = useRef(false);

  const location = useLocation();
  const history = useNavigate();

  const isSmallScreen = useScreenSize();

  const contentRef = useRef<HTMLDivElement>(null);

  const isEditMode = location.pathname.includes('/edit/');
  const caseId = isEditMode ? location.pathname.split('/').pop() : undefined;

  const isCreateClaimsFromCasesTabEnabled = useCheckPermissions([CREATE_CLAIMS_FROM_CASES_TAB]);
  const isCreatePoliciesFromCasesTabEnabled = useCheckPermissions([CREATE_POLICY_FROM_CASES_TAB]);
  const isCreateServicesFromCasesTabEnabled = useCheckPermissions([CREATE_SERVICES_FROM_CASES_TAB]);
  const isServiceEnabled = useServiceCaseEnabled();

  const tabPermissions: TabPermissions = {
    [CaseTypeEnum.claim]: isCreateClaimsFromCasesTabEnabled,
    [CaseTypeEnum.policy]: isCreatePoliciesFromCasesTabEnabled,
    [CaseTypeEnum.service]: isCreateServicesFromCasesTabEnabled && isServiceEnabled,
    [CaseTypeEnum.general]: true,
  };

  // Get initial case type from path
  const { type: initialCaseType } = getCaseTypeFromPath({
    pathname: location.pathname,
    permissions: tabPermissions,
  });

  // Determine tab states based on case type and permissions
  const isCase = initialCaseType === CaseTypeEnum.general;

  // Update permissions based on case type
  const enabledTabs = {
    [CaseTypeEnum.claim]: !isCase || isCreateClaimsFromCasesTabEnabled,
    [CaseTypeEnum.policy]: !isCase || isCreatePoliciesFromCasesTabEnabled,
    [CaseTypeEnum.service]: (!isCase || isCreateServicesFromCasesTabEnabled) && isServiceEnabled,
    [CaseTypeEnum.general]: true,
  };

  const [activeTab, setActiveTab] = useState(initialCaseType);

  const caseData = useSelector((state: StateType): Job | null => {
    if (!caseId) return null;
    return state.jobs?.list?.find((item) => item.id === caseId) ?? null;
  });

  const userBrand = useSelector((state: StateType) => state.profile.properties.brandId);
  const brands = useSelector((state: StateType) => state.organizations?.branding ?? []);
  const currentUserId = useSelector((state: StateType) => state.auth?.user?._id);

  const isMarleyOptInDisabled = useCheckPermissions([DISABLE_MARLEY_OPT_IN]);
  const { scrollbarStyles } = useCustomStyles();

  const methods = useForm<IFormInput>({
    defaultValues: {
      contactLanguage: 'en',
      caseType: initialCaseType,
      caseVisibility: CaseVisibilityEnum.public,
      insuranceBrand: brands.find((brand) => brand === userBrand),
      assignedTo: currentUserId,
      contactCaseRole: 'insured' as keyof typeof ContactCaseRoleEnum,
    },
    mode: 'onBlur',
  });

  useCaseFormInitialization({
    isEditMode,
    caseData,
    methods,
    setIsPhoneNumberEntered,
    setSelectedContact,
    caseType: initialCaseType,
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting, isValid },
    unregister,
    reset,
    clearErrors,
    trigger,
  } = methods;

  const handleClose = useCallback(() => {
    if (isClosingRef.current) return;
    isClosingRef.current = true;

    reset();

    const pathSegments = location.pathname.split('/').filter(Boolean);
    pathSegments.pop();

    if (isEditMode) {
      pathSegments.pop();
    }

    const basePath = `/${pathSegments.join('/')}`;
    history(basePath);
    isClosingRef.current = false;
  }, [location.pathname, history, reset, isEditMode]);

  useEffect(() => () => {
    reset();
    isClosingRef.current = false;
  }, [reset]);

  const { onSubmit } = useCaseForm(handleClose);

  const handleFormSubmit = handleSubmit((data) => {
    onSubmit(data, isEditMode, caseId, caseData);
  });

  const tabConfig = {
    enabledTabs,
    isServiceEnabled,
    isEditMode,
    initialCaseType,
  };

  const isValidPhoneNumber = cleanPhone(getValues('phoneNumber')).length === 10;
  const submitButtonText = isEditMode ? 'Save Changes' : 'Create Case';
  const modalTitle = isEditMode ? `Edit ${CASE_TYPE_CONFIG[initialCaseType].modalTitle}` : 'Create Case';

  const contextValue = useMemo(() => ({ contentRef }), [contentRef]);
  const preventAndStopEvent = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleTabValidation = useCallback(async (newCaseType: CaseTypeEnum) => {
    // Clear all form errors first
    clearErrors();

    // Get the relevant fields for the current tab
    const relevantFields = TAB_FIELDS[newCaseType];

    // Reset validation state for non-relevant fields
    const allFields = Object.values(TAB_FIELDS).flat();
    const fieldsToUnregister = allFields.filter(
      (field) => !relevantFields.includes(field),
    );

    // Unregister non-relevant fields
    fieldsToUnregister.forEach((field) => {
      unregister(field);
    });

    // Trigger validation only for relevant fields
    await trigger(relevantFields as any);
  }, [clearErrors, unregister, trigger]);

  const handleCaseTypeChange = useCallback(async ({ value }: { value: string }) => {
    if (activeTab === value) return;

    setValue('caseType', value as CaseTypeEnum);

    // Handle special case for policy number
    if (
      value === CaseTypeEnum.claim
      || value === CaseTypeEnum.general
      || value === CaseTypeEnum.service
    ) {
      unregister('policyNumber');
    }

    await handleTabValidation(value as CaseTypeEnum);
    setActiveTab(value as CaseTypeEnum);
  }, [setValue, unregister, handleTabValidation, activeTab]);

  return (
    <DialogRoot
      open
      size={isSmallScreen ? 'full' : 'lg'}
      scrollBehavior="inside"
      onOpenChange={(e) => {
        if (!e.open) {
          handleClose();
        }
      }}
    >
      <CreateCaseContext.Provider value={contextValue}>
        <FormProvider {...methods}>
          <DialogContent
            ref={contentRef}
            onWheel={preventAndStopEvent}
            onTouchMove={preventAndStopEvent}
            onScroll={preventAndStopEvent}
          >
            <DialogHeader borderBottom="1px solid" borderColor="gray.200">
              <DialogTitle fontSize="xl">{modalTitle}</DialogTitle>
            </DialogHeader>
            <DialogCloseTrigger />
            <DialogBody {...scrollbarStyles}>
              <Grid width="100%" templateColumns={{ base: 'repeat(1, minmax(0, 1fr))', md: 'repeat(2, minmax(0, 1fr))' }} gap={4}>
                <GridItem colSpan={2}>
                  <Heading fontWeight="medium" size="sm">Contact Details</Heading>
                </GridItem>
                <ContactDetailsForm
                  selectedContact={selectedContact}
                  setSelectedContact={setSelectedContact}
                  setIsPhoneNumberEntered={setIsPhoneNumberEntered}
                  isEditMode={isEditMode}
                />
                {isPhoneNumberEntered && (
                <>
                  <GridItem colSpan={2}>
                    <Heading fontWeight="medium" size="sm">Case Details</Heading>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <CaseTypeTabs
                      activeTab={activeTab}
                      handleTabChange={handleCaseTypeChange}
                      tabConfig={tabConfig}
                    />
                  </GridItem>
                </>
                )}
              </Grid>
            </DialogBody>
            <CreateCaseModalFooter
              isMarleyOptInDisabled={isMarleyOptInDisabled}
              isPhoneNumberEntered={isPhoneNumberEntered}
              handleClose={handleClose}
              isSubmitting={isSubmitting}
              isValid={isValid}
              isValidPhoneNumber={isValidPhoneNumber}
              isEditMode={isEditMode}
              submitButtonText={submitButtonText}
              onSubmit={handleFormSubmit}
            />
          </DialogContent>
        </FormProvider>
      </CreateCaseContext.Provider>
    </DialogRoot>
  );
};

export default CreateCaseModal;
