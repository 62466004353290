import { Icon } from '@chakra-ui/react';
import { CirclePlus, SquarePen } from 'lucide-react';
import React from 'react';

import { Button } from '@chakra-snippets/button';

import useScreenSize from '../../Hooks/use-screen-size';

interface CreateWorkflowSettingsButtonProps {
  buttonText?: string;
  size?: 'sm' | 'md' | 'lg';
  onClick: () => void;
}

const CreateWorkflowSettingsButton = ({
  buttonText,
  size = 'md',
  onClick,
}: CreateWorkflowSettingsButtonProps) => {
  const isSmallScreen = useScreenSize();

  if (isSmallScreen) {
    return (
      <Button
        data-testid="add-workflow-settings-button"
        size={size}
        onClick={onClick}
        bg="transparent"
        css={{
          _hover: {
            bg: 'transparent',
          },
        }}
      >
        <Icon
          className="edit-icon"
          css={{ color: 'blue.60 !important' }}
        >
          <SquarePen />
        </Icon>
      </Button>
    );
  }

  return (
    <Button
      data-testid="add-workflow-settings-button"
      size={size}
      onClick={onClick}
    >
      <Icon><CirclePlus /></Icon>
      {buttonText || 'Add workflow settings'}
    </Button>
  );
};

export default CreateWorkflowSettingsButton;
