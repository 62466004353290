import { defineRecipe } from '@chakra-ui/react';

export const buttonRecipe = defineRecipe({
  base: {
    colorPalette: 'navy',
    fontSize: 'md',
  },
  defaultVariants: {
    variant: 'solid',
  },
  variants: {
    variant: {
      outline: {
        _icon: {
          color: 'colorPalette.solid',
        },
        _hover: {
          bg: 'colorPalette.muted',
        },
      },
      solid: {
        color: 'colorPalette.contrast !important',
        _icon: {
          color: 'colorPalette.contrast !important',
        },
      },
      link: {
        _icon: {
          color: 'colorPalette.solid',
        },
      },
      ghost: {
        _hover: {
          bg: 'gray.100',
        },
      },
    },
  },
});

export default buttonRecipe;
