import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import {
  ChevronDown,
  Lock,
  Unlock,
  FolderOpen,
  Archive,
  Search,
  MessageSquareMore,
  FileText,
  CircleCheck,
  CheckCheck,
  UserRound,
  Ban,
} from 'lucide-react';
import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  clearActiveJob,
  sendSurvey,
  cleanSendSurvey,
  jobPutRequest,
} from '@app/actions/job';
import completeReleaseIssues from '@app/actions/job-ts';
import { toggleConfirmation } from '@app/actions/ui';
import {
  setReleaseIssueModalOpen,
  setReleaseIssueModalError,
} from '@app/actions/ui-ts';
import { updateEndUserOptStatus } from '@app/actions/users';
import AlertDialog, { useAlertDialog } from '@app/components/chakra/alert-dialog';
import SimpleMenu from '@app/components/chakra/menus/simple-menu';
import { DROPDOWN_LABEL_MARK_ISSUES_COMPLETE } from '@app/constants/integration-constants';
import { userOptInStatus, OptInStatus } from '@app/constants/opt-in-status';
import { FEATURE_FLAG_TOTAL_LOSS_ASSIST, REDACTION_VIEW_ACCESS } from '@app/constants/permissions';
import { lookNumberConfig, getLookerEnvironment } from '@app/helpers/analytics';
import { isReleaseIssueOpen } from '@app/helpers/network-partners-utils';
import isLoadedFromMobile from '@app/helpers/platform';
import { localToRemoteUrl } from '@app/helpers/urls';
import { StateType } from '@app/types/reducer-state';
import { Button } from '@chakra-snippets/button';

import SurveyModal from './SurveyModal/survey-modal';
import ReleaseIssuesModal from '../../Form/ReleaseIssues/ReleaseIssuesModal';
import LargeLookContent from '../../LargeLookContent/large-look-content';
import chatModuleSelector from '../Chat/selector';

interface ChatActionsMenuProps {
  handleClose: (shouldNavigate?: boolean) => void;
}

const ChatActionsMenu = ({ handleClose }: ChatActionsMenuProps) => {
  const loadedFromMobile = isLoadedFromMobile();
  const {
    releaseIssues = [],
    isReleaseIssueModalOpen = false,
    selectedReleaseIssue = '',
    jobId,
    chatPdfLink,
    assignedOperator,
    isClosed,
    customerPdfLink,
    job,
    isOpenIssuesRemaining,
    lockingUserProgress = {},
  } = useSelector(chatModuleSelector);
  const { recipient, permissions } = useSelector((state: StateType) => ({
    recipient: state?.jobs?.activeJob?.customer || {},
    permissions: state?.auth?.permissions || [],
  }));
  const recipientId = recipient?.id || recipient?._id;
  const isUpdatingLockStatus = recipientId
    ? lockingUserProgress[recipientId] === true
    : false;
  const filteredReleaseIssues = useMemo(
    () => releaseIssues.filter((issue: unknown) => isReleaseIssueOpen(issue)),
    [releaseIssues],
  );
  const dispatch = useDispatch();
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [showLargeLook, setShowLargeLook] = useState(false);
  const { isDialogOpen: isLockoutDialogOpen, toggleDialog: toggleLockoutDialog } = useAlertDialog();
  const { isDialogOpen: isOptOutDialogOpen, toggleDialog: toggleOptOutDialog } = useAlertDialog();
  const history = useNavigate();

  type ThunkAppDispatch = ThunkDispatch<unknown, void, Action>;

  const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();
  const appThunkDispatch = useAppThunkDispatch();

  const handleToggleOpenCase = useCallback(async () => {
    const theJob = { ...job, isOpen: isClosed };
    const isReopeningJob = isClosed;
    try {
      await appThunkDispatch(jobPutRequest(theJob, isReopeningJob, false));
      if (!isClosed) {
        dispatch(clearActiveJob());
        handleClose(true);
        history('/chats');
      }
    } catch (error) {
      console.error('Error closing or reopening case', error);
    }
  }, [job, isClosed, appThunkDispatch, dispatch, handleClose, history]);

  const closeSendSurvey = () => {
    dispatch(cleanSendSurvey());
    setShowSurveyModal(false);
  };

  const handleOptOutCustomer = () => {
    dispatch(updateEndUserOptStatus({
      _id: recipient?.id || recipient?._id,
      jobId,
      status: OptInStatus.Unsubscribed,
    }));
    toggleOptOutDialog();
  };

  const toggleUserLocked = useCallback(
    (locked: boolean) => {
      dispatch(
        updateEndUserOptStatus({
          _id: recipient?.id || recipient?._id,
          jobId,
          status: locked ? OptInStatus.LockedOut : OptInStatus.OptedIn,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          isUpdatingLockStatus: true,
        }),
      );
      if (isLockoutDialogOpen) {
        toggleLockoutDialog();
      }
    },
    [
      dispatch,
      isLockoutDialogOpen,
      jobId,
      recipient?._id,
      recipient?.id,
      toggleLockoutDialog,
    ],
  );

  const operatorHasAuditLogAccess = useMemo(
    () => permissions.includes('AUDIT_LOG'),
    [permissions],
  );
  const operatorHasUnredactedAccess = useMemo(
    () => permissions.includes(REDACTION_VIEW_ACCESS),
    [permissions],
  );
  const isTotalLossEnabled = useMemo(
    () => permissions.includes(FEATURE_FLAG_TOTAL_LOSS_ASSIST),
    [permissions],
  );
  const isTwoWayEnabled = process.env.COPART_TWO_WAY_ENABLED;
  const shouldShowMarkIssuesComplete = isTotalLossEnabled && isTwoWayEnabled;
  const isRedactionOrgEnabled = useMemo(
    () => permissions.includes('FEATURE_FLAG_UI_REDACTION'),
    [permissions],
  );
  const recipientStatus = {
    blocked: recipient?.blocked ?? false,
    locked: recipient?.locked ?? false,
    verified: recipient?.verified ?? false,
  };
  const isPendingOptIn = userOptInStatus(recipientStatus) === OptInStatus.PendingOptIn;
  const isUnsubscribed = recipient?.blocked;
  const menuItems = useMemo(() => {
    const caseAction = [
      {
        id: `${!isClosed ? 'chatMenu-closecase' : 'chatMenu-reopencase'}`,
        value: `${!isClosed ? 'chatMenu-closecase' : 'chatMenu-reopencase'}`,
        label: !isClosed ? 'Close Case' : 'Re-open Case',
        leftIcon: !isClosed ? <Archive /> : <FolderOpen />,
        onClick: () => handleToggleOpenCase(),
      },
    ];
    const auditLogButtons = [
      {
        id: 'chatMenu-auditLog',
        value: 'chatMenu-auditLog',
        leftIcon: <Search />,
        label: 'Audit Log',
        onClick: () => setShowLargeLook(true),
      },
    ];
    const unredactedTranscriptButtons = [
      {
        id: 'chatMenu-casetrans',
        value: 'chatMenu-casetrans',
        leftIcon: <FileText />,
        label: 'Case Transcript',
        onClick: () => window.open(localToRemoteUrl(chatPdfLink)),
      },
      {
        id: 'chatMenu-usertrans',
        value: 'chatMenu-usertrans',
        leftIcon: <UserRound />,
        label: 'Customer Transcript',
        onClick: () => window.open(localToRemoteUrl(customerPdfLink)),
      },
    ];
    const redactionActions = [
      {
        id: 'chatMenu-casetrans-redacted',
        value: 'chatMenu-casetrans-redacted',
        leftIcon: <Lock />,
        label: 'Redacted Case Transcript',
        onClick: () => window.open(localToRemoteUrl(`${chatPdfLink}&redacted=true`)),
      },
      {
        id: 'chatMenu-usertrans-redacted',
        value: 'chatMenu-usertrans-redacted',
        label: 'Redacted Customer Transcript',
        leftIcon: <Lock />,
        onClick: () => window.open(localToRemoteUrl(`${customerPdfLink}&redacted=true`)),
      },
    ];
    const copartActions = [
      {
        id: 'chatMenu-resolve-release-issues',
        value: 'chatMenu-resolve-release-issues',
        label: DROPDOWN_LABEL_MARK_ISSUES_COMPLETE,
        leftIcon: <CircleCheck />,
        onClick: () => dispatch(setReleaseIssueModalOpen({ isOpen: true })),
      },
    ];
    const resendWelcomeAction = [
      {
        id: 'chatMenu-resendWelcome',
        value: 'chatMenu-resendWelcome',
        leftIcon: <MessageSquareMore />,
        label: 'Resend Opt-In Text',
        onClick: () => dispatch(
          toggleConfirmation({
            id: 'resendwelcome',
            isOpen: true,
            selectedId: jobId,
          }),
        ),
      },
    ];
    const sendSurveyAction = [
      {
        id: 'chatMenu-sendSurvey',
        value: 'chatMenu-sendSurvey',
        leftIcon: <CheckCheck />,
        label: 'Send Survey',
        onClick: () => setShowSurveyModal(true),
      },
    ];
    const optStatusAction = [
      {
        id: 'chatMenu-optOutCustomer',
        value: 'chatMenu-optOutCustomer',
        leftIcon: <Ban />,
        label: 'Opt-Out Customer',
        onClick: () => toggleOptOutDialog(),
      },
    ];
    const lockUnlockAction = [
      {
        id: `${
          recipient?.locked
            ? 'chatMenu-unlockCustomer'
            : 'chatMenu-lockCustomer'
        }`,
        value: `${
          recipient?.locked
            ? 'chatMenu-unlockCustomer'
            : 'chatMenu-lockCustomer'
        }`,
        leftIcon: recipient?.locked ? <Unlock /> : <Lock />,
        label: `${recipient?.locked ? 'Unlock Customer' : 'Lock Out Customer'}`,
        onClick: () => {
          if (recipient?.locked) {
            toggleUserLocked(false);
          } else {
            toggleLockoutDialog();
          }
        },
        isDisabled:
          isUpdatingLockStatus || (!recipient?.locked && isPendingOptIn),
      },
    ];
    return [
      ...((!isRedactionOrgEnabled || operatorHasUnredactedAccess) && !loadedFromMobile
        ? unredactedTranscriptButtons
        : []),
      ...(isRedactionOrgEnabled && !loadedFromMobile ? redactionActions : []),
      ...(operatorHasAuditLogAccess && !loadedFromMobile ? auditLogButtons : []),
      ...(isOpenIssuesRemaining && shouldShowMarkIssuesComplete
        ? copartActions
        : []),
      ...(!recipient.verified ? resendWelcomeAction : []),
      ...(recipient.verified && assignedOperator ? sendSurveyAction : []),
      ...(!isUnsubscribed ? optStatusAction : []),
      ...lockUnlockAction,
      ...caseAction,
    ];
  }, [isClosed,
    recipient?.locked,
    recipient.verified,
    isUpdatingLockStatus,
    isPendingOptIn,
    isRedactionOrgEnabled,
    operatorHasUnredactedAccess,
    loadedFromMobile,
    operatorHasAuditLogAccess,
    isOpenIssuesRemaining,
    shouldShowMarkIssuesComplete,
    assignedOperator,
    isUnsubscribed,
    handleToggleOpenCase,
    chatPdfLink,
    customerPdfLink,
    dispatch,
    jobId,
    toggleOptOutDialog,
    toggleUserLocked,
    toggleLockoutDialog,
  ]);

  const lookNumber = lookNumberConfig[getLookerEnvironment()].auditLog;

  const handleSubmitReleaseIssues = async (data: unknown) => {
    try {
      await appThunkDispatch(completeReleaseIssues({ jobId, data })).unwrap();
      dispatch(setReleaseIssueModalOpen({ isOpen: !isReleaseIssueModalOpen }));
    } catch (error) {
      dispatch(setReleaseIssueModalError());
    }
  };

  return (
    <div className="chat-component-header">
      {showLargeLook && (
        <LargeLookContent
          caseId={jobId}
          open={showLargeLook}
          closeLook={() => setShowLargeLook(false)}
          lookNumber={lookNumber}
        />
      )}
      <SurveyModal
        closeSendSurvey={() => closeSendSurvey()}
        sendSurvey={() => dispatch(sendSurvey(job))}
        isAssignedJob={!!assignedOperator}
        showSurvey={showSurveyModal}
      />
      <ReleaseIssuesModal
        show={isReleaseIssueModalOpen}
        setShow={() => dispatch(
          setReleaseIssueModalOpen({ isOpen: !isReleaseIssueModalOpen }),
        )}
        releaseIssueData={filteredReleaseIssues}
        onSubmitReleaseIssues={handleSubmitReleaseIssues}
        selectedReleaseIssue={selectedReleaseIssue}
      />
      {isOptOutDialogOpen && (
        <AlertDialog
          id="optout-customer"
          data-test="optout-customer-dialog"
          title="Confirm Opt Out"
          isDialogOpen={isOptOutDialogOpen}
          toggleDialog={toggleOptOutDialog}
          cancelText="Cancel"
          confirmText="Opt Out Customer"
          onConfirm={() => handleOptOutCustomer()}
          confirmColor="red"
        >
          Only confirm if the customer has requested to opt out. Opting the customer out will
          prevent text communications across all cases. The customer may opt back in at any time.
        </AlertDialog>
      )}
      {isLockoutDialogOpen && (
        <AlertDialog
          id="lockout-customer"
          data-test="lockout-customer-dialog"
          title="Confirm Lock Out"
          isDialogOpen={isLockoutDialogOpen}
          toggleDialog={toggleLockoutDialog}
          cancelText="Cancel"
          confirmText="Lock Out Customer"
          onConfirm={() => toggleUserLocked(true)}
          confirmColor="red"
        >
          Locking out a customer will block them from sending messages in all cases.
        </AlertDialog>
      )}
      <SimpleMenu
        options={menuItems}
        menuTrigger={(
          <Button variant="outline" size="sm">
            Actions
            <ChevronDown />
          </Button>
        )}
        onSelect={(e) => menuItems.find((item) => item.value === e.value)?.onClick?.()}
        maxHeight="400px"
      />
    </div>
  );
};

export default ChatActionsMenu;
