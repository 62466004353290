import React, {
  useEffect, useState, useMemo,
} from 'react';

import SearchableSelect from '@app/components/chakra/selects/searchable-select';
import { useGroupsQuery } from '@app/services/group';
import { Group } from '@app/types/api/group';

interface GroupFilterProps {
  onChange: (groups: Map<string, string>) => void;
}

const GroupFilter: React.FC<GroupFilterProps> = ({
  onChange,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [selectedGroups, setSelectedGroups] = useState<Map<string, string>>(
    new Map(),
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearch(searchQuery);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  const groupsQuery = useGroupsQuery({
    searchText: debouncedSearch || '',
  });

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = groupsQuery;

  const groups = useMemo(
    () => data?.pages.reduce(
      (accumulator, item) => [...accumulator, ...item.groups],
      [],
    ) || [],
    [data?.pages],
  );

  const handleMenuClose = () => {
    onChange(selectedGroups);
  };

  const options = useMemo(() => groups.map((group: Group) => ({
    value: group._id,
    label: group.name,
    selected: selectedGroups.has(group._id),
  })), [groups, selectedGroups]);

  const handleValueChange = (details: { value: string[] }) => {
    const newSelectedGroups = new Map(details.value.map((value) => [value, value]));
    setSelectedGroups(newSelectedGroups);
    onChange(newSelectedGroups);
  };

  return (
    <SearchableSelect
      id="group-filter"
      onClose={handleMenuClose}
      options={options}
      searchValue={searchQuery}
      onSearchChange={setSearchQuery}
      onValueChange={handleValueChange}
      loading={isLoading}
      increaseDataSet={fetchNextPage}
      shouldLoadMoreItems={!searchQuery?.trim() && hasNextPage}
      multiple
      minW="150px"
      itemName="groups"
      placeholder="All"
      withAvatar
      clearable
    />
  );
};

export default GroupFilter;
