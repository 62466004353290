import {
  ButtonGroup,
  Text,
  Input,
  Box,
} from '@chakra-ui/react';
import React, { useState } from 'react';

import { DELETE_WORKFLOW_SETTINGS } from '@app/constants/endpoints';
import { deleteData } from '@app/hooks/react-query-helpers';
import { Button } from '@chakra-snippets/button';
import {
  DialogRoot, DialogContent, DialogHeader, DialogBody, DialogFooter, DialogTitle,
} from '@chakra-snippets/dialog';
import { Field } from '@chakra-snippets/field';

interface DeleteWorkflowSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  workflowName: string;
  refetchAllData: () => void;
}

const DeleteWorkflowSettingsModal: React.FC<DeleteWorkflowSettingsModalProps> = ({
  isOpen,
  onClose,
  workflowName,
  refetchAllData,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const resetModalAndClose = () => {
    setInputValue('');
    setError('');
    onClose();
  };

  const handleDelete = async () => {
    if (inputValue !== workflowName) {
      setError('Workflow name does not match');
      return;
    }

    try {
      await deleteData(DELETE_WORKFLOW_SETTINGS(workflowName));
      refetchAllData();
      resetModalAndClose();
    } catch (err) {
      setError('Error deleting workflow settings');
    }
  };

  return (
    <DialogRoot open={isOpen} onOpenChange={resetModalAndClose} placement="center">
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Confirm delete</DialogTitle>
        </DialogHeader>

        <DialogBody>
          <Box bg="blue.50" p={4} borderRadius="md" mb={4}>
            <Text color="blue.700">Enter workflow name to delete</Text>
            <Text color="blue.700" fontWeight="bold">
              {workflowName}
            </Text>
          </Box>

          <Field invalid={!!error} errorText={error}>
            <Input
              placeholder="Enter workflow name to confirm"
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
                setError('');
              }}
            />
          </Field>
        </DialogBody>

        <DialogFooter>
          <ButtonGroup>
            <Button variant="ghost" onClick={resetModalAndClose}>
              Cancel
            </Button>
            <Button colorPalette="red" onClick={handleDelete}>
              Delete
            </Button>
          </ButtonGroup>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default DeleteWorkflowSettingsModal;
