import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { addUserRoles, removeUserRoles } from '@app/actions/users';
import SimpleSelect from '@app/components/chakra/selects/simple-select';
import { SYSTEM_ADMIN } from '@app/constants/permissions';
import { SYS_ADMIN } from '@app/constants/roles';
import { useCheckPermissions } from '@app/helpers/common';
import { User } from '@app/types/api/user';
import { toaster } from '@chakra-snippets/toaster';

interface AssignRoleProps {
  user: User;
}

const AssignRole: React.FC<AssignRoleProps> = ({
  user,
}) => {
  const dispatch = useDispatch();
  const [selectedRoles, setSelectedRoles] = useState<string[]>(user.roles ?? []);

  const hasRole = (roleName: string) => selectedRoles.some((role) => role === roleName);

  const orgRoles = useSelector((state) => state.users.groups);

  const handleMenuClose = async () => {
    const addRoles = selectedRoles.filter((role) => !user.roles?.includes(role)) ?? [];
    const removeRoles = user.roles?.filter((role) => !selectedRoles.includes(role)) ?? [];

    if (removeRoles.length > 0) {
      await dispatch(removeUserRoles(user, removeRoles, toaster));
    }

    if (addRoles.length > 0) {
      await dispatch(addUserRoles(user, addRoles, toaster));
    }
  };

  useEffect(() => {
    setSelectedRoles(user.roles ?? []);
  }, [user.roles]);

  const isUserSysAdmin = useCheckPermissions([SYSTEM_ADMIN]);
  const cannotAssignSysAdmin = (roleNames: string[]) => !isUserSysAdmin
  && roleNames.includes(SYS_ADMIN);

  const handleSelect = (details: { value: string[] }) => {
    if (user?.archived) return;
    if (!cannotAssignSysAdmin(details.value)) {
      setSelectedRoles(details.value);
    }
  };

  return (
    <SimpleSelect
      id={`assign-role-${user.id}`}
      data-testid={`assign-role-${user.id}`}
      onClose={handleMenuClose}
      onValueChange={handleSelect}
      options={orgRoles.map((role) => {
        const { groupName } = role;
        return {
          value: groupName,
          label: groupName,
          disabled: (cannotAssignSysAdmin([groupName]) || user?.archived),
          selected: hasRole(groupName),
        };
      })}
      closeOnSelect={false}
      multiple
      placeholder="No roles"
      itemName="roles"
      minW="150px"
      overrideMaxWidth
    />
  );
};

export default AssignRole;
