import { Tabs } from '@chakra-ui/react';
import React from 'react';

import { CaseTypeEnum, TabConfig } from '@app/types/create-case';

import ClaimForm from './claim-form';
import GeneralForm from './general-form';
import PolicyForm from './policy-form';
import ServiceForm from './service-form';

interface CaseTypeTabsProps {
  activeTab: string;
  handleTabChange:({ value }: { value: string }) => void;
  tabConfig: TabConfig;
}

const CaseTypeTabs: React.FC<CaseTypeTabsProps> = ({
  activeTab,
  handleTabChange,
  tabConfig,
}) => {
  const {
    enabledTabs,
    isServiceEnabled,
    isEditMode,
  } = tabConfig;

  const renderTabPanel = (tabName: string, Component: React.FC) => (
    <Tabs.Content px={0} value={tabName}>
      <Component />
    </Tabs.Content>
  );

  return (
    <Tabs.Root
      w="100%"
      variant="enclosed"
      value={activeTab}
      fitted
      onValueChange={handleTabChange}
      lazyMount
      unmountOnExit
    >
      <Tabs.List>
        <Tabs.Trigger
          disabled={isEditMode || !enabledTabs[CaseTypeEnum.claim]}
          value={CaseTypeEnum.claim}
        >
          Claim
        </Tabs.Trigger>
        <Tabs.Trigger
          disabled={isEditMode || !enabledTabs[CaseTypeEnum.policy]}
          value={CaseTypeEnum.policy}
        >
          Policy
        </Tabs.Trigger>
        <Tabs.Trigger
          disabled={isEditMode}
          value={CaseTypeEnum.general}
        >
          General
        </Tabs.Trigger>
        {isServiceEnabled && (
          <Tabs.Trigger
            disabled={isEditMode || !enabledTabs[CaseTypeEnum.service]}
            value={CaseTypeEnum.service}
          >
            Service
          </Tabs.Trigger>
        )}
      </Tabs.List>
      {renderTabPanel(CaseTypeEnum.claim, ClaimForm)}
      {renderTabPanel(CaseTypeEnum.policy, PolicyForm)}
      {renderTabPanel(CaseTypeEnum.general, GeneralForm)}
      {renderTabPanel(CaseTypeEnum.service, ServiceForm)}
    </Tabs.Root>
  );
};

export default CaseTypeTabs;
