import {
  Input,
  List,
  Portal,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  useFormContext, type RegisterOptions, type FieldValues, Path, PathValue,
} from 'react-hook-form';

import { useCustomStyles } from '@app/chakra-theme/hooks/use-custom-styles';
import { Field } from '@chakra-snippets/field';

interface AutoCompleteProps<T extends FieldValues> {
  id: string;
  label: string;
  name: Path<T>;
  options: { _id: string; label: string }[];
  defaultValue?: string;
  registerOptions?: RegisterOptions<T>;
  placeholder?: string;
}

const AutoComplete = <T extends FieldValues>({
  id,
  label,
  name,
  options,
  defaultValue,
  registerOptions,
  placeholder,
}: AutoCompleteProps<T>) => {
  const {
    open,
    onOpen,
    onClose,
  } = useDisclosure();
  const [searchQuery, setSearchQuery] = useState(defaultValue || '');
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const {
    setValue,
    getValues,
    trigger,
    register,
    formState: { errors },
  } = useFormContext<T>();
  const { formControlStyles } = useCustomStyles();

  useEffect(() => {
    register(name, registerOptions);
  }, [register, name, registerOptions]);

  const filteredOptions = options.filter(
    (option) => option.label.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const shouldShowOptions = searchQuery.length > 0 && open && filteredOptions.length > 0;

  const handleSelect = useCallback((option: { _id: string; label: string }) => {
    setSearchQuery(option.label);
    setValue(name, option._id as PathValue<T, Path<T>>);
    trigger(name);
    onClose();
  }, [name, setValue, trigger, onClose]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose();
    }
  }, [onClose]);

  const isRequired = registerOptions?.required;

  return (
    <Field
      id={name}
      invalid={!!errors[name]}
      css={formControlStyles}
      position="relative"
      label={`${label}${isRequired ? ' (Required)' : ''}`}
      errorText={errors[name]?.message as string}
    >
      <Input
        ref={inputRef}
        value={searchQuery || getValues(name)}
        onChange={(e) => {
          setSearchQuery(e.target.value);
          setValue(name, e.target.value as PathValue<T, Path<T>>);
          trigger(name);
          if (!open && e.target.value) onOpen();
        }}
        onKeyDown={handleKeyDown}
        placeholder={placeholder || ''}
        aria-autocomplete="list"
        aria-expanded={shouldShowOptions}
        aria-controls={`${id}-listbox`}
      />
      {shouldShowOptions && (
        <Portal>
          <Box
            ref={listRef}
            position="fixed"
            top={`${inputRef.current?.getBoundingClientRect().bottom}px`}
            left={`${inputRef.current?.getBoundingClientRect().left}px`}
            width={`${inputRef.current?.offsetWidth}px`}
            zIndex={1400}
          >
            <List.Root
              id={`${id}-listbox`}
              bg="white"
              boxShadow="lg"
              borderRadius="md"
              mt={1}
              maxH="200px"
              overflowY="auto"
              role="listbox"
            >
              {filteredOptions.map((option) => (
                <List.Item
                  key={option._id}
                  px={4}
                  py={2}
                  cursor="pointer"
                  _hover={{ bg: 'gray.100' }}
                  onClick={() => handleSelect(option)}
                  role="option"
                >
                  {option.label}
                </List.Item>
              ))}
            </List.Root>
          </Box>
        </Portal>
      )}
    </Field>
  );
};

export default AutoComplete;
