import { defineRecipe } from '@chakra-ui/react';

export const headingRecipe = defineRecipe({
  base: {
    fontWeight: 'normal',
    margin: '0 !important',
  },
  variants: {
    size: {
      xs: { textStyle: 'xs !important' },
      sm: { textStyle: 'sm !important' },
      md: { textStyle: 'md !important' },
      lg: { textStyle: 'lg !important' },
      xl: { textStyle: 'xl !important' },
    },
  },
});

export default headingRecipe;
