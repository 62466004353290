import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import AssignUser from '@app/components/chakra/menus/action/assign-user';
import { type IFormInput } from '@app/types/create-case';
import { Field } from '@chakra-snippets/field';

import { CreateCaseContext } from '../context';

const formControlStyles = {
  '&:hover .chakra-menu__menu-button': {
    borderColor: 'gray.300',
  },
};

const AssignUserMenu = () => {
  const {
    setValue,
    getValues,
    trigger,
    register,
    formState: { errors },
  } = useFormContext<IFormInput>();
  const { contentRef } = useContext(CreateCaseContext);

  register('assignedTo', {
    required: { value: true, message: 'You must select a user' },
  });

  const currentValue = getValues('assignedTo');

  const labelId = 'assignedTo-label';

  return (
    <Field
      id={labelId}
      invalid={!!errors.assignedTo}
      label="Assignee (Required)"
      errorText={errors.assignedTo?.message as string}
      css={formControlStyles}
    >
      <AssignUser
        id="assignedToForm"
        defaultLabel="Choose Assignee"
        rowId="assignedToForm"
        selectedId={currentValue}
        handleSelect={(option) => {
          setValue('assignedTo', option);
          trigger('assignedTo');
          trigger('caseVisibility');
        }}
        size="md"
        aria-labelledby={labelId}
        contentRef={contentRef}
        matchWidth
      />
    </Field>
  );
};

export default AssignUserMenu;
