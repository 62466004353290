import {
  Box,
  Text,
  Stack,
  Flex,
  type NumberInputValueChangeDetails,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';

import { CASE_TYPE_CONFIG } from '@app/components/CreateCase/constants';
import { CaseTypeEnum } from '@app/types/create-case';
import { Radio, RadioGroup } from '@chakra-snippets/radio';

import CaseTypeSettingsAutoAssign from './case-type-settings-auto-assign';
import CaseTypeSettingsHeader from './case-type-settings-header';
import {
  DEFAULT_MAX_CASES_AUTO_ASSIGN,
  MAX_CASES_AUTO_ASSIGN,
  MIN_CASES_AUTO_ASSIGN,
} from '../constants';

export interface CaseTypeSettingsData {
  enabled?: boolean;
  queueEnabled: boolean;
  autoAssignEnabled: boolean;
  maxAutoAssignedCases: number;
}

interface CaseTypeSettingsProps {
  caseType: CaseTypeEnum;
  initialSettings: CaseTypeSettingsData | undefined;
  onChange: (settings: CaseTypeSettingsData) => void;
}

interface SettingsUpdate {
  enabled?: boolean;
  queueEnabled?: boolean;
  autoAssignEnabled?: boolean;
  maxAutoAssignedCases?: number;
}

const getInitialSettings = (settings: CaseTypeSettingsData | undefined, isServiceType: boolean)
: CaseTypeSettingsData => ({
  enabled: isServiceType ? (settings?.enabled ?? false) : undefined,
  queueEnabled: settings?.queueEnabled ?? false,
  autoAssignEnabled: settings?.autoAssignEnabled ?? false,
  maxAutoAssignedCases: (settings?.maxAutoAssignedCases ?? DEFAULT_MAX_CASES_AUTO_ASSIGN) === 0
    ? DEFAULT_MAX_CASES_AUTO_ASSIGN
    : settings?.maxAutoAssignedCases ?? DEFAULT_MAX_CASES_AUTO_ASSIGN,
});

const CaseTypeSettings: React.FC<CaseTypeSettingsProps> = ({
  caseType,
  initialSettings,
  onChange,
}) => {
  const isServiceType = caseType === CaseTypeEnum.service;
  const title = CASE_TYPE_CONFIG[caseType].orgSettingsTitle;

  // Use useMemo to compute settings from initialSettings
  // This avoids recalculating on every render while still reflecting prop changes
  const settings = useMemo(
    () => getInitialSettings(initialSettings, isServiceType),
    [initialSettings, isServiceType],
  );

  const queueRadioValue = settings.queueEnabled ? 'enabled' : 'disabled';

  // Single function to handle all settings updates with validation
  const updateSettings = (updates: SettingsUpdate) => {
    // First create a complete new settings object
    const newSettings = { ...settings, ...updates };

    // Enforce settings dependencies
    if (!newSettings.queueEnabled) {
      newSettings.autoAssignEnabled = false;
    }
    if (!newSettings.autoAssignEnabled) {
      newSettings.maxAutoAssignedCases = DEFAULT_MAX_CASES_AUTO_ASSIGN;
    }
    if (isServiceType && !newSettings.enabled) {
      newSettings.autoAssignEnabled = false;
      newSettings.maxAutoAssignedCases = DEFAULT_MAX_CASES_AUTO_ASSIGN;
    }

    // Validate maxAutoAssignedCases
    if (newSettings.maxAutoAssignedCases !== undefined) {
      newSettings.maxAutoAssignedCases = Math.max(
        MIN_CASES_AUTO_ASSIGN,
        Math.min(MAX_CASES_AUTO_ASSIGN, newSettings.maxAutoAssignedCases),
      );
    }

    // Use Pattern 1: Parent is source of truth
    onChange(newSettings); // Parent will update initialSettings
  };

  // Simplified handler functions
  const handleQueueTypeChange = (value: string) => {
    const queueEnabled = value === 'enabled';
    updateSettings({
      queueEnabled,
      // When queue is disabled, auto-assign must be disabled
      autoAssignEnabled: queueEnabled ? settings.autoAssignEnabled : false,
    });
  };

  const handleAutoAssignChange = (isEnabled: boolean) => {
    updateSettings({
      autoAssignEnabled: isEnabled,
      // If auto-assign is enabled, queue must be enabled
      queueEnabled: isEnabled ? true : settings.queueEnabled,
    });
  };

  const handleMaxCasesChange = (details: NumberInputValueChangeDetails) => {
    const value = parseInt(details.value, 10);
    if (Number.isNaN(value)) return;

    updateSettings({
      maxAutoAssignedCases: Math.max(
        MIN_CASES_AUTO_ASSIGN,
        Math.min(MAX_CASES_AUTO_ASSIGN, value),
      ),
    });
  };

  const handleServiceTypeToggle = (enabled: boolean) => {
    updateSettings({ enabled });
  };

  const onChangeQueueType = (e: React.FormEvent<HTMLDivElement>) => {
    handleQueueTypeChange((e.target as HTMLInputElement).value);
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="md"
      p={6}
      mb={6}
      bg="white"
      borderColor="gray.200"
      maxWidth="750px"
    >
      <CaseTypeSettingsHeader
        title={title}
        isServiceType={isServiceType}
        enabled={settings.enabled ?? false}
        onToggle={handleServiceTypeToggle}
      />

      {(!isServiceType || (isServiceType && settings.enabled)) && (
        <RadioGroup
          name="isQueueEnabled"
          value={queueRadioValue}
          colorPalette="blue"
        >
          <Stack direction="column" gap={0}>
            <Box
              p={4}
              borderWidth="1px"
              borderRadius="md"
              borderTopRadius="md"
              borderBottomRadius={!settings.queueEnabled ? 'md' : 'none'}
              borderColor={!settings.queueEnabled ? 'blue.500' : 'gray.200'}
              mb={0}
            >
              <Flex alignItems="flex-start">
                <Radio
                  value="disabled"
                  colorPalette="blue"
                  mt={1}
                  onChange={onChangeQueueType}
                  data-testid={`${caseType.toLowerCase()}-queue-disabled-radio`}
                />
                <Box ml={3}>
                  <Text fontWeight="medium" color="gray.800">Queue disabled</Text>
                  <Text color="gray.500" fontSize="sm">
                    Cases will only show as unassigned and there is no list to display them.
                  </Text>
                </Box>
              </Flex>
            </Box>
            <Box
              p={4}
              borderWidth="1px"
              borderRadius="md"
              borderTopRadius={settings.queueEnabled ? 'md' : 'none'}
              borderBottomRadius="md"
              borderColor={settings.queueEnabled ? 'blue.500' : 'gray.200'}
            >
              <Flex alignItems="flex-start">
                <Radio
                  value="enabled"
                  colorPalette="blue"
                  mt={1}
                  onChange={onChangeQueueType}
                  data-testid={`${caseType.toLowerCase()}-queue-enabled-radio`}
                />
                <Box ml={3}>
                  <Text fontWeight="medium" color="gray.800">Queue enabled</Text>
                  <Text color="gray.500" fontSize="sm">
                    Cases not assigned will show in a queue and allow for a push or pull model.
                  </Text>
                </Box>
              </Flex>
              {settings.queueEnabled && (
                <CaseTypeSettingsAutoAssign
                  caseType={caseType}
                  autoAssignEnabled={settings.autoAssignEnabled}
                  maxAutoAssignedCases={settings.maxAutoAssignedCases}
                  onAutoAssignChange={handleAutoAssignChange}
                  onMaxCasesChange={handleMaxCasesChange}
                />
              )}
            </Box>
          </Stack>
        </RadioGroup>
      )}
    </Box>
  );
};

export default CaseTypeSettings;
