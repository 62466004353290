import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Icon, Text } from '@chakra-ui/react';
import { Button } from '@chakra-snippets/button';
import { CirclePlus } from 'lucide-react';
import { fetchData, useDynamicMutation } from '@app/hooks/react-query-helpers';
import { useQuery } from '@tanstack/react-query';
import { getKeys } from '@app/slices/templates';
import SimpleSelect from '@app/components/chakra/selects/simple-select';

import './MessageTemplates.less';
import MessageTemplateForm from '@app/components/Form/MessageTemplate/MessageTemplateForm';
import { extractTokens } from '@app/helpers/common';
import { MESSAGE_TEMPLATE_ROUTE } from '@app/constants/endpoints';
import { CREATE_TEMPLATE_OPTION } from '@app/components/Form/MessageTemplate/constants';
import BinocularsIcon from '../../../../../images/icons/binoculars.svg';
import { capitalizeEachWord } from '../../../../helpers/format';
import PermissionVerifier from '../../../PermissionVerifier/PermissionVerifier';
import TemplateCard from './TemplateCard';
import GhostRows from '../../../elements/Ghosts/Ghosts';
import Panel from '../../../Panel/Panel';
import SearchBar from '../../../SearchBar/SearchBar';
import { CREATE_TEMPLATE_BUTTON_TEXT, TEMPLATES_TAB_TITLE } from './constants';

const EmptyRows = ({
  searchTerm,
  setShow,
  hasNoMessages,
  lineOfBusinessName,
}) => (
  <div className="message-templates-empty">
    {hasNoMessages && (
      <div className="noTemplatesWrap">
        <p>
          {`There aren't any message templates for the ${
            lineOfBusinessName
              ? `${lineOfBusinessName} line of
          business`
              : 'Organization'
          }.`}
        </p>
        <Button onClick={() => setShow(true)} variant="outline">
          {CREATE_TEMPLATE_BUTTON_TEXT}
        </Button>
      </div>
    )}
    <div className="message-templates-empty-help">
      {searchTerm && !hasNoMessages ? (
        <>
          <div className="message-templates-empty-icon">
            <BinocularsIcon />
          </div>
          <div className="message-templates-empty-header">
            <Translate value="messageTemplates.noTemplateFound" />
          </div>
          {I18n.t('messageTemplates.coundNotFind')}
          {' '}
          &quot;
          {searchTerm}
          &quot;
        </>
      ) : (
        ''
      )}
    </div>
  </div>
);

EmptyRows.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  setShow: PropTypes.func.isRequired,
  hasNoMessages: PropTypes.bool.isRequired,
  lineOfBusinessName: PropTypes.string.isRequired,
};

const sortDropdownList = [
  {
    id: 'newest',
    value: 'newest',
    label: 'Newest',
    sort: 'createdAt:desc',
  },
  {
    id: 'oldest',
    value: 'oldest',
    label: 'Oldest',
    sort: 'createdAt:asc',
  },
  {
    id: 'lastUpdated',
    value: 'lastUpdated',
    label: 'Last Updated',
    sort: 'updatedAt:desc',
  },
  {
    id: 'az',
    value: 'az',
    label: 'A-Z',
    sort: 'displayName:asc',
  },
  {
    id: 'za',
    value: 'za',
    label: 'Z-A',
    sort: 'displayName:desc',
  },
];

/**
 * Message Templates Panel
 * @param {Object} lineOfBusiness line of business entity
 * */
const MessageTemplates = ({ lineOfBusiness = null }) => {
  const [show, setShow] = useState(false);
  const [formObject, setFormObject] = useState(); // template data for editing
  const [filter, setFilter] = useState('');
  const [sortType, setSortType] = useState(sortDropdownList[0]);
  const [submitFn, setSubmitFn] = useState(CREATE_TEMPLATE_OPTION);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getKeys());
  }, [dispatch]);

  const queryObj = {
    category: 'CHAT',
    searchFilter: filter,
    sort: sortType.sort,
    ...(lineOfBusiness?._id && { lineOfBusinessId: lineOfBusiness._id }),
  };
  const queryParams = new URLSearchParams(queryObj);
  const queryKey = ['templates', queryObj];
  const query = useQuery({
    queryKey,
    queryFn: () => fetchData(`${MESSAGE_TEMPLATE_ROUTE}/?${queryParams}`),
  });
  const templatesList = query?.data?.templates?.list || [];
  const { isLoading } = query;

  const messageTemplateMutation = useDynamicMutation(
    queryKey,
    {
      success: `Template ${submitFn}${
        submitFn === 'edit' ? 'e' : ''
      }d successfully`,
      error: 'Error updating templates',
    },
    () => setShow(false),
  );

  const handleModalOpen = (modalKey, template) => {
    setFormObject(template);
    setSubmitFn(modalKey);
    setShow(true);
  };

  const handleSubmitTemplate = (data, templateId) => {
    const { body, tokens } = extractTokens(data.body);
    const submitFnMap = {
      create: () => messageTemplateMutation.mutate({
        endpoint: '/api/templates',
        method: 'POST',
        body: {
          ...data,
          body,
          tokens,
          category: 'CHAT',
        },
      }),
      update: () => messageTemplateMutation.mutate({
        endpoint: `/api/templates/${templateId}`,
        method: 'PUT',
        body: {
          ...data,
          body,
          tokens,
        },
      }),
      delete: () => messageTemplateMutation.mutate({
        endpoint: `/api/templates/${templateId}`,
        method: 'PUT',
        body: {
          ...data,
          body,
          tokens,
          archived: true,
        },
      }),
    };
    submitFnMap[submitFn]();
  };

  const hasTemplates = templatesList.length > 0;

  const lineOfBusinessName = lineOfBusiness
    ? `${capitalizeEachWord(lineOfBusiness?.type)} ${capitalizeEachWord(
      lineOfBusiness?.displayName,
    )}`
    : '';

  const finalSortDropdownList = useMemo(
    () => sortDropdownList.map((sortOption) => ({
      ...sortOption,
      selected: sortType?.id === sortOption.id,
    })),
    [sortType],
  );

  return (
    <Panel
      className="template-mgmt-page"
      title={TEMPLATES_TAB_TITLE}
      header={(
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PermissionVerifier
            neededPermissions={['MESSAGE_TEMPLATE_ADD_AND_EDIT']}
          >
            <Button
              disabled={isLoading}
              onClick={() => handleModalOpen(CREATE_TEMPLATE_OPTION)}
            >
              <Icon><CirclePlus /></Icon>
              {CREATE_TEMPLATE_BUTTON_TEXT}
            </Button>
          </PermissionVerifier>
        </div>
      )}
    >
      <p>
        Create custom message templates available on
        {' '}
        {lineOfBusiness ? lineOfBusinessName : 'Organization'}
        {' '}
        cases.
      </p>
      <div className="message-templates">
        <MessageTemplateForm
          show={show}
          lob={lineOfBusiness}
          handleSubmit={handleSubmitTemplate}
          method={submitFn}
          toggleModal={setShow}
          templateData={formObject}
        />
        <div className="message-templates-list">
          {isLoading && <GhostRows type="card" />}
          {!isLoading && (
            <section className="countSortSearchBar">
              <div>
                {templatesList?.length === 1
                  ? `${templatesList?.length} Template`
                  : `${templatesList?.length} Templates`}
              </div>
              <div className="searchSort">
                <SearchBar
                  placeholder="Search templates..."
                  onChange={(e) => setFilter(e.target.value)}
                  value={filter}
                />
                <Text whiteSpace="nowrap" pr={1}>Sort by:</Text>
                <SimpleSelect
                  options={finalSortDropdownList}
                  itemName={`Sort: ${sortType?.label}`}
                  onValueChange={(e) => setSortType(finalSortDropdownList
                    .find((item) => item.value === e.value[0]))}
                  sameWidth
                  minW="100px"
                />
              </div>
            </section>
          )}
          <section className="scrollableTemplateList">
            {hasTemplates
              && !isLoading
              && templatesList.map(
                (
                  template, // TODO: use query results instead of the list
                ) => (
                  <TemplateCard
                    template={template}
                    setEditItem={() => handleModalOpen('update', template)}
                    setTemplateToRemove={() => handleModalOpen('delete', template)}
                    key={template?.id}
                  />
                ),
              )}
            {!hasTemplates && !isLoading && (
              <EmptyRows
                searchTerm={filter}
                setShow={setShow}
                hasNoMessages={templatesList?.length === 0}
                lineOfBusinessName={lineOfBusinessName}
              />
            )}
          </section>
        </div>
      </div>
    </Panel>
  );
};

MessageTemplates.propTypes = {
  lineOfBusiness: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,
};

export default MessageTemplates;
