import { createListCollection } from '@chakra-ui/react';
import _ from 'lodash';
import React, { useState } from 'react';

import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from '@chakra-snippets/select';

import { detectTimezone, timeZoneOptions } from '../Utils';

interface Reply {
  sendCriteria: {
    timeZone: string;
  };
}

interface TimezoneDropdownProps {
  reply: Reply;
  setReply: (r: Reply) => void;
  contentRef: React.RefObject<HTMLDivElement>;
}

const TimezoneDropdown: React.FC<TimezoneDropdownProps> = ({
  reply,
  setReply,
  contentRef,
}) => {
  const [timezone, setTimezone] = useState(
    _.has(reply, 'sendCriteria.timeZone')
      ? reply.sendCriteria.timeZone
      : 'EST/EDT',
  );
  const [defaultTimezone] = useState(
    detectTimezone()?.label,
  );
  const [isTimezoneDropdownOpen, setIsTimezoneDropdownOpen] = useState(false);

  const handleSelectTimeZone = (e: string) => {
    setTimezone(e);
    setIsTimezoneDropdownOpen(!isTimezoneDropdownOpen);
    setReply({
      ...reply,
      sendCriteria: {
        ...reply.sendCriteria,
        timeZone: e,
      },
    });
  };

  return (
    <SelectRoot
      collection={createListCollection({
        items: timeZoneOptions,
        itemToString: (item) => item.label,
        itemToValue: (item) => item.id,
      })}
      value={[timezone]}
      onValueChange={(e) => handleSelectTimeZone(e.value[0])}
      defaultValue={defaultTimezone ? [defaultTimezone] : undefined}
      w="50%"
    >
      <SelectTrigger>
        <SelectValueText />
      </SelectTrigger>
      <SelectContent portalRef={contentRef}>
        {timeZoneOptions.map((option) => (
          <SelectItem key={option.id} item={option}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </SelectRoot>
  );
};

export default TimezoneDropdown;
