import { Lock } from 'lucide-react';
import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { useDispatch } from 'react-redux';

import { updateGroups } from '@app/actions/users';
import SearchableSelect from '@app/components/chakra/selects/searchable-select';
import { useGroupsQuery } from '@app/services/group';
import { Group } from '@app/types/api/group';
import { User } from '@app/types/api/user';
import { toaster } from '@chakra-snippets/toaster';

interface AssignGroupProps {
  user: User;
}

const AssignGroup: React.FC<AssignGroupProps> = ({ user }) => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [selectedGroups, setSelectedGroups] = useState<string[]>(
    user.groups ?? [],
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearch(searchQuery);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  useEffect(() => {
    setSelectedGroups(user.groups ?? []);
  }, [user.groups]);

  const userGroupsQuery = useGroupsQuery({
    searchText: debouncedSearch || '',
    ids: [...(user.groups ?? []), ...(user.groupLeads ?? [])],
  });

  const allGroupsQuery = useGroupsQuery({
    searchText: debouncedSearch || '',
  });

  const { data: userGroupsData, isLoading: isLoadingUserGroups } = userGroupsQuery;

  const {
    data: allGroupsData,
    isLoading: isLoadingAllGroups,
    fetchNextPage,
    hasNextPage,
  } = allGroupsQuery;

  const groups = useMemo(
    () => {
      const userGroups = userGroupsData?.pages.reduce(
        (accumulator, item) => [...accumulator, ...item.groups],
        [],
      ) || [];

      const otherGroups = allGroupsData?.pages.reduce(
        (accumulator, item) => [...accumulator, ...item.groups],
        [],
      ) || [];

      // Filter out duplicates and combine user's groups with other groups
      const userGroupIds = new Set(userGroups.map((g: Group) => g._id));
      const uniqueOtherGroups = otherGroups.filter((g: Group) => !userGroupIds.has(g._id));

      return [...userGroups, ...uniqueOtherGroups];
    },
    [userGroupsData?.pages, allGroupsData?.pages],
  );

  const isLoading = isLoadingUserGroups || isLoadingAllGroups;

  const handleMenuClose = useCallback(async () => {
    const addGroups = selectedGroups.filter((groupId) => !user.groups?.includes(groupId)) ?? [];
    const removeGroups = user.groups?.filter((groupId) => !selectedGroups.includes(groupId)) ?? [];

    if (addGroups.length > 0 || removeGroups.length > 0) {
      dispatch(
        updateGroups(user, {
          add: addGroups,
          remove: removeGroups,
        }, toaster),
      );
      setSelectedGroups(user.groups ?? []);
    }
    setSearchQuery('');
  }, [dispatch, selectedGroups, user]);

  const buildButtonLabel = (
    userGroups: string[] = [],
    orgGroups: Group[] = [],
  ) => {
    const actualUserGroups = userGroups.filter(
      (groupId) => orgGroups.some((group) => group._id === groupId),
    );

    const groupsCount = userGroups.length || 0;
    const hasMultipleGroups = groupsCount > 1;

    if (hasMultipleGroups) {
      return `${groupsCount} Groups`;
    }

    if (groupsCount === 1) {
      const groupName = orgGroups.find(
        (group) => group._id === actualUserGroups[0],
      )?.name;
      return groupName || 'No Groups';
    }

    return 'No Groups';
  };

  const buttonLabel = buildButtonLabel(
    [...(user.groups || []), ...(user.groupLeads || [])],
    groups,
  );

  const menuOptions = useMemo(() => {
    const hasGroup = (groupId: string) => selectedGroups.some((group) => group === groupId);

    const hasGroupLead = (groupId: string) => user.groupLeads?.some((group) => group === groupId);

    const getGroupTitle = (
      isLead: boolean | undefined,
      hasGroupAccess: boolean | undefined,
    ) => {
      if (isLead) return 'Lead';
      if (hasGroupAccess) return 'Member';
      return 'Member';
    };

    const leads = [...groups].filter((group) => hasGroupLead(group._id || ''));
    const presSelectedGroups = new Set(user?.groups || []);
    const members = [...groups].filter((group) => !hasGroupLead(group._id || '')).sort((a, b) => {
      const aIsSelected = presSelectedGroups.has(a._id || '');
      const bIsSelected = presSelectedGroups.has(b._id || '');
      if (aIsSelected && !bIsSelected) return -1;
      if (!aIsSelected && bIsSelected) return 1;
      return 0;
    });

    return [...leads, ...members]
      .map((group: Group) => {
        const isLead = hasGroupLead(group._id || '');
        const hasGroupAccess = hasGroup(group._id || '');
        return {
          value: group._id || '',
          label: group.name,
          group: getGroupTitle(isLead, hasGroupAccess),
          disabled: isLead,
          selected: Boolean(hasGroupAccess || isLead),
          disabledIcon: isLead ? <Lock /> : undefined,
          disabledTooltip: isLead ? 'You can change the lead status from the group management page.' : undefined,
        };
      });
  }, [groups, selectedGroups, user?.groupLeads, user?.groups]);

  const handleValueChange = (details: { value: string[] }) => {
    const visibleOptionIds = new Set(menuOptions.map((option) => option.value));
    const preservedSelections = selectedGroups.filter((groupId) => !visibleOptionIds.has(groupId));

    setSelectedGroups([...preservedSelections, ...details.value]);
  };

  return (
    <SearchableSelect
      id={`assign-group-${user.id}`}
      onClose={handleMenuClose}
      options={menuOptions}
      searchValue={searchQuery}
      onSearchChange={setSearchQuery}
      loading={isLoading}
      increaseDataSet={fetchNextPage}
      shouldLoadMoreItems={!searchQuery?.trim() && hasNextPage}
      multiple
      minW="150px"
      itemName="groups"
      placeholder={buttonLabel}
      withAvatar
      onValueChange={handleValueChange}
      valueRender={() => buildButtonLabel(
        [...(user.groups || []), ...(user.groupLeads || [])],
        groups,
      )}
    />
  );
};

export default AssignGroup;
