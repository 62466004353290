/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MoreIcon } from '@himarley/unity';
import { IconButton } from '@chakra-ui/react';
import SimpleMenu from '@app/components/chakra/menus/simple-menu';
import AssignDropdown from '../../../AssignDropdown/AssignDropdown';
import TableCell from '../TableCell/TableCell';
import { toggleConfirmation } from '../../../../actions/ui';
import CopyIcon from '../../../../../images/icons/copy.svg';

import './TableRow.less';
import { copyTextToClipboard } from '../../../../helpers/common';

const tip = (value) => <Tooltip id="tooltip">{value}</Tooltip>;

const overlay = (value, overlayValue) => <span><OverlayTrigger placement="right" overlay={tip(overlayValue)}><span>{value}</span></OverlayTrigger></span>;

const NewActionMenu = ({ options, handleSelect }) => (
  <SimpleMenu
    onSelect={(e) => handleSelect(e.value)}
    options={options}
    menuTrigger={(
      <IconButton variant="ghost" size="sm">
        <MoreIcon />
      </IconButton>
    )}
  />
);

const TableRow = ({
  item, className, useDefault, itemId, header, actions,
  children, columns, toggleConfirmation, hasAccessToCase, useNewActionMenu,
}) => {
  const TableRowActionMenu = useNewActionMenu ? NewActionMenu : AssignDropdown;

  const memoizedSelect = useCallback((selectId) => {
    const action = actions.find((a) => a.value === selectId);
    if (action.confirmation) {
      toggleConfirmation({ id: action.value, isOpen: true, selectedId: item.id });
    } else {
      action.action(itemId);
    }
  }, [actions, item, itemId, toggleConfirmation]);

  return (
    <div className={`sort-row sort-row-data ${!hasAccessToCase ? 'redacted' : ''} table-row ${className || ''} `}>
      {header}
      {columns && columns.map((c) => {
        const location = Array.isArray(c.location)
          ? c.location.find((l) => _.get(item, l)) : c.location;

        let label = item && _.get(item, location, '');
        const secondaryLabel = (item && c.secondaryLocation) ? item[c.secondaryLocation] : '';
        const id = item && _.get(item, 'id', '');
        if (useDefault && label === undefined) {
          label = c.default;
        }

        let cell;
        if (c.tooltip) {
          const tooltipvalue = item && _.get(item, c.tooltip);
          const tooltipformat = c.tooltipFormat
            ? (c.tooltipFormat({ value: tooltipvalue }))
            : (<span>{tooltipvalue}</span>);
          cell = overlay(label, tooltipformat);
        } else {
          cell = c.format
            ? (hasAccessToCase ? c.format({ value: label, id, isGroup: item?.isGroup }) : '[ Private ]')
            : (<div title={hasAccessToCase ? label : '[ Private ]'}>{hasAccessToCase ? label : '[ Private ]'}</div>);
        }
        return (
          <TableCell
            key={c.label}
            id={c.id || location}
            secondaryLabel={secondaryLabel}
            className
          >
            <div className="table-row-item-label">
              {cell}
              {c.copyEnabled ? <CopyIcon onClick={() => copyTextToClipboard(label)} className="table-row-copy-icon" /> : <div className="table-row-copy-icon" />}
            </div>
          </TableCell>
        );
      })}
      {children}
      {actions.length > 0
        && (
          <TableCell id="actions">
            <div className="action-dropdown">
              {hasAccessToCase
                ? (
                  <TableRowActionMenu
                    id="actions"
                    defaultLabel="Actions"
                    pullRight
                    handleSelect={memoizedSelect}
                    options={actions}
                  />
                )
                : <div />}
            </div>
          </TableCell>
        )}
    </div>
  );
};

TableRow.propTypes = {
  children: PropTypes.node,
  itemId: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  header: PropTypes.node,
  actions: PropTypes.arrayOf(Object),
  columns: PropTypes.arrayOf(Object).isRequired,
  item: PropTypes.instanceOf(Object),
  useDefault: PropTypes.bool,
  toggleConfirmation: PropTypes.func,
  hasAccessToCase: PropTypes.bool,
  useNewActionMenu: PropTypes.bool,
};

TableRow.defaultProps = {
  className: '',
  actions: [],
  children: null,
  header: '',
  item: {},
  id: '',
  itemId: '',
  useDefault: false,
  toggleConfirmation: () => { },
  hasAccessToCase: true,
  useNewActionMenu: false,
};

export { TableRow };

export default connect(null, { toggleConfirmation })(TableRow);
