import { Button } from '@chakra-ui/react';
import React, { useState, useRef } from 'react';

import Spinner from '@app/components/chakra/spinner';
import {
  DialogRoot, DialogContent, DialogHeader, DialogBody,
  DialogCloseTrigger, DialogTitle,
} from '@chakra-snippets/dialog';

import OfficeHoursRow from './office-hours-row';
import OfficeHoursModal from './OfficeHoursModal/OfficeHoursModal';
import NoMessages from '../SharedComponents/NoMessages/NoMessages';
import { type Reply } from '../types';

import './OfficeHours.less';

interface OfficeHoursProps {
  userId: string;
  isLoading: boolean;
  oooReplies: Reply[];
  error: string;
  reply: Reply;
}

const OfficeHours: React.FC<OfficeHoursProps> = ({
  userId,
  isLoading,
  oooReplies,
  error,
  reply,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const contentRef = useRef(null);
  let body: React.JSX.Element | React.JSX.Element[] = <NoMessages error={error} />;
  if (isLoading) {
    body = (
      <div className="autoreplies-loading-icon">
        <Spinner h="50px" w="50px" />
      </div>
    );
  } else if (oooReplies && oooReplies.length > 0) {
    body = oooReplies.map((oooReply) => (
      <OfficeHoursRow key={oooReply.id} userId={userId} reply={oooReply} />
    ));
  }

  return (
    <>
      <div className="auto-replies-container office-hours-container">
        <div className="office-hours-header">
          <h2>Office Hours Messages</h2>
          {oooReplies && oooReplies.length > 0 ? null : (
            <div className="button-row">
              <Button
                data-test="add-office-hours-message"
                variant="ghost"
                size="sm"
                onClick={() => setIsModalOpen(true)}
              >
                + Create New Message
              </Button>
            </div>
          )}
        </div>
        <div className="office-hours-subheader">
          <p>
            Configure automated messages that will be sent to customers during
            regular business hours or after regular business hours.
          </p>
        </div>
        {body}
      </div>
      {isModalOpen ? (
        <DialogRoot
          data-test="create-ooo-modal"
          open={isModalOpen}
          onOpenChange={(e) => setIsModalOpen(e.open)}
          size="lg"
        >
          <DialogContent className="create-ooo-modal" ref={contentRef}>
            <DialogHeader>
              <DialogTitle>New Away Message</DialogTitle>
            </DialogHeader>
            <DialogBody>
              <OfficeHoursModal
                pageProps={{ reply, userId }}
                toggleModal={setIsModalOpen}
                contentRef={contentRef}
              />
            </DialogBody>
            <DialogCloseTrigger />
          </DialogContent>
        </DialogRoot>
      ) : null}
    </>
  );
};

export default OfficeHours;
