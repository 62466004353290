import {
  createListCollection, Box, Flex, Spinner, Icon, SelectValueChangeDetails,
} from '@chakra-ui/react';
import { SearchBar } from '@himarley/unity';
import React, { useCallback, useRef } from 'react';

import { Avatar } from '@chakra-snippets/avatar';
import {
  SelectRoot,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValueText,
  SelectItemGroup,
} from '@chakra-snippets/select';
import { Tooltip } from '@chakra-snippets/tooltip';

interface Option {
  label: string;
  value: string;
  group?: string;
  disabled?: boolean;
  selected: boolean;
  disabledIcon?: React.ReactNode;
  disabledTooltip?: string;
}

interface SearchableSelectProps {
  id: string;
  placeholder?: string;
  options: Option[];
  onClose?: () => void;
  searchValue: string;
  onSearchChange: (value: string) => void;
  loading?: boolean;
  shouldLoadMoreItems?: boolean;
  lazyMount?: boolean;
  increaseDataSet?: () => void;
  multiple?: boolean;
  clearable?: boolean;
  minW?: string;
  itemName?: string;
  withAvatar?: boolean;
  onValueChange: (details: SelectValueChangeDetails<Option>) => void;
  contentRef?: React.RefObject<HTMLDivElement>;
  matchWidth?: boolean;
  valueRender?: () => React.ReactNode;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

const SelectMenuItem = ({ option, withAvatar }: {
  option: Option;
  withAvatar?: boolean;
}) => (
  option.disabledTooltip ? (
    <Tooltip showArrow ids={{ trigger: option.value }} content={option.disabledTooltip}>
      <span>
        <SelectItem
          key={option.value}
          item={option}
        >
          <Flex gap="2" justifyContent="flex-start" alignItems="center">
            {withAvatar && <Avatar size="xs" name={option.label} />}
            {option.label}
            {option.disabledIcon && <Icon>{option.disabledIcon}</Icon>}
          </Flex>
        </SelectItem>
      </span>
    </Tooltip>
  ) : (
    <SelectItem key={option.value} item={option}>
      <Flex gap="2" justifyContent="flex-start" alignItems="center">
        {withAvatar && <Avatar size="xs" name={option.label} />}
        {option.label}
        {option.disabledIcon && <Icon>{option.disabledIcon}</Icon>}
      </Flex>
    </SelectItem>
  )
);

const SearchableSelect: React.FC<SearchableSelectProps> = ({
  id,
  placeholder,
  options,
  onClose,
  searchValue,
  onSearchChange,
  loading,
  shouldLoadMoreItems,
  lazyMount = false,
  increaseDataSet,
  multiple = false,
  clearable = false,
  minW,
  itemName,
  withAvatar = false,
  onValueChange,
  contentRef,
  matchWidth = false,
  valueRender,
  disabled = false,
  size = 'md',
}) => {
  const observer = useRef<IntersectionObserver | null>(null);

  const lastPostElementRef = useCallback(
    (node: Element | null) => {
      if (!shouldLoadMoreItems || !increaseDataSet) {
        return;
      }
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && increaseDataSet) {
          increaseDataSet();
        }
      });

      if (node) observer.current?.observe(node);
    },
    [shouldLoadMoreItems, increaseDataSet],
  );

  const hasGroup = options?.some((option) => option.group);
  let groupedItems = null;
  let groupedOptions: { [key: string]: typeof options } = {};
  if (hasGroup) {
    groupedOptions = options.reduce((acc, option) => {
      if (!option.group) {
        if (!acc.ungrouped) {
          acc.ungrouped = [];
        }
        acc.ungrouped.push(option);
      } else if (option.group) {
        if (!acc[option.group]) {
          acc[option.group] = [];
        }
        acc[option.group].push(option);
      }
      return acc;
    }, {} as { [key: string]: typeof options });

    groupedItems = (
      <>
        {groupedOptions.ungrouped?.map((option) => (
          <SelectMenuItem option={option} withAvatar={withAvatar} data-testid={option.value} />
        ))}
        {Object.keys(groupedOptions)
          .filter((group) => group !== 'ungrouped')
          .map((group) => (
            <SelectItemGroup key={group} label={group}>
              {groupedOptions[group].map((option) => (
                <SelectMenuItem
                  option={option}
                  withAvatar={withAvatar}
                  data-testid={option.value}
                />
              ))}
            </SelectItemGroup>
          ))}
      </>
    );
  }

  return (
    <SelectRoot
      id={id}
      data-testid={id}
      multiple={multiple}
      collection={createListCollection({
        items: options,
        itemToString: (item) => item.label,
        itemToValue: (item) => item.value,
      })}
      onExitComplete={onClose}
      lazyMount={lazyMount}
      minW={minW}
      value={options.filter((option) => option.selected).map((option) => option.value)}
      positioning={{
        sameWidth: matchWidth,
      }}
      onValueChange={onValueChange}
      disabled={disabled}
      size={size}
    >
      <SelectTrigger clearable={clearable}>
        <SelectValueText itemName={itemName} placeholder={placeholder}>
          {valueRender && (() => valueRender())}
        </SelectValueText>
      </SelectTrigger>
      <SelectContent portalled portalRef={contentRef} data-testid={`${id}-select-content`}>
        <Box
          top={0}
          bg="white"
          zIndex={1}
          borderBottom="1px solid"
          borderColor="gray.200"
          pb={1}
          px={2}
        >
          <Flex justify="space-between">
            <Box w="90%" css={{ '& div': { width: 'unset' } }}>
              <SearchBar
                id={`${id}-search`}
                placeholder="Search"
                value={searchValue}
                onValueChange={onSearchChange}
                onClear={() => onSearchChange?.('')}
              />
            </Box>
          </Flex>
        </Box>
        {loading ? (
          <Flex justify="center" align="center" minH="250px" py={4}>
            <Spinner size="sm" color="gray.400" />
          </Flex>
        ) : (
          <Box maxH="260px" overflowY="auto">
            {hasGroup ? groupedItems : options.map((option) => (
              <SelectMenuItem option={option} withAvatar={withAvatar} data-testid={option.value} />
            ))}
            <div ref={lastPostElementRef} />
          </Box>
        )}
      </SelectContent>
    </SelectRoot>
  );
};

export default SearchableSelect;
