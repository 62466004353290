/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Divider, Ghosts } from '@himarley/unity';
import { CirclePlus } from 'lucide-react';
import { Button } from '@chakra-snippets/button';
import { Icon } from '@chakra-ui/react';
import BrandModal from './BrandModal';
import {
  updateBranding,
  setIsUpdatingBrand,
} from '../../../actions/organization';
import BrandLinesColumn from './BrandLinesColumn';
import './CompanyHierarchy.less';

const buildBranding = (organization) => {
  const brandingArray = organization.branding?.length ? organization.branding : [];
  return brandingArray;
};

const buildBrandToLinesOfBusinessMap = (branding, lob) => {
  const brandToLinesOfBusinessMap = new Map(branding?.map((brand) => [brand, []]));

  lob?.forEach((lineOfBusiness) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    brandToLinesOfBusinessMap.has(lineOfBusiness?.branding)
      && brandToLinesOfBusinessMap.set(lineOfBusiness.branding, [
        ...brandToLinesOfBusinessMap.get(lineOfBusiness?.branding),
        lineOfBusiness,
      ]);
  });

  return brandToLinesOfBusinessMap;
};

const CompanyHierarchy = ({ organization }) => {
  const dispatch = useDispatch();
  const isGettingBrands = useSelector(
    (state) => state?.organizations?.isGettingFullOrganizationData,
  );
  const isUpdatingBranding = useSelector((state) => state?.organizations?.isUpdatingBranding);

  const brandingArray = buildBranding(organization);
  const [showModal, setShowModal] = useState(false);
  const [branding, setBranding] = useState(brandingArray);
  // since brands can be sorted&filtered a hashmap is required
  // to keep track of which string within array user is editing/deleting
  const brandingMap = new Map(branding.map((brand, index) => [brand, index]));

  const brandToLinesOfBusinessMap = buildBrandToLinesOfBusinessMap(branding, organization?.linesOfBusiness);

  useEffect(() => {
    const newBrandingArray = buildBranding(organization);
    setBranding(newBrandingArray.sort((a, b) => a.localeCompare(b)));
  }, [organization]);

  useEffect(() => {
    if (isUpdatingBranding === false) {
      dispatch(setIsUpdatingBrand(null));
      setShowModal(false);
    } else if (isUpdatingBranding === 'error') {
      dispatch(setIsUpdatingBrand(null));
    }
  }, [dispatch, isUpdatingBranding]);

  const onAddBrand = (newBrand) => {
    const successText = `${newBrand} Brand created.`;
    const errorText = `${newBrand} Brand could not be created. Please try again.`;
    dispatch(updateBranding(
      {
        organizationId: organization?._id,
        branding: [...organization.branding, newBrand],
      },
      successText,
      errorText,
    ));
  };

  const onEditBrand = (newBrand, index) => {
    const successText = 'Brand name updated.';
    const errorText = 'Brand name could not be updated. Please try again.';
    const brandingCopy = [...organization.branding];
    brandingCopy[index] = newBrand;

    // only proceed with saving if length of original array same as new array
    if (brandingCopy.length === organization.branding.length) {
      dispatch(updateBranding(
        {
          organizationId: organization?._id,
          branding: brandingCopy,
        },
        successText,
        errorText,
      ));
    }
  };

  const onDeleteBrand = (index, brandName) => {
    const successText = `${brandName} Brand deleted.`;
    const errorText = `${brandName} Brand could not be deleted. Please try again.`;
    const brandingCopy = [...organization.branding];
    brandingCopy.splice(index, 1);

    // only proceed with saving if length of new array is one less than original array
    if (brandingCopy.length === organization.branding.length - 1) {
      dispatch(updateBranding(
        {
          organizationId: organization?._id,
          branding: brandingCopy,
        },
        successText,
        errorText,
      ));
    }
  };

  const renderAddBrandButton = (isOutline = false) => (
    <Button
      disabled={isGettingBrands}
      data-testid="new-brand-button"
      onClick={() => {
        setShowModal(true);
      }}
      variant={`${isOutline ? 'outline' : 'solid'}`}
    >
      <Icon><CirclePlus /></Icon>
      Add Brand
    </Button>
  );

  return (
    <section className="brandsWrap">
      {showModal && (
        <BrandModal
          show={showModal}
          isEditing={false}
          hideModal={() => setShowModal(false)}
          handleAddBrand={onAddBrand}
          brandingMap={brandingMap}
          isUpdatingBranding={isUpdatingBranding}
        />
      )}
      <header>
        <h1>Company Hierarchy</h1>
        {renderAddBrandButton(false)}
      </header>
      <p>
        Add additional Brands or Lines of Business to set up a
        company hierarchy that best matches your business needs.
      </p>
      <div className="topBar">
        <h3>
          {
            `${branding?.length} ${branding?.length === 1 ? 'Active Brand' : 'Active Brands'
            }`
          }
        </h3>
      </div>
      <Divider />
      <div className="brandListWrap">
        {isGettingBrands
          ? (
            <>
              <section className="brandLinesColWrap">
                <Ghosts className="hm-row hm-row-brand" numItems={1} />
                <div className="verticalDivider">
                  <div className="verticalLeftOne" />
                  <div />
                </div>
                <Ghosts className="linesOfBusinessWrap" numItems={1} />
              </section>
              <section className="brandLinesColWrap">
                <Ghosts className="hm-row hm-row-brand" numItems={1} />
                <div className="verticalDivider">
                  <div className="verticalLeftOne" />
                  <div />
                </div>
                <Ghosts className="linesOfBusinessWrap" numItems={1} />
              </section>
              <section className="brandLinesColWrap">
                <Ghosts className="hm-row hm-row-brand" numItems={1} />
                <div className="verticalDivider">
                  <div className="verticalLeftOne" />
                  <div />
                </div>
                <Ghosts className="linesOfBusinessWrap" numItems={1} />
              </section>
              <section className="brandLinesColWrap">
                <Ghosts className="hm-row hm-row-brand" numItems={1} />
                <div className="verticalDivider">
                  <div className="verticalLeftOne" />
                  <div />
                </div>
                <Ghosts className="linesOfBusinessWrap" numItems={1} />
              </section>
            </>
          )
          : (
            <>
              <>
                {[...brandToLinesOfBusinessMap.keys()]
                  ?.sort((a, b) => a.localeCompare(b))
                  ?.map((brandName) => (
                    <BrandLinesColumn
                      brandName={brandName}
                      handleEditBrand={onEditBrand}
                      handleDeleteBrand={onDeleteBrand}
                      brandingMap={brandingMap}
                      key={brandName}
                      linesOfBusiness={brandToLinesOfBusinessMap.get(brandName)}
                      orgId={organization?._id}
                    />
                  ))}
              </>
              <section className="addBrandBtnColumn">
                {renderAddBrandButton(true)}
              </section>
            </>
          )}
      </div>
    </section>
  );
};

CompanyHierarchy.propTypes = {
  organization: PropTypes.shape({ branding: PropTypes.shape([]) }),
};

CompanyHierarchy.defaultProps = {
  organization: { branding: [], _id: '' },
};

export default CompanyHierarchy;
