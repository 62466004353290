import { defineSlotRecipe, defineStyle } from '@chakra-ui/react';
import { theme } from '@chakra-ui/theme';
import { Modal as BaseDialog } from '@chakra-ui/theme/components';

let baseStyle;

if (BaseDialog.baseStyle) {
  baseStyle = BaseDialog.baseStyle({
    colorScheme: 'gray',
    colorMode: 'light',
    theme,
  });
}

export const dialogSlotRecipe = defineSlotRecipe({
  slots: ['title'],
  base: {
    ...baseStyle,
    title: defineStyle({
      margin: '0 !important',
      fontSize: 'xl !important',
    }),
  },
});

export default dialogSlotRecipe;
