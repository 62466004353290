import { Button, Icon } from '@chakra-ui/react';
import _ from 'lodash';
import { ChevronDown } from 'lucide-react';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { jobPutRequest } from '@app/actions/job';
import AssignUser from '@app/components/chakra/menus/action/assign-user';
import CheckBoxInput from '@app/components/elements/form/CheckBoxInput/CheckBoxInput';
import TableCell from '@app/components/elements/table/TableCell/TableCell';
import TableRowComponent from '@app/components/elements/table/TableRow/TableRow';
import { useCheckForCaseAccess, handleUserAssignment } from '@app/helpers/cases';

interface CaseRowProps {
  columns: {
    id: string;
    label: string;
    icon: string;
    disabled: boolean;
    onSelect: (e: unknown) => void;
  }[];
  c: {
    id: string;
    displayId: string;
    isClosed: boolean;
  };
  actions: {
    id: string;
    label: string;
    icon: string;
    disabled: boolean;
    onSelect: (e: unknown) => void;
  }[];
  assignedOperator: {
    name: string;
    id: string;
  };
  isChecked: boolean;
  setCaseSelected: (e: unknown) => void;
}

const CaseRow: React.FC<CaseRowProps> = ({
  columns,
  c,
  actions,
  assignedOperator,
  setCaseSelected,
  isChecked,
}) => {
  const operator = _.get(assignedOperator, 'name');
  const hasAccessToCase = useCheckForCaseAccess(c);
  const dispatch = useDispatch();
  const memoizedHandleUserAssignment = useMemo(
    () => handleUserAssignment(c, jobPutRequest, dispatch),
    [c, dispatch],
  );
  const memoizedSelect = useCallback(
    (e: unknown) => memoizedHandleUserAssignment(e),
    [memoizedHandleUserAssignment],
  );
  const renderTableCell = () => {
    if (c.isClosed) {
      return (
        <Button
          width="162px"
          size="sm"
          variant="outline"
          disabled
          justifyContent="space-between"
        >
          {_.get(assignedOperator, 'name', 'Unassigned')}
          <Icon><ChevronDown /></Icon>
        </Button>
      );
    }
    if (hasAccessToCase) {
      return (
        <AssignUser
          id="cases"
          defaultLabel="Unassigned"
          rowId={`assign-${c.id}`}
          selectedId={_.get(assignedOperator, 'id', 'unassigned')}
          handleSelect={memoizedSelect}
        />
      );
    }
    return (<span>{operator}</span>);
  };

  return (
    <span data-test="case-table-row">
      <TableRowComponent
        id="case"
        actions={actions}
        className={c.isClosed ? 'closed-case' : ''}
        columns={columns}
        item={c}
        itemId={c.id}
        header={(
          <>
            <span className="row-checkbox">
              <CheckBoxInput
                onChange={setCaseSelected}
                uniqueKey={c.id}
                value={c.id}
                isChecked={isChecked}
              />
            </span>
            <TableCell link={hasAccessToCase ? `/chats/${c.id}` : ''} id="case-number">
              {c.displayId}
              {' '}
            </TableCell>
          </>
        )}
        hasAccessToCase={hasAccessToCase}
        useNewActionMenu
      >
        <TableCell id="assigned">
          {renderTableCell()}
        </TableCell>
      </TableRowComponent>
    </span>
  );
};

export default CaseRow;
