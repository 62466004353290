import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import TabRouter from '@app/components/chakra/tab-router';
import PermissionVerifier from '@app/components/PermissionVerifier/PermissionVerifier';
import {
  GROUP_MANAGEMENT, USER_MANAGEMENT, TEMPLATE_MANAGEMENT,
  USER_MANAGEMENT_TAB, SYSTEM_ADMIN,
} from '@app/constants/permissions';

import AppIntegrations from './AppIntegrations/AppIntegrations';
import GroupMgmt from './GroupMgmt/GroupMgmt';
import LinesOfBusinessContainer from './LinesOfBusiness/LinesOfBusinessContainer';
import MessageTemplates from './MessageTemplates/MessageTemplates';
import UserMgmt from './UserMgmt/user-mgmt';
import VendorPartners from './VendorPartners/VendorPartners';

import './UserMgmtRoot.less';

interface UserMgmtRootProps {
  subNavId?: string;
}

const UserMgmtRoot: React.FC<UserMgmtRootProps> = ({ subNavId = 'users' }) => {
  const currentPermissions = useSelector((state) => state?.auth?.permissions || []);
  const currentUserRoles = useSelector((state) => state?.auth?.user?.roles || []);
  const hasGroupMgmt = currentPermissions.includes(GROUP_MANAGEMENT);
  const hasUserMgmt = currentPermissions.includes(USER_MANAGEMENT);
  const hasTemplateMgmt = currentPermissions.includes(TEMPLATE_MANAGEMENT);

  const hasLinesOfBusiness = (currentPermissions.includes(SYSTEM_ADMIN) || currentUserRoles.includes('ADMIN'));

  const tabList: {
    id: string;
    title: string;
    component: React.ReactNode;
  }[] = useMemo(() => {
    const list = [];
    if (hasUserMgmt) {
      list.push({
        id: 'users',
        title: 'Users',
        component: <UserMgmt />,
      });
    }
    if (hasGroupMgmt) {
      list.push({
        id: 'groups',
        title: 'Groups',
        component: <GroupMgmt />,
      });
    }
    if (hasLinesOfBusiness) {
      list.push({
        id: 'linesOfBusiness',
        title: 'Lines of Business',
        component: <LinesOfBusinessContainer />,
      });
    }

    if (hasTemplateMgmt) {
      list.push({
        id: 'templates',
        title: 'Message Templates',
        component: <MessageTemplates lineOfBusiness={{}} />,
      });
    }

    list.push(...[
      {
        id: 'partners',
        title: 'Vendor Partners',
        component: <VendorPartners />,
      },
      {
        id: 'integrations',
        title: 'Integrations',
        component: <AppIntegrations />,
      },
    ]);

    return list;
  }, [hasGroupMgmt, hasLinesOfBusiness, hasTemplateMgmt, hasUserMgmt]);

  return (
    <PermissionVerifier neededPermissions={[USER_MANAGEMENT_TAB]} redirect>
      <div className="user-mgmt-root">
        <TabRouter
          tabList={tabList}
          basePath="usermgmt"
          defaultTabId={subNavId}
          tabContentCss={{
            overflow: 'visible',
          }}
        />
      </div>
    </PermissionVerifier>
  );
};

export default UserMgmtRoot;
