import {
  Box,
} from '@chakra-ui/react';
import React from 'react';

import { ROLE_ENDUSER } from '@app/constants/general';
import { type User } from '@app/types/api/user';
import { Avatar, AvatarGroup } from '@chakra-snippets/avatar';
import { Tooltip } from '@chakra-snippets/tooltip';

interface ParticipantCirclesProps {
  participants: User[]
  onClick: () => void
}

const ParticipantCircles: React.FC<ParticipantCirclesProps> = (
  { participants, onClick },
) => (
  <Tooltip showArrow content="View Participants">
    <Box
      onClick={onClick}
      cursor="pointer"
    >
      <AvatarGroup data-testid="participant-circles">
        {participants.map((participant) => {
          const {
            imageUrl,
            name,
            role,
            id,
          } = participant;

          return (
            <Avatar
              data-testid="participant-circle"
              id={id}
              key={`participantCircle-${id}`}
              bg={role === ROLE_ENDUSER ? 'marleyBlue.500' : 'marleyRed.500'}
              color="white"
              size="xs"
              name={name}
              src={imageUrl}
              loading="eager"
            />
          );
        })}
      </AvatarGroup>
    </Box>
  </Tooltip>
);

export default ParticipantCircles;
