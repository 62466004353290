import {
  Text,
} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';

import { Button } from '@chakra-snippets/button';
import {
  DialogRoot, DialogContent, DialogBody,
  DialogCloseTrigger, DialogFooter,
  DialogActionTrigger,
} from '@chakra-snippets/dialog';

interface MassNotificationDeleteVerifyModalProps {
  show: boolean;
  toggleShow: (id?: string) => void;
  deleteNotification: (id: string) => void;
  activeMassNotification: unknown;
}

const MassNotificationDeleteVerifyModal: React.FC<MassNotificationDeleteVerifyModalProps> = ({
  show,
  toggleShow,
  deleteNotification,
  activeMassNotification,
}) => (
  <DialogRoot
    data-jest="mass-notification-modal"
    open={show}
    role="alertdialog"
  >
    <DialogContent>
      <DialogBody mt={6}>
        <Text textAlign="center" fontSize="xl">
          Are you sure you want to delete this mass notification?
        </Text>
      </DialogBody>
      <DialogFooter alignContent="center">
        <DialogActionTrigger asChild>
          <Button onClick={() => toggleShow()} variant="outline">Cancel</Button>
        </DialogActionTrigger>
        <DialogActionTrigger asChild>
          <Button
            onClick={() => {
              deleteNotification(_.get(activeMassNotification, 'id') || '');
              toggleShow();
            }}
          >
            Yes
          </Button>
        </DialogActionTrigger>
      </DialogFooter>
      <DialogCloseTrigger />
    </DialogContent>
  </DialogRoot>
);

export default MassNotificationDeleteVerifyModal;
