import { defineSlotRecipe } from '@chakra-ui/react';

export const textRecipe = defineSlotRecipe({
  slots: ['root'],
  base: {
    root: {
      m: 0,
    },
  },
});

export default textRecipe;
