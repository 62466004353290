import React, { useMemo, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import SelectMenu from '@app/components/chakra/fields/select-menu';
import LoBIcon from '@app/components/Settings/CompanyHierarchy/lob-icon';
import { capitalizeFirstLetter } from '@app/helpers/format';
import { type IFormInput } from '@app/types/create-case';
import { StateType } from '@app/types/reducer-state';

import { CreateCaseContext } from '../context';

const LineOfBusinessMenu = () => {
  const { getValues } = useFormContext<IFormInput>();
  const { contentRef } = useContext(CreateCaseContext);

  const insuranceBrand = getValues('insuranceBrand');
  const linesOfBusiness = useSelector(
    (state: StateType) => state.organizations?.linesOfBusiness ?? [],
  );

  const lineOfBusinessOptions = useMemo(() => [
    {
      id: '',
      label: 'None',
    },
    ...linesOfBusiness
      .filter((lob) => lob.branding === insuranceBrand)
      .map((lob) => ({
        id: lob._id,
        label: lob.displayName,
        group: `${capitalizeFirstLetter(lob.type)} Line`,
        leftIconElement: <LoBIcon name={lob.subType} />,
      })),
  ], [insuranceBrand, linesOfBusiness]);

  return (
    <SelectMenu<IFormInput>
      id="line-of-business"
      label="Line Of Business"
      name="lineOfBusiness"
      placeholder="Choose Line Of Business"
      options={lineOfBusinessOptions}
      contentRef={contentRef}
    />
  );
};

export default LineOfBusinessMenu;
