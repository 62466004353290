import { defineRecipe } from '@chakra-ui/react';

export const formLabelRecipe = defineRecipe({
  base: {
    color: 'gray.500',
    fontSize: '12px',
    fontWeight: 'normal',
  },
});

export default formLabelRecipe;
