/* eslint-disable no-nested-ternary */
import {
  Table, IconButton, Flex, Text, Icon,
} from '@chakra-ui/react';
import { Copy, Ellipsis } from 'lucide-react';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { toggleConfirmation } from '@app/actions/ui';
import AssignGroup from '@app/components/chakra/menus/action/assign-group';
import AssignRole from '@app/components/chakra/menus/action/assign-role';
import SimpleMenu from '@app/components/chakra/menus/simple-menu';
import UserRow from '@app/components/chakra/user-row';
import { formatPhoneNumber } from '@app/helpers/format';
import { type User } from '@app/types/api/user';

interface OperatorRowProps {
  user: User;
  itemId: string;
  actions: {
    id: string;
    value: string;
    label: string;
    confirmation?: boolean;
    action?: (id: string) => void;
    openModal?: () => void;
    leftIcon?: React.ReactNode;
  }[];
  hasAccessToCase?: boolean;
  authId: string;
  rowId?: string;
}

const OperatorRow: React.FC<OperatorRowProps> = ({
  user,
  itemId,
  actions,
  hasAccessToCase = true,
  authId,
  rowId = 'operator-row',
}) => {
  const dispatch = useDispatch();

  const memoizedSelect = useCallback(
    (selectId: string) => {
      const action = actions.find((a) => a.id === selectId);
      if (action?.openModal) {
        action.openModal();
      }
      if (action?.confirmation) {
        dispatch(
          toggleConfirmation({
            id: action.id,
            isOpen: true,
            selectedId: user.id,
          }),
        );
      } else if (action?.action) {
        action.action(itemId);
      }
    },
    [actions, dispatch, user.id, itemId],
  );

  const archivedTdStyle = {
    opacity: '50%',
  };

  return (
    <Table.Row className={`${!hasAccessToCase ? 'redacted' : ''}`} data-testid={rowId}>
      <Table.Cell
        data-testid="operator-name"
        css={user?.archived ? archivedTdStyle : {}}
        maxWidth={250}
      >
        <UserRow
          showTitle
          option={user}
          size="sm"
          isYou={authId === user?.id}
          boldName
          borderRadius="full"
          showEmail={false}
          userNameFontWeight="500"
        />
      </Table.Cell>
      <Table.Cell
        data-testid="operator-oktaUserId"
        css={user?.archived ? { ...archivedTdStyle, ...{ '&:hover': { '& button': { visibility: 'visible' } } } } : { '&:hover': { '& button': { visibility: 'visible' } } }}
      >
        {user?.oktaUserId && (
          <Flex gap={4} alignItems="center">
            <Text lineClamp={1} maxWidth={200} title={user?.oktaUserId}>
              {user?.oktaUserId}
            </Text>
            <IconButton
              visibility="hidden"
              variant="ghost"
              onClick={() => user?.oktaUserId && navigator.clipboard.writeText(user.oktaUserId)}
              title="Copy unique id"
              aria-label="unique id"
              size="sm"
            >
              <Icon><Copy /></Icon>
            </IconButton>
          </Flex>
        )}
      </Table.Cell>
      <Table.Cell
        data-testid="operator-email"
        css={user?.archived ? { ...archivedTdStyle, ...{ '&:hover': { '& button': { visibility: 'visible' } } } } : { '&:hover': { '& button': { visibility: 'visible' } } }}
      >
        {user?.email && (
          <Flex gap={4} alignItems="center">
            <Text lineClamp={1} maxWidth={200} title={user?.email}>
              {user?.email}
            </Text>
            <IconButton
              visibility="hidden"
              variant="ghost"
              onClick={() => user?.email && navigator.clipboard.writeText(user.email)}
              title="Copy email"
              aria-label="email"
              size="sm"
            >
              <Icon><Copy /></Icon>
            </IconButton>
          </Flex>
        )}
      </Table.Cell>
      <Table.Cell id="phone" data-testid="operator-phone" css={user?.archived ? archivedTdStyle : {}}>
        {user?.displayPhoneNumber || user?.contactNumber
          ? formatPhoneNumber(user?.displayPhoneNumber || user?.contactNumber)
          : ''}
      </Table.Cell>
      <Table.Cell id="last-active">{user?.lastLoginTime}</Table.Cell>
      <Table.Cell id="roles" css={user?.archived ? { ...archivedTdStyle, pointerEvents: 'none' } : {}}>
        <AssignRole user={{ ...user, roles: user.roles || [] }} />
      </Table.Cell>
      <Table.Cell id="groups" css={user?.archived ? { ...archivedTdStyle, pointerEvents: 'none' } : {}}>
        <AssignGroup user={{
          ...user,
          groups: user.groups || [],
          groupLeads: user.groupLeads || [],
        }}
        />
      </Table.Cell>
      {actions?.length > 0 && (
        <Table.Cell id="actions">
          {hasAccessToCase && (
            <SimpleMenu
              dataTestId={`${itemId}-actions-menu`}
              onSelect={(e) => memoizedSelect(e.value)}
              options={actions}
              menuTrigger={(
                <IconButton variant="ghost" size="sm">
                  <Icon><Ellipsis /></Icon>
                </IconButton>
              )}
            />
          )}
        </Table.Cell>
      )}
    </Table.Row>
  );
};

export default OperatorRow;
