import { Box } from '@chakra-ui/react';
import React from 'react';

import { Button } from '@chakra-snippets/button';
import { Tooltip } from '@chakra-snippets/tooltip';
import './ChatParticipantAddButton.less';

interface ChatParticipantAddButtonProps {
  onClick: () => void;
}

const ChatParticipantAddButton: React.FC<ChatParticipantAddButtonProps> = ({ onClick }) => (
  <Tooltip showArrow content="Add Participant">
    <Button
      variant="ghost"
      className="chat-participant-add-button"
      data-testid="chat-participant-add-button"
      onClick={onClick}
      size="sm"
      pl={1}
      color="gray.500"
    >
      <Box className="chat-participant-add-circle">+</Box>
    </Button>
  </Tooltip>
);

export default ChatParticipantAddButton;
