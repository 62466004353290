import {
  IconButton, Icon,
} from '@chakra-ui/react';
import {
  Card,
} from '@himarley/unity';
import { Ellipsis, X, SquarePen } from 'lucide-react';
import React, { useState } from 'react';

import Row from '@app/components/elements/Row/Row';
import Title from '@app/components/elements/text/Title/Title';
import { capitalizeEachWord } from '@app/helpers/format';
import {
  MenuRoot, MenuTrigger, MenuContent, MenuItem,
} from '@chakra-snippets/menu';

import EditLineOfBusinessModal from './edit-line-of-business-modal';
import { typeToLine } from './helpers';
import LoBIcon from './lob-icon';
import RemoveLineOfBusinessModal from './remove-line-of-business-modal';

interface LineOfBusinessCardProps {
  name: string;
  lob: {
    _id: string;
    subType: string;
    type: 'personal' | 'commercial' | 'other';
    displayName: string;
    branding: string;
  };
}

const LineOfBusinessCard: React.FC<LineOfBusinessCardProps> = ({ name = '', lob }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const menuItems = [
    {
      id: 'edit',
      onClick: () => setShowEditModal(true),
      icon: <Icon><SquarePen /></Icon>,
      label: 'Edit',
    },
    {
      id: 'remove',
      onClick: () => setShowDeleteModal(true),
      icon: <Icon color="red.500"><X /></Icon>,
      label: 'Remove',
      color: 'red.500',
    },
  ];

  return (
    <>
      <EditLineOfBusinessModal
        show={showEditModal}
        isEditing
        hideModal={() => setShowEditModal(false)}
        name={name}
        lob={lob}
      />
      <RemoveLineOfBusinessModal
        show={showDeleteModal}
        hideModal={() => setShowDeleteModal(false)}
        name={name}
        lob={lob}
      />
      <Row type="lob">
        <Card className="brandList">
          <Row type="">
            <div>
              <LoBIcon name={lob?.subType} boxSize="26px" />
            </div>
            <div className="lobName">
              <Title type="subheader">
                <b>
                  {capitalizeEachWord(lob?.displayName || lob?.subType)}
                </b>
              </Title>
              <p>{typeToLine[lob?.type]}</p>
            </div>
            <div className="iconRow">
              <MenuRoot>
                <MenuTrigger asChild>
                  <IconButton data-testId={`${lob?.type}${lob?.subType}LoBActionDropdown`} aria-label="options" variant="ghost">
                    <Ellipsis />
                  </IconButton>
                </MenuTrigger>
                <MenuContent>
                  {menuItems.map((item) => (
                    <MenuItem
                      color={item.color}
                      key={item.id}
                      onClick={item.onClick}
                      value={item.id}
                    >
                      {item.icon}
                      {item.label}
                    </MenuItem>
                  ))}
                </MenuContent>
              </MenuRoot>
            </div>
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default LineOfBusinessCard;
