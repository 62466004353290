'use client';

import type { CollectionItem } from '@chakra-ui/react';
import { Select as ChakraSelect, Portal } from '@chakra-ui/react';
import * as React from 'react';

import { CloseButton } from './close-button';

const SelectClearTrigger = React.forwardRef<
HTMLButtonElement,
ChakraSelect.ClearTriggerProps
>((props, ref) => (
  <ChakraSelect.ClearTrigger minW="unset" asChild {...props} ref={ref}>
    <CloseButton
      size="xs"
      variant="plain"
      focusVisibleRing="inside"
      focusRingWidth="2px"
      pointerEvents="auto"
    />
  </ChakraSelect.ClearTrigger>
));

interface SelectTriggerProps extends ChakraSelect.ControlProps {
  clearable?: boolean
}

export const SelectTrigger = React.forwardRef<
HTMLButtonElement,
SelectTriggerProps
>((props, ref) => {
  const {
    children,
    clearable,
    ...rest
  } = props;
  return (
    <ChakraSelect.Control {...rest}>
      <ChakraSelect.Trigger ref={ref}>{children}</ChakraSelect.Trigger>
      <ChakraSelect.IndicatorGroup>
        {clearable && <SelectClearTrigger />}
        <ChakraSelect.Indicator />
      </ChakraSelect.IndicatorGroup>
    </ChakraSelect.Control>
  );
});

interface SelectContentProps extends ChakraSelect.ContentProps {
  portalled?: boolean
  portalRef?: React.RefObject<HTMLElement>
  overrideMaxWidth?: boolean
}

export const SelectContent = React.forwardRef<
HTMLDivElement,
SelectContentProps
>((props, ref) => {
  const {
    portalled = true,
    portalRef,
    overrideMaxWidth = false,
    ...rest
  } = props;
  return (
    <Portal disabled={!portalled} container={portalRef}>
      <ChakraSelect.Positioner w={overrideMaxWidth ? 'max-content !important' : undefined}>
        <ChakraSelect.Content {...rest} ref={ref} p={0} />
      </ChakraSelect.Positioner>
    </Portal>
  );
});

export const SelectItem = React.forwardRef<
HTMLDivElement,
ChakraSelect.ItemProps
>((props, ref) => {
  const {
    item,
    children,
    ...rest
  } = props;
  return (
    <ChakraSelect.Item justifyContent="space-between" key={item.value} item={item} {...rest} ref={ref}>
      {children}
      <ChakraSelect.ItemIndicator />
    </ChakraSelect.Item>
  );
});

interface SelectValueTextProps
  extends Omit<ChakraSelect.ValueTextProps, 'children'> {
  children?(items: CollectionItem[]): React.ReactNode
  itemName?: string;
}

export const SelectValueText = React.forwardRef<
HTMLSpanElement,
SelectValueTextProps
>((props, ref) => {
  const {
    children,
    itemName,
    ...rest
  } = props;
  return (
    <ChakraSelect.ValueText maxW="75%" {...rest} ref={ref}>
      <ChakraSelect.Context>
        {(select) => {
          const items = select.selectedItems;
          if (items.length === 0) return props.placeholder;
          if (children) return children(items);
          if (items.length === 1) return select.collection.stringifyItem(items[0]);
          return `${items.length} ${itemName ?? 'selected'}`;
        }}
      </ChakraSelect.Context>
    </ChakraSelect.ValueText>
  );
});

export const SelectRoot = React.forwardRef<
HTMLDivElement,
ChakraSelect.RootProps
>((props, ref) => (
  <ChakraSelect.Root
    {...props}
    ref={ref}
    positioning={{ sameWidth: true, ...props.positioning }}
  >
    {props.asChild ? (
      props.children
    ) : (
      <>
        <ChakraSelect.HiddenSelect />
        {props.children}
      </>
    )}
  </ChakraSelect.Root>
)) as ChakraSelect.RootComponent;

interface SelectItemGroupProps extends ChakraSelect.ItemGroupProps {
  label: React.ReactNode
}

export const SelectItemGroup = React.forwardRef<
HTMLDivElement,
SelectItemGroupProps
>((props, ref) => {
  const {
    children,
    label,
    ...rest
  } = props;
  return (
    <ChakraSelect.ItemGroup {...rest} ref={ref}>
      <ChakraSelect.ItemGroupLabel>{label}</ChakraSelect.ItemGroupLabel>
      {children}
    </ChakraSelect.ItemGroup>
  );
});

export const SelectLabel = ChakraSelect.Label;
export const SelectItemText = ChakraSelect.ItemText;
