import {
  Flex,
  Text,
} from '@chakra-ui/react';
import { CheckmarkFilledIcon } from '@himarley/unity';
import debounce from 'lodash/debounce';
import React, { useEffect, useState, useMemo } from 'react';
import {
  useFormContext,
  type RegisterOptions,
  type FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';

import { useCustomStyles } from '@app/chakra-theme/hooks/use-custom-styles';
import SearchableSelect from '@app/components/chakra/selects/searchable-select';
import { useGroupsQuery } from '@app/services/group';
import { Group } from '@app/types/api/group';
import { Avatar } from '@chakra-snippets/avatar';
import { Field } from '@chakra-snippets/field';

interface GroupLabelProps {
  groupName: string;
}

const GroupLabel: React.FC<GroupLabelProps> = ({ groupName }) => (
  <Flex align="center" gap={2}>
    <Avatar
      name={groupName}
      size="xs"
      bg="marleyRed.500"
      color="white"
    />
    <Text>{groupName}</Text>
  </Flex>
);

interface GroupSelectMenuProps<T extends FieldValues> {
  id?: string;
  label?: string;
  name: Path<T>;
  registerOptions?: RegisterOptions<T>;
  contentRef?: React.RefObject<HTMLDivElement>;
}

const GroupSelectMenu = <T extends FieldValues>({
  id = 'groups',
  label = 'Groups',
  name,
  registerOptions,
  contentRef,
}: GroupSelectMenuProps<T>) => {
  const form = useFormContext<T>();
  const { formControlStyles } = useCustomStyles();
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const debouncedSetSearch = useMemo(
    () => debounce((value: string) => {
      setDebouncedSearch(value);
    }, 300),
    [],
  );

  useEffect(() => {
    if (searchQuery.length > 2 || searchQuery.length === 0) {
      debouncedSetSearch(searchQuery);
    }
    return () => {
      debouncedSetSearch.cancel();
    };
  }, [searchQuery, debouncedSetSearch]);

  useEffect(() => {
    form.register(name, registerOptions);
  }, [form, name, registerOptions]);

  const selectedGroups = useMemo<string[]>(
    () => (form.getValues(name) as string[]) || [],
    [form, name],
  );

  const groupsQuery = useGroupsQuery({
    searchText: debouncedSearch || '',
  });

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
  } = groupsQuery;

  const groups = useMemo(
    () => data?.pages.reduce(
      (accumulator, item) => [...accumulator, ...item.groups],
      [],
    ) || [],
    [data?.pages],
  );

  const options = useMemo(() => groups.map((group: Group) => ({
    value: group._id,
    label: <GroupLabel groupName={group.name} />,
    onClick: () => {
      const updatedGroups = selectedGroups.includes(group._id || '')
        ? selectedGroups.filter((groupId: string) => groupId !== group._id)
        : [...selectedGroups, group._id];

      form.setValue(name, updatedGroups as PathValue<T, Path<T>>, { shouldValidate: true });
    },
    rightIcon: selectedGroups.includes(group._id || '') ? CheckmarkFilledIcon : undefined,
    closeOnSelect: false,
    selected: selectedGroups.includes(group._id || ''),
  })), [selectedGroups, groups, form, name]);

  return (
    <Field
      invalid={!!form.formState.errors[name]}
      css={formControlStyles}
      label={label}
      errorText={form.formState.errors[name]?.message as string}
    >
      <SearchableSelect
        id={id}
        options={options}
        loading={isLoading}
        placeholder="Select Groups"
        searchValue={searchQuery}
        onSearchChange={setSearchQuery}
        increaseDataSet={fetchNextPage}
        shouldLoadMoreItems={!searchQuery?.trim() && hasNextPage}
        onValueChange={(details) => {
          form.setValue(name, details.value as PathValue<T, Path<T>>, { shouldValidate: true });
        }}
        contentRef={contentRef}
        matchWidth
        multiple
      />
    </Field>
  );
};

export default GroupSelectMenu;
