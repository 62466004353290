import { Icon } from '@chakra-ui/react';
import { Calendar } from 'lucide-react';
import React, { Dispatch, SetStateAction } from 'react';

import {
  MenuRoot,
  MenuSeparator,
  MenuTrigger,
  MenuContent,
  MenuItem,
} from '@chakra-snippets/menu';

type ScheduleMenuProps = {
  setShowScheduleMessageModal: (value: boolean) => void;
  queueMessage: (object: unknown) => void;
  message: string;
  chatId: string;
  jobId: string;
  setMessageValue: Dispatch<SetStateAction<string>>;
  showScheduleMenu: boolean;
  scheduleMenuRef: React.RefObject<HTMLButtonElement>;
  setShowScheduleMenu: (value: boolean) => void;
};

const ScheduleMenu: React.FC<ScheduleMenuProps> = ({
  setShowScheduleMessageModal,
  queueMessage,
  message,
  chatId,
  jobId,
  setMessageValue,
  showScheduleMenu,
  scheduleMenuRef,
  setShowScheduleMenu,
}) => {
  const scheduleMessage = (option: string) => {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(now.getDate() + 1);
    let scheduledTime: Date;
    switch (option) {
      case '9am':
        scheduledTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 9, 0, 0);
        break;
      case '1pm':
        scheduledTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 13, 0, 0);
        break;
      case '5pm':
        scheduledTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 17, 0, 0);
        break;
      case '9am-tomorrow':
        scheduledTime = new Date(
          tomorrow.getFullYear(),
          tomorrow.getMonth(),
          tomorrow.getDate(),
          9,
          0,
          0,
        );
        break;
      default:
        throw new Error('Invalid option');
    }

    const formObject = {
      message,
      eventTime: scheduledTime.getTime(),
    };
    queueMessage({
      ...formObject,
      chatId,
      jobId,
    });
    setMessageValue('');
  };

  const currentTime = new Date();
  const isBefore855AM = currentTime.getHours() < 8
    || (currentTime.getHours() === 8 && currentTime.getMinutes() < 55);
  const isBefore1255PM = currentTime.getHours() < 12
    || (currentTime.getHours() === 12 && currentTime.getMinutes() < 55);
  const isBefore455PM = currentTime.getHours() < 16
    || (currentTime.getHours() === 16 && currentTime.getMinutes() < 55);

  return (
    <MenuRoot
      open={showScheduleMenu}
      onOpenChange={(details) => setShowScheduleMenu(details.open)}
    >
      <MenuTrigger asChild>
        <button ref={scheduleMenuRef} aria-label="Schedule message options" type="button" data-testid="schedule-menu-button" />
      </MenuTrigger>
      <MenuContent>
        {isBefore855AM && (
          <MenuItem
            data-testid="schedule-item-9am"
            value="9am"
            onClick={() => scheduleMessage('9am')}
          >
            Send today at 9:00 AM
          </MenuItem>
        )}
        {isBefore1255PM && (
          <MenuItem
            data-testid="schedule-item-1pm"
            value="1pm"
            onClick={() => scheduleMessage('1pm')}
          >
            Send today at 1:00 PM
          </MenuItem>
        )}
        {isBefore455PM && !isBefore855AM && !isBefore1255PM && (
          <MenuItem
            data-testid="schedule-item-5pm"
            value="5pm"
            onClick={() => scheduleMessage('5pm')}
          >
            Send today at 5:00 PM
          </MenuItem>
        )}
        <MenuItem
          data-testid="schedule-item-9am-tomorrow"
          value="9am-tomorrow"
          onClick={() => scheduleMessage('9am-tomorrow')}
        >
          Send tomorrow at 9:00 AM
        </MenuItem>
        <MenuSeparator m={0} />
        <MenuItem
          data-testid="schedule-item-custom"
          onClick={() => setShowScheduleMessageModal(true)}
          value="custom"
        >
          <Icon>
            <Calendar />
          </Icon>
          Custom Time
        </MenuItem>
      </MenuContent>
    </MenuRoot>
  );
};

export default ScheduleMenu;
