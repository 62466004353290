export const CLAIMS_TAB = 'CLAIMS_TAB';
export const POLICY_TAB = 'POLICY_TAB';
export const CASES_TAB = 'CASES_TAB';
export const CHATS_TAB = 'CHATS_TAB';
export const MASSNOTIFICATIONS_TAB = 'MASSNOTIFICATIONS_TAB';
export const USER_MANAGEMENT_TAB = 'USER_MANAGEMENT_TAB';
export const ANALYTICS_TAB = 'ANALYTICS_TAB';
export const MESSAGE_TEMPLATE_ADD_AND_EDIT = 'MESSAGE_TEMPLATE_ADD_AND_EDIT';
export const SIDE_CHAT_ACCESS = 'SIDE_CHAT_ACCESS';
export const TRANSLATE_ACCESS = 'TRANSLATE_ACCESS';
export const CASE_VISIBILITY = 'CASE_VISIBILITY';
export const GROUP_MANAGEMENT = 'GROUP_MANAGEMENT';
export const USER_MANAGEMENT = 'USER_MANAGEMENT';
export const TEMPLATE_MANAGEMENT = 'TEMPLATE_MANAGEMENT';
export const PRIVACY_ALL_PRIVATE_REDACTED = 'PRIVACY_ALL_PRIVATE_REDACTED';
export const PRIVACY_ALL_PRIVATE_HIDDEN = 'PRIVACY_ALL_PRIVATE_HIDDEN';
export const PRIVACY_ALL_PRIVATE_TYPES = 'PRIVACY_ALL_PRIVATE_TYPES';
export const OVERRIDE_CASE_VISIBILITY = 'OVERRIDE_CASE_VISIBILITY';
export const NEEDS_ATTENTION = 'NEEDS_ATTENTION';
export const NEEDS_ACTION = 'NEEDS_ACTION';
export const NOTES_ACCESS = 'NOTES_ACCESS';
export const FOCUSED_OPERATOR = 'FOCUSED_OPERATOR';
export const CHAT_ALL_INBOX = 'CHAT_ALL_INBOX';
export const DISPLAY_MESSAGE_STATUS = 'DISPLAY_MESSAGE_STATUS';
export const ADMIN_NOTIFICATION_CONFIG = 'ADMIN_NOTIFICATION_CONFIG';
export const DISABLE_CREATE_CASE = 'DISABLE_CREATE_CASE';
export const NOTE_REPLIES_ENABLED = 'NOTE_REPLIES_ENABLED';
export const ORG_INACTIVITY_AUTO_REPLY = 'ORG_INACTIVITY_AUTO_REPLY';
export const ORG_SETTINGS_USER_MESSAGE_PREFIX = 'ORG_SETTINGS_USER_MESSAGE_PREFIX';
export const PREFIX_CLAIM_NUMBER = 'PREFIX_CLAIM_NUMBER';
export const PREFIX_PHONE_NUMBER = 'PREFIX_PHONE_NUMBER';
export const PREFIX_USER_TITLE = 'PREFIX_USER_TITLE';
export const TITLE_ENABLED = 'TITLE_ENABLED';
export const SYSTEM_ADMIN = 'SYSTEM_ADMIN';
export const TITLE_EDIT_ONLY_ADMIN = 'TITLE_EDIT_ONLY_ADMIN';
export const ORG_SETTINGS_AUTO_MESSAGE_PREFIX = 'ORG_SETTINGS_AUTO_MESSAGE_PREFIX';
export const LINES_OF_BUSINESS_ENABLE = 'LINES_OF_BUSINESS_ENABLE';
export const CREATE_CASE_FROM_CHATS_PAGE = 'CREATE_CASE_FROM_CHATS_PAGE';
export const FEATURE_FLAG_TOTAL_LOSS_ASSIST = 'FEATURE_FLAG_TOTAL_LOSS_ASSIST';
export const FEATURE_FLAG_IMAGE_REDACTION = 'FEATURE_FLAG_IMAGE_REDACTION';
export const FEATURE_FLAG_VERISK_MEDIA_SYNC = 'FEATURE_FLAG_VERISK_MEDIA_SYNC';
export const MANUAL_OPT_IN = 'MANUAL_OPT_IN';
export const FEATURE_FLAG_WELCOME_FLOW_REFACTOR = 'FEATURE_FLAG_WELCOME_FLOW_REFACTOR';
export const DISABLE_MARLEY_OPT_IN = 'DISABLE_MARLEY_OPT_IN';
export const FEATURE_FLAG_UNREAD_STATE_REFACTOR = 'FEATURE_FLAG_UNREAD_STATE_REFACTOR';
export const FEATURE_FLAG_VIRUS_SCANNING = 'FEATURE_FLAG_VIRUS_SCANNING';
export const FEATURE_FLAG_VERISK = 'FEATURE_FLAG_VERISK';
export const FEATURE_FLAG_CCC_FIRST_LOOK = 'FEATURE_FLAG_CCC_FIRST_LOOK';
export const FNOL_UI = 'FNOL_UI';
export const REDACTION_VIEW_ACCESS = 'REDACTION_VIEW_ACCESS';
export const CREATE_CLAIMS_FROM_CASES_TAB = 'CREATE_CLAIMS_FROM_CASES_TAB';
export const CREATE_POLICY_FROM_CASES_TAB = 'CREATE_POLICY_FROM_CASES_TAB';
export const CREATE_SERVICES_FROM_CASES_TAB = 'CREATE_SERVICES_FROM_CASES_TAB';
export const SERVICE_CASE_MANAGEMENT = 'SERVICE_CASE_MANAGEMENT';
export const SHOW_MY_INSIGHTS = 'SHOW_MY_INSIGHTS';
export const EVENT_BASED_MY_TEAMS_DASHBOARD = 'EVENT_BASED_MY_TEAMS_DASHBOARD';
export const ALL_CASE_TYPES_AUTO_ASSIGN = 'ALL_CASE_TYPES_AUTO_ASSIGN';
