/* eslint-disable no-shadow */
import _ from 'lodash';
/* eslint-disable no-underscore-dangle */
import { getCreationSource } from '@app/helpers/creation-source';
import {
  CLOSE_CHAT, NEW_MESSAGE, CHAT_ADD_USER, SET_ACTIVE_CHAT_FIRST_KEY_STROKE,
  INLINE_MESSAGE_UPDATE, INLINE_UPDATE_JOB_NEEDS_ATTENTION_MESSAGE_COUNT,
  UNREDACTED_MESSAGE, CLEAR_UNREDACTED_MESSAGE,
  UPDATE_MESSAGE, ENDUSER_JOINED_CHAT,
  CHAT_FORWARD_MEDIA, LOAD_ALL_CHAT_PARTICIPANTS,
  INLINE_UPDATE_JOB_NEEDS_ACTION_MESSAGE_COUNT,
} from '../constants/actions';
import {
  REMOVE_USER_FROM_CHAT, ADD_USER_TO_CHAT, GET_CHAT_PARTICIPANTS,
  GET_ALL_CHAT_PARTICIPANTS,
  GET_UNREDACTED_MESSAGE,
  FORWARD_MEDIA,
  PUT_MESSAGE_NEEDS_ACTION_RESPONSE_ENDPOINT,
} from '../constants/endpoints';
import { USER_IS_TYPING, CHAT_VIEW_TOPIC } from '../constants/websocket';
import Message from '../models/message';
import User from '../models/user';
import { apiCall } from './api';
import { audioNotification } from './ui';

import { markChatAsUnread } from './profile';
import { apiRequest, dispatchRequest } from './api/api';
import Job from '../models/job';
import { BROADCAST_CHANNEL } from '../constants/general';

export const closeChat = (chatId) => ({ type: CLOSE_CHAT, payload: chatId });

export const cancelScheduledMessage = (
  id,
) => dispatchRequest(apiRequest.delete.message.queued(id), {
  type: 'CANCEL_SCHEDULED_MESSAGE',
  processPayload: (data) => ({ id: data.id, notification: { text: 'Message cancelled' } }),
});

export const getScheduledMessages = (
  chatId,
) => dispatchRequest(apiRequest.get.chat.scheduledMessages(chatId), {
  type: 'GET_SCHEDULED_MESSAGES',
  processPayload: (data) => data
    .filter((d) => !!d?.payload?.body?.eventTime)
    .map((d) => ({ id: d.id, ...d.payload.body })),
});

export const setChatParticipants = ({ body, chatId }) => ({
  type: 'LOAD_CHAT_PARTICIPANTS',
  payload: {
    chatId,
    users: body.participants.map((u) => new User(u)),
  },
});

export const setChatParticipantsFromEvent = ({ chat }) => ({
  type: 'LOAD_CHAT_PARTICIPANTS',
  payload: {
    chatId: chat._id,
    users: chat.participants.map((u) => new User(u)),
  },
});

export const getChatParticipants = (chatId) => {
  const args = {
    endpoint: GET_CHAT_PARTICIPANTS(chatId),
    method: 'GET',
    action: 'LOAD_CHAT_PARTICIPANTS',
    process: ({ body }) => ({ users: body.map((u) => new User(u)), chatId }),
  };

  return apiCall({ args });
};

export const getAllChatParticipants = (chatId) => {
  const args = {
    endpoint: GET_ALL_CHAT_PARTICIPANTS(chatId),
    method: 'GET',
    action: 'LOAD_ALL_CHAT_PARTICIPANTS',
    process: ({ body }) => ({
      chatId,
      activeParticipants: body.participants?.map((u) => new User(u)) || [],
      pendingParticipants: body.pendingParticipants?.map((u) => new User(u)) || [],
      inactiveParticipants: body.inactiveParticipants?.map((u) => new User(u)) || [],
    }),
  };

  return apiCall({ args });
};

export const addTranslation = (data) => (dispatch) => {
  const messageId = _.get(data, '_id');
  const translatedMessage = _.get(data, 'additionalInfo.translatedMessage');
  dispatch({
    type: 'ADD_TRANSLATION',
    payload: {
      messageId,
      translatedMessage,
    },
  });
};

export const newMessage = (data) => (
  (dispatch, getState) => {
    const message = new Message(data);
    const currentUserId = _.get(getState(), 'auth.user._id');
    const authorId = _.get(data, 'authorId');
    const jobId = _.get(data, 'jobId');
    const isInbound = _.get(data, 'isInbound', false);
    const activeJobId = _.get(getState(), 'jobs.activeChat.jobId', '');
    const viewedChats = _.get(getState(), 'jobs.viewedChats', '');
    const permissions = _.get(getState(), 'auth.permissions');

    const isOperatorOnJob = authorId !== currentUserId
      && _.find(message.participants, (id) => id === currentUserId);

    if (isOperatorOnJob) {
      dispatch(audioNotification());
    }

    if (isInbound && activeJobId === jobId) {
      const channel = new BroadcastChannel(BROADCAST_CHANNEL);
      channel.postMessage({ authId: currentUserId, jobId, messageId: message.id });
    } else if (isInbound && activeJobId !== jobId && viewedChats.has(jobId)) {
      dispatch(markChatAsUnread(currentUserId, jobId));
    }
    const jobData = new Job(data.job);
    dispatch({
      type: NEW_MESSAGE,
      payload: {
        type: data.type,
        chatId: data.chatId,
        jobId: data.jobId,
        job: jobData,
        permissions,
        operatorId: currentUserId,
        message,
        currentUserId,
      },
    });
  }
);

const sendUserIsTypingSocketEvent = (
  socket,
  chatId,
  userId,
  authorName,
  organizationId,
) => socket.send({
  action: 'upstream-event',
  topic: CHAT_VIEW_TOPIC(organizationId, chatId),
  eventType: USER_IS_TYPING,
  payload: [{
    payload: {
      userId,
      authorName,
      chatId,
    },
    name: USER_IS_TYPING,
  }],
  skipPacketRecovery: true,
});

const typingLockTimeInMS = 2000;
let typingLock = false;
export const newTyping = () => (
  (dispatch, getState) => {
    const state = getState();
    const socket = _.get(state, 'socket.socketObject');
    const chatId = _.get(state, 'jobs.activeChat.id');
    const userId = _.get(state, 'auth.user._id');
    const organizationId = _.get(state, 'auth.user.organizationId');
    const authorName = {
      firstName: _.get(state, 'auth.user.firstName', ''),
      lastName: _.get(state, 'auth.user.lastName', ''),
    };
    // one of the required args is undefined
    if (![state, socket, chatId, userId, authorName].every((curr) => !!curr)) {
      console.error(new Error('MISSING REQUIRED VARS FOR USER TYPING WS PACKET'));
      return;
    }

    const firstKeyStroke = _.get(state, 'jobs.activeChat.firstKeyStroke', false);
    // if this is the first keystroke on the activechat, send packet automatically and mark that key
    // has been pressed

    if (firstKeyStroke) {
      dispatch({
        type: SET_ACTIVE_CHAT_FIRST_KEY_STROKE,
        payload: {
          setting: false,
        },
      });
      sendUserIsTypingSocketEvent(socket, chatId, userId, authorName, organizationId);
    } else if (!typingLock) {
      // only allow upstream packet if a packet has not been sent in the last 'typingLockTimeInMS'
      // reduces the amount of traffic on our BE
      typingLock = true;
      sendUserIsTypingSocketEvent(socket, chatId, userId, authorName, organizationId);
      setTimeout(() => {
        typingLock = false;
      }, typingLockTimeInMS);
    }
  }
);

export function removeUserFromChat({ chatId, userId }) {
  const creationSource = getCreationSource();
  const args = {
    endpoint: REMOVE_USER_FROM_CHAT(chatId),
    method: 'PUT',
    action: 'CHAT_REMOVE_USER',
    body: { userId, creationSource },
  };

  return apiCall({ args });
}

export function addUserToChat({ chatId, userId }) {
  const creationSource = getCreationSource();
  const args = {
    endpoint: ADD_USER_TO_CHAT(chatId),
    method: 'PUT',
    action: CHAT_ADD_USER,
    body: { userId, creationSource },
  };

  return apiCall({ args });
}

export function handleMessageNeedsActionResponse(messageId, jobId, responseType) {
  const args = {
    endpoint: PUT_MESSAGE_NEEDS_ACTION_RESPONSE_ENDPOINT(messageId, jobId, responseType),
    method: 'PUT',
    action: 'PUT_MESSAGE_NEEDS_ACTION_RESPONSE',
  };
  return apiCall({ args });
}

export const inlineMessageUpdate = (data) => (dispatch) => {
  dispatch({
    type: INLINE_MESSAGE_UPDATE,
    payload: {
      ...data,
    },
  });
  dispatch({
    type: INLINE_UPDATE_JOB_NEEDS_ATTENTION_MESSAGE_COUNT,
    payload: {
      ...data,
      needsAttentionMessageCount: 1,
    },
  });
};

export const needsActionUpdate = (data) => (dispatch) => {
  dispatch({
    type: INLINE_MESSAGE_UPDATE,
    payload: {
      ...data,
    },
  });

  dispatch({
    type: INLINE_UPDATE_JOB_NEEDS_ACTION_MESSAGE_COUNT,
    payload: {
      ...data,
    },
  });
};

export const updateMessage = (newMessage) => (dispatch) => {
  dispatch({
    type: UPDATE_MESSAGE,
    payload: {
      ...newMessage,
    },
  });
};

export const getUnredactedMessage = (orgId, messageId, redactionType) => {
  const args = {
    endpoint: GET_UNREDACTED_MESSAGE(orgId, messageId, redactionType),
    method: 'GET',
    action: UNREDACTED_MESSAGE,
    process: ({ body }) => body,
  };

  return apiCall({ args });
};

export const clearUnredactedMessage = () => ({ type: CLEAR_UNREDACTED_MESSAGE });

export const endUserJoinedChat = (data) => (dispatch) => {
  // Updates legacy chat in job reducer
  dispatch({
    type: ENDUSER_JOINED_CHAT,
    payload: data,
  });

  // Updates participants reducer for multi party chat
  if (data.chat) {
    dispatch({
      type: LOAD_ALL_CHAT_PARTICIPANTS,
      payload: {
        chatId: data.chat._id,
        activeParticipants: data.chat.participants?.map((u) => new User(u)) || [],
        pendingParticipants: data.chat.pendingParticipants?.map((u) => new User(u)) || [],
        inactiveParticipants: data.chat.inactiveParticipants?.map((u) => new User(u)) || [],
      },
    });
  }
};

export const forwardMedia = (chatId, messageId) => {
  const args = {
    endpoint: FORWARD_MEDIA(chatId),
    method: 'POST',
    action: CHAT_FORWARD_MEDIA,
    body: { messageId },
  };
  return apiCall({ args });
};
