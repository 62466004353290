import React from 'react';
import CommercialAutoSvg from './assets/commercial_auto.svg';
import CommercialPropertySvg from './assets/commercial_property.svg';
import FailedToSendSvg from './assets/failed_to_send.svg';
import GeneralLiabilitySvg from './assets/general_liability.svg';
import HasAQuestionSvg from './assets/has_a_question.svg';
import HomeSvg from './assets/home.svg';
import LockedOutSvg from './assets/locked_out.svg';
import NewNoteReplySvg from './assets/new_note_reply.svg';
import AutoSvg from './assets/auto.svg';
import LifeSvg from './assets/life.svg';
import CropSvg from '../../../images/icons/crop.svg';
import OtherSvg from '../../../images/icons/other.svg';
import WorkersCompSvg from '../../../images/icons/health.svg';
import ReleaseIssueSvg from '../../../images/icons/GreyAlertIcon.svg';
import CaseSvg from '../../../images/icons/case.svg';
import LockClosedIcon from '../../../images/icons/lock-closed.svg';
import ErrorIcon from '../../../images/icons/black-error.svg';
import { ImportantIcon, StopIcon } from '@himarley/unity';
/**
 * Generates an array of tag objects with specific properties based on input tags.
 *
 * Given an array of tag strings, this function maps each string to a predefined object
 * that contains className, icon, and label properties. If a tag string does not have a
 * predefined object, a new object is created with the tag string as the label and className,
 * and a null icon.
 *
 * @param {string[]} tags - An array of tag strings. Each string corresponds to a key in the
 * typeMap object, which provides predefined properties for certain tags.
 * @returns {Object[]} - An array of objects, each containing the properties:
 *   @property {string} className - A string indicating the CSS class to be applied for the tag.
 *   @property {React.Element|null} icon - A React element for the SVG icon, or null if no icon is available.
 *   @property {string} label - A string to be displayed as the tag's label.
 *
 * @example
 * const tags = ['auto', 'claim', 'unknown'];
 * const tagArray = generateTagArray(tags);
 * // Expected output:
 * // [
 * //   { className: 'white', icon: <AutoSvg />, label: 'Auto' },
 * //   { className: 'white', icon: <CaseSvg />, label: 'Claim' },
 * //   { className: 'unknown', icon: null, label: 'unknown' }
 * // ]
 */
const generateTagArray = (tags) => {
  if (!tags || !tags.length) return [];
  const typeMap = {
    auto: {
      className: 'white',
      icon: <AutoSvg />,
      label: 'Auto',
    },
    claim: {
      className: 'white',
      icon: <CaseSvg />,
      label: 'Claim',
    },
    'commercial auto': {
      className: 'white',
      icon: <CommercialAutoSvg />,
      label: 'Commercial Auto',
    },
    property: {
      className: 'white',
      icon: <CommercialPropertySvg />,
      label: 'Commercial Property',
    },
    crop: {
      className: 'white',
      icon: <CropSvg />,
      label: 'Crop',
    },
    'failed to send': {
      className: 'red',
      icon: <FailedToSendSvg />,
      label: 'Failed to Send',
    },
    'general case': {
      className: 'white',
      icon: <CaseSvg />,
      label: 'General Case',
    },
    service: {
      className: 'white',
      icon: <CaseSvg />,
      label: 'Service Case',
    },
    'general-liability': {
      className: 'white',
      icon: <GeneralLiabilitySvg />,
      label: 'General Liability',
    },
    'has question': {
      className: 'paleShadeBlue',
      icon: <HasAQuestionSvg />,
      label: 'Has a Question',
    },
    home: {
      className: 'white',
      icon: <HomeSvg />,
      label: 'Home',
    },
    life: {
      className: 'white',
      icon: <LifeSvg />,
      label: 'Life',
    },
    'locked out': {
      className: 'red',
      icon: <LockedOutSvg />,
      label: 'Locked Out',
    },
    'needs attention': {
      className: 'paleShadePink',
      icon: <ReleaseIssueSvg />,
      label: 'Needs Attention',
    },
    'my note mentions and replies': {
      className: 'paleShadeBlue',
      icon: <NewNoteReplySvg />,
      label: 'New Note Mention',
    },
    'new note reply': {
      className: 'paleShadeBlue',
      icon: <NewNoteReplySvg />,
      label: 'New Mention/Reply',
    },
    'not opted in': {
      className: 'paleShadeGrey',
      icon: <ImportantIcon />,
      label: 'Not Opted In',
    },
    'opted out': {
      className: 'paleShadeGrey',
      icon: <StopIcon />,
      label: 'Opted Out',
    },
    other: {
      className: 'white',
      icon: <OtherSvg />,
      label: 'Other',
    },
    policy: {
      className: 'white',
      icon: <CaseSvg />,
      label: 'Policy',
    },
    'release issue': {
      className: 'paleShadePink',
      icon: <ReleaseIssueSvg />,
      label: 'Release Issue',
    },
    'total loss': {
      className: 'paleShadePink',
      icon: <AutoSvg />,
      label: 'Copart',
    },
    'workers-comp': {
      className: 'white',
      icon: <WorkersCompSvg />,
      label: 'Workers Comp',
    },
    private: {
      className: 'white',
      icon: <LockClosedIcon />,
      label: 'Private',
    },
    error: {
      className: 'paleShadePink',
      icon: <ErrorIcon />,
      label: 'Case Type Missing',
    },
    verisk: {
      className: 'paleShadePink',
      icon: <CommercialPropertySvg />,
      label: 'Verisk',
    },
  };
  return tags.map((tag) => typeMap[tag] ?? { className: tag, icon: null, label: tag });
};

export default generateTagArray;
