import { Button, Icon } from '@chakra-ui/react';
import { RefreshCcw } from 'lucide-react';
import React from 'react';

import styles from './ChatInbox.module.less';

interface InboxErrorProps {
  className?: string;
  text?: string | React.ReactNode;
}

const InboxError: React.FC<InboxErrorProps> = ({ className = 'errorWrap', text = 'Unable to load chats' }) => {
  const handleRefresh = () => {
    window?.location?.reload();
  };

  return (
    <section className={styles[className]}>
      <p>{text}</p>
      <Button
        variant="outline"
        data-testId="button-test-inboxRefreshBtn"
        onClick={handleRefresh}
      >
        <Icon><RefreshCcw /></Icon>
        Refresh
      </Button>
    </section>
  );
};

export default InboxError;
