import {
  IconButton, Text, Stack, Flex,
} from '@chakra-ui/react';
import { ChevronLeft, Ellipsis } from 'lucide-react';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { clearActiveJob } from '@app/actions/job';
import useScreenSize from '@app/components/Hooks/use-screen-size';
import LobIcon from '@app/components/Settings/CompanyHierarchy/lob-icon';
import UserPhoto2 from '@app/components/UserPhoto2/UserPhoto2';
import { capitalizeEachWord } from '@app/helpers/format';
import { StateType } from '@app/types/reducer-state';

import './ChatHeader.less';

import chatModuleSelector from '../selector';

type ChatHeaderProps = {
  setIsRightBarVisible: (isVisible: boolean) => void;
};

const ChatHeader: React.FC<ChatHeaderProps> = ({
  setIsRightBarVisible,
}) => {
  const isSmallScreen = useScreenSize();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { assignedOperator } = useSelector(chatModuleSelector);

  const {
    jobNumber,
    lineOfBusiness,
    recipient,
  } = useSelector(
    (state: StateType) => ({
      jobNumber: state?.jobs?.activeJob?.displayId || '',
      lineOfBusiness: state?.jobs?.activeJob?.lineOfBusiness,
      recipient: state?.jobs?.activeJob?.customer || {},
    }),
  );

  const handleRightBarOpen = () => {
    setIsRightBarVisible(true);
  };

  return (
    <Flex className="chat-component-header">
      <div className="chat-component-inner">
        {isSmallScreen && (
          <IconButton
            aria-label="Back button"
            variant="ghost"
            className="profile-page-back-button"
            mr="2"
            onClick={() => {
              history('/chats');
              dispatch(clearActiveJob());
            }}
          >
            <ChevronLeft />
          </IconButton>
        )}
        <div className="chat-component-avatar">
          {lineOfBusiness?.subType ? (
            <LobIcon
              name={lineOfBusiness?.subType}
              className="line-of-business-icon"
            />
          ) : (
            <UserPhoto2
              id={recipient.id}
              imageUrl={recipient.imageUrl}
              name={recipient.name}
              className="header-user-photo"
              isRecipient
              isCustomer={false}
              isFinishedLoading
              testId="chat-header-user-photo"
            />
          )}
        </div>
        <Stack className="chat-component-text-wrap" gap="2px">
          <Text className="chat-component-text-name" data-testid="chat-component-text-name">{recipient.name}</Text>
          <Text
            className="chat-component-text-id"
            data-testid="chat-component-text-id"
            lineClamp={1}
          >
            {lineOfBusiness?.displayName
              ? `${capitalizeEachWord(lineOfBusiness?.displayName)}:`
              : 'ID'}
            {' '}
            {jobNumber}
            {!isSmallScreen && (
              <>
                {' '}
                -
                {' '}
                <span className="chat-component-assigned-header" data-testid="assignedOperatorName">
                  {assignedOperator
                    ? `Assigned to ${assignedOperator}`
                    : 'Unassigned'}
                </span>
              </>
            )}
          </Text>
        </Stack>
        <IconButton
          className="chat-component-more-button"
          aria-label="Show case details"
          onClick={handleRightBarOpen}
          variant="ghost"
        >
          <Ellipsis />
        </IconButton>
      </div>
    </Flex>
  );
};

export default ChatHeader;
