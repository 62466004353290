import {
  Box,
  Text,
  Flex,
  type NumberInputValueChangeDetails,
} from '@chakra-ui/react';
import React from 'react';

import { CaseTypeEnum } from '@app/types/create-case';
import {
  NumberInputRoot, NumberInputField,
} from '@chakra-snippets/number-input';
import { Switch } from '@chakra-snippets/switch';

import {
  MAX_CASES_AUTO_ASSIGN,
  MIN_CASES_AUTO_ASSIGN,
} from '../constants';

const CaseTypeSettingsAutoAssign: React.FC<{
  caseType: CaseTypeEnum;
  autoAssignEnabled: boolean;
  maxAutoAssignedCases: number;
  onAutoAssignChange: (enabled: boolean) => void;
  onMaxCasesChange: (details: NumberInputValueChangeDetails) => void;
}> = ({
  caseType,
  autoAssignEnabled,
  maxAutoAssignedCases,
  onAutoAssignChange,
  onMaxCasesChange,
}) => (
  <Box mt={4}>
    <Box borderTopWidth="1px" borderColor="gray.200" mx={-4} pt={4} />
    <Box ml={8}>
      <Flex justify="space-between" align="flex-start" mb={4}>
        <Box mt={1}>
          <Switch
            data-testid={`${caseType.toLowerCase()}-auto-assign-enabled-toggle`}
            name="autoAssignEnabled"
            checked={autoAssignEnabled}
            onChange={(e: React.FormEvent<HTMLLabelElement>) => {
              onAutoAssignChange((e.target as HTMLInputElement).checked);
            }}
            colorPalette="blue"
            size="lg"
          />
        </Box>
        <Box flex="1" ml={4}>
          <Text fontWeight="medium" color="gray.800" mb={1}>Auto-assign cases on</Text>
          <Text color="gray.500" fontSize="sm" mb={4}>
            Cases will be automatically assigned to available operators as they are created.
            If no operators are available, they will be added to a queue until an operator is
            available or an operator pulls from queue.
          </Text>

          <Box mt={4}>
            <Text fontWeight="medium" color="gray.800" mb={2}>Max auto-assigned cases per operator</Text>
            <NumberInputRoot
              min={MIN_CASES_AUTO_ASSIGN}
              max={MAX_CASES_AUTO_ASSIGN}
              size="md"
              maxW="350px"
              value={maxAutoAssignedCases.toString()}
              onValueChange={onMaxCasesChange}
              withControl
            >
              <NumberInputField
                borderColor="gray.200"
                fontSize="md"
                width="100%"
                data-testid={`${caseType.toLowerCase()}-max-cases-input`}
              />
            </NumberInputRoot>
            <Text color="gray.500" fontSize="sm" mt={2}>
              Must be greater than 0 and no greater than
              {' '}
              {MAX_CASES_AUTO_ASSIGN}
            </Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  </Box>
);

export default CaseTypeSettingsAutoAssign;
