import {
  ButtonGroup,
  Flex,
  Spacer,
  Icon,
} from '@chakra-ui/react';
import { Info } from 'lucide-react';
import React from 'react';

import { Button } from '@chakra-snippets/button';
import { DialogFooter } from '@chakra-snippets/dialog';
import { Tag } from '@chakra-snippets/tag';
import { Tooltip } from '@chakra-snippets/tooltip';

interface ModalFooterProps {
  isMarleyOptInDisabled: boolean;
  isPhoneNumberEntered: boolean;
  handleClose: () => void;
  isSubmitting: boolean;
  isValid: boolean;
  isValidPhoneNumber: boolean;
  isEditMode: boolean;
  submitButtonText: string;
  onSubmit: () => void;
}

const CreateCaseModalFooter: React.FC<ModalFooterProps> = ({
  isMarleyOptInDisabled,
  isPhoneNumberEntered,
  handleClose,
  isSubmitting,
  isValid,
  isValidPhoneNumber,
  isEditMode,
  submitButtonText,
  onSubmit,
}) => (
  <DialogFooter borderTop="1px solid" borderColor="gray.200">
    <Flex justifyContent="space-between" width="100%">
      {isMarleyOptInDisabled && isPhoneNumberEntered && (
      <Tag
        bg="gray.50"
        py={2}
        borderRadius="md"
        px={3}
        endElement={(
          <Tooltip
            showArrow
            content="By creating this case you confirm that this customer has already consented to receive messages from your organization."
            positioning={{ placement: 'top' }}
          >
            <Icon ml={1} boxSize={4}>
              <Info />
            </Icon>
          </Tooltip>
        )}
      >
        Did you confirm opt-in?
      </Tag>
      )}
      <Spacer />
      <ButtonGroup gap={3}>
        <Button variant="ghost" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          data-testid="create-case-submit-button"
          loading={isSubmitting}
          disabled={!isValid || (!isValidPhoneNumber && !isEditMode)}
          onClick={onSubmit}
        >
          {submitButtonText}
        </Button>
      </ButtonGroup>
    </Flex>
  </DialogFooter>
);

export default CreateCaseModalFooter;
