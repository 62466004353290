import {
  Card, Text, Flex, Spacer, VStack,
} from '@chakra-ui/react';
import { CircleCheck, Info } from 'lucide-react';
import React from 'react';

import { DISABLE_MARLEY_OPT_IN } from '@app/constants/permissions';
import { useCheckPermissions } from '@app/helpers/common';
import { formatPhoneNumberV2 } from '@app/helpers/format';
import { type Contact } from '@app/types/create-case';
import { Tag } from '@chakra-snippets/tag';
import { Tooltip } from '@chakra-snippets/tooltip';

interface ContactCardProps {
  contact: Contact;
}
const OPTED_IN = 'OPTED_IN';

const ContactCard: React.FC<ContactCardProps> = ({ contact }) => {
  const isDisableMarleyOptIn = useCheckPermissions([DISABLE_MARLEY_OPT_IN]);
  const optedIn = contact?.optIn?.status === OPTED_IN;

  let optInWidget = null;

  if (!isDisableMarleyOptIn) {
    if (optedIn) {
      optInWidget = (
        <Tag variant="outline" colorPalette="green" startElement={<CircleCheck />}>
          Opted-In
        </Tag>
      );
    } else {
      optInWidget = (
        <Tooltip
          showArrow
          content="Customers that have not previously opted-in to receive texts must opt-in before you can message them on this case."
          positioning={{
            placement: 'top',
          }}
        >
          <Tag variant="outline" colorPalette="gray" startElement={<Info />}>
            Not Opted-In
          </Tag>
        </Tooltip>
      );
    }
  }

  return (
    <Card.Root variant="outline" data-testid="contact-card">
      <Card.Body p={3}>
        <Flex minWidth="max-content" alignItems="center">
          <VStack alignItems="baseline" gap={1}>
            <Text fontSize="md">
              {contact?.profile?.firstName || contact?.firstName}
              {' '}
              {contact?.profile?.lastName || contact?.lastName}
            </Text>
            <Text color="gray.500" fontSize="sm">{formatPhoneNumberV2(contact?.profile?.phoneNumber || contact?.phoneNumber)}</Text>
          </VStack>
          <Spacer />
          <Text>{optInWidget}</Text>
        </Flex>
      </Card.Body>
    </Card.Root>
  );
};

export default ContactCard;
