/* eslint-disable react/no-array-index-key */
import {
  Button,
  ButtonGroup,
  Grid,
  GridItem,
  Text,
  Input,
  Stack,
  Flex,
  IconButton,
  Icon,
  SelectRoot,
  SelectTrigger,
  SelectContent,
  SelectItem,
  createListCollection,
} from '@chakra-ui/react';
import { CirclePlus, Trash } from 'lucide-react';
import React, { useEffect, useMemo, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { CREATE_WORKFLOW_SETTINGS, UPDATE_WORKFLOW_SETTINGS, CREATE_WORKFLOW_KEYWORD } from '@app/constants/endpoints';
import { postData, putData } from '@app/hooks/react-query-helpers';
import { useOrganizationDisplayName } from '@app/selectors/organization';
import {
  TimezoneState,
} from '@app/types/workflow-settings';
import { Checkbox } from '@chakra-snippets/checkbox';
import {
  DialogRoot,
  DialogContent,
  DialogHeader,
  DialogCloseTrigger,
  DialogTitle,
  DialogFooter,
  DialogBody,
} from '@chakra-snippets/dialog';
import { Field } from '@chakra-snippets/field';
import { Radio, RadioGroup } from '@chakra-snippets/radio';
import { Switch } from '@chakra-snippets/switch';

import { defaultBusinessHours } from './constants';
import { IWorkflowSettingsFormInput, WorkflowSettingsWithMetadata } from './types';
import {
  mapFormToWorkflowSettingsAndTrigger,
  getFormDefaultValues,
  generate24HoursInHoursAndMinutes,
  validatePhoneNumber,
} from './utils';

interface WorkflowSettingsModalProps {
  onClose: () => void;
  initialData?: WorkflowSettingsWithMetadata;
  type: 'edit' | 'create';
  refetchAllData: () => void;
}

interface TimeOption {
  id: string;
  label: string;
  value: string;
}

const WorkflowSettingsModal: React.FC<WorkflowSettingsModalProps> = ({
  onClose,
  initialData,
  type,
  refetchAllData,
}) => {
  const organizationDisplayName = useOrganizationDisplayName();
  const isEdit = type === 'edit';

  const methods = useForm<IWorkflowSettingsFormInput>({
    mode: 'onBlur',
    defaultValues: getFormDefaultValues(initialData, organizationDisplayName),
  });

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    formState: { isSubmitting, errors },
  } = methods;

  // Memoize time options and timezone options
  const timeOptionsList = useMemo(() => createListCollection({
    items: generate24HoursInHoursAndMinutes().map(({ displayTime, timeValue }) => ({
      id: timeValue,
      label: displayTime,
      value: timeValue,
    })),
  }), []);

  const timezoneOptionsList = useMemo(() => createListCollection({
    items: [
      {
        id: TimezoneState.AMERICA_NEW_YORK,
        label: '(UTC-05:00) Eastern Time (US)',
        value: TimezoneState.AMERICA_NEW_YORK,
      },
      {
        id: TimezoneState.AMERICA_CHICAGO,
        label: '(UTC-06:00) Central Time (US)',
        value: TimezoneState.AMERICA_CHICAGO,
      },
      {
        id: TimezoneState.AMERICA_DENVER,
        label: '(UTC-07:00) Mountain Time (US)',
        value: TimezoneState.AMERICA_DENVER,
      },
      {
        id: TimezoneState.AMERICA_PHOENIX,
        label: '(UTC-07:00) Mountain Time - Arizona (US)',
        value: TimezoneState.AMERICA_PHOENIX,
      },
      {
        id: TimezoneState.AMERICA_LA,
        label: '(UTC-08:00) Pacific Time (US)',
        value: TimezoneState.AMERICA_LA,
      },
      {
        id: TimezoneState.AMERICA_ANCHORAGE,
        label: '(UTC-09:00) Alaska Time (US)',
        value: TimezoneState.AMERICA_ANCHORAGE,
      },
      {
        id: TimezoneState.PACIFIC_HONOLULU,
        label: '(UTC-10:00) Hawaii Time (US)',
        value: TimezoneState.PACIFIC_HONOLULU,
      },
      {
        id: TimezoneState.AMERICA_DETROIT,
        label: '(UTC-05:00) Eastern Time - Michigan (US)',
        value: TimezoneState.AMERICA_DETROIT,
      },
      {
        id: TimezoneState.AMERICA_INDIANA_INDIANAPOLIS,
        label: '(UTC-05:00) Eastern Time - Indiana (US)',
        value: TimezoneState.AMERICA_INDIANA_INDIANAPOLIS,
      },
    ],
  }), []);

  // Memoize form values that are watched frequently
  const organizationNameTypeValue = watch('organizationNameType');
  const completionEmailEnabled = watch('completionEmail');
  const escalation = watch('escalation');
  const escalationSchedule = watch('escalationSchedule');

  // Memoize handlers
  const handleAddEmail = useCallback((field: 'completionEmailAddresses' | 'escalationEmailAddresses') => {
    const currentEmails = getValues(field);
    setValue(field, [...currentEmails, '']);
  }, [getValues, setValue]);

  const handleRemoveEmail = useCallback((index: number, field: 'completionEmailAddresses' | 'escalationEmailAddresses') => {
    const currentEmails = getValues(field);
    const newEmails = currentEmails.filter((_, i) => i !== index);
    setValue(field, newEmails);
  }, [getValues, setValue]);

  const handleTimeChange = useCallback((day: string, field: 'startTime' | 'endTime', value: string) => {
    const currentSchedule = getValues('escalationSchedule') || {};
    const updatedSchedule = {
      ...currentSchedule,
      [day]: {
        ...currentSchedule[day],
        [field]: value,
      },
    };

    setValue('escalationSchedule', updatedSchedule, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  }, [getValues, setValue]);

  const handleTimezoneChange = useCallback((value: string) => {
    setValue('timezone', value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  }, [setValue]);

  // Modify the handleDayEnabledChange handler
  const handleDayEnabledChange = useCallback((day: string, enabled: boolean) => {
    const currentSchedule = getValues('escalationSchedule') || {};
    const updatedSchedule = {
      ...currentSchedule,
      [day]: {
        ...currentSchedule[day],
        enabled,
      },
    };

    setValue('escalationSchedule', updatedSchedule, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  }, [getValues, setValue]);

  // Memoize business hours rendering
  const BusinessHoursSection = useMemo(() => (
    <>
      {Object.keys(defaultBusinessHours).map((day) => {
        const isEnabled = escalationSchedule?.[day]?.enabled ?? false;
        return (
          <GridItem key={day}>
            <Flex gap={4} align="center">
              <Checkbox
                checked={isEnabled}
                onChange={() => handleDayEnabledChange(day, !isEnabled)}
                flexGrow={0}
                flexShrink={0}
                w="30%"
              >
                {day}
              </Checkbox>
              <Flex flex={1} gap={2} align="center">
                <SelectRoot
                  {...register(`escalationSchedule.${day}.startTime`)}
                  disabled={!isEnabled}
                  collection={timeOptionsList}
                  value={[getValues(`escalationSchedule.${day}.startTime`) || '']}
                  onValueChange={(e) => {
                    if (e.items?.length === 1) {
                      handleTimeChange(day, 'startTime', e.items[0].value);
                    }
                  }}
                >
                  <SelectTrigger>
                    {timeOptionsList.items.find((item: TimeOption) => item.value === getValues(`escalationSchedule.${day}.startTime`))?.label || 'Select time'}
                  </SelectTrigger>
                  <SelectContent
                    position="absolute"
                    zIndex={1000}
                    bg="white"
                    boxShadow="lg"
                    borderRadius="md"
                    border="1px solid"
                    borderColor="gray.200"
                    maxH="300px"
                    overflowY="auto"
                    w="fit-content"
                    minW="100%"
                  >
                    {timeOptionsList.items.map((item: TimeOption) => (
                      <SelectItem key={item.id} item={item}>
                        {item.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </SelectRoot>
                <Text>to</Text>
                <SelectRoot
                  {...register(`escalationSchedule.${day}.endTime`)}
                  disabled={!isEnabled}
                  collection={timeOptionsList}
                  value={[getValues(`escalationSchedule.${day}.endTime`) || '']}
                  onValueChange={(e) => {
                    if (e.items?.length === 1) {
                      handleTimeChange(day, 'endTime', e.items[0].value);
                    }
                  }}
                >
                  <SelectTrigger>
                    {timeOptionsList.items.find((item: TimeOption) => item.value === getValues(`escalationSchedule.${day}.endTime`))?.label || 'Select time'}
                  </SelectTrigger>
                  <SelectContent
                    position="absolute"
                    zIndex={1000}
                    bg="white"
                    boxShadow="lg"
                    borderRadius="md"
                    border="1px solid"
                    borderColor="gray.200"
                    maxH="300px"
                    overflowY="auto"
                    w="fit-content"
                    minW="100%"
                  >
                    {timeOptionsList.items.map((item: TimeOption) => (
                      <SelectItem key={item.id} item={item}>
                        {item.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </SelectRoot>
              </Flex>
            </Flex>
          </GridItem>
        );
      })}
    </>
  ), [escalationSchedule, getValues, handleTimeChange, handleDayEnabledChange, timeOptionsList]);

  useEffect(() => {
    if (organizationNameTypeValue === 'default') {
      setValue('organizationName', organizationDisplayName);
    }
  }, [organizationNameTypeValue, organizationDisplayName, setValue]);

  const completionEmailAddresses = watch('completionEmailAddresses');
  const singleOptInEnabled = watch('enableSingleOptIn');
  const policyLookupEnabled = watch('enablePolicyLookup');
  const claimSubmissionEnabled = watch('enableClaimSubmission');
  const escalationEmailAddresses = watch('escalationEmailAddresses');

  const onSubmit = async (data: IWorkflowSettingsFormInput) => {
    const { workflowSettings, keywordTrigger } = mapFormToWorkflowSettingsAndTrigger(
      data,
      organizationDisplayName,
    );
    const {
      workflowName,
      ...workflowSettingsWithoutName
    } = workflowSettings;

    // Handle form submission
    try {
      if (isEdit) {
        await putData(
          UPDATE_WORKFLOW_SETTINGS(workflowName),
          { ...workflowSettingsWithoutName },
        );
      } else {
        await postData(
          CREATE_WORKFLOW_SETTINGS(),
          { [workflowName]: workflowSettingsWithoutName },
        );
        await postData(
          CREATE_WORKFLOW_KEYWORD(),
          { [keywordTrigger]: workflowName },
        );
      }
    } catch (error) {
      console.error('Error submitting form', error);
    }
    refetchAllData();
    onClose();
  };

  return (
    <DialogRoot
      open
      onOpenChange={onClose}
      size="lg"
    >
      <FormProvider {...methods}>
        <DialogContent as="form" onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader borderBottom="1px solid" borderColor="gray.200">
            <DialogTitle>Workflow settings</DialogTitle>
          </DialogHeader>
          <DialogCloseTrigger />

          <DialogBody>
            <Grid gap={6}>
              <GridItem>
                <Text fontSize="lg" fontWeight="medium">Name and keyword settings</Text>
                <Text color="gray.600">Used to find and trigger the workflow</Text>
              </GridItem>

              <GridItem>
                <Field
                  invalid={!!errors.workflowName}
                  label="Workflow name"
                  errorText={errors.workflowName?.message}
                  helperText="This is set when first creating the workflow settings and cannot be edited"
                  required
                >
                  <Input
                    {...register('workflowName', {
                      pattern: {
                        value: /^(DEFAULT|[A-Z_]+:[A-Z_]+:[A-Z_]+)$/,
                        message: 'Workflow name must be either DEFAULT or follow format CLIENT_ID:TYPE:CLAIMTYPE (e.g., ABC_COMPANY:FNOL:AUTO)',
                      },
                      required: 'Workflow name is required',
                    })}
                    disabled={type === 'edit'}
                  />
                </Field>
              </GridItem>

              <GridItem>
                <Field label="Keyword trigger" helperText="This is set when first creating the workflow settings and cannot be edited" required>
                  <Input
                    {...register('keywordTrigger')}
                    disabled={type === 'edit'}
                  />
                </Field>
              </GridItem>

              <GridItem>
                <Field
                  invalid={!!errors.redirectPhoneNumber}
                  label="Redirect landline number"
                  errorText={errors.redirectPhoneNumber?.message || null}
                >
                  <Input
                    {...register('redirectPhoneNumber', {
                      required: 'Redirect landline number is required',
                      validate: validatePhoneNumber,
                    })}
                    placeholder="1-555-555-5555"
                    type="tel"
                  />
                </Field>
              </GridItem>

              <GridItem>
                <Text fontSize="lg" fontWeight="medium">Display values</Text>
                <Text color="gray.600">May be displayed as part of messages</Text>
              </GridItem>

              <GridItem>
                <RadioGroup
                  defaultValue="default"
                  value={organizationNameTypeValue}
                >
                  <Stack gap={4}>
                    <Radio
                      key="defaultOrganizationName"
                      onChange={() => setValue('organizationNameType', 'default')}
                      value="default"
                    >
                      <Text as="span" ml={2}>Default organization name</Text>
                    </Radio>
                    <Radio
                      key="customOrganizationName"
                      onChange={() => setValue('organizationNameType', 'custom')}
                      value="custom"
                    >
                      <Text as="span" ml={2}>Customize organization name</Text>
                    </Radio>
                  </Stack>
                </RadioGroup>
              </GridItem>

              <GridItem>
                <Field
                  invalid={!!errors.organizationName}
                  label="Organization name"
                  errorText={errors.organizationName?.message}
                  required
                >
                  <Input
                    {...register('organizationName')}
                    disabled={organizationNameTypeValue === 'default'}
                  />
                </Field>
              </GridItem>

              <GridItem>
                <Field
                  invalid={!!errors.phoneNumber}
                  label="Phone number"
                  errorText={errors.phoneNumber?.message || null}
                  required
                >
                  <Input
                    {...register('phoneNumber', {
                      required: 'Phone number is required',
                      validate: validatePhoneNumber,
                    })}
                    placeholder="1-555-555-5555"
                    type="tel"
                  />
                </Field>
              </GridItem>

              <GridItem>
                <Field
                  invalid={!!errors.website}
                  label="Website"
                  errorText={errors.website?.message || null}
                  required
                >
                  <Input
                    {...register('website', {
                      required: 'Website is required',
                    })}
                    placeholder="www.example.com"
                  />
                </Field>
              </GridItem>

              <GridItem>
                <Flex direction="row" justify="space-between" align="center">
                  <GridItem flexGrow={1}>
                    <Text fontSize="lg" fontWeight="medium">Completion email</Text>
                    <Text color="gray.600" fontSize="sm">
                      Sends email of FNOL data when FNOL is completed
                    </Text>
                  </GridItem>
                  <Field w="auto">
                    <Switch
                      {...register('completionEmail')}
                      checked={completionEmailEnabled}
                      size="lg"
                      mr={0}
                    />
                  </Field>
                </Flex>
              </GridItem>

              {completionEmailEnabled && (
                <GridItem>
                  <Field
                    label="Email to"
                    invalid={!!errors.completionEmailAddresses?.length}
                    errorText={Array.isArray(errors.completionEmailAddresses) ? errors.completionEmailAddresses.map((err) => err?.message).filter(Boolean).join(', ') : null}
                    helperText="Enter one or more email addresses to receive completion notifications"
                  >
                    <Stack gap={2}>
                      {completionEmailAddresses.map((email: string, index: number) => (
                        <Flex key={`completion-email-${index}`} direction="column" gap={2}>
                          <Flex gap={2}>
                            <Input
                              {...register(`completionEmailAddresses.${index}`, {
                                required: 'Email address is required',
                                pattern: {
                                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: 'Invalid email address format',
                                },
                              })}
                              defaultValue={email}
                              placeholder="completion-email@example.com"
                            />
                            {completionEmailAddresses.length > 1 && (
                              <IconButton
                                aria-label="Remove email"
                                variant="ghost"
                                onClick={() => handleRemoveEmail(index, 'completionEmailAddresses')}
                              >
                                <Icon><Trash /></Icon>
                              </IconButton>
                            )}
                            {index === completionEmailAddresses.length - 1 && (
                              <IconButton
                                aria-label="Add email"
                                variant="outline"
                                onClick={() => handleAddEmail('completionEmailAddresses')}
                              >
                                <Icon><CirclePlus /></Icon>
                              </IconButton>
                            )}
                          </Flex>
                        </Flex>
                      ))}
                    </Stack>
                  </Field>
                </GridItem>
              )}

              <GridItem>
                <Text fontSize="lg" fontWeight="medium">Other settings</Text>
              </GridItem>

              <GridItem>
                <Stack gap={3}>
                  <Field
                    invalid={!!errors.enableSingleOptIn}
                    errorText={errors.enableSingleOptIn?.message || null}
                  >
                    <Checkbox
                      checked={singleOptInEnabled}
                      {...register('enableSingleOptIn', {
                        setValueAs: (value) => value === 'on' || value === true,
                        validate: (value) => value !== undefined || value !== null || 'Single opt-in selection is required',
                      })}
                    >
                      Enable single opt-in (Recommended)
                    </Checkbox>
                  </Field>

                  <Field
                    invalid={!!errors.enablePolicyLookup}
                    errorText={errors.enablePolicyLookup?.message || null}
                  >
                    <Checkbox
                      checked={policyLookupEnabled}
                      {...register('enablePolicyLookup', {
                        setValueAs: (value) => value === 'on' || value === true,
                        validate: (value) => value !== undefined || value !== null || 'Policy lookup selection is required',
                      })}
                    >
                      Enable policy lookup
                    </Checkbox>
                  </Field>

                  <Field
                    invalid={!!errors.enableClaimSubmission}
                    errorText={errors.enableClaimSubmission?.message || null}
                  >
                    <Checkbox
                      checked={claimSubmissionEnabled}
                      {...register('enableClaimSubmission', {
                        setValueAs: (value) => value === 'on' || value === true,
                        validate: (value) => value !== undefined || value !== null || 'Claim submission selection is required',
                      })}
                    >
                      Enable claim submission
                    </Checkbox>
                  </Field>
                </Stack>
              </GridItem>

              <GridItem>
                <Flex direction="row" justify="space-between" align="center">
                  <GridItem flexGrow={1}>
                    <Text fontSize="lg" fontWeight="medium">Escalation</Text>
                    <Text color="gray.600">Enable escalation notifications based on business hours</Text>
                  </GridItem>
                  <Field w="auto">
                    <Switch
                      {...register('escalation')}
                      checked={escalation}
                      size="lg"
                      mr={0}
                    />
                  </Field>
                </Flex>
              </GridItem>

              {escalation && (
                <>
                  <GridItem>
                    <Field label="Time Zone">
                      <SelectRoot
                        {...register('timezone')}
                        collection={timezoneOptionsList}
                        value={[watch('timezone') || '']}
                        onValueChange={(e) => {
                          if (e.items?.length === 1) {
                            handleTimezoneChange(e.items[0].value);
                          }
                        }}
                      >
                        <SelectTrigger>
                          {timezoneOptionsList.items.find((item: TimeOption) => item.value === watch('timezone'))?.label || 'Select timezone'}
                        </SelectTrigger>
                        <SelectContent>
                          {timezoneOptionsList.items.map((item: TimeOption) => (
                            <SelectItem key={item.id} item={item}>
                              {item.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </SelectRoot>
                    </Field>
                  </GridItem>

                  <GridItem>
                    <Field label="Business Hours" />
                  </GridItem>

                  {BusinessHoursSection}

                  <GridItem>
                    <Field
                      label="Escalation email (within business hours)"
                      invalid={!!errors.escalationEmailAddresses?.length}
                      errorText={Array.isArray(errors.escalationEmailAddresses) ? errors.escalationEmailAddresses.map((err) => err?.message).filter(Boolean).join(', ') : null}
                    >
                      <Stack gap={2}>
                        {escalationEmailAddresses.map((email: string, index: number) => (
                          <Flex key={`escalation-email-${index}`} direction="column" gap={2}>
                            <Flex gap={2}>
                              <Input
                                {...register(`escalationEmailAddresses.${index}`, {
                                  pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Invalid email address format',
                                  },
                                })}
                                defaultValue={email}
                              />
                              {escalationEmailAddresses.length > 1 && (
                                <IconButton
                                  aria-label="Remove email"
                                  variant="ghost"
                                  onClick={() => handleRemoveEmail(index, 'escalationEmailAddresses')}
                                >
                                  <Icon><Trash /></Icon>
                                </IconButton>
                              )}
                              {index === escalationEmailAddresses.length - 1 && (
                                <IconButton
                                  aria-label="Add email"
                                  variant="outline"
                                  onClick={() => handleAddEmail('escalationEmailAddresses')}
                                >
                                  <Icon><CirclePlus /></Icon>
                                </IconButton>
                              )}
                            </Flex>
                          </Flex>
                        ))}
                      </Stack>
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Escalation phone (outside business hours)" errorText={errors.escalationPhoneNumber?.message || null}>
                      <Input
                        {...register('escalationPhoneNumber', {
                          validate: (value) => value === '' || validatePhoneNumber(value),
                        })}
                        placeholder="1-555-555-5555"
                        type="tel"
                      />

                    </Field>
                  </GridItem>
                </>
              )}

            </Grid>
          </DialogBody>

          <DialogFooter borderTop="1px solid" borderColor="gray.200">
            <ButtonGroup>
              <Button
                variant="ghost"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                loading={isSubmitting}
              >
                Save
              </Button>
            </ButtonGroup>
          </DialogFooter>
        </DialogContent>
      </FormProvider>
    </DialogRoot>
  );
};

export default React.memo(WorkflowSettingsModal);
