import {
  Box, VStack, Text, Flex,
} from '@chakra-ui/react';
import React from 'react';

import { getShortenedEmail } from '@app/helpers/common';
import { type User } from '@app/types/api/user';
import { Avatar } from '@chakra-snippets/avatar';

interface UserRowProps {
  option: User;
  size?: 'xs' | 'sm' | 'md';
  showTitle?: boolean;
  isYou?: boolean;
  boldName?: boolean;
  borderRadius?: string;
  showEmail?: boolean;
  userNameFontWeight?: string;
}

const UserRow: React.FC<UserRowProps> = ({
  option,
  size = 'md',
  showTitle = false,
  isYou = false,
  boldName = false,
  borderRadius,
  showEmail = true,
  userNameFontWeight,
}) => (
  <Box w="100%">
    <Flex gap={3} alignItems="center">
      <Avatar
        size={size}
        id={option.id}
        src={option.imageUrl}
        name={(option.name || option.label || '').replace('Assign to', '').replace('(me)', '').trim()}
        borderRadius={borderRadius || (size === 'md' ? 'md' : 'full')}
        bg="marleyRed.500"
        color="white"
      />
      <VStack gap={0} alignItems="flex-start">
        <Text
          lineClamp={1}
          maxWidth={250}
          m={0}
          title={option.label || option.name}
          fontSize={(showTitle && '0.875rem') || (boldName ? 'md' : size)}
          fontWeight={userNameFontWeight || (size === 'md' || boldName ? 'semibold' : 'normal')}
        >
          {option.label || option.name}
          {isYou ? ' (Me)' : ''}
          {option?.archived ? ' (Deactivated)' : ''}
        </Text>
        {showEmail && size === 'md' ? (
          <Text
            mt={1}
            color="gray.500"
            mb={0}
            title={getShortenedEmail(option.email)}
          >
            {getShortenedEmail(option.email)}
          </Text>
        ) : ''}
        {showTitle && option.title && (
        <Text
          fontSize="0.75rem"
          lineClamp={1}
          maxWidth={250}
          title={option.title}
          mt={1}
          color="gray.500"
          mb={0}
        >
          {option.title}
        </Text>
        )}
      </VStack>
    </Flex>
  </Box>
);

export default UserRow;
