import { NumberInput as ChakraNumberInput } from '@chakra-ui/react';
import * as React from 'react';

export type NumberInputProps = ChakraNumberInput.RootProps & {
  withControl?: boolean;
};

export const NumberInputRoot = React.forwardRef<
HTMLDivElement,
NumberInputProps
>((props, ref) => {
  const {
    children,
    withControl = false,
    ...rest
  } = props;
  return (
    <ChakraNumberInput.Root ref={ref} variant="outline" {...rest}>
      {children}
      {withControl && (
        <ChakraNumberInput.Control>
          <ChakraNumberInput.IncrementTrigger />
          <ChakraNumberInput.DecrementTrigger />
        </ChakraNumberInput.Control>
      )}
    </ChakraNumberInput.Root>
  );
});

export const NumberInputField = ChakraNumberInput.Input;
export const NumberInputScrubber = ChakraNumberInput.Scrubber;
export const NumberInputLabel = ChakraNumberInput.Label;
