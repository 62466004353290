import { defineRecipe } from '@chakra-ui/react';

export const iconRecipe = defineRecipe({
  base: {
    height: '20px',
    width: '20px',
  },
  variants: {
    color: {
      'gray.500': { color: 'gray.500' },
      currentColor: { color: 'currentColor' },
    },
  },
  defaultVariants: {
    color: 'gray.500',
  },
});

export default iconRecipe;
