import * as commonReducer from './common';

/*
  this reducer is required for when searching/filtering groups in
  chat inbox page quick filter
*/

export const initialState = {
  list: [],
  listById: {},
};

const reducer = (state = initialState) => state;

export default commonReducer.reducer('SEARCHGROUPS', reducer);
