import { Button } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import AssignUser from '@app/components/chakra/menus/action/assign-user';

import UserRow from '../../UserRow/UserRow';

interface Participant {
  id: string;
  name: string;
  role: string;
}

interface ActiveCase {
  customer?: {
    id: string;
  };
  assignedOperator?: {
    id: string;
  };
}

interface ParticipantsProps {
  participants: Participant[];
  removeUser: (id: string) => void;
  activeCase: ActiveCase;
  jobPutRequest: (id: string) => void;
}

const Participants: React.FC<ParticipantsProps> = ({
  participants,
  removeUser,
  activeCase,
  jobPutRequest,
}) => {
  const claimantId = activeCase?.customer?.id;
  const assignedOperatorId = activeCase?.assignedOperator?.id;
  const assignedOperator = participants.find((p) => p.id === assignedOperatorId) || { id: '' };
  const nonAssignedParticipants = participants.filter((p) => (assignedOperatorId ? p.id !== assignedOperatorId : true));
  const isAssigned = assignedOperatorId;

  const renderAssignUser = useMemo(() => (
    <AssignUser
      id="primaryOperatorAssign"
      defaultLabel="Unassigned"
      selectedId={assignedOperatorId || 'unassigned'}
      handleSelect={(id) => jobPutRequest(id)}
      shouldPrefetch
      rowId="1"
      matchWidth
    />
  ), [assignedOperatorId, jobPutRequest, participants]);

  return (
    <div className="participants">
      {!isAssigned && (
        <UserRow
          className="unassigned-user-row"
          key="unassigned"
          user={{ name: 'Not Applicable' }}
        >
          <div className="assigned-operator-role">Unassigned Case</div>
          {renderAssignUser}
        </UserRow>
      )}
      <div className="subtitle">
        Participants
        {participants.length > 0 && `(${participants.length})`}
      </div>
      {isAssigned && (
        <UserRow
          key={assignedOperator.id}
          user={assignedOperator}
        >
          <div className="assigned-operator-role">Primary Operator</div>
          {renderAssignUser}
        </UserRow>
      )}
      {nonAssignedParticipants
        && nonAssignedParticipants.map((u) => (
          <UserRow
            key={u.id}
            user={u}
            right={
              u.id !== claimantId && (
                <div className="add-button">
                  <Button onClick={() => removeUser(u.id)} variant="outline">
                    Remove
                  </Button>
                </div>
              )
            }
          >
            <div className="user-name">{u.name}</div>
            <div className="user-role">
              {u.role === 'operator' ? 'Secondary Operator' : 'Customer'}
            </div>
          </UserRow>
        ))}
    </div>
  );
};

export default Participants;
