import { defineSlotRecipe, defineStyle } from '@chakra-ui/react';
import { Tag as BaseTag } from '@chakra-ui/theme/components';

const { baseStyle } = BaseTag;

export const tagSlotRecipe = defineSlotRecipe({
  slots: ['root'],
  base: {
    ...baseStyle,
    root: defineStyle({
      '& button': {
        paddingInline: '0 !important',
      },
    }),
  },
});

export default tagSlotRecipe;
