import { Box, createListCollection } from '@chakra-ui/react';
import { SearchBar } from '@himarley/unity';
import debounce from 'lodash/debounce';
import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { lazyLoad } from '@app/actions/common';
import UserRow from '@app/components/chakra/user-row';
import { sortByAlphabetical } from '@app/helpers/sorting';
import { operatorType } from '@app/models/marleyTypes';
import { type User } from '@app/types/api/user';
import { type StateType } from '@app/types/reducer-state';
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from '@chakra-snippets/select';

const getDropdownLabelItems = (auth: {
  user: {
    firstName?: string;
    lastName?: string;
    email?: string;
    role?: string;
    title?: string;
    imageUrl?: string;
    _id?: string;
  };
}) => {
  const {
    firstName,
    lastName,
    email: authEmail,
    role: authRole,
    title: authTitle,
    imageUrl,
    _id: authId,
  } = auth.user;

  const me = firstName && lastName ? `${firstName} ${lastName} (me)` : 'Me';

  return [
    {
      id: authId,
      label: me,
      email: authEmail,
      role: authRole,
      title: authTitle,
      imageUrl,
    },
    { id: 'unassigned', label: 'Unassigned' },
  ];
};

interface AssignUserProps {
  id: string;
  rowId: string;
  selectedId: string;
  handleSelect: (id: string, rowId: string) => void;
  contentRef?: React.RefObject<HTMLDivElement>;
  defaultLabel?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  matchWidth?: boolean;
  shouldPrefetch?: boolean;
}

const debouncedLoadUsers = debounce((loadUsers: (
  params: { searchText: string }) => void, searchText: string) => {
  loadUsers({ searchText });
}, 300);

const AssignUser: React.FC<AssignUserProps> = ({
  id,
  rowId,
  selectedId,
  handleSelect,
  contentRef,
  defaultLabel,
  size = 'sm',
  matchWidth = false,
  shouldPrefetch = false,
}) => {
  const [searchFilter, setSearchFilter] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (shouldPrefetch) {
      dispatch(lazyLoad(operatorType, { offset: 0, limit: 20, active: true }, 'users'));
    }
  }, [dispatch, shouldPrefetch]);

  const {
    auth,
    users,
    recentlyAssignedOperators,
  } = useSelector(
    (state: StateType) => ({
      auth: state.auth,
      users: (state.operators?.list || []).sort((a, b) => sortByAlphabetical(a?.name, b?.name)),
      recentlyAssignedOperators: [
        ...(state.auth?.user ? [state.auth.user] : []),
        ...(state.profile?.properties?.recentlyAssignedOperators?.[id] || []),
      ]
        .map((operator: User) => ({
          id: operator.id || operator._id,
          label: operator.name || `${operator.firstName} ${operator.lastName}`,
          email: operator.email,
          title: operator.title,
          role: operator.role,
          imageUrl: operator.imageUrl,
        }))
        .filter(
          (operator, index, self) => index === self.findIndex((o) => o.id === operator.id),
        ),
    }),
  );

  const loadUsers = useCallback(
    (params?: {
      offset?: number;
      limit?: number;
      searchText?: string;
    }) => dispatch(lazyLoad(operatorType, {
      ...params,
      offset: 0,
      limit: 20,
      active: true,
    }, 'users')),
    [dispatch],
  );

  const handleSearchChange = useCallback((value: string) => {
    setSearchFilter(value);
    if (value.trim()) {
      debouncedLoadUsers(loadUsers, value);
    } else {
      loadUsers({ offset: 0, limit: 20 });
    }
  }, [loadUsers]);

  const operatorList = useMemo(() => users
    .filter((user: User) => (
      !recentlyAssignedOperators.some((op) => op.id === (user.id || user._id))
    ))
    .map((user: User) => (user.id === auth.user._id
      ? {
        ...user,
        label: `${auth.user.firstName} ${auth.user.lastName} (me)`,
      }
      : user)), [
    users, auth.user._id, auth.user.firstName, auth.user.lastName, recentlyAssignedOperators,
  ]);

  const dropdownLabelItems = useMemo(() => getDropdownLabelItems(auth), [auth]);

  const renderMenuItems = useCallback((list: User[]) => list
    .filter((option) => option.id !== auth.user._id)
    .map((option) => (
      <SelectItem
        data-dropdown-item={`item-${option.id}`}
        key={option.id}
        onClick={() => handleSelect(option.id || '', rowId)}
        item={option}
      >
        <UserRow option={option} size="xs" />
      </SelectItem>
    )), [auth.user._id, handleSelect, rowId]);

  const userOptions = dropdownLabelItems.map((item) => ({
    id: item.id,
    label: item.label,
  }));

  if (searchFilter === '') {
    userOptions.push(...recentlyAssignedOperators.map((item) => ({
      id: item.id,
      label: item.label,
    })));
  }

  userOptions.push(...operatorList.map((item) => ({
    id: item.id,
    label: item.name || '',
  })));

  return (
    <SelectRoot
      data-testid="assign-user-action-menu"
      collection={createListCollection({
        items: userOptions,
        itemToString: (item) => item.label || '',
        itemToValue: (item) => item.id || '',
      })}
      value={selectedId ? [selectedId] : undefined}
      size={size}
      positioning={matchWidth ? { sameWidth: true } : undefined}
    >
      <SelectTrigger>
        <SelectValueText placeholder={defaultLabel} />
      </SelectTrigger>
      <SelectContent
        data-testid="assign-user-action-menu-list"
        portalRef={contentRef}
        w={!matchWidth ? '250px' : undefined}
      >
        <Box
          pb={1}
          px={3}
          w="inherit"
          bg="white"
          pos="fixed"
          zIndex={1}
          paddingTop="5px"
          top={0}
          css={{ '& div': { width: 'unset' } }}
        >
          <SearchBar
            id="operators"
            placeholder="Search Operators"
            value={searchFilter}
            onValueChange={handleSearchChange}
            onClear={() => handleSearchChange('')}
          />
        </Box>
        <Box marginTop="40px">
          {dropdownLabelItems.map((option) => (
            <SelectItem
              data-dropdown-item={`item-${option.id}`}
              key={option.id}
              onClick={() => handleSelect(option.id || '', rowId)}
              item={option}
            >
              <UserRow option={option} size="xs" />
            </SelectItem>
          ))}
        </Box>
        {searchFilter === '' && renderMenuItems(recentlyAssignedOperators)}
        {renderMenuItems(operatorList)}
      </SelectContent>
    </SelectRoot>
  );
};

export default AssignUser;
