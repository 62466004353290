import { createContext } from 'react';

type CreateCaseContextType = {
  contentRef: React.RefObject<HTMLDivElement> | undefined;
};

export const CreateCaseContext = createContext<CreateCaseContextType>({
  contentRef: undefined,
});

export default CreateCaseContext;
