import {
  Grid, GridItem, Card, Heading,
} from '@chakra-ui/react';
import React, { useState } from 'react';

import LoadPartnerIcon from '@app/components/UserPhoto2/load-partner-icon';
import { TOTAL_LOSS, VERISK_RULESET_TYPE } from '@app/constants/integration-constants';
import { FEATURE_FLAG_TOTAL_LOSS_ASSIST, FEATURE_FLAG_VERISK, FEATURE_FLAG_CCC_FIRST_LOOK } from '@app/constants/permissions';
import usePermissionVerifyV2 from '@app/hooks/permission-verify';

import IntegrationRulesContainer from './integration-rules-container';

// dynamimcally populate data from carrier config into this format in HMB-9954 ticket
const getPartnerSettingsData = (
  isTLAEnabled: boolean,
  isVeriskEnabled: boolean,
  isCCCEnabled: boolean,
) => [
  ...(isTLAEnabled ? [{
    partnerIcon: 'copart',
    partnerName: 'Copart',
    partnerDescription: 'Copart operates an extensive online auction platform connecting buyers and sellers of vehicles, primarily serving insurance companies, dealerships, and individual buyers. The company specializes in handling salvaged and total loss vehicles, helping insurers quickly liquidate damaged cars while providing a robust marketplace for repairable vehicles, parts, and scrap. Copart’s advanced logistics network and digital bidding technology streamline vehicle remarketing, making the total loss process more efficient for insurers and policyholders alike.',
    carrierEnabled: true,
    rulesetType: TOTAL_LOSS,
  }] : []),
  ...(isVeriskEnabled ? [{
    partnerIcon: 'verisk',
    partnerName: 'Verisk',
    partnerDescription: 'Verisk is a global leader in data-driven decision support, offering advanced analytics, risk modeling, and AI-powered solutions to help insurers and businesses manage risk and optimize operations. The company aggregates vast amounts of industry data, including claims histories, property risk assessments, and catastrophe modeling, to provide actionable intelligence that enhances underwriting, fraud detection, and compliance. With its suite of products, including ISO reports, Xactware claims estimation tools, and telematics solutions, Verisk plays a critical role in streamlining insurance workflows and improving risk management strategies.',
    carrierEnabled: true,
    rulesetType: VERISK_RULESET_TYPE,
  }] : []),
  ...(isCCCEnabled ? [{
    partnerIcon: 'ccc',
    partnerName: 'CCC',
    partnerDescription: 'CCC Intelligent Solutions is a leading software provider that helps insurers, repair shops, and OEMs manage the auto claims process efficiently. Their platform leverages AI, telematics, and extensive data analytics to provide automated damage assessment, claims triage, and repair shop network management. CCC’s estimating and workflow solutions help insurers reduce cycle times and improve accuracy in total loss valuations, repair cost predictions, and claim resolutions. By integrating with insurers, body shops, and supply chain partners, CCC enhances transparency and efficiency across the entire claims ecosystem, ensuring a smoother experience for policyholders.',
    carrierEnabled: false,
    rulesetType: 'PARTNER',
  }] : []),
];

const IntegrationSettingsContainer = () => {
  const {
    FEATURE_FLAG_TOTAL_LOSS_ASSIST: isTLAEnabled,
    FEATURE_FLAG_VERISK: isVeriskEnabled,
    FEATURE_FLAG_CCC_FIRST_LOOK: isCCCEnabled,
  } = usePermissionVerifyV2([
    FEATURE_FLAG_TOTAL_LOSS_ASSIST,
    FEATURE_FLAG_VERISK,
    FEATURE_FLAG_CCC_FIRST_LOOK,
  ]);
  const [selectedPartnerData, setSelectedPartnerData] = useState<{
    rulesetType: string | null,
    partnerName: string | null
  }>({
    rulesetType: null,
    partnerName: null,
  });

  const partnerSettingsData = getPartnerSettingsData(isTLAEnabled, isVeriskEnabled, isCCCEnabled);

  const handleCardClick = (partnerName: string, rulesetType: string) => {
    setSelectedPartnerData({ partnerName, rulesetType });
  };

  const handleCloseRulesContainer = () => {
    setSelectedPartnerData({ rulesetType: null, partnerName: null });
  };

  return (
    <>
      {!selectedPartnerData.rulesetType && !selectedPartnerData.partnerName && (
        <div data-testid="integration-settings-container">
          <header>
            <h1>Integration Settings</h1>
          </header>
          <p>Configure rules and settings per software integration</p>
          <Grid
            templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
            gap={6}
            alignItems="stretch"
            mt={6}
          >
            {partnerSettingsData.map(({
              partnerIcon,
              partnerName,
              partnerDescription,
              rulesetType,
            }) => (
              <GridItem key={partnerIcon}>
                <Card.Root
                  onClick={() => handleCardClick(partnerName, rulesetType)}
                  _hover={{ cursor: 'pointer', bg: 'gray.100' }}
                  variant="outline"
                  boxShadow="none"
                  height="100%"
                >
                  <Card.Header pb="0">
                    <LoadPartnerIcon partner={partnerIcon} isThumbnail={false} />
                    <Heading mt="2" size="md" fontWeight="bold">{partnerName}</Heading>
                  </Card.Header>
                  <Card.Body pt="10px">
                    <p>{partnerDescription}</p>
                  </Card.Body>
                </Card.Root>
              </GridItem>
            ))}
          </Grid>
        </div>
      )}
      {selectedPartnerData.rulesetType && selectedPartnerData.partnerName && (
        <IntegrationRulesContainer
          partnerName={selectedPartnerData.partnerName}
          type={selectedPartnerData.rulesetType}
          onClose={handleCloseRulesContainer}
        />
      )}
    </>
  );
};

export default IntegrationSettingsContainer;
