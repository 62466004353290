import { Icon, Text } from '@chakra-ui/react';
import { Info } from 'lucide-react';
import React, { useState } from 'react';

import AlertDialog from '@app/components/chakra/alert-dialog';
import { Tooltip } from '@chakra-snippets/tooltip';

import './ChatBanner.less';

const renderBannerMessage = ({
  isLocked,
  isBlocked,
  isNotActivated,
  isInChatBotMode,
  marleyNumberStatus,
  isInFNOLFlow,
}: {
  isLocked: boolean;
  isBlocked: boolean;
  isNotActivated: boolean;
  isInChatBotMode: boolean;
  marleyNumberStatus: string;
  isInFNOLFlow: boolean;
}) => {
  let chatBlockBanner = '';
  let chatBlockBannerMessage: React.ReactNode = '';
  let chatBlockBannerClass = '';
  if (isLocked) {
    chatBlockBanner = 'Customer Locked Out';
  } else if (isBlocked) {
    chatBlockBanner = 'User Opted Out';
    chatBlockBannerMessage = (
      <div>
        User has opted out.
        <br />
        This user has opted not to receive SMS messages through the Hi Marley
        system.
      </div>
    );
  } else if (isInFNOLFlow) {
    chatBlockBanner = 'FNOL in progress.';
  } else if (isNotActivated) {
    chatBlockBanner = 'Customer Not Opted-In';
    chatBlockBannerMessage = (
      <div>
        This customer has been invited to join this conversation but has not
        opted-in yet. You can resend the opt-in text to them.
      </div>
    );
  } else if (isInChatBotMode) {
    chatBlockBanner = 'Automated workflow is gathering information';
    chatBlockBannerMessage = (
      <div>
        An automated workflow is currently gathering information from this person.
        To cancel this workflow press the `Take Over` button below.
      </div>
    );
  } else if (marleyNumberStatus === 'NEW_MARLEY_NUMBER') {
    chatBlockBanner = 'The number for this case was recently changed';
    chatBlockBannerClass = 'changed-number-info-header';
    chatBlockBannerMessage = (
      <div>
        Due to regulatory messaging changes the number for this case has
        changed. A text message was sent before your last message to the enduser
        explaining why the number changed.
      </div>
    );
  }

  return {
    chatBlockBanner,
    chatBlockBannerMessage,
    chatBlockBannerClass,
  };
};

type Job = {
  marleyNumberStatus: string;
  id?: string;
};

interface ChatBannerProps {
  isNotActivated: boolean;
  isLocked: boolean;
  isBlocked: boolean;
  isInChatBotMode: boolean;
  isInFNOLFlow: boolean;
  job: Job;
  updateJob: (job: Job) => void;
  toggleConfirmation: (confirmation: {
    id: string;
    isOpen: boolean;
    selectedId: string;
  }) => void;
  jobId: string;
  updateEndUserOptStatus?: () => void;
  isUnlockingUser?: boolean;
}

const ChatBanner: React.FC<ChatBannerProps> = ({
  isNotActivated,
  isLocked,
  isBlocked,
  isInChatBotMode,
  job,
  updateJob,
  toggleConfirmation,
  jobId,
  updateEndUserOptStatus = () => {},
  isUnlockingUser = false,
  isInFNOLFlow,
}) => {
  const [showUserActivationInfo, setShowUserActivationInfo] = useState(false);

  const toggleUserActivationInfo = (updatedVal: boolean) => {
    if (isLocked) {
      updateEndUserOptStatus();
      return;
    }
    setShowUserActivationInfo(updatedVal);
  };

  const handleClickRemoveMarleyNumberStatusBanner = () => {
    updateJob({
      ...job,
      marleyNumberStatus: 'BANNER_CLOSED',
    });
  };
  const { marleyNumberStatus } = job;
  const {
    chatBlockBanner,
    chatBlockBannerMessage,
    chatBlockBannerClass,
  } = renderBannerMessage({
    isLocked,
    isBlocked,
    isNotActivated,
    isInChatBotMode,
    marleyNumberStatus,
    isInFNOLFlow,
  });
  const showResendWelcome = isNotActivated
    && !isLocked
    && !isBlocked
    && !isInChatBotMode
    && !isInFNOLFlow;
  const showRemoveMarleyNumberChangeBannerButton = job.marleyNumberStatus === 'NEW_MARLEY_NUMBER';
  return (
    <div
      className={`chat-banner ${chatBlockBannerClass} ${
        isLocked ? 'chat-banner-locked' : 'chat-banner-default'
      }`}
    >
      <AlertDialog
        isDialogOpen={showUserActivationInfo}
        toggleDialog={() => toggleUserActivationInfo(false)}
        title={chatBlockBanner}
      >
        <Text>{chatBlockBannerMessage}</Text>
      </AlertDialog>
      <div className="chat-banner-text" data-testid={`chat-banner-text-${job?.id}`}>
        {chatBlockBanner}
        {chatBlockBannerMessage && (
          <Tooltip showArrow content={chatBlockBannerMessage}>
            <Icon color="white" pl={1}><Info /></Icon>
          </Tooltip>
        )}
      </div>
      <div className="chat-banner-buttons">
        {showResendWelcome && (
          <button
            className="chat-banner-button"
            onClick={() => toggleConfirmation({
              id: 'resendwelcome',
              isOpen: true,
              selectedId: jobId,
            })}
            type="button"
          >
            Resend Opt-In Text
          </button>
        )}
        {isLocked && (
          <button
            className="chat-banner-button"
            disabled={isUnlockingUser}
            onClick={() => toggleUserActivationInfo(true)}
            type="button"
          >
            {isUnlockingUser ? 'Unlocking...' : 'Unlock Customer'}
          </button>
        )}
        {showRemoveMarleyNumberChangeBannerButton && (
          <button
            className="chat-banner-button"
            onClick={handleClickRemoveMarleyNumberStatusBanner}
            type="button"
          >
            Dismiss
          </button>
        )}
      </div>
    </div>
  );
};

export default ChatBanner;
