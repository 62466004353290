import { defineSlotRecipe } from '@chakra-ui/react';

export const avatarSlotRecipe = defineSlotRecipe({
  slots: ['root'],
  variants: {
    variant: {
      solid: {
        root: {
          colorPalette: 'marleyRed',
        },
      },
    },
  },
  defaultVariants: {
    variant: 'solid',
  },
});

export default avatarSlotRecipe;
