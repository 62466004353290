import { GridItem } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { updateUserProperties } from '@app/actions/profile';
import SelectMenu from '@app/components/chakra/fields/select-menu';
import usePermissionVerify from '@app/components/elements/hooks/usePermissionVerify';
import {
  CASE_VISIBILITY,
  LINES_OF_BUSINESS_ENABLE,
} from '@app/constants/permissions';
import { CaseVisibilityEnum } from '@app/types/create-case';
import { type IFormInput } from '@app/types/create-case';
import { StateType } from '@app/types/reducer-state';

import { CreateCaseContext } from './context';
import AssignUserMenu from './fields/assign-user-menu';
import LineOfBusinessMenu from './fields/line-of-business-menu';

const SharedForm = () => {
  const dispatch = useDispatch();
  const brands = useSelector((state: StateType) => state.organizations.branding);
  const { getValues } = useFormContext();
  const { contentRef } = useContext(CreateCaseContext);

  const handleBrandChange = (brand: string) => {
    dispatch(updateUserProperties({ brandId: brand }));
  };

  const { hasAccess: isLinesOfBusinessEnabled } = usePermissionVerify(
    { neededPermissions: [LINES_OF_BUSINESS_ENABLE] },
  );

  const { hasAccess: isCaseVisibilityEnabled } = usePermissionVerify(
    { neededPermissions: [CASE_VISIBILITY] },
  );

  const validateAssignedUser = (visibility: string | undefined) => {
    if (!visibility) return true;

    const assignedUser = getValues('assignedTo');
    if ([
      CaseVisibilityEnum.privateHidden,
      CaseVisibilityEnum.privateRedacted,
    ].includes(visibility as CaseVisibilityEnum)) {
      return assignedUser !== 'unassigned' || 'Private cases must be assigned to a user';
    }
    return true;
  };

  return (
    <>
      <GridItem colSpan={{ base: 2, md: 1 }}>
        <SelectMenu<IFormInput>
          id="insurance-brand"
          label="Insurance Brand"
          name="insuranceBrand"
          placeholder="Choose Insurance Brand"
          options={brands?.map((brand) => (
            { id: brand, label: brand, handleSelect: handleBrandChange }
          ))}
          registerOptions={brands?.length > 0 ? { required: { value: true, message: 'Insurance Brand is Required' } } : {}}
          contentRef={contentRef}
        />
      </GridItem>
      <GridItem colSpan={{ base: 2, md: 1 }}>
        {isLinesOfBusinessEnabled && getValues('insuranceBrand') && (
          <LineOfBusinessMenu />
        )}
      </GridItem>
      <GridItem colSpan={{ base: 2, md: 1 }}>
        <AssignUserMenu />
      </GridItem>
      <GridItem colSpan={{ base: 2, md: 1 }}>
        {isCaseVisibilityEnabled && (
          <SelectMenu<IFormInput>
            id="case-visibility"
            label="Case Visibility"
            name="caseVisibility"
            options={[{ id: CaseVisibilityEnum.public, label: 'Public' },
              { id: CaseVisibilityEnum.privateRedacted, label: 'Private' },
            ]}
            registerOptions={{
              required: {
                value: true,
                message: 'Case Visibility is Required',
              },
              validate: validateAssignedUser,
            }}
            contentRef={contentRef}
          />
        )}
      </GridItem>
    </>
  );
};

export default SharedForm;
