import { Button } from '@chakra-ui/react';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';

import { getAutoReplies } from '@app/actions/profile';
import Spinner from '@app/components/chakra/spinner';
import {
  DialogRoot, DialogContent, DialogHeader, DialogBody,
  DialogCloseTrigger, DialogTitle,
} from '@chakra-snippets/dialog';

import GeneralAutoReplyModal from './GeneralAutoReplyModal/GeneralAutoReplyModal';
import GeneralAutoReplyRow from './GeneralAutoReplyRow/general-auto-reply-row';
import NoMessages from '../SharedComponents/NoMessages/NoMessages';
import './GeneralAutoReplies.less';
import { Reply } from '../types';

interface GeneralAutoRepliesProps {
  userId: string;
  isLoading: boolean;
  generalAutoReplies: Reply[];
  error: unknown;
  reply: Reply;
}

const GeneralAutoReplies: React.FC<GeneralAutoRepliesProps> = ({
  userId,
  isLoading,
  generalAutoReplies,
  error,
  reply,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    getAutoReplies(userId);
  }, [userId]);

  // only show auto replies if userId matches
  // to prevent the logged in user auto replies from being used when editing a different user
  const filteredGeneralAutoReplies = useMemo(
    () => (generalAutoReplies?.[0]?.pk?.includes(userId)
      ? generalAutoReplies
      : []),
    [generalAutoReplies, userId],
  );

  let body: React.JSX.Element | React.JSX.Element[] = <NoMessages error={error} />;

  if (isLoading) {
    body = (
      <div className="autoreplies-loading-icon">
        <Spinner h="50px" w="50px" />
      </div>
    );
  } else if (filteredGeneralAutoReplies && filteredGeneralAutoReplies.length > 0) {
    body = filteredGeneralAutoReplies.map((filteredReply) => (
      <GeneralAutoReplyRow key={filteredReply.id} userId={userId} reply={filteredReply} />
    ));
  }

  return (
    <>
      <div className="auto-replies-container general-auto-replies-container">
        <div className="general-auto-replies-header">
          <h2>Away Messages</h2>
          {filteredGeneralAutoReplies && filteredGeneralAutoReplies.length > 0
            ? null
            : (
              <div className="button-row">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setIsModalOpen(true)}
                >
                  + Create New Message
                </Button>
              </div>
            )}
        </div>
        <div className="general-auto-replies-subheader">
          <p>Configure an away message that sends at all times.</p>
        </div>
        {body}
      </div>
      <DialogRoot
        data-test="create-auto-reply-modal"
        open={isModalOpen}
        onOpenChange={(e) => setIsModalOpen(e.open)}
        size="lg"
      >
        <DialogContent ref={contentRef} className="create-auto-reply-modal">
          <DialogHeader>
            <DialogTitle>New Away Message</DialogTitle>
          </DialogHeader>
          <DialogBody>
            <GeneralAutoReplyModal
              pageProps={{ reply, userId }}
              toggleModal={setIsModalOpen}
              contentRef={contentRef}
            />
          </DialogBody>
          <DialogCloseTrigger />
        </DialogContent>
      </DialogRoot>
    </>
  );
};

export default GeneralAutoReplies;
