import { Copy } from 'lucide-react';
import React from 'react';
import { useSelector } from 'react-redux';
import stripTags from 'striptags';

import { Button } from '@chakra-snippets/button';
import {
  DialogRoot, DialogContent, DialogHeader, DialogBody,
  DialogCloseTrigger, DialogFooter, DialogTitle,
} from '@chakra-snippets/dialog';

import '../MassNotificationVerificationModal/MassNotificationVerificationModal.less';
import '../MassNotificationModal/MassNotificationModal.less';
import './MassNotificationViewModal.less';

const copyToClipboard = (
  message: string,
  setMessageCopied: { (copied: boolean): void; (arg0: boolean): void; },
) => {
  navigator.clipboard.writeText(message)
    .then(() => {
      setMessageCopied(true);
    });
};

const formatMessage = (message: string) => {
  const dollarRegex = /(\s|^)(\$(\S)+)\b/gm;
  const newMessage = stripTags(message);
  return newMessage.replace(dollarRegex, (_, g1, g2) => `<span class="boldFormattedWord">${g1}${g2}</span>`);
};

interface MassNotificationViewModalProps {
  show: boolean;
  hide: () => void;
  activeMassNotification?: {
    Title?: string;
    Message?: string;
  };
  setMessageCopied: (copied: boolean) => void;
}

const MassNotificationViewModal: React.FC<MassNotificationViewModalProps> = ({
  show,
  hide,
  activeMassNotification,
  setMessageCopied,
}) => {
  const copied = useSelector((state) => state.massNotifications.messageCopied);

  return (
    <DialogRoot
      open={show}
      onOpenChange={hide}
    >
      <DialogContent className="mass-notification-view-modal">
        <DialogHeader>
          <DialogTitle>View Notification</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <div className="textDisplay">
            <div className="inputTitle">Title</div>
            <div className="mass-notification-secondary-title">
              {activeMassNotification && activeMassNotification.Title}
            </div>
            <div className="inputTitle">Message</div>
            {
              activeMassNotification
              // eslint-disable-next-line react/no-danger
              && <div className="mass-notification-secondary-title" dangerouslySetInnerHTML={{ __html: formatMessage(activeMassNotification?.Message || '') }} />
            }
          </div>
        </DialogBody>
        {activeMassNotification && (
          <DialogFooter>
            {
              !copied
              && (
                <Button
                  onClick={() => copyToClipboard(activeMassNotification?.Message || '', setMessageCopied)}
                >
                  Copy to clipboard
                  {' '}
                  <Copy />
                </Button>
              )
            }
            {copied
              && (
                <Button>
                  Copied to clipboard!
                </Button>
              )}
          </DialogFooter>
        )}
        <DialogCloseTrigger />
      </DialogContent>
    </DialogRoot>
  );
};

export default MassNotificationViewModal;
