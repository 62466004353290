import {
  Grid,
  GridItem,
  ButtonGroup,
  Heading,
  Link,
  Text,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { createUser } from '@app/actions/users';
import AutoComplete from '@app/components/chakra/fields/auto-complete';
import GroupSelectMenu from '@app/components/chakra/fields/group-select-menu';
import TextField from '@app/components/chakra/fields/text-field';
import { cleanPhone, formatPhoneNumberV2 } from '@app/helpers/format';
import { StateType } from '@app/types/reducer-state';
import { Alert } from '@chakra-snippets/alert';
import { Button } from '@chakra-snippets/button';
import { DialogBody, DialogFooter } from '@chakra-snippets/dialog';
import { toaster } from '@chakra-snippets/toaster';

interface IFormInput {
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  phoneNumber: string;
  contactNumber: string;
  groups: string[];
}

const InviteUserForm = (
  { toggleModal, contentRef }: {
    toggleModal: () => void;
    contentRef: React.RefObject<HTMLDivElement>;
  },
) => {
  const methods = useForm<IFormInput>({
    mode: 'onBlur',
  });
  const dispatch = useDispatch();
  const toastIdRef = useRef<null | string | number | undefined>(null);
  const isTitleEnabled = useSelector((state: StateType) => (state.auth?.permissions || []).includes('TITLE_ENABLED'));
  const listTitles = useSelector((state: StateType) => state.operators?.listOfTitles || []);
  const identityProvider = useSelector((state: StateType) => state.auth?.user?.organization?.identityProvider || 'MARLEY');

  const prepareSubmitData = (data: IFormInput) => ({
    ...data,
    phoneNumber: data.phoneNumber ? cleanPhone(data.phoneNumber) : undefined,
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(async (data) => {
        toastIdRef.current = toaster.create({
          description: 'Creating user...',
          type: 'loading',
        });

        try {
          await dispatch(createUser(prepareSubmitData(data)));
          if (toastIdRef.current) {
            toaster.update(toastIdRef.current, {
              description: 'User created successfully',
              type: 'success',
              duration: 5000,
              meta: {
                closable: true,
              },
            });
          }
          toggleModal();
        } catch (error) {
          if (toastIdRef.current) {
            toaster.update(toastIdRef.current, {
              description: 'Failed to create user',
              type: 'error',
              duration: 5000,
              meta: {
                closable: true,
              },
            });
          }
        }
      })}
      >
        <DialogBody>
          <Grid
            width="100%"
            templateColumns={{ sm: 'repeat(1, minmax(0, 1fr))', md: 'repeat(2, minmax(0, 1fr))' }}
            gap={4}
          >
            <GridItem colSpan={2}>
              <Heading size="md" fontWeight={500}>User Information</Heading>
            </GridItem>
            <GridItem>
              <TextField<IFormInput>
                name="firstName"
                label="First Name"
                registerOptions={{ required: { value: true, message: 'First Name is Required' } }}
              />
            </GridItem>
            <GridItem>
              <TextField<IFormInput>
                name="lastName"
                label="Last Name"
                registerOptions={{ required: { value: true, message: 'Last Name is Required' } }}
              />
            </GridItem>
            <GridItem>
              <TextField<IFormInput>
                name="email"
                label="Email"
                registerOptions={{
                  required: { value: true, message: 'Email is Required' },
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Invalid Email Format',
                  },
                }}
              />
            </GridItem>
            {isTitleEnabled && (
            <GridItem>
              <AutoComplete<IFormInput>
                id="title"
                name="title"
                label="Title"
                options={listTitles}
              />
            </GridItem>
            )}
            <GridItem>
              <TextField<IFormInput>
                name="phoneNumber"
                label="Phone Number"
                formatValue={formatPhoneNumberV2}
                leftElement="+1"
                registerOptions={{
                  minLength: { value: 14, message: 'Phone number must be 10 digits' },
                  maxLength: { value: 14, message: 'Phone number must be 10 digits' },
                }}
              />
            </GridItem>
            <GridItem>
              <TextField<IFormInput>
                name="contactNumber"
                label="Office Line"
              />
            </GridItem>
            <GridItem colSpan={2}>
              <Heading size="md" fontWeight={500}>Group Assignment</Heading>
            </GridItem>
            <GridItem colSpan={2}>
              <GroupSelectMenu<IFormInput> name="groups" contentRef={contentRef} />
            </GridItem>
            <GridItem colSpan={2}>
              <Alert fontSize="sm" status="warning" variant="subtle">
                {identityProvider === 'MARLEY' ? 'The user will receive an activation email to activate their account and sign in.' : (
                  <Text>
                    <Link target="_blank" href="https://himarley.zendesk.com/hc/en-us/articles/360057881753-Is-Single-Sign-On-SSO-right-for-your-organization-" rel="noreferrer">Single-Sign-On (SSO)</Link>
                    {' '}
                    is enabled so once you create this user they will be able to login.
                    <br />
                    An email will NOT be sent to inform the user they have access.
                  </Text>
                )}
              </Alert>
            </GridItem>
          </Grid>
        </DialogBody>
        <DialogFooter>
          <ButtonGroup>
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                methods.reset();
                toggleModal();
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!methods.formState.isValid}
              loading={methods.formState.isSubmitting}
              data-testid="invite-user-submit-button"
            >
              Invite User
            </Button>
          </ButtonGroup>
        </DialogFooter>
      </form>
    </FormProvider>
  );
};

export default InviteUserForm;
