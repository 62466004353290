import {
  IconButton,
  Card,
  HStack,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import { Trash2 } from 'lucide-react';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { cancelScheduledMessage } from '@app/actions/chat';
import { formatDate } from '@app/helpers/datetime';
import { type User } from '@app/types/api/user';
import { Avatar } from '@chakra-snippets/avatar';
import {
  DialogRoot, DialogContent, DialogHeader, DialogBody,
  DialogCloseTrigger, DialogTitle,
} from '@chakra-snippets/dialog';

interface ListScheduledMessagesProps {
  chatId: string;
  show: boolean;
  toggleShow: (value: boolean) => void;
}

const ListScheduledMessages: React.FC<ListScheduledMessagesProps> = ({
  chatId,
  show,
  toggleShow,
}) => {
  const dispatch = useDispatch();
  const { scheduledMessages, chats } = useSelector((state) => ({
    scheduledMessages: [...(state.jobs?.scheduledMessages || [])],
    chats: state.jobs?.chats || {},
  }));

  let participants: User[] = [];
  if (chats && chats[chatId]) {
    participants = chats[chatId].participants;
  }

  const noNotesFound = scheduledMessages?.length === 0;

  return (
    <DialogRoot
      open={show}
      onOpenChange={(e) => toggleShow(e.open)}
      size="lg"
      scrollBehavior="inside"
      placement="center"
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Scheduled Messages</DialogTitle>
        </DialogHeader>
        <DialogBody mb={4}>
          <VStack>
            {noNotesFound ? (
              <div data-test="marley-note-none" className="marley-note-none">
                No messages scheduled.
              </div>
            ) : scheduledMessages?.map((m) => {
              const author = (participants || []).find((p) => p.id === _.get(m, 'userId._id'));
              const authorName = _.get(author, 'name', 'user');
              return (
                <Card.Root w="100%">
                  <Card.Header>
                    <HStack>
                      <div className="marley-note-author">
                        <Avatar
                          size="xs"
                          id={_.get(author, 'id')}
                          src={_.get(author, 'imageUrl')}
                          name={authorName}
                          borderRadius="md"
                          bg="marleyRed.500"
                          color="white"
                        />
                        <div className="marley-note-author-name">{authorName}</div>
                      </div>
                      <div className="marley-note-createdAt">{formatDate(new Date(m.eventTime).toLocaleString())}</div>
                      <div
                        data-test="delete-note-button"
                        className="marley-note-icons"
                      >
                        <IconButton
                          aria-label="Cancel scheduled message"
                          onClick={() => dispatch(cancelScheduledMessage(m.id))}
                          variant="ghost"
                        >
                          <Trash2 />
                        </IconButton>
                      </div>
                    </HStack>
                  </Card.Header>
                  <Card.Body>
                    {m.messageBody}
                  </Card.Body>
                </Card.Root>
              );
            })}
          </VStack>
        </DialogBody>
        <DialogCloseTrigger />
      </DialogContent>
    </DialogRoot>
  );
};

export default ListScheduledMessages;
