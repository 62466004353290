import { defineSlotRecipe } from '@chakra-ui/react';

export const linkRecipe = defineSlotRecipe({
  slots: ['root'],
  base: {
    root: {
      textDecoration: 'underline',
      color: 'blue.500',
    },
  },
});

export default linkRecipe;
