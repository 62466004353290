import { createListCollection, SelectValueChangeDetails } from '@chakra-ui/react';
import React from 'react';

import {
  SelectRoot,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValueText,
} from '@chakra-snippets/select';

interface Option {
  label: string;
  value: string;
  disabled?: boolean;
  selected?: boolean;
}

interface SimpleSelectProps {
  id: string;
  multiple?: boolean;
  onClose?: () => void;
  lazyMount?: boolean;
  placeholder?: string;
  options: Option[];
  closeOnSelect?: boolean;
  itemName?: string;
  minW?: string;
  onValueChange: (details: SelectValueChangeDetails<Option>) => void;
  contentRef?: React.RefObject<HTMLDivElement>;
  disabled?: boolean;
  overrideMaxWidth?: boolean;
}

const SimpleSelect: React.FC<SimpleSelectProps> = ({
  id,
  multiple = false,
  onClose,
  lazyMount = false,
  placeholder = '',
  options,
  closeOnSelect = true,
  itemName,
  minW,
  onValueChange,
  contentRef,
  disabled = false,
  overrideMaxWidth = false,
}) => (
  <SelectRoot
    id={id}
    data-testid={`${id}-select`}
    multiple={multiple}
    collection={createListCollection({
      items: options,
      itemToString: (item) => item.label,
      itemToValue: (item) => item.value,
    })}
    onExitComplete={onClose}
    lazyMount={lazyMount}
    closeOnSelect={closeOnSelect}
    value={options.filter((option) => option.selected).map((option) => option.value)}
    onValueChange={onValueChange}
    minW={minW}
    disabled={disabled}
  >
    <SelectTrigger>
      <SelectValueText itemName={itemName} placeholder={placeholder} />
    </SelectTrigger>
    <SelectContent
      portalRef={contentRef}
      overrideMaxWidth={overrideMaxWidth}
    >
      {options.map((option) => (
        <SelectItem
          key={option.value}
          item={option}
        >
          {option.label}
        </SelectItem>
      ))}
    </SelectContent>
  </SelectRoot>
);

export default SimpleSelect;
