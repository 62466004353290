/* eslint-disable react/prop-types */
import { MessageCircleMore, Pin } from 'lucide-react';
import { Icon } from '@chakra-ui/react';
import React from 'react';

const isUnreadStateRefactorEnabled = process.env.UNREAD_STATE_REFACTOR_ENABLED;

const IconWithProps = ({ children }) => (
  <Icon boxSize="16px" mr="5px">{children}</Icon>
);

const menuItems = isUnreadStateRefactorEnabled ? [
  {
    id: 1,
    label: 'Pin Chat',
    subIcon: <IconWithProps><Pin /></IconWithProps>,
  },
  {
    id: 2,
    label: 'Mark Unread',
    subIcon: <IconWithProps><MessageCircleMore /></IconWithProps>,
  },
] : [
  {
    id: 1,
    label: 'Pin Chat',
    subIcon: <IconWithProps><Pin /></IconWithProps>,
  },
];

const menuItemsUnpinned = isUnreadStateRefactorEnabled ? [
  {
    id: 1,
    label: 'Unpin Chat',
    subIcon: <IconWithProps><Pin /></IconWithProps>,
  },
  {
    id: 2,
    label: 'Mark Unread',
    subIcon: <IconWithProps><MessageCircleMore /></IconWithProps>,
  },
] : [
  {
    id: 1,
    label: 'Pin Chat',
    subIcon: <IconWithProps><Pin /></IconWithProps>,
  },
];

const ROW_TYPES = {
  PINNED_HEADER: 'pinnedHeader',
  UNPINNED_HEADER: 'unpinnedHeader',
  END_OF_LIST: 'endOfList',
};

export { menuItems, menuItemsUnpinned, ROW_TYPES };
