import { createSystem, defineConfig, defaultConfig } from '@chakra-ui/react';

import { alertRecipe } from './alert.recipe';
import { avatarSlotRecipe } from './avatar.recipe';
import { buttonRecipe } from './button.recipe';
import { checkboxSlotRecipe } from './checkbox.recipe';
import { colors, colorTokens } from './colors';
import { dialogSlotRecipe } from './dialog.recipe';
import { fieldSlotRecipe } from './field.recipe';
import { formLabelRecipe } from './form-label.recipe';
import { headingRecipe } from './heading.recipe';
import { iconRecipe } from './icon.recipe';
import { inputRecipe } from './input.recipe';
import { linkRecipe } from './link.recipe';
import { menuSlotRecipe } from './menu.recipe';
import { radioGroupSlotRecipe } from './radio-group.recipe';
import { selectRecipe } from './select.recipe';
import { spinnerRecipe } from './spinner.recipe';
import { tableSlotRecipe } from './table.recipe';
import { tabsSlotRecipe } from './tabs.recipe';
import { tagSlotRecipe } from './tag.recipe';
import { textRecipe } from './text.recipe';
import { toastSlotRecipe } from './toast.recipe';

const config = defineConfig({
  globalCss: {
    body: {
      color: 'gray.900',
    },
    ':where(img, svg, video, canvas, audio, iframe, embed, object)': {
      display: 'unset',
    },
    b: {
      fontWeight: 'unset',
    },
    '& p': {
      margin: '0 !important',
    },
    '& button': {
      paddingInline: '6px',
    },
  },
  theme: {
    recipes: {
      alert: alertRecipe,
      button: buttonRecipe,
      formLabel: formLabelRecipe,
      heading: headingRecipe,
      icon: iconRecipe,
      link: linkRecipe,
      select: selectRecipe,
      spinner: spinnerRecipe,
      text: textRecipe,
      input: inputRecipe,
    },
    slotRecipes: {
      checkbox: checkboxSlotRecipe,
      avatar: avatarSlotRecipe,
      field: fieldSlotRecipe,
      menu: menuSlotRecipe,
      dialog: dialogSlotRecipe,
      table: tableSlotRecipe,
      tabs: tabsSlotRecipe,
      tag: tagSlotRecipe,
      toast: toastSlotRecipe,
      radioGroup: radioGroupSlotRecipe,
    },
    tokens: {
      colors,
      fonts: {
        body: { value: '"Poppins", "Helvetica", sans-serif' },
        heading: { value: '"Poppins", "Helvetica", sans-serif' },
      },
    },
    semanticTokens: {
      colors: colorTokens,
    },
  },
  preflight: true,
});

export const system = createSystem(defaultConfig, config);

export default system;
