export const colors = {
  blue: {
    100: { value: '#C4CEF8' },
    200: { value: '#A4B6F5' },
    300: { value: '#839FF0' },
    400: { value: '#5C88EC' },
    50: { value: '#E2E6FC' },
    60: { value: '#5E7388' },
    500: { value: '#1B73E7' },
    600: { value: '#225BB4' },
    700: { value: '#224484' },
    800: { value: '#1D2F57' },
    900: { value: '#151B2D' },
  },
  gray: {
    100: { value: '#E4E7EB' },
    200: { value: '#CBD2D9' },
    300: { value: '#9AA5B1' },
    400: { value: '#7B8794' },
    50: { value: '#F5F7FA' },
    60: { value: '#DCE2E5' },
    500: { value: '#616E7C' },
    600: { value: '#52606D' },
    700: { value: '#3E4C59' },
    800: { value: '#323F4B' },
    900: { value: '#1F2933' },
  },
  green: {
    100: { value: '#BDE4D4' },
    200: { value: '#9BD6C0' },
    300: { value: '#78C8AB' },
    400: { value: '#4FB997' },
    50: { value: '#DEF1EA' },
    500: { value: '#00AB84' },
    600: { value: '#158668' },
    700: { value: '#1A634E' },
    800: { value: '#184235' },
    900: { value: '#12241E' },
  },
  navy: {
    100: { value: '#B7BED2' },
    200: { value: '#8B96B8' },
    300: { value: '#60709F' },
    400: { value: '#364885' },
    50: { value: '#E2E6EC' },
    500: { value: '#263456' },
    600: { value: '#202B48' },
    700: { value: '#19223A' },
    800: { value: '#13192C' },
    900: { value: '#1a202c' },
  },
  orange: {
    100: { value: '#FFE1CA' },
    200: { value: '#FFD1B0' },
    300: { value: '#FFC296' },
    400: { value: '#FFB179' },
    50: { value: '#FFF0E4' },
    500: { value: '#FCA463' },
    600: { value: '#D48B55' },
    700: { value: '#905F3C' },
    800: { value: '#5E402A' },
    900: { value: '#312319' },
  },
  red: {
    100: { value: '#FFBCB1' },
    200: { value: '#FC9A8C' },
    300: { value: '#F47768' },
    400: { value: '#EA4F46' },
    50: { value: '#FFDED7' },
    500: { value: '#DE0A26' },
    600: { value: '#AE1820' },
    700: { value: '#811A1A' },
    800: { value: '#561814' },
    900: { value: '#2E120C' },
  },
  marleyRed: {
    50: { value: '#FACAC6' },
    100: { value: '#F9B8B4' },
    200: { value: '#F7A6A1' },
    300: { value: '#F6958E' },
    400: { value: '#F4837B' },
    500: { value: '#F2685F' },
    600: { value: '#F16055' },
    700: { value: '#F04E42' },
    800: { value: '#EE3C2F' },
    900: { value: '#ED2A1D' },
  },
  marleyBlue: {
    500: { value: '#44C7E9' },
  },
};

export const colorTokens = {
  navy: {
    solid: { value: '{colors.navy.500}' },
    contrast: { value: 'white' },
    fg: { value: '{colors.navy.700}' },
    muted: { value: '{colors.navy.50}' },
    subtle: { value: '{colors.navy.200}' },
    emphasized: { value: '{colors.navy.300}' },
    focusRing: { value: '{colors.navy.500}' },
  },
  marleyRed: {
    solid: { value: '{colors.marleyRed.500}' },
    contrast: { value: 'white' },
    fg: { value: '{colors.marleyRed.700}' },
    muted: { value: '{colors.marleyRed.50}' },
    subtle: { value: '{colors.marleyRed.200}' },
    emphasized: { value: '{colors.marleyRed.300}' },
    focusRing: { value: '{colors.marleyRed.500}' },
  },
};

export default colors;
