import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ButtonRow, Divider } from '@himarley/unity';
import { Button } from '@chakra-snippets/button';
import { CaseTypeEnum } from '@app/types/create-case';
import { useIsServiceProductEnabled, useIsAllCaseTypesAutoAssignEnabled } from '@app/helpers/featureFlags';
import { updateOrgSetting } from '../../../actions/organization';
import {
  ORG_INACTIVITY_AUTO_REPLY,
  ORG_SETTINGS_USER_MESSAGE_PREFIX,
  ORG_SETTINGS_AUTO_MESSAGE_PREFIX,
  PREFIX_CLAIM_NUMBER,
  PREFIX_PHONE_NUMBER,
  PREFIX_USER_TITLE,
} from '../../../constants/permissions';
import usePermissionVerify from '../../elements/hooks/usePermissionVerify';
import { makeApiRequest } from '../../../actions/api/api';
import InactivityAutoReply from './inactivity-auto-reply';
import UserMessagePrefix from './user-message-prefix';
import { MIN_MIN_VALUE, snackBarSuccessMessage } from './constants';
import SystemMessagePrefix from './system-message-prefix';
import CaseTypeSettings from './case-type-settings/case-type-settings';
import { checkAutoReplyChanged, checkUserMessagePrefixChanged } from './utils';
import './OrgSettings.less';

const OrgSettings = ({ organization }) => {
  const dispatch = useDispatch();

  // Memoized organization data
  const organizationSettings = useMemo(
    () => organization.organizationSettings || {},
    [organization.organizationSettings],
  );
  const orgAutoReply = useMemo(
    () => organization.orgAutoReply || {},
    [organization.orgAutoReply],
  );

  // ===== Inactivity Auto Reply Settings =====
  const [isAutoReplyEnabled, setIsAutoReplyEnabled] = useState(orgAutoReply?.active || false);
  const [minutes, setMinutes] = useState(
    orgAutoReply?.sendCriteria?.minutesInactiveThreshold || MIN_MIN_VALUE,
  );
  const [autoReplyMessage, setAutoReplyMessage] = useState(orgAutoReply?.message?.during?.value || '');

  // Permission checks
  const { hasAccess: canViewInactivityAutoReply } = usePermissionVerify({
    neededPermissions: [ORG_INACTIVITY_AUTO_REPLY],
  });

  // Initialize auto reply values when orgAutoReply loads
  useEffect(() => {
    if (orgAutoReply?.pk) {
      setIsAutoReplyEnabled(orgAutoReply.active || false);
      setMinutes(orgAutoReply.sendCriteria?.minutesInactiveThreshold || MIN_MIN_VALUE);
      setAutoReplyMessage(orgAutoReply.message?.during?.value || '');
    }
  }, [orgAutoReply]);

  const isAutoReplyChanged = checkAutoReplyChanged(
    orgAutoReply,
    isAutoReplyEnabled,
    minutes,
    autoReplyMessage,
  );

  const saveAutoReply = () => {
    if (!orgAutoReply?.pk || !isAutoReplyChanged) {
      return;
    }

    const reply = { ...orgAutoReply };
    reply.active = isAutoReplyEnabled;
    reply.sendCriteria.minutesInactiveThreshold = parseInt(minutes, 10);
    reply.message.during.value = autoReplyMessage;
    const orgName = organization.name.toUpperCase();

    if (orgAutoReply.pk === 'ORG#DEFAULT') {
      reply.pk = `ORG#${orgName}`;
      dispatch(
        makeApiRequest(
          'POST_ORGANIZATIONS_AUTOREPLIES',
          [orgName],
          reply,
          undefined,
          snackBarSuccessMessage,
        ),
      );
    } else {
      dispatch(
        makeApiRequest(
          'PUT_ORGANIZATIONS_AUTOREPLIES',
          [orgName, orgAutoReply.id],
          reply,
          undefined,
          snackBarSuccessMessage,
        ),
      );
    }
  };

  // ===== User Message Prefix Settings =====
  const [isTitleEnabled, setIsTitleEnabled] = useState(false);
  const [isPhoneNumberEnabled, setIsPhoneNumberEnabled] = useState(false);
  const [isCaseIdEnabled, setIsCaseIdEnabled] = useState(false);

  // Permission checks
  const { hasAccess: canViewUserMessagePrefix } = usePermissionVerify({
    neededPermissions: [ORG_SETTINGS_USER_MESSAGE_PREFIX],
  });
  const { hasAccess: hasCaseIdNumberPrefix } = usePermissionVerify({
    neededPermissions: [PREFIX_CLAIM_NUMBER],
  });
  const { hasAccess: hasPhoneNumberPrefix } = usePermissionVerify({
    neededPermissions: [PREFIX_PHONE_NUMBER],
  });
  const { hasAccess: hasUserTitlePrefix } = usePermissionVerify({
    neededPermissions: [PREFIX_USER_TITLE],
  });

  // Initialize user message prefix values with permissions as fallback
  useEffect(() => {
    if (organization?.organizationSettings?.userMessagePrefix) {
      const { userMessagePrefix } = organization.organizationSettings;
      setIsCaseIdEnabled(userMessagePrefix.isCaseIdEnabled ?? hasCaseIdNumberPrefix);
      setIsTitleEnabled(userMessagePrefix.isTitleEnabled ?? hasUserTitlePrefix);
      setIsPhoneNumberEnabled(userMessagePrefix.isPhoneNumberEnabled ?? hasPhoneNumberPrefix);
    } else {
      // If no settings exist, use permission flags as defaults
      setIsTitleEnabled(hasUserTitlePrefix);
      setIsCaseIdEnabled(hasCaseIdNumberPrefix);
      setIsPhoneNumberEnabled(hasPhoneNumberPrefix);
    }
  }, [
    organization.organizationSettings,
    hasCaseIdNumberPrefix,
    hasPhoneNumberPrefix,
    hasUserTitlePrefix,
  ]);

  const isUserMessagePrefixChanged = checkUserMessagePrefixChanged(
    organization,
    isCaseIdEnabled,
    isTitleEnabled,
    isPhoneNumberEnabled,
  );

  // ===== Auto Message Prefix Settings =====
  const [autoMessagePrefixText, setAutoMessagePrefixText] = useState('');
  const savedMessagePrefix = organizationSettings?.autoMessagePrefix;

  // Permission checks
  const autoPrefixPermission = usePermissionVerify({
    neededPermissions: [ORG_SETTINGS_AUTO_MESSAGE_PREFIX],
  });
  const canViewAutoMessagePrefix = autoPrefixPermission?.hasAccess || false;

  useEffect(() => {
    setAutoMessagePrefixText(
      savedMessagePrefix && savedMessagePrefix.prefix !== undefined
        ? savedMessagePrefix.prefix
        : 'Marley',
    );
  }, [savedMessagePrefix]);

  const compareMessagePrefix = organizationSettings?.autoMessagePrefix?.prefix !== undefined
    ? organizationSettings?.autoMessagePrefix?.prefix
    : 'Marley';

  const isEditingAutoMessagePrefix = autoMessagePrefixText !== compareMessagePrefix;

  // ===== Case Type Settings =====

  // Feature flag checks to see if the service product is enabled
  const isServiceProductEnabled = useIsServiceProductEnabled();

  // Feature flag checks to see if the non-service auto assign settings are enabled
  const isNonServiceAutoAssignSettingsEnabled = useIsAllCaseTypesAutoAssignEnabled();

  // State to store the case type settings
  const [caseTypeSettings, setCaseTypeSettings] = useState(
    organizationSettings?.caseTypes || {},
  );

  // Function to determine if a case type is a service case type
  const isServiceCaseType = (caseType) => caseType === CaseTypeEnum.service;

  // Update caseTypeSettings when organizationSettings changes
  useEffect(() => {
    if (organizationSettings?.caseTypes) {
      setCaseTypeSettings(organizationSettings.caseTypes);
    }
  }, [organizationSettings?.caseTypes]);

  const handleCaseTypeSettingsChange = (caseType, settings) => {
    setCaseTypeSettings((prevSettings) => {
      if (Object.values(CaseTypeEnum).includes(caseType)) {
        return {
          ...prevSettings,
          [caseType]: settings,
        };
      }
      return prevSettings;
    });
  };

  // Function to check if case type settings have changed
  const isCaseTypeSettingsChanged = () => {
    if (!organizationSettings?.caseTypes) return false;
    const originalSettings = organizationSettings.caseTypes;

    return Object.keys(CaseTypeEnum).some((caseTypeKey) => {
      const caseType = CaseTypeEnum[caseTypeKey];
      const current = caseTypeSettings[caseType] || {};
      const original = originalSettings[caseType] || {};

      // For service case type, compare enabled flag
      if (caseType === CaseTypeEnum.service) {
        if (Boolean(current.enabled) !== Boolean(original.enabled)) {
          return true;
        }
      }

      // For all case types, compare auto-assign settings
      if (Boolean(current.autoAssignEnabled) !== Boolean(original.autoAssignEnabled)) {
        return true;
      }

      // For all case types, compare queue settings
      if (Boolean(current.queueEnabled) !== Boolean(original.queueEnabled)) {
        return true;
      }

      // Only compare maxAutoAssignedCases if autoAssignEnabled is true
      if (
        current.autoAssignEnabled
        && current.maxAutoAssignedCases !== original.maxAutoAssignedCases
      ) {
        return true;
      }

      return false;
    });
  };

  // ===== Save and Cancel Handlers =====
  const onSaveOrg = () => {
    dispatch(
      updateOrgSetting(
        {
          organizationId: organization._id,
          organizationSettings: {
            ...organizationSettings,
            autoMessagePrefix: {
              prefix: autoMessagePrefixText,
            },
            userMessagePrefix: {
              isTitleEnabled,
              isCaseIdEnabled,
              isPhoneNumberEnabled,
            },
            caseTypes: caseTypeSettings,
          },
        },
        snackBarSuccessMessage,
      ),
    );
    saveAutoReply();
  };

  const handleCancel = () => {
    // Reset auto reply settings
    setMinutes(orgAutoReply?.sendCriteria?.minutesInactiveThreshold);
    setAutoReplyMessage(orgAutoReply?.message?.during?.value);
    setIsAutoReplyEnabled(orgAutoReply?.active);

    // Reset user message prefix
    setIsCaseIdEnabled(
      organization?.organizationSettings?.userMessagePrefix?.isCaseIdEnabled || false,
    );
    setIsTitleEnabled(
      organization?.organizationSettings?.userMessagePrefix?.isTitleEnabled || false,
    );
    setIsPhoneNumberEnabled(
      organization?.organizationSettings?.userMessagePrefix?.isPhoneNumberEnabled || false,
    );

    // Reset auto message prefix
    setAutoMessagePrefixText(
      savedMessagePrefix && savedMessagePrefix.prefix !== undefined
        ? savedMessagePrefix.prefix
        : 'Marley',
    );

    // Reset case type settings
    setCaseTypeSettings(organization?.organizationSettings?.caseTypes || {});
  };

  const isSaveButtonEnabled = isAutoReplyChanged
    || isEditingAutoMessagePrefix
    || isUserMessagePrefixChanged
    || isCaseTypeSettingsChanged();

  return (
    <section className="brandsWrap">
      <header>
        <h1>Org. Settings</h1>
      </header>
      <p className="orgSettingsInfo">
        Org. Settings apply to your entire organization unless overridden per
        brand, line of business, role, or by individual user settings.
      </p>

      {canViewInactivityAutoReply && (
        <>
          <Divider />
          <InactivityAutoReply
            isAutoReplyEnabled={isAutoReplyEnabled}
            setIsAutoReplyEnabled={setIsAutoReplyEnabled}
            minutes={minutes}
            autoReplyMessage={autoReplyMessage}
            setMinutes={setMinutes}
            setAutoReplyMessage={setAutoReplyMessage}
            orgAutoReply={orgAutoReply}
          />
        </>
      )}
      {canViewAutoMessagePrefix && (
        <>
          <Divider />
          <SystemMessagePrefix
            autoMessagePrefixText={autoMessagePrefixText}
            setAutoMessagePrefixText={setAutoMessagePrefixText}
          />
        </>
      )}
      {canViewUserMessagePrefix && (
        <>
          <Divider />
          <UserMessagePrefix
            isTitleEnabled={isTitleEnabled}
            isCaseIdEnabled={isCaseIdEnabled}
            isPhoneNumberEnabled={isPhoneNumberEnabled}
            setIsTitleEnabled={setIsTitleEnabled}
            setIsCaseIdEnabled={setIsCaseIdEnabled}
            setIsPhoneNumberEnabled={setIsPhoneNumberEnabled}
          />
        </>
      )}
      {/* Case Type Queue Settings */}
      {isServiceProductEnabled && (
        <>
          <Divider className="my-4" />
          {Object.values(CaseTypeEnum).map((caseType) => {
            // Skip non-service case types if isNonServiceAutoAssignSettingsEnabled is false
            if (!isNonServiceAutoAssignSettingsEnabled && !isServiceCaseType(caseType)) {
              return null;
            }

            return (
              <CaseTypeSettings
                key={caseType}
                caseType={caseType}
                initialSettings={caseTypeSettings[caseType]}
                onChange={(settings) => handleCaseTypeSettingsChange(caseType, settings)}
              />
            );
          })}
        </>
      )}
      <div className="orgSettingsButtonRow">
        <Divider />
        <ButtonRow type="positive">
          <Button onClick={handleCancel} variant="outline">
            Cancel
          </Button>
          <Button
            onClick={onSaveOrg}
            disabled={!isSaveButtonEnabled}
            data-testid="save-org-settings-button"
            data-is-changed={isSaveButtonEnabled ? 'true' : 'false'}
            data-user-message-prefix-changed={isUserMessagePrefixChanged ? 'true' : 'false'}
          >
            Save Org. Settings
          </Button>
        </ButtonRow>
      </div>
    </section>
  );
};

OrgSettings.propTypes = {
  organization: PropTypes.shape({
    _id: PropTypes.string,
    active: PropTypes.bool,
    branding: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    orgAutoReply: PropTypes.shape({
      pk: PropTypes.string,
      active: PropTypes.bool,
      sendCriteria: PropTypes.shape({
        minutesInactiveThreshold: PropTypes.number,
      }),
      message: PropTypes.shape({
        during: PropTypes.shape({
          value: PropTypes.string,
        }),
      }),
    }),
    organizationSettings: PropTypes.shape({
      userMessagePrefix: PropTypes.shape({
        isCaseIdEnabled: PropTypes.bool,
        isTitleEnabled: PropTypes.bool,
        isPhoneNumberEnabled: PropTypes.bool,
      }),
      autoMessagePrefix: PropTypes.shape({
        prefix: PropTypes.string,
      }),
      caseTypes: PropTypes.shape({
        service: PropTypes.shape({
          enabled: PropTypes.bool,
          autoAssignEnabled: PropTypes.bool,
          maxAutoAssignedCases: PropTypes.number,
        }),
        claim: PropTypes.shape({
          autoAssignEnabled: PropTypes.bool,
          maxAutoAssignedCases: PropTypes.number,
        }),
        policy: PropTypes.shape({
          autoAssignEnabled: PropTypes.bool,
          maxAutoAssignedCases: PropTypes.number,
        }),
        case: PropTypes.shape({
          autoAssignEnabled: PropTypes.bool,
          maxAutoAssignedCases: PropTypes.number,
        }),
      }),
    }),
  }).isRequired,
};

export default OrgSettings;
