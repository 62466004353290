import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ErrorBoundary from '@app/components/ErrorBoundary/ErrorBoundary';
import useScreenSize from '@app/components/Hooks/use-screen-size';
import { StateType } from '@app/types/reducer-state';

import Chat from './Chat/Chat';
import EmptyNewInboxJob from './EmptyNewInboxJob';
import RightBar from './RightBar/right-bar';
import {
  setActiveJob,
  clearActiveJob,
} from '../../actions/job';
import { getViewedChats } from '../../actions/profile';
import SearchScreen from '../ChatsContainer/ChatActive/SearchScreen';
import ChatInbox from '../ChatsContainer/ChatInbox/chat-inbox';
import InboxError from '../ChatsContainer/ChatInbox/inbox-error';
import LoadingIcon from '../icons/LoadingIcon';
import MediaModalContainer from '../Media/MediaModal/MediaModalContainer';
import MessageTemplateModalContainer from '../modal/TemplatesModal/MessageTemplateModalContainer';

import './Chats.less';

export const LoadingChat = () => (
  <div className="chatComponent chatComponent-state-box chatComponent-loading">
    <div className="chatComponent-message">Loading messages</div>
    <div className="chatComponent-icon">
      <LoadingIcon size="lg" />
    </div>
  </div>
);

export const EmptyJob = () => (
  <div className="chatComponent chatComponent-state-box">
    <div className="chatComponent-message">No open chats!</div>
    <div className="chatComponent-helpmessage">
      Open one of your chats by clicking on a case to the left
    </div>
  </div>
);

export const NoAccessToJob = () => (
  <div className="chatComponent chatComponent-state-box">
    <div className="chatComponent-message">Oops!</div>
    <div className="chatComponent-helpmessage">
      You do not have access to view this chat
    </div>
  </div>
);

interface ChatsProps {
  subNavId: string;
}

const Chats: React.FC<ChatsProps> = (props) => {
  const isSmallScreen = useScreenSize();

  const dispatch = useDispatch();
  const chatView = useSelector((state: StateType) => state.ui.chatView);
  // eslint-disable-next-line no-underscore-dangle
  const userId = useSelector((state: StateType) => state.auth.user._id);
  const activeCase = useSelector((state: StateType) => state.jobs.activeJob);
  const activeSearch = useSelector((state: StateType) => state?.jobs?.search);
  const totalChatCount = useSelector((state: StateType) => state?.jobs?.totalChatCount || '0');
  const { subNavId } = props;
  const [isRightBarVisible, setIsRightBarVisible] = React.useState(false);

  useEffect(() => {
    dispatch(getViewedChats(userId));
    if (subNavId) {
      dispatch(setActiveJob(subNavId));
    } else {
      dispatch(clearActiveJob());
    }
  }, []);

  const activeChatForMobileViewer = isSmallScreen && activeCase._id;

  const ChatsError = (
    <InboxError
      text={(
        <>
          <span>Unable to load case.</span>
          <span> Please try again later or refresh.</span>
        </>
      )}
      className="activeWrap"
    />
  );

  let ChatModule;
  switch (chatView?.status) {
    case 'loading':
      ChatModule = <LoadingChat />;
      break;
    case 'empty':
      if (activeSearch) {
        ChatModule = <SearchScreen />;
        break;
      }
      ChatModule = <EmptyNewInboxJob totalChatCount={totalChatCount} />;
      break;
    case 'failed':
      ChatModule = <NoAccessToJob />;
      break;
    case 'loaded':
      if (subNavId) {
        ChatModule = <Chat setIsRightBarVisible={setIsRightBarVisible} />;
        break;
      }
      ChatModule = <EmptyNewInboxJob totalChatCount={totalChatCount} />;
      break;
    case 'error':
      ChatModule = ChatsError;
      break;
    default: {
      ChatModule = <NoAccessToJob />;
    }
  }

  return (
    <div className="chatsComponent">
      {!activeChatForMobileViewer && <ChatInbox />}
      {(activeChatForMobileViewer || !isSmallScreen) && (
        <ErrorBoundary errorMessage={ChatsError}>
          {ChatModule}
        </ErrorBoundary>
      )}
      <RightBar
        activeCase={activeCase}
        isVisible={isRightBarVisible}
        setIsRightBarVisible={setIsRightBarVisible}
      />
      {chatView?.status === 'loaded' && <MediaModalContainer />}
      {chatView?.status === 'loaded' && <MessageTemplateModalContainer />}
    </div>
  );
};

export default Chats;
