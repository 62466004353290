export const MAX_MIN_VALUE = 999;
export const MIN_MIN_VALUE = 5;
export const DEFAULT_MAX_CASES_AUTO_ASSIGN = 2;
export const MAX_CASES_AUTO_ASSIGN = 10;
export const MIN_CASES_AUTO_ASSIGN = 1;

export const constructUserPrefixPreview = (
  isTitleEnabled: boolean,
  isCaseIdEnabled: boolean,
  isPhoneNumberEnabled: boolean,
) => `Justin A. ${isTitleEnabled ? '| Sr. Claims Adjuster |' : '|'}${
  isCaseIdEnabled ? ' 987654321 |' : ''
}${isPhoneNumberEnabled ? ' +15555555555 |' : ''} Lovable Insurance:`;

export const snackBarSuccessMessage = 'Org. Settings Saved';
