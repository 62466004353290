import {
  ButtonGroup,
  DialogTitle,
} from '@chakra-ui/react';
import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { sendMassNotification } from '@app/actions/massNotifications';
import { Button } from '@chakra-snippets/button';
import { Checkbox } from '@chakra-snippets/checkbox';
import {
  DialogRoot, DialogContent, DialogHeader, DialogBody,
  DialogCloseTrigger,
} from '@chakra-snippets/dialog';

import './MassNotificationVerificationModal.less';

interface MassNotificationVerificationModalProps {
  show: boolean;
  toggleShow: () => void;
  activeMassNotification?: {
    Id: string;
    id?: string;
    BatchesLeft?: number;
    ScheduledSendDate?: string;
    Status: string;
    NumberOfInvalidEntries?: number
  };
  isLoading?: boolean;
}

const MassNotificationVerificationModal: React.FC<MassNotificationVerificationModalProps> = ({
  show,
  toggleShow,
  activeMassNotification,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const [isConfirmed, setIsConfirmed] = useState(false);

  const toggleConfirmed = () => {
    setIsConfirmed(!isConfirmed);
  };

  const handleHide = () => {
    setIsConfirmed(false);
    toggleShow();
  };

  const realErrors = activeMassNotification?.NumberOfInvalidEntries
    ? activeMassNotification.NumberOfInvalidEntries
    : 0;

  return (
    <DialogRoot
      onOpenChange={() => handleHide()}
      data-jest="mass-notification-modal"
      open={show}
      size="lg"
    >
      <DialogContent className="mass-notification-modal">
        <DialogHeader textAlign="center">
          <DialogTitle>
            {realErrors > 0
              ? 'Some notifications won\'t be delivered'
              : 'Have you reviewed all your information? '}
          </DialogTitle>
        </DialogHeader>
        <DialogBody textAlign="center">
          {realErrors > 0 && (
            <div className="mass-notification-warning first">
              We found errors that will result in
              {' '}
              {realErrors}
              {' '}
              undelivered notifications.
              In the meantime, you can send out the notification to the remaining recipients.
            </div>
          )}
          <div className={`mass-notification-warning ${realErrors > 0 ? '' : 'alone'}`}>
            {realErrors > 0 ? 'Get the error report from the actions dropdown for more information.' : 'All deliveries are permanent and cannot be recalled.'}
          </div>
          <ButtonGroup className="buttonContainer">
            {
              realErrors > 0
              && <Button my={2} variant="outline" onClick={() => handleHide()}>Do Not Send</Button>
            }
            <Button
              my={2}
              onClick={() => {
                dispatch(sendMassNotification(_.get(activeMassNotification, 'id', '')));
                handleHide();
              }}
              disabled={!isConfirmed || isLoading}
            >
              {isLoading ? 'Sending' : 'Send'}
              {' '}
              Notification
            </Button>
          </ButtonGroup>
          <div className="mass-note-verification-section">
            <Checkbox
              checked={isConfirmed}
              onChange={toggleConfirmed}
              size="sm"
              mr={0}
            >
              {realErrors > 0 ? `I understand that ${realErrors} people won’t receive this notification` : 'Yes, I have reviewed the information'}
            </Checkbox>
          </div>
        </DialogBody>
        {realErrors === 0
          && (
            <DialogCloseTrigger />
          )}
      </DialogContent>
    </DialogRoot>
  );
};

export default MassNotificationVerificationModal;
