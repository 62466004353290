import {
  HStack, VStack, Box, Text, Image,
} from '@chakra-ui/react';
import React from 'react';

interface MediaVirusProps {
  isBlocked?: boolean;
  containerDimensions: {
    height: number;
    width: number;
  };
  fileUrl: string;
  providerId: string;
}

const MediaVirus: React.FC<MediaVirusProps> = ({
  isBlocked,
  containerDimensions,
  fileUrl,
  providerId,
}) => {
  const totalWidth = containerDimensions.width * 2;

  const height = `${containerDimensions.height}px`;
  const width = `${containerDimensions.width}px`;

  const title = isBlocked ? 'Virus Blocked' : 'Virus Scan In Progress';
  const description = isBlocked ? 'Our system detected a virus and has blocked the infected file.' : 'Our system is scanning this file for viruses. Virus scan will run in the background even if you logout or go to other screens.';

  return (
    <HStack flex={`0 0 ${totalWidth}px`}>
      <Box>
        <Image
          data-testid={`media-image-${providerId}-${isBlocked ? 'virus' : 'queued'}`}
          height={height}
          width={width}
          src={fileUrl}
        />
      </Box>
      <Box width={width}>
        <VStack ml="10px" align="left" gap="0px">
          <Text fontWeight={500} color="gray.900">{title}</Text>
          <Text fontWeight={400} color="gray.500">{description}</Text>
        </VStack>
      </Box>
    </HStack>
  );
};

export default MediaVirus;
