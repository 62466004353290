import {
  Alert, Box,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CloseButton } from '@chakra-snippets/close-button';

import { closeNotification } from '../../actions/notification';

const NOTIFICATION_TIMEOUT = 10000;

const Notification = () => {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification?.notification);

  useEffect(() => {
    if (notification && !notification.persistent) {
      const notificationTimeout = setTimeout(() => {
        dispatch(closeNotification(notification));
      }, NOTIFICATION_TIMEOUT);

      return () => clearTimeout(notificationTimeout);
    }
    return undefined;
  }, [dispatch, notification]);

  const splitNotification = (notification?.text ?? '').split('%l');
  const links = notification?.links ?? [];
  const linksComponent = links.map(({ label, url }) => (
    <a href={url} target="_blank" rel="noreferrer">{label}</a>
  ));

  const notificationText: Array<string | React.JSX.Element> = [];
  splitNotification.forEach((n) => {
    notificationText.push(n);
    const LinksComponent = linksComponent.shift();
    if (LinksComponent) notificationText.push(LinksComponent);
  });

  return (
    <Box mt={1}>
      {notification && notification.show && (
      <Alert.Root status={notification.type || 'error'}>
        <Alert.Content>
          <Alert.Title>
            {notificationText}
          </Alert.Title>
          <Alert.Description>
            {notification.explanation && `${notification.explanation}`}
          </Alert.Description>
        </Alert.Content>
        {notification.canClose && (
        <CloseButton pos="relative" top="-2" insetEnd="-2" onClick={() => dispatch(closeNotification(notification))} />
        )}
      </Alert.Root>
      )}
    </Box>
  );
};

export default Notification;
