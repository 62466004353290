import {
  Box,
  Card,
  Heading,
  Text,
  Stack,
  HStack,
  Icon,
  IconButton,
  Button,
} from '@chakra-ui/react';
import {
  AutoIcon,
  BuildingIcon,
  ImportantIcon,
  LockIcon,
} from '@himarley/unity';
import { Trash } from 'lucide-react';
import React from 'react';

import type { WorkflowSettings } from '../../../types/workflow-settings';

interface WorkflowSettingsCardProps extends WorkflowSettings {
  type?: string;
  workflowName: string;
  keywordTrigger: string;
  onEdit?: () => void;
  onDelete?: () => void;
}

const WorkflowSettingsCard: React.FC<WorkflowSettingsCardProps> = ({
  workflowName,
  keywordTrigger,
  carrierDisplayName,
  timeZone,
  carrierPhone,
  redirectPhone,
  carrierWebsite,
  emailEnabled,
  emailTo,
  policyLookupEnabled,
  claimSubmissionEnabled,
  singleOptInEnabled,
  escalation,
  businessHours,
  type,
  onEdit,
  onDelete,
}) => {
  const isDefaultSettings = workflowName === 'DEFAULT';

  const getIcon = () => {
    switch (type) {
      case 'AUTO':
        return <AutoIcon />;
      case 'PROPERTY':
        return <BuildingIcon />;
      case 'DEFAULT':
        return <LockIcon />;
      default:
        return <ImportantIcon />;
    }
  };

  return (
    <Card.Root maxW="xl" minW="sm" borderRadius="lg" variant="outline">
      <Card.Header>
        <HStack justify="space-between" align="center">
          <HStack>
            <Icon boxSize={6}>{getIcon()}</Icon>
            <Heading size="md">{workflowName}</Heading>
          </HStack>
          <HStack verticalAlign="center">
            {
              !isDefaultSettings && (
                <IconButton
                  aria-label="Delete workflow"
                  variant="ghost"
                  onClick={onDelete}
                >
                  <Icon><Trash /></Icon>
                </IconButton>
              )
            }
            <Button
              aria-label="Edit"
              variant="outline"
              onClick={onEdit}
            >
              Edit
            </Button>
          </HStack>
        </HStack>
      </Card.Header>

      <Card.Body>
        <Stack gap={4}>
          <Box>
            <Text fontWeight="bold">Workflow name</Text>
            <Text>{workflowName}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Keyword trigger</Text>
            <Text>{keywordTrigger || 'N/A'}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Redirect landline number</Text>
            <Text>{redirectPhone}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Organization name</Text>
            <Text>{carrierDisplayName}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Phone number</Text>
            <Text>{carrierPhone}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Website</Text>
            <Text>{carrierWebsite}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Completion email</Text>
            <Text>{emailEnabled ? 'Enabled' : 'Disabled'}</Text>
          </Box>
          {
            emailEnabled && (
              <Box>
                <Text fontWeight="bold">Email to</Text>
                {
                  emailTo.map((email) => (
                    <Text key={email.value}>{email.value}</Text>
                  ))
                }
              </Box>
            )
          }
          <Box>
            <Text fontWeight="bold">Single opt-in</Text>
            <Text>{singleOptInEnabled ? 'Enabled' : 'Disabled'}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Policy lookup</Text>
            <Text>{policyLookupEnabled ? 'Enabled' : 'Disabled'}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Claim submission</Text>
            <Text>{claimSubmissionEnabled ? 'Enabled' : 'Disabled'}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Escalation</Text>
            <Text>{escalation ? 'Enabled' : 'Disabled'}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Time zone</Text>
            <Text>{timeZone}</Text>
          </Box>
          {
            escalation && (
              <>
                <Box>
                  <Text fontWeight="bold">Business hours</Text>
                  {Object.entries(businessHours || {}).map(([day, hours]) => (
                    <Text key={day}>
                      {day}
                      :
                      {' '}
                      {hours.start}
                      -
                      {hours.end}
                    </Text>
                  ))}
                </Box>
                <Box>
                  <Text fontWeight="bold">Escalation email (within business hours)</Text>
                  {
                    escalation?.withinBusinessHours?.emailTo?.length ? (
                      escalation?.withinBusinessHours?.emailTo?.map((email) => (
                        <Text key={email.value}>{email.value}</Text>
                      ))
                    ) : <Text color="red">Not set</Text>
                  }
                </Box>
                <Box>
                  <Text fontWeight="bold">Escalation phone (outside business hours)</Text>
                  <Text>
                    {
                      escalation?.outsideBusinessHours?.carrierEscalationNumber
                        ? escalation?.outsideBusinessHours?.carrierEscalationNumber
                        : <Text color="red">Not set</Text>
                    }
                  </Text>
                </Box>
              </>
            )
          }
        </Stack>
      </Card.Body>
    </Card.Root>
  );
};

export default WorkflowSettingsCard;
