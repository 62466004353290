import { Input } from '@chakra-ui/react';
import React from 'react';
import {
  Path, PathValue, useFormContext, type RegisterOptions, type FieldValues,
} from 'react-hook-form';

import isLoadedFromMobile from '@app/helpers/platform';
import { Field } from '@chakra-snippets/field';
import { InputGroup } from '@chakra-snippets/input-group';

interface TextFieldProps<T extends FieldValues> {
  label: string;
  name: Path<T>;
  type?: string;
  formatValue?: (value?: string) => string;
  fieldInfo?: string;
  leftElement?: string;
  registerOptions?: RegisterOptions<T>,
  hidden?: boolean;
  isDisabled?: boolean;
}

const TextField = <T extends FieldValues>({
  label,
  name,
  type = 'text',
  formatValue,
  fieldInfo,
  leftElement,
  registerOptions,
  hidden,
  isDisabled,
}: TextFieldProps<T>) => {
  const {
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<T>();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let formattedValue = event.target.value;
    if (formatValue) {
      formattedValue = formatValue(event.target.value);
    }
    setValue(name, formattedValue as PathValue<T, Path<T>>, { shouldValidate: true });
    await trigger(name);
  };

  const updatedRegisterOptions = registerOptions || {};

  if (formatValue) {
    updatedRegisterOptions.onChange = handleChange;
  }

  const isRequired = updatedRegisterOptions?.required;

  return (
    <Field
      label={`${label}${isRequired ? ' (Required)' : ''}`}
      id={name}
      invalid={!!errors[name]}
      hidden={hidden}
      disabled={isDisabled}
      errorText={errors[name]?.message as string}
      helperText={fieldInfo}
    >
      <InputGroup w="100%" startElement={leftElement}>
        <Input
          id={name}
          {...register(name, updatedRegisterOptions)}
          type={type}
          onFocus={(elem) => {
            if (isLoadedFromMobile()) {
              setTimeout(() => {
                elem?.target?.scrollIntoView({ behavior: 'smooth', block: 'center' });
              }, 500);
            }
          }}
        />
      </InputGroup>
    </Field>
  );
};

export default TextField;
