import {
  Spinner,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  hideMassNotificationModal,
} from '@app/actions/massNotifications';
import {
  DialogRoot, DialogContent, DialogHeader, DialogBody,
  DialogCloseTrigger, DialogTitle,
} from '@chakra-snippets/dialog';

import './MassNotificationModal.less';

import CreateMassNotificationForm from '../CreateMassNotificationForm/create-mass-notification-form';

interface MassNotificationModalProps {
  show: boolean;
  onHide: () => void;
}

const MassNotificationModal: React.FC<MassNotificationModalProps> = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const modalLoading = useSelector((state) => state.massNotifications.modalLoading);

  const handleClickHideModal = () => {
    onHide();
    dispatch(hideMassNotificationModal());
  };

  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <DialogRoot
      open={show}
      onOpenChange={handleClickHideModal}
      size="lg"
    >
      <DialogContent className="mass-notification-creation-modal" ref={contentRef}>
        <DialogHeader>
          <DialogTitle>New Notification</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <CreateMassNotificationForm onHide={onHide} contentRef={contentRef} />
          <div className={`marleySpinner flexCenter ${modalLoading ? '' : 'hidden'}`}>
            <Spinner
              size="xl"
              css={{ '--spinner-track-color': 'colors.gray.50' }}
              animationDuration="0.75s"
            />
          </div>
        </DialogBody>
        <DialogCloseTrigger />
      </DialogContent>
    </DialogRoot>
  );
};

export default MassNotificationModal;
