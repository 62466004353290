import {
  Separator, ButtonGroup, Text,
} from '@chakra-ui/react';
import React from 'react';

import { Button } from '@chakra-snippets/button';
import { DialogBody, DialogFooter } from '@chakra-snippets/dialog';

const createRemovePrompt = (name: string, relationship: string) => (relationship
  ? `Remove ${name} (${relationship}) from this conversation?` : `Remove ${name} from this conversation?`
);

interface RemoveParticipantProps {
  participant: {
    id: string;
    imageUrl: string;
    name: string;
    role: string;
    relationship: string;
  };
  onClickRemoveParticipant: () => void;
  onClose: () => void;
  removeChatParticipantStatus: string;
}

const RemoveParticipant: React.FC<RemoveParticipantProps> = ({
  participant,
  onClickRemoveParticipant,
  onClose,
  removeChatParticipantStatus,
}) => {
  const { name, relationship } = participant;

  const isPending = removeChatParticipantStatus === 'PENDING';
  const hasError = removeChatParticipantStatus === 'ERROR';

  return (
    <>
      <DialogBody>
        <Text fontSize="md">
          {createRemovePrompt(name, relationship)}
        </Text>
        {
          hasError ? (
            <>
              <Separator />
              <Text
                color="red.500"
                data-testid="removeParticipantFormError"
              >
                Unable to remove participant. Please try again later.
              </Text>
            </>
          ) : null
        }
      </DialogBody>
      <DialogFooter>
        <ButtonGroup>
          <Button
            data-testid="removeParticipantCancelButton"
            disabled={isPending}
            variant="outline"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            data-testid="removeParticipantRemoveButton"
            disabled={isPending}
            onClick={onClickRemoveParticipant}
            colorPalette="red"
          >
            {isPending ? 'Removing...' : 'Remove Participant'}
          </Button>
        </ButtonGroup>
      </DialogFooter>
    </>
  );
};

export default RemoveParticipant;
