import { createListCollection } from '@chakra-ui/react';
import React, { useState } from 'react';

import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
} from '@chakra-snippets/select';

import './TimeOutDropdown.less';

interface Reply {
  sendCriteria: {
    minutesInactiveThreshold: number;
  };
}

interface TimeOutDropdownProps {
  reply: Reply;
  setReply: (r: Reply) => void;
  contentRef: React.RefObject<HTMLDivElement>;
}

const TimeOutDropdown: React.FC<TimeOutDropdownProps> = ({
  reply,
  setReply,
  contentRef,
}) => {
  const [timeout, setTimeoutValue] = useState(reply?.sendCriteria?.minutesInactiveThreshold.toString() || '120');
  const [isTimeoutDropdownOpen, setIsTimeoutDropdownOpen] = useState(false);
  const timeoutOptions = [
    { id: '120', label: '2 hours' },
    { id: '90', label: '1.5 hours' },
    { id: '60', label: '1 hour' },
    { id: '45', label: '45 minutes' },
    { id: '30', label: '30 minutes' },
    { id: '20', label: '20 minutes' },
    { id: '15', label: '15 minutes' },
    { id: '10', label: '10 minutes' },
    { id: '1', label: '1 minute' },
  ];

  const handleSelectTimeOut = (e: string) => {
    setTimeoutValue(e);
    setIsTimeoutDropdownOpen(!isTimeoutDropdownOpen);
    setReply({
      ...reply,
      sendCriteria: {
        ...reply.sendCriteria,
        minutesInactiveThreshold: parseInt(e, 10),
      },
    });
  };

  return (
    <div className="timeout-container">
      <SelectRoot
        collection={createListCollection({
          items: timeoutOptions,
          itemToString: (item) => item.label,
          itemToValue: (item) => item.id,
        })}
        value={[timeout]}
        onValueChange={(e) => handleSelectTimeOut(e.value[0])}
      >
        <SelectTrigger>
          <SelectValueText />
        </SelectTrigger>
        <SelectContent portalRef={contentRef}>
          {timeoutOptions.map((option) => (
            <SelectItem key={option.id} item={option}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </SelectRoot>
    </div>
  );
};

export default TimeOutDropdown;
