import { MIN_MIN_VALUE } from './constants';

// Helper function to check if auto reply settings have changed
export const checkAutoReplyChanged = (
  orgAutoReply: {
    pk?: string;
    active?: boolean;
    sendCriteria?: { minutesInactiveThreshold?: number };
    message?: { during?: { value?: string } }
  },
  isAutoReplyEnabled: boolean,
  minutes: string | number,
  autoReplyMessage: string,
): boolean => {
  if (!orgAutoReply?.pk) return false;

  return isAutoReplyEnabled !== Boolean(orgAutoReply.active)
      || (
        parseInt(String(minutes), 10) !== (
          orgAutoReply.sendCriteria?.minutesInactiveThreshold || MIN_MIN_VALUE
        )
      )
      || autoReplyMessage !== (orgAutoReply.message?.during?.value || '');
};

// Helper function to check if user message prefix settings have changed
export const checkUserMessagePrefixChanged = (
  organization: {
    organizationSettings?: {
      userMessagePrefix?: {
        isCaseIdEnabled?: boolean;
        isTitleEnabled?: boolean;
        isPhoneNumberEnabled?: boolean;
      }
    }
  },
  isCaseIdEnabled: boolean,
  isTitleEnabled: boolean,
  isPhoneNumberEnabled: boolean,
) => {
  // Get the current userMessagePrefix settings from organization
  const userMessagePrefix = organization?.organizationSettings?.userMessagePrefix || {};

  // Explicitly convert all values to boolean to ensure proper comparison
  const orgCaseIdEnabled = Boolean(userMessagePrefix.isCaseIdEnabled);
  const orgTitleEnabled = Boolean(userMessagePrefix.isTitleEnabled);
  const orgPhoneNumberEnabled = Boolean(userMessagePrefix.isPhoneNumberEnabled);

  // Compare current values with the proposed values
  const isCaseIdChanged = orgCaseIdEnabled !== Boolean(isCaseIdEnabled);
  const isTitleChanged = orgTitleEnabled !== Boolean(isTitleEnabled);
  const isPhoneNumberChanged = orgPhoneNumberEnabled !== Boolean(isPhoneNumberEnabled);

  // Return true if any setting has changed
  return isCaseIdChanged || isTitleChanged || isPhoneNumberChanged;
};
