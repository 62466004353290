/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
import React, { useCallback, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Avatar } from '@himarley/unity';
import { LogOut, CircleHelp, ChevronLeft } from 'lucide-react';
import { Icon, IconButton } from '@chakra-ui/react';
import { Button } from '@chakra-snippets/button';
import useScreenSize from '@app/components/Hooks/use-screen-size';
import { SHOW_MY_INSIGHTS } from '@app/constants/permissions';
import MarleyIcon from '../icons/MarleyIcon';
import Notification from '../Notification/notification';
import { logout } from '../../actions/auth';
import {
  getAutoReplies,
  toggleAutoAssign,
  getUserProfile,
} from '../../actions/profile';
import TooltipInfo from '../elements/Tootip/TooltipInfo';
import SlideToggle from '../SlideToggle/SlideToggle';
import { checkPermissionsContains, useCheckPermissions } from '../../helpers/common';
import { AUTO_ASSIGN_PERMISSIONS } from '../../constants/general';
import './Header.less';
import MuteIcon from '../icons/MuteIcon';
import { ProfileDropdown } from './ProfileDropdown';
import { caseType } from '../../models/marleyTypes';
import { clearActiveJob } from '../../actions/job';
import CreateCaseButton from '../CreateCase/create-case-button';
import {
  openProfileConfig, setConfigItem, muteAudio, closeForm, closeProfileConfig,
} from '@app/actions/ui';

const Header = (props) => {
  const {
    setConfigItem,
    permissions,
    toggleAutoAssign,
    logout,
    muteAudio,
    muted,
    getAutoReplies,
    authUserId,
    disableAway,
    profile,
    isAway,
    awayMessage,
  } = props;

  useEffect(() => {
    getAutoReplies(authUserId);
  }, [authUserId]);

  const { onCreateCasePage, viewingStatus } = useSelector((state) => ({
    onCreateCasePage: state?.jobs?.form?.isCreate,
    viewingStatus: state.ui?.chatView?.status,
  }));
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const isMyInsightsEnabled = useCheckPermissions([SHOW_MY_INSIGHTS]);
  const handleOpenProfile = useCallback(() => {
    dispatch(getUserProfile(authUserId));
    dispatch(openProfileConfig());
    history('/profile');
  }, [dispatch, authUserId, history]);

  const onProfilePage = location?.pathname === '/profile';
  const onChatsPage = location?.pathname.includes('/chats/');

  const isAutoAssign = _.get(profile, 'isAutoAssign', false);
  const isSmallScreen = useScreenSize();

  const viewingJob = (viewingStatus === 'loading' || viewingStatus === 'loaded');

  // Hide header on small screens when viewing a case
  const hideHeader = isSmallScreen && onChatsPage && viewingStatus === 'loaded';
  if (hideHeader) {
    return null;
  }

  const navToChatsPage = () => {
    dispatch(closeProfileConfig());
    history('/chats');
    if (onCreateCasePage) {
      dispatch(closeForm(caseType));
    } else if (viewingJob) {
      dispatch(clearActiveJob());
    }
  };

  const hasPendo = !isSmallScreen && document.getElementsByClassName('_pendo-badge').length > 0;

  const handlePendoOpen = () => {
    if (!hasPendo) return;
    document.getElementsByClassName('_pendo-badge')[0]?.click();
  };

  return (
    <header className="app-header">
      {isSmallScreen ? (
        <div className="mobile-friendly-header">
          {(onProfilePage || onCreateCasePage || viewingJob) ? (
            <IconButton
              variant="ghost"
              onClick={navToChatsPage}
            >
              <Icon><ChevronLeft /></Icon>
            </IconButton>
          ) : (
            <Button
              variant="unstyled"
              onClick={() => {
                handleOpenProfile();
                setConfigItem('GENERAL_INFO');
              }}
            >
              <Avatar
                id={authUserId}
                imageUrl={profile?.imageUrl}
                name={`${profile?.firstName} ${profile?.lastName}`}
                className="user-profile-photo"
              />
            </Button>
          )}
          <MarleyIcon />
          {onProfilePage ? (
            <IconButton
              variant="ghost"
              className="logout-button"
              onClick={() => logout()}
            >
              <Icon><LogOut /></Icon>
            </IconButton>
          )
            : (!onCreateCasePage && !viewingJob) ? <CreateCaseButton />
              : <div className="right-header-icon-placeholder" />}
        </div>
      ) : (
        <div className="app-header-inner">
          <div className="header-left">
            {isMyInsightsEnabled ? (
              <Link className="icon-box" to="/home">
                <MarleyIcon />
              </Link>
            ) : (
              <div className="icon-box">
                <MarleyIcon />
              </div>
            )}
          </div>
          <Notification />
          <div className="header-profile" to="/profile">
            {checkPermissionsContains(permissions, AUTO_ASSIGN_PERMISSIONS) && (
              <div className="user-auto-assign-block">
                <div className="auto-assign" data-testid="auto-assign-cases">
                  Auto-Assign
                  <TooltipInfo
                    message="Will auto-assign cases from queue"
                    placement="bottom"
                  />
                </div>
                <SlideToggle
                  on={isAutoAssign}
                  onToggle={toggleAutoAssign}
                  testId="auto-assign-btn"
                />
              </div>
            )}
            <IconButton
              className="mute-button"
              variant="unstyled"
              onClick={() => muteAudio(!muted)}
            >
              <MuteIcon muted={muted} />
            </IconButton>
            {hasPendo && (
              <IconButton
                title="Resource Center"
                variant="ghost"
                onClick={handlePendoOpen}
              >
                <Icon><CircleHelp /></Icon>
              </IconButton>
            )}
            <ProfileDropdown
              disableAway={disableAway}
              profile={profile}
              authUserId={authUserId}
              awayMessage={awayMessage}
              setConfigItem={setConfigItem}
              isAway={isAway}
              logout={logout}
            />
          </div>
        </div>
      )}
    </header>
  );
};

Header.propTypes = {
  permissions: PropTypes.arrayOf(String),
  setConfigItem: PropTypes.func.isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
  authUserId: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  muteAudio: PropTypes.func.isRequired,
  getAutoReplies: PropTypes.func.isRequired,
  muted: PropTypes.bool,
  toggleAutoAssign: PropTypes.func.isRequired,
  disableAway: PropTypes.bool.isRequired,
  isAway: PropTypes.bool.isRequired,
  awayMessage: PropTypes.instanceOf(Object).isRequired,
};

Header.defaultProps = {
  permissions: [],
  muted: false,
};

Header.contextTypes = {
  router: PropTypes.instanceOf(Object).isRequired,

};

export const mapDispatchToProps = {
  getAutoReplies,
  toggleAutoAssign,
  setConfigItem,
  muteAudio,
  logout,
};

const mapStateToProps = (state) => {
  const awayMessage = _.get(state.auth, 'userAutoReply.generalAutoReplies.list.0', {});
  const isAway = _.get(state.auth, 'userStatus', 'active') === 'away';
  return {
    score: _.get(state.auth, 'score'),
    authUserId: state.auth.user?._id,
    profile: state.auth.user,
    permissions: _.get(state.auth, 'permissions', []),
    muted: state.ui && state.ui.muted,
    isAway,
    awayMessage,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Header);
