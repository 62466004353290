/* eslint-disable max-len */
import get from 'lodash/get';
import { usersJsonToCSV } from '../helpers/jsonToCSVConvert';
import { encodedUri } from '../helpers/urls';
import { showSnackbar } from './notification';
import { TITLE_ENABLED } from '../constants/permissions';
import { constructFocusOperatorRoleText } from '../helpers/snackBarText';
import User from '../models/user';
import { CHANGE_UNIQUE_ID } from '../constants/general';

import {
  GET_BULK_UPLOAD_USERS_CSV_TEMPLATE,
  GET_ORGANIZATION_ROLES as ENDPOINT_GET_ORGANIZATION_ROLES,
  UPDATE_USER as UPDATE_USER_ENDPOINT,
  CREATE_OPERATOR,
  CREATE_END_USER,
  DELETE_USER,
  CREATE_USERS,
  CHECK_USER,
  UPDATE_USER_ROLES,
  GET_ITEMS,
  GET_USERS_TO_EXPORT,
  UPDATE_END_USER_OPT_STATUS,
  GET_CHANGE_UNIQUE_IDS_CSV_TEMPLATE,
  UPDATE_OPERATORS_UNIQUE_IDS,
  GET_ACTIVE_OPERATORS_COUNT,
  PARTIAL_UPDATE_END_USER,
} from '../constants/endpoints';
import {
  NEW_BULK_USER,
  ADD_USER,
  NEW_USER,
  UPDATED_USER_REQUEST_SUCCESS,
  UPDATED_USER_REQUEST_ERROR,
  UPDATED_USER_REQUEST_CLEAN,
  UPDATED_USER,
  GET_ORGANIZATION_ROLES,
  UPDATE_USER_ROLE,
  RESET_EDIT_OBJECT,
  CLEAR_USERS,
  BULK_UPDATE_USER_ROLES,
  UPDATE_LOCK_PROGRESS,
  SET_UPDATE_USER_STATUS,
  UPDATE_USER,
  SET_ACTIVE_OPERATOR_COUNT,
  UPDATE_ACTIVE_OPERATOR_COUNT,
  IS_FETCHING_ACTIVE_OPERATOR_COUNT,
  UPDATE_USER_GROUPS,
  UPDATE_OPERATOR_ARCHIVED_STATE,
  ADD_OR_REMOVE_USER_GROUPS,
} from '../constants/actions';

import { apiCall, doRequest, validateAuthErrorAndDispatchDeauth } from './api';

export function checkUser(phone) {
  const args = {
    endpoint: CHECK_USER(phone),
    method: 'GET',
    action: 'MOBILE_TYPE',
    process: ({ body }) => body,
  };
  return apiCall({ args });
}

export const modifiedUser = (data) => ({
  type: UPDATED_USER,
  payload: new User(data),
});

export const bulkUpdateUserRoles = (userRolesMap) => ({
  type: BULK_UPDATE_USER_ROLES,
  payload: userRolesMap,
});

export const clearMobileLookup = () => ({ type: 'CLEAR_MOBILE_LOOKUP' });

export const updateUser = (data) => (dispatch) => {
  const args = {
    endpoint: UPDATE_USER_ENDPOINT,
    method: 'PUT',
    body: data,
    action: UPDATE_USER,
  };
  dispatch(apiCall({ args }));
};

export const updateEndUser = (id, data) => (dispatch) => {
  const args = {
    endpoint: PARTIAL_UPDATE_END_USER(id),
    method: 'PATCH',
    body: data,
    action: UPDATE_USER,
  };
  dispatch(apiCall({ args }));
};

export const setUpdateUserStatus = (status) => (dispatch) => dispatch({
  type: SET_UPDATE_USER_STATUS,
  status,
});

export function cleanUserRequest() {
  return { type: UPDATED_USER_REQUEST_CLEAN };
}

export const getUsersByEmail = (email) => () => {
  const opts = {
    endpoint: GET_ITEMS('OPERATOR', { email }),
    method: 'GET',
  };
  return doRequest(opts);
};

export const getChunkedUsersByEmail = (email, chunkSize = 15) => async () => {
  const chunks = [];
  for (let i = 0; i < email.length; i += chunkSize) {
    chunks.push(email.slice(i, i + chunkSize));
  }
  let users = [];
  for (const chunk of chunks) {
    const result = await doRequest({
      endpoint: GET_ITEMS('OPERATOR', { email: chunk }),
      method: 'GET',
    });
    users = users.concat(result);
  }
  return users;
};

export const deleteUser = (id, toaster, status) => {
  const args = {
    endpoint: DELETE_USER(id),
    action: UPDATE_OPERATOR_ARCHIVED_STATE,
    method: 'delete',
    process: () => ({ id, archived: true, status }),
    callback: ({ dispatch }) => {
      dispatch({
        type: UPDATE_ACTIVE_OPERATOR_COUNT,
        payload: { count: -1 },
      });
    },
    errorCallback: ({ error }) => {
      if (toaster) {
        const defaultErrorMessage = 'Error deactivating user. Please try again.';
        toaster.create({
          type: 'error',
          description: typeof error === 'string' ? error : error?.error || error?.message || defaultErrorMessage,
        });
      }
    },
  };
  return apiCall({ args });
};

/**
 * Removes null, undefined, and empty string values from an object
 * @param {*} obj
 * @returns {Object}
 */
export const removeNullPropertiesFromObject = (obj) => {
  if (!obj || typeof obj !== 'object') return obj;

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (typeof value !== 'string') {
      return acc;
    }
    acc[key] = value;
    return acc;
  }, {});
};

export const reactivateOperator = (operator, toaster, toastIdRef, status) => {
  const args = {
    endpoint: CREATE_OPERATOR,
    action: UPDATE_OPERATOR_ARCHIVED_STATE,
    method: 'POST',
    process: () => ({ id: operator?.id, archived: false, status }),
    body: removeNullPropertiesFromObject(operator),
    callback: ({ dispatch }) => {
      if (toastIdRef?.current) {
        toaster.update(toastIdRef.current, {
          description: 'Successfully reactivated user.',
          type: 'success',
        });
      }
      dispatch({
        type: UPDATE_ACTIVE_OPERATOR_COUNT,
        payload: { count: 1 },
      });
    },
    errorCallback: () => {
      if (toastIdRef?.current) {
        toaster.update(toastIdRef.current, {
          description: 'Failed to reactivate user. Please try again.',
          type: 'error',
        });
      }
    },
  };
  return apiCall({ args });
};

export const addUser = (data) => ({ type: ADD_USER, payload: data });

export const addBulkUser = (data) => ({ type: NEW_BULK_USER, payload: data });

export const createUser = (user) => {
  const args = {
    endpoint: CREATE_OPERATOR,
    action: NEW_USER,
    method: 'POST',
    body: { ...user },
    process: ({ body }) => new User(body),
    callback: ({ dispatch }) => {
      dispatch({
        type: UPDATE_ACTIVE_OPERATOR_COUNT,
        payload: { count: 1 },
      });
    },
  };
  return apiCall({ args });
};

export const getActiveOperatorCount = (toast) => (dispatch) => {
  dispatch({
    type: IS_FETCHING_ACTIVE_OPERATOR_COUNT,
    payload: { isFetching: true },
  });
  const args = {
    callback: () => {
      dispatch({
        type: IS_FETCHING_ACTIVE_OPERATOR_COUNT,
        payload: { isFetching: false },
      });
    },
    errorCallback: () => {
      if (toast) {
        toast({
          description: 'Error fetching user count. Please try again.',
          status: 'error',
        });
      }
      dispatch({
        type: IS_FETCHING_ACTIVE_OPERATOR_COUNT,
        payload: { isFetching: undefined },
      });
    },
    action: SET_ACTIVE_OPERATOR_COUNT,
    endpoint: GET_ACTIVE_OPERATORS_COUNT,
    method: 'GET',
  };
  return dispatch(apiCall({ args }));
};

export const createUsers = (users, isUpdating = false) => {
  const args = {
    action: 'CREATE_BULK_USERS',
    endpoint: CREATE_USERS,
    method: 'POST',
    body: { users, isUpdating },
    process: ({ body }) => body,
    callback: ({ dispatch }) => {
      dispatch(getActiveOperatorCount());
    },
    errorCallback: ({ dispatch }) => {
      dispatch(getActiveOperatorCount());
    },
  };
  return apiCall({ args });
};

export function updateOperatorsUniqueIds(users) {
  const args = {
    action: 'UPDATE_OPERATORS_UNIQUE_IDS',
    endpoint: UPDATE_OPERATORS_UNIQUE_IDS,
    method: 'PUT',
    body: { users },
    process: ({ body }) => body,
  };
  return apiCall({ args });
}

const processNewUser = ({ body }) => new User(body);

export function createEndUser(user, callback) {
  const args = {
    endpoint: CREATE_END_USER,
    action: NEW_USER,
    method: 'POST',
    body: { ...user },
    callback,
    process: processNewUser,
  };
  return apiCall({ args });
}

export const updateEndUserOptStatus = (data) => (dispatch) => {
  const {
    _id, status, timeZone, jobId, isUpdatingLockStatus,
  } = data;
  if (isUpdatingLockStatus) {
    dispatch({
      type: UPDATE_LOCK_PROGRESS,
      payload: { id: _id, inProgress: true },
    });
  }

  const args = {
    endpoint: UPDATE_END_USER_OPT_STATUS(_id, status),
    method: 'POST',
    action: UPDATED_USER_REQUEST_SUCCESS,
    body: {
      status,
      timeZone,
      source: 'CASE_CREATION',
      reference: jobId,
    },
    callback: () => {
      if (isUpdatingLockStatus) {
        dispatch({
          type: UPDATE_LOCK_PROGRESS,
          payload: { id: _id, inProgress: false },
        });
      }
    },
    errorCallback: ({ error }) => {
      validateAuthErrorAndDispatchDeauth(error, dispatch);
      dispatch({
        type: UPDATED_USER_REQUEST_ERROR,
        payload: 'Error updating end-user opt status. Please try again.',
      });
      if (isUpdatingLockStatus) {
        dispatch({
          type: UPDATE_LOCK_PROGRESS,
          payload: { id: _id, inProgress: false },
        });
      }
    },
  };
  return dispatch(apiCall({ args }));
};

// Load all roles for an organization
export const getOrganizationRoles = () => (dispatch, getStore) => {
  const organizationId = get(getStore(), 'auth.user.organization.name', '');
  const encodedOrgId = encodedUri(
    ENDPOINT_GET_ORGANIZATION_ROLES,
    organizationId,
  );
  const args = {
    endpoint: encodedOrgId,
    action: GET_ORGANIZATION_ROLES,
    method: 'GET',
    process: ({ body }) => body,
  };
  return dispatch(apiCall({ args }));
};

// add a roles to a user
export const addUserRoles = (user, roleNames, toaster) => (dispatch) => {
  const args = {
    endpoint: UPDATE_USER_ROLES(user.id),
    action: UPDATE_USER_ROLE,
    method: 'PUT',
    body: { roles: roleNames, orgName: get(user, 'organization.name') },
    process: ({ body }) => body,
    callback: () => {
      const toastText = constructFocusOperatorRoleText(
        user?.roles || [],
        roleNames,
      );
      if (toaster && toastText) {
        toaster.create({
          type: 'warning',
          title: toastText,
        });
      } else if (toaster) {
        toaster.create({
          type: 'success',
          title: 'User roles added successfully',
        });
      }
    },
  };
  return dispatch(apiCall({ args }));
};

// remove roles from a user
export const removeUserRoles = (user, roleNames, toaster) => (dispatch) => {
  const args = {
    endpoint: UPDATE_USER_ROLES(user.id),
    action: UPDATE_USER_ROLE,
    method: 'DELETE',
    body: { roles: roleNames },
    process: ({ body }) => body,
    callback: () => {
      if (toaster) {
        toaster.create({
          type: 'success',
          title: 'User roles removed successfully',
        });
      }
    },
  };
  return dispatch(apiCall({ args }));
};

export const resetEditObj = (issuingFormType) => ({
  type: RESET_EDIT_OBJECT,
  payload: { issuingFormType },
});

export const clearUsers = () => (dispatch) => dispatch({ type: CLEAR_USERS });

export const clearFormErrors = () => ({ type: 'CLEAR_FORM_ERRORS' });

export const downloadCSVTemplate = (type) => {
  doRequest({
    endpoint:
      type === CHANGE_UNIQUE_ID
        ? GET_CHANGE_UNIQUE_IDS_CSV_TEMPLATE
        : GET_BULK_UPLOAD_USERS_CSV_TEMPLATE,
    responseType: 'blob',
    method: 'GET',
  })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      const fileName = type === CHANGE_UNIQUE_ID
        ? 'ChangeUniqueIdsTemplate.csv'
        : 'BulkUploadUsersTemplate.csv';
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      return res;
    })
    .catch((err) => {
      console.error('failed to download csv');
      console.error(err);
    });
};

export const getUsersToExport = () => (dispatch, getStore) => {
  const isTitleEnabled = get(getStore(), 'auth.permissions', []).includes(
    TITLE_ENABLED,
  );

  dispatch(
    showSnackbar({
      text: 'Your download should begin shortly',
      isError: false,
    }),
  );
  doRequest({
    endpoint: GET_USERS_TO_EXPORT,
    responseType: 'blob',
    method: 'GET',
  })
    .then((res) => {
      const csv = usersJsonToCSV(res, isTitleEnabled);
      const hiddenElement = document.createElement('a');
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
        csv,
      )}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'Users.csv';
      hiddenElement.click();
      return res;
    })
    .catch(() => {
      dispatch(
        showSnackbar({
          text: 'This action has failed to complete. No file downloaded. Please try again.',
          isError: true,
        }),
      );
    });
};

export const updateUserGroups = ({
  id, groups, groupLeads, operator,
}) => ({
  type: UPDATE_USER_GROUPS,
  payload: {
    id,
    groups,
    groupLeads,
    user: operator,
  },
});

export const updateGroups = (user, addRemoveGroups, toaster) => (dispatch, getState) => {
  const { add = [], remove = [] } = addRemoveGroups;
  const args = {
    endpoint: `/api/operators/${user.id}/groups`,
    action: ADD_OR_REMOVE_USER_GROUPS,
    method: 'PATCH',
    body: {
      members: {
        add,
        remove,
      },
    },
    process: ({ body }) => {
      // Get the updated groups and groupLeads from the response
      const { groups, groupLeads } = body;

      // Find the full operator object from state
      const operator = getState().operators.list.find((op) => op.id === user.id || op._id === user.id);

      // Dispatch the updateUserGroups action with all needed data
      dispatch(updateUserGroups({
        id: user.id,
        groups,
        groupLeads,
        operator,
      }));
      return body;
    },
    callback: () => {
      if (toaster) {
        toaster.create({
          type: 'success',
          description: 'User groups updated successfully',
        });
      }
    },
    errorCallback: () => {
      if (toaster) {
        toaster.create({
          type: 'error',
          description: 'Failed to update user groups',
        });
      }
    },
  };
  return dispatch(apiCall({ args }));
};
