/* eslint-disable jsx-a11y/media-has-caption */
import { Icon } from '@chakra-ui/react';
import { StarburstFilledIcon } from '@himarley/unity';
import React, { useState } from 'react';

import styles from './TranscribedAudioMessage.module.less';

interface TranscribedAudioMessageProps {
  messageBody: string;
  recordingUrl: string;
}

const TranscribedAudioMessage: React.FC<TranscribedAudioMessageProps> = (props) => {
  const [showFullText, setShowFullText] = useState(false);
  const { messageBody, recordingUrl } = props;
  const truncateLength = 300;

  const shouldShowViewMore = messageBody.length > truncateLength && !showFullText;
  const threeDots = shouldShowViewMore ? '...' : '';
  const truncatedBody = showFullText ? messageBody : `${messageBody.slice(0, truncateLength)?.trim()}${threeDots} `;

  return (
    <div className={styles['transcribed-audio-chat-message']} data-testid="transcribed-audio-chat-message">
      <audio
        className={styles['audio-player']}
        src={recordingUrl}
        controls
      >
        Listen
      </audio>
      <div className={styles['audio-transcription']}>Audio Transcription</div>
      <div>
        {truncatedBody}
        {shouldShowViewMore
          ? (
            <button
              type="button"
              onClick={() => setShowFullText(true)}
              className={styles['view-more-button']}
            >
              view more
            </button>
          )
          : null}
      </div>
      <div className={styles['ai-container']}>
        <Icon mr="4px" boxSize="12px"><StarburstFilledIcon /></Icon>
        <span className={styles['ai-message']}>Generated by Marley AI</span>
      </div>
    </div>
  );
};

export default TranscribedAudioMessage;
