import {
  NEEDS_ATTENTION,
  FEATURE_FLAG_TOTAL_LOSS_ASSIST,
  NEEDS_ACTION,
  NOTES_ACCESS,
  CASE_VISIBILITY,
  DISPLAY_MESSAGE_STATUS,
  OVERRIDE_CASE_VISIBILITY,
  FEATURE_FLAG_VERISK,
} from '../constants/permissions';

import { LOWEST_PRIORITY_SCORE } from '../constants/general';
import { PRIVATE_REDACTED, REDACTED_FROM_VIEWER } from '../constants/jobState';
import { PRIVATE_REDACTED_ALLOWED_TAGS } from '../constants/tags';

export const TAG_TYPES = {
  lockedOut: {
    id: 'locked-out',
    label: 'locked out',
    sortRanking: 1,
  },
  failedToSend: {
    id: 'failed-to-send',
    label: 'failed to send',
    sortRanking: 2,
    permission: DISPLAY_MESSAGE_STATUS,
  },
  needsAttention: {
    id: 'needs-attention',
    label: 'needs attention',
    sortRanking: 3,
    permission: NEEDS_ATTENTION,
  },
  releaseIssue: {
    id: 'release-issue',
    label: 'release issue',
    sortRanking: 4,
    permission: FEATURE_FLAG_TOTAL_LOSS_ASSIST,
  },
  hasQuestion: {
    id: 'has-question',
    label: 'has question',
    sortRanking: 5,
    permission: NEEDS_ACTION,
  },
  newNoteMention: {
    id: 'my-note-mentions-and-replies',
    label: 'my note mentions and replies',
    sortRanking: 6,
    permission: NOTES_ACCESS,
  },
  totalLoss: {
    id: 'total-loss',
    label: 'total loss',
    sortRanking: 7,
    permission: FEATURE_FLAG_TOTAL_LOSS_ASSIST,
  },
  notOptedIn: {
    id: 'not-opted-in',
    label: 'not opted in',
    sortRanking: LOWEST_PRIORITY_SCORE,
  },
  optedOut: {
    id: 'opted-out',
    label: 'opted out',
    sortRanking: LOWEST_PRIORITY_SCORE,
  },
  private: {
    id: 'private',
    label: 'private',
    sortRanking: LOWEST_PRIORITY_SCORE,
    permission: CASE_VISIBILITY,
  },
  verisk: {
    id: 'verisk',
    label: 'verisk',
    sortRanking: LOWEST_PRIORITY_SCORE,
    permission: FEATURE_FLAG_VERISK,
  },
};

const buildBusinessTag = (caseItem) => {
  const { type, lineOfBusiness } = caseItem;
  const caseType = type === 'CASE' ? 'General Case' : type;
  // TO DO: might have to think about returning an array of objects
  // to support showing lineOfBusiness.displayName instead of subtype
  const businessTag = lineOfBusiness?.subType
    ? lineOfBusiness.subType
    : caseType;
  return businessTag?.toLowerCase();
};

export const buildTagsList = (caseItem, userId, permissions) => {
  const {
    showFailedMessage,
    needsAttentionMessageCount,
    isTotalLoss,
    needsAction,
    unreadNoteMentions,
    customerId,
    releaseIssues,
    veriskData,
    privacy,
  } = caseItem;
  const { verified, blocked, locked } = customerId || {};
  const showNotOptedIn = customerId ? !verified && !blocked && !locked : false;
  const businessTag = buildBusinessTag(caseItem);
  const hasUnreadNoteMentions = unreadNoteMentions?.[userId] !== undefined;
  const hasUnresolvedReleaseIssue = releaseIssues?.find((r) => (r?.status && r.status !== 'RESOLVED'));

  const includeFailedMessage = showFailedMessage
    && permissions.includes(TAG_TYPES.failedToSend.permission);
  const includeNeedsAttention = needsAttentionMessageCount > 0
    && permissions.includes(TAG_TYPES.needsAttention.permission);
  const includeReleaseIssue = hasUnresolvedReleaseIssue
    && permissions.includes(TAG_TYPES.releaseIssue.permission);
  const includeHasQuestion = needsAction?.hasQuestionMessageCount > 0
    && permissions.includes(TAG_TYPES.hasQuestion.permission);
  const includeNotesTag = hasUnreadNoteMentions
    && permissions.includes(TAG_TYPES.newNoteMention.permission);
  const includeTotalLoss = isTotalLoss
    && permissions.includes(TAG_TYPES.totalLoss.permission);
  const includeVeriskTag = !!veriskData?.originalAssignmentId
    && permissions.includes(TAG_TYPES.verisk.permission);
  // using technique below to conditionally add items to the array
  // https://amberley.dev/blog/2020-09-07-conditionally-add-to-array-or-obj/
  return [
    ...(locked ? [TAG_TYPES.lockedOut.label] : []),
    ...(includeFailedMessage ? [TAG_TYPES.failedToSend.label] : []),
    ...(includeNeedsAttention ? [TAG_TYPES.needsAttention.label] : []),
    ...(includeReleaseIssue ? [TAG_TYPES.releaseIssue.label] : []),
    ...(includeVeriskTag ? [TAG_TYPES.verisk.label] : []),
    ...(includeHasQuestion ? [TAG_TYPES.hasQuestion.label] : []),
    ...(includeNotesTag ? [TAG_TYPES.newNoteMention.label] : []),
    ...(includeTotalLoss ? [TAG_TYPES.totalLoss.label] : []),
    ...(showNotOptedIn ? [TAG_TYPES.notOptedIn.label] : []),
    ...(blocked ? [TAG_TYPES.optedOut.label] : []),
    ...(businessTag ? [businessTag] : []),
    ...(privacy?.includes('private') ? [TAG_TYPES.private.label] : []),
  ];
};

const getSmartSortOrderFromTagsList = (tagsList) => {
  // first tag is of highest priority
  const [firstTag = null] = tagsList;
  if (!firstTag) {
    return LOWEST_PRIORITY_SCORE;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const { label, sortRanking } of Object.values(TAG_TYPES)) {
    if (label === firstTag) {
      return sortRanking;
    }
  }
  return LOWEST_PRIORITY_SCORE;
};

export const buildFullName = (profile) => (`${profile?.firstName} ${profile?.lastName}`);

export const buildLastMessage = (lastMessage, createdAt) => {
  if (lastMessage?.sentAt) {
    return { ...lastMessage };
  }
  if (createdAt) {
    return {
      ...lastMessage,
      sentAt: createdAt,
    };
  }
  return {
    ...lastMessage,
    sentAt: new Date().toISOString(),
  };
};

const isJobVisibleToUser = (visibleTo, userId, permissions) => {
  if ((permissions || []).includes(OVERRIDE_CASE_VISIBILITY)) return true;
  return visibleTo?.some((item) => item.id === userId && item.type === 'user');
};

const intersection = (arr1, arr2) => {
  const set1 = new Set(arr1);
  return arr2.filter((i) => set1.has(i));
};

export const buildInboxJob = (job, userId, permissions) => {
  if (!job || !job?._id) {
    return null;
  }
  const {
    _id, isOpen, createdAt, customerId, lastMessage, privacy, hasUnreadMessages,
    metadataReferenceId, reference, referenceId, operatorId, secondaryOperatorIds,
    unreadNoteMentions, customerName: custName, lastViewedMessage,
    unreadMessageCount: unreadMessageCountFromJob, type,
  } = job;

  const lastMsg = buildLastMessage(lastMessage, createdAt);
  const isPrivate = privacy?.includes('private');
  const cusId = customerId?._id || customerId;
  let customerName = custName;
  if (!customerName) {
    customerName = customerId?.profile ? buildFullName(customerId?.profile) : null;
  }
  const caseNumber = reference || referenceId || metadataReferenceId || _id;
  const legacyUnreadMessageCount = hasUnreadMessages ? 1 : 0; // TO DO: do this properly someday.
  const isUnreadStateRefactorEnabled = process.env.UNREAD_STATE_REFACTOR_ENABLED;
  const newUnreadMessageCountFromJob = unreadMessageCountFromJob || 0;
  const unreadMessageCount = isUnreadStateRefactorEnabled
    ? newUnreadMessageCountFromJob : legacyUnreadMessageCount;
  const tags = buildTagsList(job, userId, permissions);
  const smartSort = getSmartSortOrderFromTagsList(tags);

  // Case Visibility
  const visibleToUser = isJobVisibleToUser(job?.visibleTo, userId, permissions);
  const jobPrivacySetting = job?.privacy;
  if (jobPrivacySetting === PRIVATE_REDACTED && !visibleToUser) {
    return {
      _id,
      operatorId,
      secondaryOperatorIds,
      createdAt,
      isOpen,
      isPrivate,
      caseNumber,
      isPinned: job?.isPinned,
      tags: intersection(PRIVATE_REDACTED_ALLOWED_TAGS, tags || []),
      state: REDACTED_FROM_VIEWER,
      customerName: 'Private Case',
      lastMessage: {
        sentAt: job?.lastMessage?.sentAt,
        body: 'This case is marked as private.',
      },
    };
  }
  return {
    _id,
    customerId: cusId,
    customerName,
    createdAt,
    caseNumber,
    unreadMessageCount,
    isPinned: job?.isPinned,
    isOpen,
    isPrivate,
    lastMessage: lastMsg,
    tags,
    operatorId,
    secondaryOperatorIds,
    smartSort,
    unreadNoteMentions,
    lastViewedMessage,
    type,
  };
};

/**
* Unread messages we want to allow to count are:
*  - all inbound messages
*  - outbound messages sent from other operators
*      (not from the user making the request and not from our system)
*
*  In regards to outbound AI messages, we want to notify the requesting user
*  of an unread message for the edge case where an automated message sends
*  for another operator on the chat. We do not want to be notified of our away
*  messages, but we are interested in other operators' away messages on the chat
*
*  The field 'isAI' is not used in the logic here since away and OOO messages
*  are AI, but send on behalf of the logged in user. We want to track outbound
*  messages, sent by other operators, but not sent by "Marley" (IE, the welcome
*  messages). The welcome messages do not have authorIds which is why we need
*  to check for the existence of the field authorId
*/
export const isApplicableUnreadMessage = (message, userId, isNotViewedByUser = false) => {
  let authorId = typeof message.authorId === 'string' ? message.authorId : message.authorId?._id;

  if (!authorId) {
    authorId = message?.author?.id;
  }

  return (
    message.isInbound
  || (
    !message.isInbound // outbound
    && (typeof authorId !== 'undefined') // not sent on behalf of the "Marley system"
    && authorId !== userId // it is not sent on behalf of the requesting user
  )
  || (
    isNotViewedByUser // if the chat has not been viewed
  )
  );
};
