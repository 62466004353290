import {
  ButtonGroup,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';

import { Button } from '@chakra-snippets/button';
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from '@chakra-snippets/dialog';

interface AlertDialogProps {
  title: string;
  isDialogOpen: boolean;
  toggleDialog: () => void;
  children: React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  onConfirm?: () => void;
  confirmColor?: string;
  id?: string;
  isDisabled?: boolean;
}

function useAlertDialog(initialState = false) {
  const [isDialogOpen, setIsDialogOpen] = useState(initialState);

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  return {
    isDialogOpen,
    toggleDialog,
  };
}

const AlertDialog: React.FC<AlertDialogProps> = ({
  title,
  isDialogOpen,
  toggleDialog,
  children,
  cancelText,
  confirmText,
  onConfirm,
  confirmColor = 'navy',
  id = 'alert-dialog',
  isDisabled = false,
}) => {
  const cancelRef = useRef(null);

  return (
    <DialogRoot
      open={isDialogOpen}
      data-testId={id}
      role="alertdialog"
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          {children}
        </DialogBody>
        <DialogFooter>
          <ButtonGroup>
            {cancelText && (
            <Button
              data-testid={`${id}-cancel-button`}
              variant="outline"
              ref={cancelRef}
              onClick={toggleDialog}
            >
              {cancelText}
            </Button>
            )}
            {confirmText && (
            <Button
              data-testid={`${id}-confirm-button`}
              onClick={onConfirm}
              colorPalette={confirmColor}
              disabled={isDisabled}
            >
              {confirmText}
            </Button>
            )}
          </ButtonGroup>
        </DialogFooter>
        <DialogCloseTrigger />
      </DialogContent>
    </DialogRoot>
  );
};

export default AlertDialog;
export { useAlertDialog };
