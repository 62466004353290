import { createListCollection } from '@chakra-ui/react';
import React, { useMemo, useState, useEffect } from 'react';
import {
  useFormContext, type RegisterOptions, type FieldValues, Path,
  PathValue,
} from 'react-hook-form';

import { useCustomStyles } from '@app/chakra-theme/hooks/use-custom-styles';
import { Field } from '@chakra-snippets/field';
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
  SelectItemGroup,
} from '@chakra-snippets/select';

type Option = {
  id: string;
  label: string;
  group?: string;
  handleSelect?: (value: string) => void;
  leftIconElement?: React.ReactNode;
  onClick?: () => void;
};

const SelectItemOption = ({
  option,
}: {
  option: Option;
}) => (
  <SelectItem
    id={option.id}
    key={option.id}
    item={option}
    onClick={option.onClick}
    justifyContent="flex-start"
  >
    {option.leftIconElement}
    {option.label}
  </SelectItem>
);

interface SelectMenuProps<T extends FieldValues> {
  id: string;
  label: string;
  name: Path<T>;
  options: Option[];
  defaultValue?: string;
  placeholder?: string;
  registerOptions?: RegisterOptions<T>;
  isDisabled?: boolean;
  contentRef?: React.RefObject<HTMLDivElement>;
}

const SelectMenu = <T extends FieldValues>({
  id,
  label,
  name,
  options = [],
  defaultValue,
  placeholder,
  registerOptions,
  isDisabled,
  contentRef,
}: SelectMenuProps<T>) => {
  const {
    setValue,
    getValues,
    trigger,
    register,
    formState: { errors },
  } = useFormContext<T>();
  const { formControlStyles } = useCustomStyles();

  register(name, registerOptions);

  let valueLabel = '';
  const valuesName = getValues(name);
  if (valuesName) {
    valueLabel = options.find((option) => option.id === valuesName)?.label || '';
  }

  const [selectedOption, setSelectedOption] = useState<string>(valuesName || defaultValue || '');

  useEffect(() => {
    setSelectedOption(valueLabel || defaultValue || '');
  }, [valueLabel, defaultValue]);

  const menuOptions = useMemo(() => {
    const handleSelect = (option: {
      id: string;
      label: string;
      group?: string;
      handleSelect?: (value: string) => void;
    }) => {
      if (option.handleSelect) {
        option.handleSelect(option.id);
      }
      setSelectedOption(option.label);
      setValue(name, option.id as PathValue<T, Path<T>>);
      trigger(name);
    };

    const sortedOptions = [...(options || [])].sort((a, b) => {
      if (!a.group && b.group) return -1;
      if (a.group && !b.group) return 1;
      if (a.group && b.group) {
        return a.group.localeCompare(b.group);
      }
      return 0;
    });

    return sortedOptions.map((option) => ({
      onClick: () => handleSelect(option),
      ...option,
      selected: option.label === selectedOption,
    }));
  }, [name, options, selectedOption, setValue, trigger]);

  const isRequired = registerOptions?.required;
  const hasGroup = menuOptions.some((option) => option.group);

  const groupedOptions = menuOptions.reduce<Record<string, Option[]>>((acc, option) => {
    if (option.group) {
      acc[option.group] = [...(acc[option.group] || []), option];
    }
    return acc;
  }, {});

  return (
    <Field
      id={id}
      label={`${label}${isRequired ? ' (Required)' : ''}`}
      invalid={!!errors[name]}
      errorText={errors[name]?.message as string}
      css={formControlStyles}
    >
      <SelectRoot
        disabled={isDisabled}
        collection={createListCollection({
          items: menuOptions,
          itemToString: (item) => item.label,
          itemToValue: (item) => item.id,
        })}
        defaultValue={selectedOption ? [selectedOption] : undefined}
        positioning={{ sameWidth: true }}
        data-testid={`select-menu-${id}`}
      >
        <SelectTrigger>
          <SelectValueText placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent portalRef={contentRef}>
          {hasGroup ? (
            Object.entries(groupedOptions).map(([group, groupOptions]) => (
              <SelectItemGroup key={group} label={group}>
                {groupOptions.map((option) => (
                  <SelectItemOption key={option.id} option={option} />
                ))}
              </SelectItemGroup>
            ))
          ) : (
            menuOptions.map((option) => (
              <SelectItemOption key={option.id} option={option} />
            ))
          )}
        </SelectContent>
      </SelectRoot>
    </Field>
  );
};

export default SelectMenu;
