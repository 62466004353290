import { Spinner } from '@chakra-ui/react';
import { CheckmarkFilledIcon, CircleXFilledIcon } from '@himarley/unity';
import React from 'react';

import { Tooltip } from '@chakra-snippets/tooltip';

const TOOLTIP_MESSAGE_MEDIA_UPLOAD_SUCCESS = 'Image successfully synced to Verisk';
const TOOLTIP_MESSAGE_MEDIA_UPLOAD_FAILED = 'Image did not successfully sync to Verisk';
const TOOLTIP_MESSAGE_MEDIA_UPLOAD_PENDING = 'Syncing Media';
const TOOLTIP_OPEN_DELAY = 0;

const MediaUploadStatusBadge = ({
  mediaUploadStatus,
}: { mediaUploadStatus?: string }) => {
  switch (mediaUploadStatus) {
    case 'success':
      return (
        <Tooltip positioning={{ placement: 'top' }} showArrow openDelay={TOOLTIP_OPEN_DELAY} content={TOOLTIP_MESSAGE_MEDIA_UPLOAD_SUCCESS}>
          <div data-testid="icon-media-upload-success" className="icon-media-upload">
            <CheckmarkFilledIcon color="green" />
          </div>
        </Tooltip>
      );
    case 'failed':
      return (
        <Tooltip positioning={{ placement: 'top' }} showArrow openDelay={TOOLTIP_OPEN_DELAY} content={TOOLTIP_MESSAGE_MEDIA_UPLOAD_FAILED}>
          <div data-testid="icon-media-upload-failed" className="icon-media-upload">
            <CircleXFilledIcon color="red" />
          </div>
        </Tooltip>
      );
    case 'pending':
      return (
        <Tooltip positioning={{ placement: 'top' }} showArrow openDelay={TOOLTIP_OPEN_DELAY} content={TOOLTIP_MESSAGE_MEDIA_UPLOAD_PENDING}>
          <div data-testid="icon-media-upload-pending" className="icon-media-upload icon-media-upload-spinner"><Spinner /></div>
        </Tooltip>
      );
    default:
      return <div />;
  }
};

export default MediaUploadStatusBadge;
