import { defineSlotRecipe, defineStyle } from '@chakra-ui/react';
import { Menu as BaseMenu } from '@chakra-ui/theme/components';

export const menuSlotRecipe = defineSlotRecipe({
  slots: ['groupTitle', 'trigger'],
  base: {
    groupTitle: defineStyle({
      ...BaseMenu.baseStyle?.groupTitle,
      margin: 3,
    }),
    trigger: defineStyle({
      ...BaseMenu.baseStyle?.button,
      _hover: { bg: 'gray.100' },
      _active: { bg: 'gray.100' },
      _expanded: { bg: 'gray.100' },
    }),
  },
});

export default menuSlotRecipe;
