import React, { useRef } from 'react';

import {
  DialogRoot, DialogContent, DialogHeader, DialogTitle,
} from '@chakra-snippets/dialog';

import ParticipantFormContainer from './participant-form-container';

interface ParticipantModalProps {
  onClose: () => void;
  initialParticipantData?: {
    phoneNumber: string;
    firstName: string;
    lastName: string;
    relationship: string;
  };
}

const ParticipantModal: React.FC<ParticipantModalProps> = (
  { onClose, initialParticipantData = null },
) => {
  const title = initialParticipantData ? 'Edit Participant' : 'Add Participant';
  const contentRef = useRef<HTMLDivElement>(null);
  return (
    <DialogRoot placement="center" size="md" open onOpenChange={onClose}>
      <DialogContent ref={contentRef}>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <ParticipantFormContainer
          onClose={onClose}
          initialParticipantData={initialParticipantData}
          contentRef={contentRef}
        />
      </DialogContent>
    </DialogRoot>
  );
};

export default ParticipantModal;
