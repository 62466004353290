import {
  CheckboxGroup,
  Grid, GridItem, Heading, Text, HStack, Box, type SelectValueChangeDetails, type ListCollection,
  createListCollection,
} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import { CirclePlus } from 'lucide-react';
import React from 'react';

import { Checkbox } from '@chakra-snippets/checkbox';
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
} from '@chakra-snippets/select';

import { constructUserPrefixPreview } from './constants';
import Preview from './Preview';

interface TitleDropdownItem {
  id?: string;
  label?: string;
  placeholderLabel?: string;
  value?: boolean;
}

const titleDropdownList: ListCollection<TitleDropdownItem> = createListCollection({
  items: [
    {
      id: 'hide',
      label: "Don't Show Title",
      placeholderLabel: 'hide',
      value: false,
    },
    {
      id: 'show',
      label: 'Job Title',
      placeholderLabel: 'show',
      value: true,
    },
  ],
});

interface UserMessagePrefixProps {
  isTitleEnabled: boolean;
  isCaseIdEnabled: boolean;
  isPhoneNumberEnabled: boolean;
  setIsTitleEnabled: (value: boolean) => void;
  setIsCaseIdEnabled: (value: boolean) => void;
  setIsPhoneNumberEnabled: (value: boolean) => void;
}

const UserMessagePrefix: React.FC<UserMessagePrefixProps> = ({
  isTitleEnabled,
  isCaseIdEnabled,
  isPhoneNumberEnabled,
  setIsTitleEnabled,
  setIsCaseIdEnabled,
  setIsPhoneNumberEnabled,
}) => {
  // Simplified toggle function - directly updates parent state
  const titleToggle = (e: SelectValueChangeDetails) => {
    if (e.items?.length === 1) {
      const selectedItem = e.items[0];
      const newValue = Boolean(selectedItem.value);
      if (newValue !== isTitleEnabled) {
        setIsTitleEnabled(newValue);
      }
    }
  };

  // Get current item based on isTitleEnabled prop
  const currentTitleItem = isTitleEnabled
    ? titleDropdownList.items[1] // "show" item
    : titleDropdownList.items[0]; // "hide" item

  return (
    <HStack gap="10%">
      <Box w="50%">
        <Grid templateRows="repeat(4, 1fr)" templateColumns="repeat(3, 1fr)">
          <GridItem colSpan={3}>
            <Heading as="h1" size="md">
              User Message Prefix
            </Heading>
            <Text mb={2}>
              Displays as the beginning of outgoing SMS text messages sent from
              users.
            </Text>
          </GridItem>
          <GridItem>
            <Text color="gray.500" mb="5px">Name Format</Text>
            <Text>First Name, Last Initial</Text>
          </GridItem>
          <GridItem justifyContent="center" display="flex" alignItems="center">
            <Icon><CirclePlus /></Icon>
          </GridItem>
          <GridItem>
            <Text color="gray.500" mb="5px">Title/Role</Text>
            <SelectRoot
              w="150px"
              size="sm"
              value={[isTitleEnabled ? 'show' : 'hide']}
              onValueChange={titleToggle}
              collection={titleDropdownList}
            >
              <SelectTrigger>
                {currentTitleItem.label || 'Select option'}
              </SelectTrigger>
              <SelectContent>
                {titleDropdownList.items.map((item) => (
                  <SelectItem key={item.id} item={item}>
                    {item.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </SelectRoot>
          </GridItem>
          <GridItem>
            <Text color="gray.500" mb="5px">Optional Fields</Text>
            <CheckboxGroup>
              <Checkbox
                checked={isCaseIdEnabled}
                onCheckedChange={(e: { checked: boolean }) => {
                  setIsCaseIdEnabled(e.checked);
                }}
                size="sm"
              >
                Case ID
              </Checkbox>
              <Checkbox
                checked={isPhoneNumberEnabled}
                onCheckedChange={(e: { checked: boolean }) => {
                  setIsPhoneNumberEnabled(e.checked);
                }}
                size="sm"
              >
                Phone
              </Checkbox>
            </CheckboxGroup>
          </GridItem>
          <GridItem justifyContent="center" display="flex" alignItems="center">
            <Icon><CirclePlus /></Icon>
          </GridItem>
          <GridItem mt="5px">
            <Text color="gray.500" mb="5px">Org/Brand Name</Text>
            <Text>$BrandName</Text>
          </GridItem>
          <GridItem colSpan={3}>
            <Text color="gray.500" mb="5px">
              NOTE: Message prefixes will cut off (and truncate...) at 100
              characters.
            </Text>
          </GridItem>
        </Grid>
      </Box>
      <Box w="50%">
        <Preview
          firstTextName={constructUserPrefixPreview(
            isTitleEnabled,
            isCaseIdEnabled,
            isPhoneNumberEnabled,
          )}
          firstTextMessage="Hi Charlotte, I'm Justin your Lovable Insurance claims adjuster.
        I'll be helping resolve your auto claim."
          secondTextMessage="Hi Justin"
        />
      </Box>
    </HStack>

  );
};

export default UserMessagePrefix;
