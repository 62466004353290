import {
  Box, Icon, Circle,
} from '@chakra-ui/react';
import { SearchBar } from '@himarley/unity';
import debounce from 'lodash/debounce';
import {
  Briefcase, UserRound, CircleUserRound, UsersRound, ChevronDown, ChevronUp,
} from 'lucide-react';
import React, {
  useCallback, useState, useEffect, useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { lazyLoad } from '@app/actions/common';
import UserRow from '@app/components/chakra/user-row';
import { sortByAlphabetical } from '@app/helpers/sorting';
import { groupType, operatorType } from '@app/models/marleyTypes';
import { type User } from '@app/types/api/user';
import { type StateType } from '@app/types/reducer-state';
import { Button } from '@chakra-snippets/button';
import {
  MenuRoot, MenuTrigger, MenuContent, MenuCheckboxItem, MenuTriggerItem,
} from '@chakra-snippets/menu';

interface Option {
  id: string;
  value: string;
  label: React.ReactNode;
  leftIcon?: React.ReactNode;
  checked?: boolean;
  onClick?: () => void;
  badgeCount?: number;
  searchComponent?: React.ReactNode;
  subMenuItems?: Omit<Option, 'subMenuItems'>[];
}

const AssignedFilterMenuItem = ({ option }: { option: Option }) => {
  if (option.subMenuItems) {
    return (
      <MenuRoot
        id={option.id}
        closeOnSelect={false}
        positioning={{ placement: 'left-start', gutter: 2 }}
      >
        <MenuTriggerItem value={option.id}>
          {option.leftIcon && <Icon>{option.leftIcon}</Icon>}
          {option.label}
          <Box marginLeft="auto" h={4} w={4}>
            {option.badgeCount ? (
              <Circle size="4" bg="marleyRed.500" color="white">
                {option.badgeCount}
              </Circle>
            ) : null}
          </Box>
        </MenuTriggerItem>
        <MenuContent>
          {option.searchComponent}
          <Box maxH="400px" overflowY="auto">
            {option.subMenuItems.map((subOption) => (
              <AssignedFilterMenuItem key={subOption.id} option={subOption} />
            ))}
          </Box>
        </MenuContent>
      </MenuRoot>
    );
  }

  return (
    <MenuCheckboxItem
      checked={option.checked || false}
      key={option.id}
      value={option.id}
      onClick={option.onClick}
    >
      {option.leftIcon && <Icon>{option.leftIcon}</Icon>}
      {option.label}
    </MenuCheckboxItem>
  );
};

interface Properties {
  operators?: User[];
  groups?: User[];
}

interface AssignedFilterProps {
  setProperties: (properties: Properties) => void;
  properties: Properties;
}

const AssignedFilter: React.FC<AssignedFilterProps> = ({ setProperties, properties }) => {
  const dispatch = useDispatch();

  const [userSearchFilter, setUserSearchFilter] = useState('');
  const [groupSearchFilter, setGroupSearchFilter] = useState('');
  const [operatorProperties, setOperatorProperties] = useState<User[]>(properties?.operators || []);
  const [groupProperties, setGroupProperties] = useState<User[]>(properties?.groups || []);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setOperatorProperties(properties?.operators || []);
    setGroupProperties(properties?.groups || []);
  }, [properties]);

  const toggleMenu = () => {
    if (isMenuOpen) {
      setProperties({ operators: operatorProperties, groups: groupProperties });
    }

    setIsMenuOpen(!isMenuOpen);
  };

  const {
    auth,
    users,
    groups,
  } = useSelector((state: StateType) => ({
    auth: {
      ...state.auth,
      user: {
        ...state.auth.user,
        name: `${state.auth.user.firstName} ${state.auth.user.lastName} (me)`,
        id: state.auth.user._id,
      },
    },
    users: (state.operators?.list || []).sort((a, b) => sortByAlphabetical(a?.name, b?.name)),
    groups: (state.groups?.list || []).sort((a, b) => sortByAlphabetical(a?.name, b?.name)),
  }));

  const loadUsers = useCallback(
    (params?: { offset?: number; limit?: number; searchText?: string }) => dispatch(lazyLoad(operatorType, {
      ...params, offset: 0, limit: 20, active: true,
    }, 'users')),
    [dispatch],
  );

  const debouncedHandleSearchUsers = useMemo(
    () => debounce((searchFilter) => {
      const userQuery: {
        offset?: number;
        limit?: number;
        searchText?: string;
      } = {
        offset: 0,
        limit: 20,
        searchText: searchFilter || '',
      };

      loadUsers(userQuery);
    }, 500),
    [], // Dependencies here are empty because debounce persists across renders
  );

  const handleSearchUsers = useCallback(() => {
    debouncedHandleSearchUsers(userSearchFilter);
  }, [debouncedHandleSearchUsers, userSearchFilter]);

  useEffect(() => {
    handleSearchUsers();

    return () => {
      debouncedHandleSearchUsers.cancel();
    };
  }, [userSearchFilter, handleSearchUsers, debouncedHandleSearchUsers]);

  const loadGroups = useCallback(
    (params?: { offset?: number; limit?: number; searchText?: string }) => dispatch(lazyLoad(groupType, { ...params, offset: 0, limit: 20 }, 'groups')),
    [dispatch],
  );

  const debouncedHandleSearchGroups = useMemo(
    () => debounce((searchFilter) => {
      const groupQuery: {
        offset?: number;
        limit?: number;
        searchText?: string;
      } = {
        searchText: searchFilter || '',
      };

      loadGroups(groupQuery);
    }, 500),
    [], // Dependencies here are empty because debounce persists across renders
  );

  const handleSearchGroups = useCallback(() => {
    debouncedHandleSearchGroups(groupSearchFilter);
  }, [debouncedHandleSearchGroups, groupSearchFilter]);

  useEffect(() => {
    handleSearchGroups();

    return () => {
      debouncedHandleSearchGroups.cancel();
    };
  }, [groupSearchFilter, handleSearchGroups, debouncedHandleSearchGroups]);

  const updateProperties = ({ user, group }: {
    user?: User,
    group?: User
  }) => {
    let newOperatorProperties = [...operatorProperties];
    let newGroupProperties = [...groupProperties];

    if (user && user.id === 'all') {
      newOperatorProperties = [];
      newGroupProperties = [];
    } else {
      if (
        user
        && operatorProperties.some((o) => o.id === user.id)
      ) {
        newOperatorProperties = newOperatorProperties?.filter(
          (operator) => operator.id !== user.id,
        );
      } else if (user) {
        newOperatorProperties = newOperatorProperties
          ? [...newOperatorProperties, user] : [user];
      }

      if (
        group
        && groupProperties.some((g) => g.id === group.id)
      ) {
        newGroupProperties = newGroupProperties.filter((g) => g.id !== group.id);
      } else if (group) {
        newGroupProperties = newGroupProperties
          ? [...newGroupProperties, group] : [group];
      }
    }

    setOperatorProperties(newOperatorProperties);
    setGroupProperties(newGroupProperties);
  };

  const selectedUsersDict = useMemo(() => operatorProperties.reduce(
    (acc: { [key: string]: User }, user) => {
      const userId = user.id || user._id;
      if (userId) {
        acc[userId] = user;
      }
      return acc;
    },
    {},
  ), [operatorProperties]);

  const selectedGroupsDict = useMemo(() => groupProperties.reduce(
    (acc: { [key: string]: User }, group) => {
      const groupId = group.id || group._id;
      if (groupId) {
        acc[groupId] = group;
      }
      return acc;
    },
    {},
  ), [groupProperties]);

  const options: Option[] = [
    {
      id: 'allCases',
      value: 'all',
      label: 'All Cases',
      leftIcon: <Briefcase />,
      onClick: () => updateProperties({
        user: {
          id: 'all',
          name: 'All ',
        },
      }),
      checked: !operatorProperties.length && !groupProperties.length,
    },
    {
      id: 'unassigned',
      value: 'unassigned',
      label: 'Unassigned',
      leftIcon: <CircleUserRound />,
      onClick: () => updateProperties({
        user: {
          id: 'unassigned',
          name: 'Unassigned',
        },
      }),
      checked: !!selectedUsersDict?.unassigned,
    },
    {
      id: 'chooseUsers',
      value: 'users',
      label: 'Choose Users',
      leftIcon: <UserRound />,
      badgeCount: operatorProperties.filter((o) => o.id !== 'unassigned').length,
      searchComponent: (
        <Box pb={1} px={3} maxW="400px">
          <Box css={{
            '& > div': { width: 'unset !important' },
          }}
          >
            <SearchBar
              id="users"
              placeholder="Search Users"
              value={userSearchFilter}
              onValueChange={setUserSearchFilter}
              onClear={() => setUserSearchFilter('')}
            />
          </Box>
        </Box>
      ),
      subMenuItems: [
        {
          id: auth.user._id || '',
          value: auth.user._id || '',
          label: (
            <UserRow
              option={auth.user}
              size="sm"
            />
          ),
          onClick: () => updateProperties({ user: auth.user }),
          checked: Boolean(auth.user._id && selectedUsersDict?.[auth.user._id]),
        },
        ...users.filter((user) => (user.id !== auth.user._id)).map((user) => ({
          id: user.id || '',
          value: user.id || '',
          label: <UserRow option={user} size="sm" />,
          onClick: () => updateProperties({ user }),
          checked: Boolean(user.id && selectedUsersDict?.[user.id]),
        })),
      ],
    },
    {
      id: 'chooseGroups',
      value: 'groups',
      label: 'Choose Groups',
      leftIcon: <UsersRound />,
      badgeCount: groupProperties.length,
      searchComponent: (
        <Box pb={1} px={3} maxW="400px">
          <Box css={{
            '& > div': { width: 'unset !important' },
          }}
          >
            <SearchBar
              id="groups"
              placeholder="Search Groups"
              value={groupSearchFilter}
              onValueChange={setGroupSearchFilter}
              onClear={() => setGroupSearchFilter('')}
            />
          </Box>
        </Box>
      ),
      subMenuItems: groups.map((group) => ({
        id: group.id || '',
        value: group.id || '',
        label: <UserRow option={group} size="sm" />,
        onClick: () => updateProperties({ group }),
        checked: Boolean(group.id && selectedGroupsDict?.[group.id]),
      })),
    },
  ];

  const defaultLabel = useMemo(() => {
    let label = 'Assigned: All';

    if (properties) {
      const pOperators = properties.operators || [];
      const pGroups = properties.groups || [];
      const hasUnassigned = pOperators.some((o) => o.id === 'unassigned');

      if (pOperators.length === 1
    && pGroups.length === 0
      ) {
        label = `Assigned: ${pOperators[0].name}`;
      } else if (pGroups.length === 1
    && pOperators.length === 0
      ) {
        label = `Assigned: ${pGroups[0].name}`;
      } else if (pOperators.length === 2
    && hasUnassigned
    && pGroups.length === 0
      ) {
        label = `Assigned: ${pOperators[0].name}, ${pOperators[1].name}`;
      } else if (pOperators.length) {
        label = `Assigned: ${pOperators.length} User${pOperators.length > 1 ? 's' : ''}`;
        if (pGroups.length) {
          label += `, ${pGroups.length} Group${pGroups.length > 1 ? 's' : ''}`;
        }
      } else if (pGroups.length) {
        label = `Assigned: ${pGroups.length} Group${pGroups.length > 1 ? 's' : ''}`;
      }
    }

    return label;
  }, [properties]);

  return (
    <MenuRoot
      id="assigned-filter"
      open={isMenuOpen}
      onOpenChange={toggleMenu}
      closeOnSelect={false}
    >
      <MenuTrigger asChild>
        <Button variant="ghost">
          {defaultLabel}
          <Icon>{isMenuOpen ? <ChevronUp /> : <ChevronDown />}</Icon>
        </Button>
      </MenuTrigger>
      <MenuContent>
        {options.map((option) => (
          <AssignedFilterMenuItem key={option.id} option={option} />
        ))}
      </MenuContent>
    </MenuRoot>
  );
};

export default AssignedFilter;
