import { Icon } from '@chakra-ui/react';
import React from 'react';

import {
  MenuRoot,
  MenuTrigger,
  MenuContent,
  MenuItem,
} from '@chakra-snippets/menu';

type SimpleMenuProps = {
  onSelect: (e: { value: string }) => void;
  options: {
    label: string;
    value: string;
    leftIcon?: React.ReactNode;
    disabled?: boolean;
    selected?: boolean;
  }[];
  menuTrigger: React.ReactNode;
  maxHeight?: string;
  sameWidth?: boolean;
  dataTestId?: string;
};

const SimpleMenu: React.FC<SimpleMenuProps> = ({
  onSelect,
  options,
  menuTrigger,
  maxHeight = '200px',
  sameWidth = false,
  dataTestId,
}) => (
  <MenuRoot
    onSelect={onSelect}
    positioning={{ sameWidth }}
  >
    <MenuTrigger asChild data-testid={dataTestId || 'simple-menu-trigger'}>
      {menuTrigger}
    </MenuTrigger>
    <MenuContent maxHeight={maxHeight}>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value} disabled={option.disabled} data-testid={`simple-menu-item-${option.value}`}>
          {option.leftIcon && <Icon>{option.leftIcon}</Icon>}
          {option.label}
        </MenuItem>
      ))}
    </MenuContent>
  </MenuRoot>
);

export default SimpleMenu;
