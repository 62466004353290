import { defineSlotRecipe } from '@chakra-ui/react';

export const toastSlotRecipe = defineSlotRecipe({
  slots: ['root'],
  variants: {
    variant: {
      'left-accent': {
        root: {
          borderLeft: '4px solid',
          borderColor: 'colorPalette.solid',
          bg: 'colorPalette.contrast',
          '&[data-type=success]': {
            bg: 'green.subtle',
            color: 'black',
            borderLeft: '4px solid',
            borderColor: 'green.solid',
            '--toast-trigger-bg': '{white/10}',
            '--toast-border-color': '{white/40}',
            _icon: {
              color: 'green.solid',
            },
          },
          '&[data-type=warning]': {
            bg: 'orange.subtle',
            color: 'black',
            '--toast-trigger-bg': '{white/10}',
            '--toast-border-color': '{white/40}',
            borderLeft: '4px solid',
            borderColor: 'orange.solid',
            _icon: {
              color: 'orange.solid',
            },
          },
          '&[data-type=error]': {
            bg: 'red.subtle',
            color: 'black',
            '--toast-trigger-bg': '{white/10}',
            '--toast-border-color': '{white/40}',
            borderLeft: '4px solid',
            borderColor: 'red.solid',
            _icon: {
              color: 'red.solid',
            },
          },
          '&[data-type=loading]': {
            bg: 'blue.subtle',
            color: 'black',
            '--toast-trigger-bg': '{white/10}',
            '--toast-border-color': '{white/40}',
            borderLeft: '4px solid',
            borderColor: 'blue.solid',
            _icon: {
              color: 'blue.solid',
            },
          },
        },
      },
    },
  },
  defaultVariants: {
    variant: 'left-accent',
  },
});

export default toastSlotRecipe;
