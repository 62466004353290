import {
  Textarea, Input,
} from '@chakra-ui/react';
import React from 'react';

import {
  downloadCSVTemplate,
} from '@app/actions/massNotifications';
import { Button } from '@chakra-snippets/button';
import { Field } from '@chakra-snippets/field';

import ImportCSVFile from './import-csv-file';

interface CreateMassNotificationInfoProps {
  createButtonEnabled: boolean;
  currentFilename: string;
  handleClickSelectFile: () => void;
  handleFileDrop: (files: FileList | null) => void;
  handleFileInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFormInputChange: (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleNextStep: () => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  formData: {
    notificationMessage: string;
    notificationTitle: string;
  };
}

const CreateMassNotificationInfo: React.FC<CreateMassNotificationInfoProps> = ({
  createButtonEnabled,
  currentFilename,
  handleClickSelectFile,
  handleFileDrop,
  handleFileInputChange,
  handleFormInputChange,
  handleNextStep,
  fileInputRef,
  formData = {},
}) => (
  <>
    <div className="modal-container">
      <h3 className="modal-step-title">Step 1: Create Notification</h3>
      <div className="miniTitle">Import recipient information by CSV file</div>
      <ImportCSVFile
        downloadCSVTemplate={downloadCSVTemplate}
        handleFileDrop={handleFileDrop}
        currentFilename={currentFilename}
        handleClickSelectFile={handleClickSelectFile}
        fileInputRef={fileInputRef}
        handleFileInputChange={handleFileInputChange}
      />
    </div>
    <div className="miniTitle">Create Information</div>
    <div className="messageInfoContainer">
      <div className="notificationNameContainer">
        <Field
          label="Notification Name"
          required
          w="100%"
        >
          <Input
            maxLength={31}
            name="notificationTitle"
            onChange={handleFormInputChange}
            type="text"
            value={formData.notificationTitle}
            size="sm"
          />
        </Field>
      </div>
    </div>
    <div className="messageInputContainer">
      <Textarea
        className="messageInput"
        placeholder="Write notification here"
        maxLength={1600}
        name="notificationMessage"
        onChange={handleFormInputChange}
        value={formData.notificationMessage}
      />
    </div>
    <div className="buttonRow">
      <Button
        type="button"
        onClick={handleNextStep}
        disabled={!createButtonEnabled}
      >
        Next
      </Button>
    </div>
  </>
);

export default CreateMassNotificationInfo;
