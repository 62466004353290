/* eslint-disable react/jsx-props-no-spreading */
import { Icon, IconProps } from '@chakra-ui/react';
import {
  CarFront,
  House,
  CircleEllipsis,
  Wheat,
  BriefcaseMedical,
  ShieldPlus,
  Building,
  ShieldAlert,
  Truck,
} from 'lucide-react';
import React from 'react';

const LOB_TYPE_TO_ICON: {
  [key: string]: (props: IconProps) => JSX.Element;
} = {
  home: (props) => <Icon {...props}><House /></Icon>,
  other: (props) => <Icon {...props}><CircleEllipsis /></Icon>,
  health: (props) => <Icon {...props}><BriefcaseMedical /></Icon>,
  truck: (props) => <Icon {...props}><Truck /></Icon>,
  property: (props) => <Icon {...props}><Building /></Icon>,
  auto: (props) => <Icon {...props}><CarFront /></Icon>,
  'general-liability': (props) => <Icon {...props}><ShieldAlert /></Icon>,
  'workers-comp': (props) => <Icon {...props}><BriefcaseMedical /></Icon>,
  crop: (props) => <Icon {...props}><Wheat /></Icon>,
  life: (props) => <Icon {...props}><ShieldPlus /></Icon>,
};

interface LoBIconProps extends IconProps {
  name: string;
}

const LoBIcon = ({
  name,
  ...props
}: LoBIconProps) => {
  const IconComponent = LOB_TYPE_TO_ICON[name?.toLowerCase()] || LOB_TYPE_TO_ICON.other;
  return IconComponent(props);
};

export default LoBIcon;
