import React from 'react';

import {
  DialogRoot, DialogContent, DialogHeader, DialogTitle,
} from '@chakra-snippets/dialog';

import RemoveParticipantContainer from './remove-participant-container';

interface RemoveParticipantModalProps {
  participant: {
    id: string,
    imageUrl: string,
    name: string,
    role: string,
    relationship: string,
  },
  onClose: () => void,
}

const RemoveParticipantModal: React.FC<RemoveParticipantModalProps> = (
  { participant, onClose },
) => (
  <DialogRoot open onOpenChange={onClose} placement="center" size="md">
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Remove Participant</DialogTitle>
      </DialogHeader>
      <RemoveParticipantContainer
        participant={participant}
        onClose={onClose}
      />
    </DialogContent>
  </DialogRoot>
);

export default RemoveParticipantModal;
