export const ConfigOptInStatus: { [key: string]: string } = {
  PENDING_OPT_IN: 'not opted in', // verified = false, blocked false, locked false
  OPTED_IN: 'already opted in', // verified = true, blocked false, locked false
};

export const KEYS = {
  INITIAL_MESSAGE: 'INITIAL_MESSAGE',
  CONFIRMATION_MESSAGE: 'CONFIRMATION_MESSAGE',
  FOLLOWUP_MESSAGE: 'FOLLOWUP_MESSAGE',
  ASSIGNED: 'ASSIGNED',
  UNASSIGNED: 'UNASSIGNED',
  ANY: 'ANY',
  OPTED_IN: 'OPTED_IN',
  PENDING_OPT_IN: 'PENDING_OPT_IN',
} as const;

export const WELCOME_FLOW_RULESETS: { [key: string]: string } = {
  INITIAL_MESSAGE: 'WELCOME_FLOW_INITIAL_MESSAGE',
  CONFIRMATION_MESSAGE: 'WELCOME_FLOW_OPTIN_CONFIRMATION',
  FOLLOWUP_MESSAGE: 'WELCOME_FLOW_OPTIN_FOLLOWUP',
};

export const CLAIM = 'Claim';
export const CASE = 'General Case';
export const SERVICE = 'Service';

export const LEGAL_MESSAGE_TOKEN = '$legalMessage';
export const MOID_LEGAL_MESSAGE_TOKEN = '$marleyOptInDisabledLegalMessage';

export const MOID_LEGAL_MESSAGE = 'By responding you are agreeing to our terms and privacy policy (himarley.com/tp). Msg & Data rates may apply. Text STOP to opt-out, HELP for help.';

export const MODAL_ACTIONS = {
  SET_MESSAGES: 'SET_MESSAGES' as const,
  SET_PREVIEW_OPTION: 'SET_PREVIEW_OPTION' as const,
  SET_SHOW_ENDING_MESSAGE: 'SET_SHOW_ENDING_MESSAGE' as const,
  TOGGLE_OPTIONAL_FOLLOW_UP: 'TOGGLE_OPTIONAL_FOLLOW_UP' as const,
  SET_IS_SAVING: 'SET_IS_SAVING' as const,
  SET_IS_DIRTY: 'SET_IS_DIRTY' as const,
  SET_TOKEN_OVERRIDE_MAP: 'SET_TOKEN_OVERRIDE_MAP' as const,
  UPDATE_MESSAGE: 'UPDATE_MESSAGE' as const,
};

export const LEGAL_MESSAGE = 'Please reply YES to agree to the terms and privacy policy (himarley.com/tp). Msg and data rates may apply. Msg freq varies. Reply HELP for help or STOP to opt out.';

export const TOKENS_MAP: Record<`$${string}`, string> = {
  $claimantFullName: 'Charlotte Customer',
  $claimantLastName: 'Customer',
  $claimantFirstName: 'Charlotte',
  $claimantPhoneNumber: '+555-555-1234',
  $contactFullName: 'Justin Adjuster',
  $contactFirstName: 'Justin',
  $contactLastName: 'Adjuster',
  $contactEmail: 'justin.adjuster@lovable.com',
  $contactTitle: 'Sr. Adjuster',
  $contactPhoneNumber: '+555-555-5555',
  $claimNumber: '987654321',
  $policyNumber: '123456',
  $companyName: 'Lovable Insurance',
};

export const RADIO_BUTTON_VALUES = [
  { id: 'ASSIGNED', label: 'Preview Assigned', name: 'RADIO_BUTTON_VALUES' },
  {
    id: 'UNASSIGNED',
    label: 'Preview Unassigned',
    name: 'RADIO_BUTTON_VALUES',
  },
];
export const RADIO_BUTTON_VALUES_OPT_IN = [
  {
    id: 'ASSIGNED',
    label: 'Preview Assigned',
    name: 'RADIO_BUTTON_VALUES_OPT_IN',
  },
  {
    id: 'UNASSIGNED',
    label: 'Preview Unassigned',
    name: 'RADIO_BUTTON_VALUES_OPT_IN',
  },
];
export const MODAL_RADIO_BUTTON_VALUES_OPT_IN = [
  {
    id: 'ASSIGNED',
    label: 'Preview Assigned',
    name: 'MODAL_RADIO_BUTTON_VALUES_OPT_IN',
  },
  {
    id: 'UNASSIGNED',
    label: 'Preview Unassigned',
    name: 'MODAL_RADIO_BUTTON_VALUES_OPT_IN',
  },
];
