import { defineSlotRecipe } from '@chakra-ui/react';

export const selectRecipe = defineSlotRecipe({
  slots: ['field'],
  base: {
    field: {
      borderRadius: 'base',
    },
  },
  variants: {
    variant: {
      outline: {
        field: {
          size: 'md',
        },
      },
    },
  },
  defaultVariants: {
    variant: 'outline',
  },
});

export default selectRecipe;
