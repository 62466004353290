import { defineSlotRecipe } from '@chakra-ui/react';

export const spinnerRecipe = defineSlotRecipe({
  slots: ['root'],
  base: {
    root: {
      color: 'green.500',
    },
  },
});

export default spinnerRecipe;
