import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getiFrameLookUrl } from '@app/actions/analytics';
import {
  DialogRoot,
  DialogContent,
  DialogCloseTrigger,
  DialogBody,
} from '@chakra-snippets/dialog';

import './LargeLookContent.less';

interface LargeLookContentProps {
  lookNumber: string;
  caseId?: string;
  closeLook: () => void;
  open: boolean;
}

const LargeLookContent: React.FC<LargeLookContentProps> = ({
  lookNumber,
  caseId = '',
  closeLook,
  open,
}) => {
  const dispatch = useDispatch();
  const { lookUrls = {} } = useSelector((state) => ({
    lookUrls: state?.analytics?.lookUrls || '',
  })) || {};

  useEffect(() => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    timezone = timezone.replace(/\//gi, '-');
    dispatch(getiFrameLookUrl(caseId, lookNumber, timezone));
  }, [caseId, dispatch, lookNumber]);

  return (
    <DialogRoot
      size="full"
      open={open}
      onOpenChange={closeLook}
    >
      <DialogContent>
        <DialogBody>
          <div className="large-look-content-container" data-testid="look-modal">
            <iframe
              className="large-look-iframe"
              title="look"
              src={lookUrls[lookNumber]}
            />
          </div>
        </DialogBody>
        <DialogCloseTrigger />
      </DialogContent>
    </DialogRoot>
  );
};

export default LargeLookContent;
