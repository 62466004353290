import {
  Input,
  Text,
  VStack,
  HStack,
  ButtonGroup,
  Separator,
} from '@chakra-ui/react';
import React from 'react';

import SimpleSelect from '@app/components/chakra/selects/simple-select';
import { Button } from '@chakra-snippets/button';
import { DialogBody, DialogFooter } from '@chakra-snippets/dialog';
import { Field } from '@chakra-snippets/field';
import { InputGroup } from '@chakra-snippets/input-group';

import './ParticipantForm.less';
import { buildOptedInWelcomeMessage, buildNonOptedInWelcomeMessage } from './welcomeMessagePrompts';

interface ParticipantFormProps {
  onClose: () => void;
  handlePhoneNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFirstNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleLastNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRelationshipChange: (value: string) => void;
  mobileLookupMessage: string | null;
  participantForm: {
    phoneNumber: string;
    firstName: string;
    lastName: string;
    relationship: string;
    error?: string;
  };
  requestStatus: string;
  onSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  relationships: { label: string; value: string; lobSubTypes?: string[] }[];
  isExistingUser: boolean;
  orgName: string;
  verifiedUser: boolean;
  isInEditMode: boolean;
  isPrimaryEndUser: boolean;
  lobSubType?: string;
  contentRef: React.RefObject<HTMLDivElement>;
}

const ParticipantForm: React.FC<ParticipantFormProps> = ({
  onClose,
  mobileLookupMessage = '',
  handlePhoneNumberChange,
  handleFirstNameChange,
  handleLastNameChange,
  handleRelationshipChange,
  participantForm = {
    phoneNumber: '',
    firstName: '',
    lastName: '',
    relationship: '',
  },
  requestStatus = undefined,
  onSubmit,
  relationships,
  isExistingUser = false,
  orgName,
  verifiedUser = false,
  isInEditMode,
  isPrimaryEndUser,
  lobSubType,
  contentRef,
}) => {
  const handleOnClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    onClose();
  };

  const isParticipantSelected = (
    participantForm.phoneNumber
    && participantForm.firstName
    && participantForm.lastName
  );

  const isPhoneNumberValid = mobileLookupMessage === 'SMS Supported' || isInEditMode;
  const isPhoneNumberAlreadyAdded = mobileLookupMessage === 'This phone number is already active on the conversation';
  const isSubmitDisabled = !isParticipantSelected || isPhoneNumberAlreadyAdded;

  // This is a temp fix for Farmers Alpha. Should be replaced with templating at some point.
  const marleyTermsOfService = 'himarley.com/tp';
  const farmersTermsOfService = `farmers.com/privacy-center/ and ${marleyTermsOfService}`;
  const termsOfService = orgName?.trim()?.toLowerCase() === 'farmers insurance' ? farmersTermsOfService : marleyTermsOfService;

  const welcomeMessageTemplate = verifiedUser
    ? buildOptedInWelcomeMessage(participantForm, orgName, termsOfService)
    : buildNonOptedInWelcomeMessage(participantForm, orgName, termsOfService);

  // only show welcome message preview if participant is selected and we are not in edit user mode
  const shouldShowWelcomeMessage = !isInEditMode && !!isParticipantSelected;

  const isLoading = requestStatus === 'PENDING';
  const isError = requestStatus === 'ERROR';

  let errorMessage;
  if (isInEditMode) {
    errorMessage = 'Unable to edit participant. Please try again later.';
  } else {
    errorMessage = 'Unable to invite participant. Please try again later.';
    if (participantForm.error) {
      errorMessage = participantForm.error;
    }
  }
  const submitButtonNotLoadingText = isInEditMode ? 'Save Participant' : 'Invite Participant';
  const submitButtonText = isLoading ? 'Saving...' : submitButtonNotLoadingText;

  const editingNameEnabled = isInEditMode || (!isExistingUser && !isLoading);

  const formatPhoneNumber = (phoneNumber: string) => {
    const cleanedValue = phoneNumber.replace(/\D/g, '');

    // Format the phone number (###) ###-####
    const formattedValue = cleanedValue
      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
      .slice(0, 14); // Limit length
    return formattedValue;
  };

  return (
    <>
      <DialogBody>
        <VStack gap={4}>
          <Field
            required
            id="addParticipantMobilePhoneInput"
            invalid={!isPhoneNumberValid}
            label="Mobile Phone"
            helperText={mobileLookupMessage}
            errorText={!isPhoneNumberValid && participantForm.phoneNumber.length === 10 ? 'Please enter a valid phone number.' : ''}
            w="100%"
          >
            <InputGroup startElement={<Text>+1</Text>} w="100%">
              <Input
                value={formatPhoneNumber(participantForm.phoneNumber)}
                placeholder="Enter Phone Number"
                disabled={isLoading || isInEditMode}
                data-testid="addParticipantMobilePhoneInput"
                onChange={handlePhoneNumberChange}
              />
            </InputGroup>
          </Field>
          <HStack justifyContent="space-between" w="100%">
            <Field required label="First Name" w="100%" id="firstNameInput">
              <Input
                value={participantForm.firstName}
                placeholder="Enter First Name"
                disabled={!editingNameEnabled}
                data-testid="firstNameInput"
                onChange={handleFirstNameChange}
                maxLength={50}
              />
            </Field>
            <Field required label="Last Name" w="100%" id="lastNameInput">
              <Input
                value={participantForm.lastName}
                placeholder="Enter Last Name"
                disabled={!editingNameEnabled}
                data-testid="lastNameInput"
                onChange={handleLastNameChange}
                maxLength={50}
              />
            </Field>
          </HStack>
          <Field label="Relationship" w="100%" id="relationshipInput">
            <SimpleSelect
              placeholder="Choose Relationship"
              disabled={isLoading || isPrimaryEndUser}
              data-testid="relationshipInput"
              id="relationshipInput"
              onValueChange={(details) => handleRelationshipChange(details.value[0])}
              options={relationships.filter(
                (relationship) => (relationship.lobSubTypes?.includes(lobSubType || '')) || !relationship.lobSubTypes,
              ).map((relationship) => ({
                label: relationship.label,
                value: relationship.value,
                selected: participantForm.relationship === relationship.value,
              }))}
              contentRef={contentRef}
            />
          </Field>
          {shouldShowWelcomeMessage ? (
            <>
              <Separator />
              {welcomeMessageTemplate}
            </>
          ) : null}
          <Separator />
          {isError ? (
            <Text
              color="red.500"
              data-testid="errorText"
            >
              {errorMessage}
            </Text>
          ) : null}
        </VStack>
      </DialogBody>
      <DialogFooter>
        <ButtonGroup>
          <Button
            onClick={handleOnClose}
            variant="outline"
            disabled={isLoading}
            data-testid="inviteParticipantCancelButton"
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitDisabled || isLoading}
            data-testid="inviteParticipantSubmitButton"
            onClick={onSubmit}
          >
            {submitButtonText}
          </Button>
        </ButtonGroup>
      </DialogFooter>
    </>
  );
};

export default ParticipantForm;
