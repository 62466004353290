'use client';

import {
  Menu as ChakraMenu, Portal, Icon, Flex, Box,
} from '@chakra-ui/react';
import { Check, ChevronRight } from 'lucide-react';
import * as React from 'react';

interface MenuContentProps extends ChakraMenu.ContentProps {
  portalled?: boolean
  portalRef?: React.RefObject<HTMLElement>
}

export const MenuContent = React.forwardRef<HTMLDivElement, MenuContentProps>(
  (props, ref) => {
    const {
      portalled = true,
      portalRef,
      ...rest
    } = props;
    return (
      <Portal disabled={!portalled} container={portalRef}>
        <ChakraMenu.Positioner>
          <ChakraMenu.Content ref={ref} {...rest} />
        </ChakraMenu.Positioner>
      </Portal>
    );
  },
);

export const MenuArrow = React.forwardRef<
HTMLDivElement,
ChakraMenu.ArrowProps
>((props, ref) => (
  <ChakraMenu.Arrow ref={ref} {...props}>
    <ChakraMenu.ArrowTip />
  </ChakraMenu.Arrow>
));

export const MenuCheckboxItem = React.forwardRef<
HTMLDivElement,
ChakraMenu.CheckboxItemProps
>((props, ref) => (
  <ChakraMenu.CheckboxItem ref={ref} {...props}>
    <Flex w="100%" align="center">
      <Flex gap={2} flex="1" minW={0}>
        {props.children}
      </Flex>
      <Box pl={1} w="20px" display="flex" justifyContent="center">
        <ChakraMenu.ItemIndicator>
          <Icon><Check /></Icon>
        </ChakraMenu.ItemIndicator>
      </Box>
    </Flex>
  </ChakraMenu.CheckboxItem>
));

export const MenuRadioItem = React.forwardRef<
HTMLDivElement,
ChakraMenu.RadioItemProps
>((props, ref) => {
  const {
    children,
    ...rest
  } = props;
  return (
    <ChakraMenu.RadioItem ref={ref} {...rest}>
      <Flex w="100%" align="center">
        <Flex gap={2} flex="1" minW={0}>
          <ChakraMenu.ItemText>{children}</ChakraMenu.ItemText>
        </Flex>
        <Box pl={1} w="20px" display="flex" justifyContent="center">
          <ChakraMenu.ItemIndicator>
            <Icon><Check /></Icon>
          </ChakraMenu.ItemIndicator>
        </Box>
      </Flex>
    </ChakraMenu.RadioItem>
  );
});

export const MenuItemGroup = React.forwardRef<
HTMLDivElement,
ChakraMenu.ItemGroupProps
>((props, ref) => {
  const {
    title,
    children,
    ...rest
  } = props;
  return (
    <ChakraMenu.ItemGroup ref={ref} {...rest}>
      {title && (
        <ChakraMenu.ItemGroupLabel userSelect="none">
          {title}
        </ChakraMenu.ItemGroupLabel>
      )}
      {children}
    </ChakraMenu.ItemGroup>
  );
});

export interface MenuTriggerItemProps extends ChakraMenu.ItemProps {
  startIcon?: React.ReactNode
}

export const MenuTriggerItem = React.forwardRef<
HTMLDivElement,
MenuTriggerItemProps
>((props, ref) => {
  const {
    startIcon,
    children,
    ...rest
  } = props;
  return (
    <ChakraMenu.TriggerItem ref={ref} {...rest}>
      <Flex gap={2} w="100%" align="center">
        {startIcon && <Icon>{startIcon}</Icon>}
        <Flex gap={2} flex="1" minW={0}>
          {children}
        </Flex>
        <Box w="20px" display="flex" justifyContent="center">
          <Icon><ChevronRight /></Icon>
        </Box>
      </Flex>
    </ChakraMenu.TriggerItem>
  );
});

export const MenuRadioItemGroup = ChakraMenu.RadioItemGroup;
export const MenuContextTrigger = ChakraMenu.ContextTrigger;
export const MenuRoot = ChakraMenu.Root;
export const MenuSeparator = ChakraMenu.Separator;

export const MenuItem = ChakraMenu.Item;
export const MenuItemText = ChakraMenu.ItemText;
export const MenuItemCommand = ChakraMenu.ItemCommand;
export const MenuTrigger = ChakraMenu.Trigger;
