import {
  Table,
  Icon,
  Badge,
} from '@chakra-ui/react';
import {
  MoreVerticalIcon,
} from '@himarley/unity';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { showSnackbar } from '@app/actions/notification';
import MessageTemplateForm from '@app/components/Form/MessageTemplate/MessageTemplateForm';
import RuleForm from '@app/components/Form/Rule/rule-form';
import { defaultActiveMessageTemplateState, defaultActiveRuleState, defaultRuleSettingsState } from '@app/constants/integration-constants';
import { extractTokens } from '@app/helpers/common';
import { getKeys } from '@app/slices/templates';
import { Rule } from '@app/types/ruleset';
import { Button } from '@chakra-snippets/button';
import {
  MenuRoot, MenuTrigger, MenuContent, MenuItem,
} from '@chakra-snippets/menu';

import {
  ActiveMessageTemplateState,
  RuleData, RuleFormData,
  RuleSettingsState,
  TemplateFormData,
  ToggleTemplateModalArgs,
} from './integration-settings-types';
import { buildRequestOptions, keyById } from './utils';
import { GET_RULESET } from '../../../constants/endpoints';
import secureRequest from '../../../helpers/secure-request';

const sortRules = (rules: Rule[]) => rules.sort((a, b) => {
  if (a.enabled !== b.enabled) {
    const aEnabled = +(Boolean(a.enabled));
    const bEnabled = +(Boolean(b.enabled));
    return bEnabled - aEnabled;
  }
  // If 'enabled' is the same, sort by 'ruleName' in ascending order
  const aRuleName = a?.ruleName || a.messageTemplate?.displayName || '';
  const bRuleName = b?.ruleName || b.messageTemplate?.displayName || '';
  return aRuleName.localeCompare(bRuleName);
});

const buildParterQuery = (partnerName: string) => {
  if (partnerName === 'Copart' || partnerName === 'Verisk') {
    return '';
  }
  return `?partnerName=${partnerName}`;
};

const eventTypeDisplay = (eventType: string): string => eventType
  .toLowerCase()
  .replace(/_/g, ' ');

const IntegrationRulesContainer = ({
  partnerName,
  type,
  onClose,
}: { partnerName: string, type: string, onClose: () => void }) => {
  const [ruleSettings, setRuleSettings] = useState(defaultRuleSettingsState);
  const [initialRuleSettings, setInitialRuleSettings] = useState(defaultRuleSettingsState);

  const [activeRule, setActiveRule] = useState(
    defaultActiveRuleState,
  );
  const [activeMessageTemplate, setActiveMessageTemplate] = useState(
    defaultActiveMessageTemplateState,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getKeys());
  }, [dispatch]);

  const toggleRuleModal = ({
    ruleId,
    ruleName,
    rule,
    enabled,
    messageTemplate,
  }: RuleData) => {
    const activeRuleData = (ruleId ? {
      ruleId,
      ruleName,
      enabled,
      showModal: true,
      rule,
      ...messageTemplate && {
        messageTemplate: {
          id: messageTemplate?.id || '',
          displayName: messageTemplate?.displayName || '',
          body: messageTemplate?.body || '',
          tokens: messageTemplate?.tokens || [],
          category: messageTemplate?.category || '',
        },
      },
    } : defaultActiveRuleState);
    setActiveRule(activeRuleData);
  };

  const toggleTemplateModal = ({
    templateId: id,
    ruleId,
    messageTemplateTitle: displayName,
    messageTemplateText: body,
    messageTemplateTokens: tokens,
    messageTemplateCategory: category,
  }: ToggleTemplateModalArgs) => {
    const activeMessageTemplateData = (id ? {
      id,
      ruleId,
      showModal: true,
      body,
      displayName,
      tokens,
      category,
    } : defaultActiveMessageTemplateState) as ActiveMessageTemplateState;
    setActiveMessageTemplate(activeMessageTemplateData);
  };

  useEffect(() => {
    const fetchRuleset = async () => {
      const partnerQuery = buildParterQuery(partnerName);
      const response = await secureRequest(GET_RULESET(type, partnerQuery), { method: 'GET' });
      const {
        rules,
        id,
        defaultVersion,
        rulesetType,
      } = await response.json();
      const rulesSorted = sortRules(rules);
      const rulesState = {
        id, rulesetType, defaultVersion, rules: { ...keyById(rulesSorted) },
      };
      setRuleSettings(rulesState);
    };
    fetchRuleset();
  }, [type]);

  const handleSubmitRules = async (ruleSettingsBody: RuleSettingsState) => {
    const isKnownPartner = ['Copart', 'Verisk'].includes(partnerName);
    if (!isKnownPartner) {
      ruleSettingsBody.partnerName = partnerName;
    }
    const { url, options } = buildRequestOptions(ruleSettingsBody);
    const response = await secureRequest(url, options);
    if (response.ok) {
      const {
        rules,
        defaultVersion,
        id,
        rulesetType,
      } = await response.json();
      const rulesSorted = sortRules(rules);
      const rulesState = {
        id,
        defaultVersion,
        rulesetType,
        rules: { ...keyById(rulesSorted) },
      };
      setInitialRuleSettings(rulesState);
      setRuleSettings(rulesState);
      dispatch(showSnackbar({ text: 'Rules and Settings saved', isError: false }));
    } else {
      setRuleSettings(initialRuleSettings);
      dispatch(showSnackbar({ text: 'Error submitting rules', isError: true }));
    }
  };

  const handleSaveMessageTemplate = async (formData: TemplateFormData) => {
    const { ruleId } = activeMessageTemplate;
    const { body, tokens } = extractTokens(formData.body); // format readable tokens as ${TOKEN}
    const formattedFormData = {
      displayName: formData.displayName,
      body,
      tokens,
    };
    const newRuleSettings: RuleSettingsState = {
      ...ruleSettings,
      rules: {
        ...ruleSettings.rules,
        [ruleId]: {
          ...ruleSettings.rules[ruleId],
          messageTemplate: {
            ...ruleSettings.rules[ruleId].messageTemplate,
            ...formattedFormData,
          },
        },
      },
    };

    setRuleSettings(newRuleSettings);
    setActiveMessageTemplate(defaultActiveMessageTemplateState);
    await handleSubmitRules(newRuleSettings);
  };

  const handleSaveRule = async (formData: RuleFormData) => {
    const { ruleId = '' } = activeRule;
    const {
      ruleName,
      enabled,
      messageTemplateName,
      messageTemplateBody,
    } = formData;
    // format readable tokens as ${TOKEN}
    const { body, tokens } = extractTokens(messageTemplateBody);

    const newRuleSettings = {
      ...ruleSettings,
      rules: {
        ...ruleSettings.rules,
        [ruleId]: {
          ...ruleSettings.rules[ruleId],
          enabled,
          ruleName,
          messageTemplate: {
            ...ruleSettings.rules[ruleId].messageTemplate,
            displayName: messageTemplateName,
            body,
            tokens,
          },
        },
      },
    };
    setRuleSettings(newRuleSettings);
    setActiveRule(defaultActiveRuleState);
    await handleSubmitRules(newRuleSettings);
  };

  return (
    <>
      <Button variant="plain" color="blue.500" onClick={onClose}>← Back to all software integrations</Button>
      <h1 style={{ margin: '20px 0' }}>{`[${partnerName}] Rules`}</h1>
      <Table.Root variant="line">
        <Table.Header position="static">
          <Table.Row>
            <Table.ColumnHeader>Rule</Table.ColumnHeader>
            <Table.ColumnHeader>Event</Table.ColumnHeader>
            <Table.ColumnHeader>Status</Table.ColumnHeader>
            <Table.ColumnHeader>Actions</Table.ColumnHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.values(ruleSettings.rules).map((rule, index) => {
            const partnerRegex = /\[.*?\]\s*/;
            const ruleName = rule.ruleName?.replace(partnerRegex, '') || rule.messageTemplate?.displayName?.replace(partnerRegex, '');
            const status = rule.enabled;
            const eventType = eventTypeDisplay(rule.rule?.event?.type || 'SEND_TEMPLATE');
            return (
              <Table.Row key={rule.id} data-testid={`integration-rules-list-item-${index}`}>
                <Table.Cell data-testid="integration-rule-name">{ruleName}</Table.Cell>
                <Table.Cell textTransform="capitalize">{eventType}</Table.Cell>
                <Table.Cell>
                  <Badge
                    colorPalette={status ? 'green' : 'red'}
                    data-testid={status ? 'enabled-rule-status' : 'disabled-rule-status'}
                    borderRadius="md"
                    bg={status ? 'green.100' : 'red.100'}
                    color={status ? 'green.600' : 'red.600'}
                    textTransform="capitalize"
                    fontWeight="semibold"
                  >
                    {status ? 'Enabled' : 'Disabled'}
                  </Badge>
                </Table.Cell>
                <Table.Cell>
                  <MenuRoot>
                    <MenuTrigger asChild>
                      <Button
                        variant="ghost"
                        size="sm"
                        aria-label="Actions"
                        data-testid={`actions-menu-${index}`}
                      >
                        <Icon as={MoreVerticalIcon} />
                      </Button>
                    </MenuTrigger>
                    <MenuContent>
                      {rule.messageTemplate && (
                      <MenuItem
                        value="edit-template"
                        onClick={() => toggleTemplateModal({
                          templateId: rule.messageTemplate.id,
                          ruleId: rule.id,
                          messageTemplateTitle: rule.messageTemplate.displayName,
                          messageTemplateText: rule.messageTemplate.body,
                          messageTemplateTokens: rule.messageTemplate.tokens,
                        })}
                        data-testid={`edit-template-${index}`}
                      >
                        Edit Template
                      </MenuItem>
                      )}
                      <MenuItem
                        value="edit-rule"
                        onClick={() => toggleRuleModal({
                          ruleId: rule.id,
                          ruleName: rule.ruleName || rule.messageTemplate.displayName,
                          rule: rule.rule,
                          enabled: rule.enabled,
                          messageTemplate: rule.messageTemplate,
                        })}
                        data-testid={`edit-rule-${index}`}
                      >
                        Edit Rule
                      </MenuItem>
                    </MenuContent>
                  </MenuRoot>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table.Root>
      <RuleForm
        show={activeRule.showModal}
        toggleModal={toggleRuleModal}
        handleSubmit={handleSaveRule}
        ruleData={activeRule}
        rulesetType={ruleSettings.rulesetType}
      />
      <MessageTemplateForm
        show={activeMessageTemplate.showModal}
        method="update"
        toggleModal={toggleTemplateModal}
        handleSubmit={handleSaveMessageTemplate}
        templateData={activeMessageTemplate}
      />
    </>
  );
};

export default IntegrationRulesContainer;
