import {
  IconButton, Icon,
} from '@chakra-ui/react';
import { Globe, ChevronDown } from 'lucide-react';
import React from 'react';

import useScreenSize from '@app/components/Hooks/use-screen-size';
import { languagesById, languageList } from '@app/helpers/languages';
import { Button } from '@chakra-snippets/button';
import {
  MenuRoot, MenuTrigger, MenuContent, MenuSeparator, MenuRadioItemGroup, MenuRadioItem,
} from '@chakra-snippets/menu';

interface LanguageMenuProps {
  languagePreference: string;
  selectLanguage: (language: string) => void;
}

const LanguageMenu: React.FC<LanguageMenuProps> = ({ languagePreference, selectLanguage }) => {
  const isSmallScreen = useScreenSize();

  return (
    <MenuRoot size="md" positioning={{ sameWidth: true }}>
      {isSmallScreen ? (
        <MenuTrigger asChild>
          <IconButton variant="ghost" size="sm">
            <Globe />
          </IconButton>
        </MenuTrigger>
      ) : (
        <MenuTrigger asChild>
          <Button variant="ghost" size="sm">
            <Icon boxSize={5}>
              <Globe />
            </Icon>
            {languagesById[languagePreference] || 'en'}
            <ChevronDown />
          </Button>
        </MenuTrigger>
      )}
      <MenuContent
        whiteSpace="nowrap"
        maxHeight="400px"
        overflowY="scroll"
        defaultValue={languagesById[languagePreference] || 'en'}
        minW="200px"
        w="auto"
      >
        <MenuRadioItemGroup
          value={languagePreference}
          onValueChange={(e) => selectLanguage(e.value)}
        >
          {languageList.map((language, index) => (
            <>
              <MenuRadioItem key={language.id} value={language.id}>
                {language.label}
              </MenuRadioItem>
              {index === 0 && <MenuSeparator m={0} />}
            </>
          ))}
        </MenuRadioItemGroup>
      </MenuContent>
    </MenuRoot>
  );
};

export default LanguageMenu;
