/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Button from '../../../../Form/Button/Button';
import SetOfficeHours from './SetOfficeHours/SetOfficeHours';
import CreateOfficeHoursMsg from './CreateOfficeHoursMsg/CreateOfficeHoursMsg';
import { makeApiRequest } from '../../../../../actions/api/api';
import './OfficeHoursModal.less';
import { anyActiveDays } from '../../Utils';
import _ from 'lodash';

const OfficeHoursModal = ({ pageProps, toggleModal, contentRef }) => {
  const {
    success, error, pendingRequest, nextButtonDisabled,
  } = useSelector(
    (state) => ({
      success: state?.profile?.success,
      error: state?.profile?.error,
      pendingRequest: state?.profile?.pendingRequest,
      nextButtonDisabled: state?.profile?.nextButtonDisabled,
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const [canSubmit, setCanSubmit] = useState(false);
  const [showCreateOfficeHoursView, setShowCreateOfficeHoursView] = useState(false);
  const [reply, setReply] = useState(pageProps.reply);
  const [isEdit, setIsEdit] = useState(false);

  // Runs ONCE after initial rendering
  useEffect(() => {
    // edit mode
    if (_.has(pageProps, 'reply.id')) {
      setIsEdit(true);
    }
  }, []);

  useEffect(() => {
    if (success && success.length > 0) {
      dispatch({ type: 'RESET_PROFILE_REQUESTS' });
      toggleModal();
    }
    if (pendingRequest) {
      setCanSubmit(false);
    } else {
      handleCanSubmit();
    }
  }, [success, pendingRequest]);

  // Runs each time reply state changes
  // each time we update the reply object check if we are able to make a submission or not
  useEffect(() => {
    handleCanSubmit();
  }, [reply]);

  // if an API error gets returned then display for 2 seconds and then clear it from the redux store
  useEffect(() => {
    if (error && error.length > 0) {
      const timer = setTimeout(() => {
        dispatch({ type: 'RESET_PROFILE_REQUESTS' });
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  // check to see if the mandatory content has been entered into the form and if so enable submit button
  function handleCanSubmit() {
    if (
      _.has(reply, 'message.after.value')
      && reply.message.after.value.length > 0
      && _.has(reply, 'sendCriteria.days')
      && anyActiveDays(reply.sendCriteria.days)
      && !pendingRequest
      && !nextButtonDisabled
    ) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }

  function handleCancel() {
    dispatch({ type: 'RESET_PROFILE_REQUESTS' });
    toggleModal();
  }

  function handleSubmit(userId, reply) {
    if (isEdit) {
      dispatch(
        makeApiRequest('PUT_USERS_AUTOREPLIES', [userId, reply.id], reply),
      );
    } else {
      dispatch(makeApiRequest('POST_USERS_AUTOREPLIES', [userId], reply));
    }
  }

  return (
    <div className="office-hours-modal">
      {showCreateOfficeHoursView ? (
        <CreateOfficeHoursMsg reply={reply} setReply={setReply} contentRef={contentRef} />
      ) : (
        <SetOfficeHours reply={reply} setReply={setReply} contentRef={contentRef} />
      )}
      <div className="auto-replies-form-error">{error}</div>
      <div className="button-row">
        {showCreateOfficeHoursView ? (
          <Button
            className="cancel outline"
            onClick={() => setShowCreateOfficeHoursView(false)}
          >
            Back
          </Button>
        ) : (
          // eslint-disable-next-line react/jsx-no-bind
          <Button className="cancel outline" onClick={handleCancel}>
            Cancel
          </Button>
        )}
        {showCreateOfficeHoursView ? (
          <Button
            data-jest="save-ooo-button"
            className="save-ooo-button hymarley-button primary"
            disabled={!canSubmit}
            onClick={() => handleSubmit(pageProps.userId, reply)}
          >
            Save
          </Button>
        ) : (
          <Button
            data-jest="next-ooo-button"
            className="next-ooo-button hymarley-button primary"
            disabled={nextButtonDisabled}
            onClick={() => setShowCreateOfficeHoursView(true)}
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
};

export default OfficeHoursModal;
