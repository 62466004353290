import {
  HStack, ButtonGroup,
} from '@chakra-ui/react';
import React from 'react';

import DateInput from '@app/components/chakra/date-input';
import SimpleSelect from '@app/components/chakra/selects/simple-select';
import { Button } from '@chakra-snippets/button';
import { Field } from '@chakra-snippets/field';
import { Radio, RadioGroup } from '@chakra-snippets/radio';

interface CreateMassNotificationSchedulingProps {
  createButtonEnabled: boolean;
  handleChangeScheduleEnabled: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFormInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | {
      target: { name: string; value: Date | null | string }
    }
  ) => void;
  handleClickSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleBackStep: () => void;
  handleNextStep: () => void;
  isScheduleEnabled: boolean;
  minScheduledDatetimeLocalStr: string;
  showErrorMessage: boolean;
  formData: {
    scheduledDatetime: Date | null;
    scheduledTimezone: string;
  };
  contentRef: React.RefObject<HTMLDivElement>;
}

const CreateMassNotificationScheduling: React.FC<CreateMassNotificationSchedulingProps> = ({
  createButtonEnabled,
  handleChangeScheduleEnabled,
  handleFormInputChange,
  handleClickSubmit,
  handleBackStep,
  handleNextStep,
  isScheduleEnabled,
  minScheduledDatetimeLocalStr,
  showErrorMessage,
  formData,
  contentRef,
}) => {
  const handleDateInputChange = (date: Date | null) => {
    handleFormInputChange({
      target: {
        name: 'scheduledDatetime',
        value: date,
      },
    });
  };

  const handleTimezoneChange = (details: { value: string[] }) => {
    handleFormInputChange({
      target: {
        name: 'scheduledTimezone',
        value: details.value[0],
      },
    });
  };

  return (
    <>
      <div className="modal-container">
        <h3 className="modal-step-title">Step 2: Schedule Notification</h3>
        <p>
          You are able to schedule a notification to be sent out at a future time
          or you can start sending it right away.
        </p>
        <RadioGroup pt={1} value={isScheduleEnabled ? 'yes' : 'no'} size="sm">
          <HStack>
            <Radio
              id="scheduleEnabledYes"
              onChange={handleChangeScheduleEnabled}
              value="yes"
              mr={0}
            >
              Schedule at a specific time
            </Radio>
            <Radio
              id="scheduleEnabledNo"
              onChange={handleChangeScheduleEnabled}
              value="no"
              mr={0}
            >
              Create notification now
            </Radio>
          </HStack>
        </RadioGroup>
        {
          isScheduleEnabled ? (
            <>
              <h4 className="modal-section-title">Date/Time to Schedule</h4>
              <p>Select the date and time your notification begins sending:</p>
              <HStack gap={2}>
                <Field
                  label="Scheduled Send Date"
                  id="scheduledDateTime"
                >
                  <DateInput
                    id="scheduledDateTime"
                    selectedDate={formData.scheduledDatetime}
                    handleDateChange={handleDateInputChange}
                    includeTime
                    dateLimit="future"
                    placeholderText="Select date and time"
                    minDate={minScheduledDatetimeLocalStr
                      ? new Date(minScheduledDatetimeLocalStr)
                      : undefined}
                  />
                </Field>
                <Field
                  id="scheduledTimezone"
                  label="Destination Timezone"
                >
                  <SimpleSelect
                    id="scheduledTimezone"
                    onValueChange={handleTimezoneChange}
                    options={[{
                      value: 'US_EAST',
                      label: 'Eastern (US)',
                      selected: formData.scheduledTimezone === 'US_EAST',
                    }, {
                      value: 'US_CENTRAL',
                      label: 'Central (US)',
                      selected: formData.scheduledTimezone === 'US_CENTRAL',
                    }, {
                      value: 'US_MOUNTAIN',
                      label: 'Mountain (US)',
                      selected: formData.scheduledTimezone === 'US_MOUNTAIN',
                    }, {
                      value: 'US_PACIFIC',
                      label: 'Pacific (US)',
                      selected: formData.scheduledTimezone === 'US_PACIFIC',
                    }]}
                    placeholder="Select timezone"
                    contentRef={contentRef}
                  />
                </Field>
              </HStack>
            </>
          ) : null
        }
      </div>
      <ButtonGroup className="buttonRow">
        {
          showErrorMessage
            ? <div className="warningText errorText">Failed to create Mass Notification, please try again later!</div>
            : null
        }
        <Button
          onClick={handleBackStep}
          variant="outline"
        >
          Back
        </Button>
        {
          isScheduleEnabled ? (
            <Button
              onClick={handleNextStep}
              disabled={
                !createButtonEnabled || !formData.scheduledDatetime || !formData.scheduledTimezone
              }
            >
              Next
            </Button>
          ) : (
            <Button
              onClick={handleClickSubmit}
              disabled={!createButtonEnabled}
            >
              Create Now
            </Button>
          )
        }
      </ButtonGroup>
    </>
  );
};

export default CreateMassNotificationScheduling;
