import React from 'react';
import PropTypes from 'prop-types';
import { SquareArrowOutUpRight } from 'lucide-react';
import { Card } from '@himarley/unity';
import { Icon } from '@chakra-ui/react';
import { Button } from '@chakra-snippets/button';
import { Translate } from 'react-redux-i18n';
import Title from '../../../elements/text/Title/Title';
import Row from '../../../elements/Row/Row';

const handleOpenLink = (link) => {
  window.open(
    link,
    '_blank',
  );
};

const IntegrationRow = ({
  Logo, title, description, link, id,
}) => (
  <Row>
    <Card className="app-integration" data-testid={id}>
      <Row type="justify">
        <Logo />
        <div>
          <Title type="subheader"><b>{title}</b></Title>
          <p><Translate value={description} /></p>
        </div>
        <Button
          onClick={() => handleOpenLink(link)}
          variant="outline"
        >
          Learn More
          <Icon><SquareArrowOutUpRight /></Icon>
        </Button>
      </Row>
    </Card>
  </Row>
);

IntegrationRow.propTypes = {
  Logo: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default IntegrationRow;
